import FormatService from "../../services/FormatService";

function AnnuityPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'investment', label: 'Jednorázový vklad (€)', value: values.investment},
        {key: 'interestRate', label: 'Očakávaný ročný výnos (%)', value: values.interestRate}
    ];

    switch (values.mode){
        case 'payment':
            inputs.push({key: 'period', label: 'Doba "rozpustenia" vkladu na sporiacom účte', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }});
            break;
        case 'period':
            inputs.push({key: 'annuity', label: 'Výška mesačnej renty (€)', value: values.annuity});
            break;
        default: break;
    }

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <div class="card">
                <div class="card-block">
                     <h3>
                         ${values.mode === 'payment' ? 'Výška mesačnej renty je' : ''}
                         ${values.mode === 'period' ? 'Doba "rozpustenia" vkladu na sporiacom účte je' : ''}
                         <strong class="pull-right">
                             ${values.mode === 'payment' ?  '<strong>'+FormatService.round(result.annuity)+'</strong> €' : ''}
                             ${values.mode === 'period' ? '<strong>'+result.period + '</strong> mesiacov' : ''}
                         </strong>
                     </h3>
                </div>
            </div>
        </div
    </div>
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default AnnuityPdfExport;