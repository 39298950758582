import {Box, Button, Form, FormField, Heading, RadioButtonGroup, Text, TextInput} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import RpmnHelp from "./RpmnHelp";
import RpmnPdfExport from "./RpmnPdfExport";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function Rpmn(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'Rpmn';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Výpočet RPMN',
        period: 30,
        periodUnits: 'y',
        loanAmount: 100000,
        fee: 0,
        feeDay: 30,
        paymentAmount: 350,
        paymentDay: 30,
        totalCosts: 100,
        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };
    const defaultResult = {totalCosts: null, yearPercentageCostsRate: null};
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', defaultResult, calcStateVersion);

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);

    function saveCalcDetail(values, result){
        const calcDetailState = {
            values:values,
            result:result
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        let reqData = {
            loanAmount: values.loanAmount,
            fee: values.fee,
            feeDay: values.feeDay,
            paymentAmount: values.paymentAmount,
            paymentDay: values.paymentDay,
            periodMonths: periodMonths
        };

        setValues(Object.assign({}, values, {activeRequest: true}));
        ApiService.call('post', '/v2/rpmn', reqData)
            .then((res) => {
                    setResult(res);
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    saveCalcDetail(values, res);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'rpmn';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: 'Ročná percentuálna miera nákladov pre spotrebiteľský úver',
            filename: 'calc-export',
            content: RpmnPdfExport({values:values,result:result,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><RpmnHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
            <Form
                value={values}
                onChange={nextValue => {
                    setValues(nextValue);
                    saveCalcDetail(nextValue, result);
                }}
                onReset={() => {
                    setValues(defaultValues);
                    setResult(defaultResult);
                    saveCalcDetail(defaultValues, defaultResult);
                }}
                onSubmit={({ value }) => onFormSubmit(value) }
            >
                <Box direction={'row'} align={'center'} wrap>
                    <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Výška úveru (€)" width={'full'}>
                            <TextInput name="loanAmount" type={'number'} />
                        </FormField>
                    </Box>
                    <FormField label="Dĺžka úveru" width={'auto'}>
                        <TextInput name="period" type="number" min="1" step="1" required={true} />
                    </FormField>
                    <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                        {value: 'm',label: "Mesiacov"},
                        {value: 'y',label: "Rokov"},
                    ]} />
                </Box>

                <Box direction={'row-responsive'}>
                    <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Výška splátky (€)" width={'full'}>
                            <TextInput name="paymentAmount" type={'number'} />
                        </FormField>
                    </Box>
                    <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Deň mesiaca, keď zaplatíme splátku" width={'full'}>
                            <TextInput name="paymentDay" type={'number'} min={1} max={30} placeholder={'1-30'} />
                        </FormField>
                    </Box>
                </Box>

                <Box direction={'row-responsive'}>
                    <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Jednorázový poplatok (€)" width={'full'}>
                            <TextInput name="fee" type={'number'} />
                        </FormField>
                    </Box>

                    <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Deň mesiaca, keď zaplatíme poplatok" width={'full'}>
                            <TextInput name="feeDay" type={'number'} min={1} max={30} placeholder={'1-30'} />
                        </FormField>
                    </Box>
                </Box>

                <Box direction="row" gap="medium" margin={{top:'medium'}}>
                    <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                    <Button type="reset" label="Nastaviť predvolené" />
                </Box>
            </Form>
        </Box>

            {result && result.yearPercentageCostsRate &&
            <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{top: 'medium', bottom: 'xsmall'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                <Box pad="small" alignContent={'between'} basis={'3/4'}>
                    <Text>Ročná percentuálna miera nákladov</Text>
                </Box>
                <Box pad="small" align={'end'} basis={'1/4'}>
                    <Heading level={2} fill={true} margin={'none'}>
                        <strong>{result.yearPercentageCostsRate} <span>%</span></strong>
                    </Heading>
                </Box>
            </Box>
            }

            {result && result.totalCosts &&
            <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{top: 'xsmall', bottom: 'medium'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                <Box pad="small" alignContent={'between'} basis={'3/4'}>
                    <Text>Celkové náklady</Text>
                </Box>
                <Box pad="small" align={'end'} basis={'1/4'}>
                    <Heading level={2} fill={true} margin={'none'}>
                        <strong>{result.totalCosts} <span>€</span></strong>
                    </Heading>
                </Box>
            </Box>
            }
        </Box>
    );
}

export default Rpmn;