import { Box, CheckBoxGroup, Heading, RadioButtonGroup, Text, TextInput } from "grommet";
import { useEffect, useState } from "react";
import LoanInsurancePdfExport from "./LoanInsurancePdfExport";

const LoanInsuranceColumnRowSelection = ({template}) => {

    const [selectedRowOption, setSelectedRowOption] = useState(getBaseRowOptions()[0].value)
    const [selectedCustomRowOptions, setSelectedCustomRowOptions] = useState([getCustomRowOptions()[0].value])
    const [selectedSpecifiedRows, setSelectedSpecifiedRows] = useState("");

    function getBaseRowOptions(){
        return [
            {
                label: "Všetky",
                value: LoanInsurancePdfExport.BASE_ROW_OPTION_ALL,
            },
            {
                label: "Prispôsobiť",
                value: LoanInsurancePdfExport.BASE_ROW_OPTION_CUSTOM,
            },   
        ]
    }
    function getCustomRowOptions(){
        return [
            {
                label: "1. a posledný riadok",
                value: LoanInsurancePdfExport.CUSTOM_ROW_OPTION_FIRST_LAST_ROW
            },
            {
                label: "zobraziť výročia",
                value: LoanInsurancePdfExport.CUSTOM_ROW_OPTION_VYROCIA
            },
            {
                label: "Vlastná voľba riadkov",
                value: LoanInsurancePdfExport.CUSTOM_ROW_OPTION_CUSTOM
            }    
        ]
    }

    function onSelectedCustomRowOption(value){
        if(!value.includes(LoanInsurancePdfExport.CUSTOM_ROW_OPTION_CUSTOM)){
            setSelectedSpecifiedRows("")
        }
        setSelectedCustomRowOptions(value)
    }


    useEffect(() => {
        if(template){
            setSelectedRowOption(template.contents.state.row_option )
            template.contents.state.row_custom_options && setSelectedCustomRowOptions(template.contents.state.row_custom_options)
            template.contents.state.row_custom_option_row_num && setSelectedSpecifiedRows(template.contents.state.row_custom_option_row_num)
        }
    },[template])

    const baseRowOptions = getBaseRowOptions();
    const customRowOptions = getCustomRowOptions();

    return (
        <>
        <Heading level={"5"} margin={{bottom:'xsmall'}}>Nastavenie</Heading>
        <Box direction={'row'} gap="large">

            <Box direction={'column'} basis="auto">
            <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'small', top:'small'}}>
                <Text color={'dark-5'}  margin={{bottom: 'medium'}} textAlign={'center'}>Riadky</Text>
                <RadioButtonGroup
                    name="row_option"
                    options={baseRowOptions}
                    value={selectedRowOption}
                    onChange={(event) => setSelectedRowOption(event.target.value)}
                />
            </Box>
            {selectedRowOption === LoanInsurancePdfExport.BASE_ROW_OPTION_CUSTOM &&                         
                <Box basis={'auto'} gap="medium" round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                <CheckBoxGroup
                    onChange={(e) => onSelectedCustomRowOption(e.value)}
                    options={customRowOptions} 
                    valueKey={'value'} 
                    value={selectedCustomRowOptions}  
                    name="row_custom_options"  
                />  
                {selectedCustomRowOptions.includes(LoanInsurancePdfExport.CUSTOM_ROW_OPTION_CUSTOM) &&                             
                    <TextInput
                        name="row_custom_option_row_num"
                        placeholder="príklad: 1-5, 8, 12, 15-19"
                        value={selectedSpecifiedRows}
                        pattern="[0-9-, ]*"
                        required
                        onChange={event => setSelectedSpecifiedRows(event.target.value)}
                    />
                }    
                </Box>                
            } 
        </Box>
    </Box>
    </>
    )    
}

export default LoanInsuranceColumnRowSelection;