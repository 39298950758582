import AuthService from "../services/AuthService";

const authData = AuthService.getAuthData();
const initialState = authData ? authData : null;

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case 'auth/login': return AuthService.getAuthData()
        default:
            return state
    }
}