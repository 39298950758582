import {Box} from 'grommet';
import React from "react";

function SavingIzpHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie výhodnosti jednotlivých sporiacich programov a IŽP. Porovnávanie pozostáva
            výhradne z porovnania poplatkových štruktúr pri zvolenom investičnom horizonte, mesačnej platbe a
            investičnej stratégii,a teda nezohľadňuje krytie, plnenie, servis, atď.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre sporenia - platbu, investičný horizont a očakávaný výnos.</li>
            <li>V prípade, že chcete počítať aj s volatilitou porfólia, do ktorého sa bude investovať, nastavte aj
                volatilitu, resp. vyberte jednu z preddefinovaných investičných stratégií.</li>
            <li>Ak máte záujem, môžete nastaviť aj stabilizačnú fázu, počas ktorej sa nakupuje konzervatívnejšie
                portfólio a zároveň už nakúpené podiely sa postupne doň presúvajú. Stabilizačná fáza je charakterizovaná
                obdobím v rokoch, výnosom a volatilitou stabilizačného portfólia.</li>
            <li>V prípade záujmu nastavte mimoriadne vklady teraz, prípadne neskôr v tabuľke</li>
            <li>Kliknite na <b>Vypočítať</b></li>
            <li>Zvoľte prdoukty, ktoré plánujete porovnávať</li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky pozostáva z troch nasledovných častí:</p>
        <ul>
            <li>Graf nad tabuľkou zobrazuje vývoj hodnoty zvoleného portfólia (jednorázovej investície). </li>
            <li>Tabuľka zobrazuje odkupnú hodnotu jednotlivých sporiacich programov/IŽP. V prvých riadkoch tabuľky vidíme
                priemerný ročný čistý výnos pravidelnej investície a hodnotu účtu na konci investičného horizontu.</li>
            <li>Graf pod tabuľkou zobrazuje vklady a vývoj odkupnej hodnoty účtov vybraných sporiacich programov/IŽP počas
                investičného horizontu.</li>
        </ul>
        <br /><br />
    </Box>);
}

export default SavingIzpHelp;
