import {Box, Button, Heading} from 'grommet';
import * as Icons from "grommet-icons";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/AuthService";

function Login(props) {

    const [active, setActive] = useState(true);
    const history = useHistory();

    useEffect(() => {
        AuthService.clearAuthData();
    },[]);

    function generateRandomString(len){
        const chars = [..."abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"];
        return [...Array(len)].map(i=>chars[Math.random()*chars.length|0]).join``;
    }

    async function generateCodeChallenge(code_verifier){
        //code_challenge = base64urlEncode(SHA256(ASCII(code_verifier)))
        const encoder = new TextEncoder();
        const data = encoder.encode(code_verifier);
        const hash = await window.crypto.subtle.digest("SHA-256", data);
        return btoa(String.fromCharCode.apply(null, new Uint8Array(hash)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    async function ugLoginAction(e){
        if (process.env.REACT_APP_DEVMOCK==='true'){
            setTimeout(function(){ history.push('/login-callback')}, 1000);
            return true;
        }
        const code_verifier = generateRandomString(120);
        const code_challenge = await generateCodeChallenge(code_verifier);
        localStorage.setItem('ugc_cv', code_verifier)
        setActive(false);
        const qParams = [
            `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
            `redirect_uri=${AuthService.getRedirectUrl()}`,
            `response_type=code`,
            `code_challenge=${code_challenge}`,
            `code_challenge_method=S256`,
            `scope=${process.env.REACT_APP_OAUTH_SCOPE}`,
            `state=umbrella-group`
        ].join("&");

        try {
            const url = `${process.env.REACT_APP_INTRANET_API_URL}/oauth/authorize?${qParams}`
            window.location.assign(url);
        } catch (e) {
            console.error(e);
            setActive(true);
        }
    }

    return (
        <Box fill={true} direction='column' alignContent='center' align='center' justify='center' background={'light-1'}>
            <Heading level={2}>Umbrella Group kalkulačka</Heading>
            <Box width='large' border={true} align='center' pad='medium' animation='fadeIn' background={'white'}>
                <Button primary disabled={!active} margin='medium' label="PRIHLÁSIŤ SA" icon={<Icons.Key />} onClick={ugLoginAction} />
            </Box>
        </Box>
    );
}

export default Login;
