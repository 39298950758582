import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CheckBox,
    Form,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    Select,
    TextInput
} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SavingIzpHelp from "./SavingIzpHelp";
import SavingIzpPdfExport from "./SavingIzpPdfExport";
import HighchartsReact from "highcharts-react-official";
import CalcDetailService from "../../services/CalcDetailService";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import FormatService from "../../services/FormatService";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import PdfExportModal from '../SavedPdfExportTemplates/PdfExportModal';
import ColumnRowSelection from './SavingIzpColumnRowSelection';

function SavingIzp(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'SavingIzp';
    const calcStateVersion = 2;

    HighchartsExporting(Highcharts);

    function ExtraPayment(month, payment){
        return {
            month: month,
            payment: payment,
        };
    }

    function EicProductData(bonds=null,cash=null,equities=null,mixed=null,fee=null,feeFromPayment=null,feePeriodInMonths=null){
        return {
            bonds: bonds,
            cash: cash,
            equities: equities,
            mixed: mixed,
            fee: fee,
            feeFromPayment: feeFromPayment,
            feePeriodInMonths: feePeriodInMonths
        };
    }

    const investmentStrategiesOptions = [
        {id: 1, name:'Superdynamická', earnings:11, sigma:18},
        {id: 2, name:'Dynamická', earnings:7, sigma:13},
        {id: 3, name:'Balancovaná', earnings:5, sigma:3},
        {id: 4, name:'Vlastná', earnings:null, sigma:null},
    ]
    const defaultValues = {
        title: 'Investičné produkty',
        payment: 50,
        earnings: 0,
        periodInYears: 30,
        sigma: 0,
        earningsStabilizationPhase: 3,
        sigmaStabilizationPhase: 1,
        yearsOfStabilizationPhase: 5,
        strategyObject: investmentStrategiesOptions[0],
        stabilizationPhase: false,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const filteredResultInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['filteredResult']) ? state.CalcDetail[calcStateKey]['filteredResult'] : null;
    const portfolioInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['portfolio']) ? state.CalcDetail[calcStateKey]['portfolio'] : null;
    const investorsListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['investorsList']) ? state.CalcDetail[calcStateKey]['investorsList'] : [];
    const activeProductsListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['activeProductsList']) ? state.CalcDetail[calcStateKey]['activeProductsList'] : [];
    const extraPaymentsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['extraPayments']) ? state.CalcDetail[calcStateKey]['extraPayments'] : [];
    const eicProductDataInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['eicProductData']) ? state.CalcDetail[calcStateKey]['eicProductData'] : null;
    const portfolioChartOptionsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['portfolioChartOptions']) ? state.CalcDetail[calcStateKey]['portfolioChartOptions'] : null;
    const chartOptionsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['chartOptions']) ? state.CalcDetail[calcStateKey]['chartOptions'] : null;


    const extraPaymentFormInitialState = {};
    const eicFormInitialState = {
        strategyObject: {id: 1, name:'Superdynamická', equities:100, bonds:0, mixed:0, cash:0},
        bonds: 0,
        cash: 0,
        equities: 0,
        mixed: 0,
        fee: 3.75,
        feeFromPayment: true,
        feePeriodInMonths: 1
    };

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [filteredResult, setFilteredResult] = useState(filteredResultInitialState);
    const [portfolio, setPortfolio] = useState(portfolioInitialState);
    const [investorsList, setInvestorsList] = useState(investorsListInitialState);
    const [activeProductsList, setActiveProductsList] = useState(activeProductsListInitialState);
    const [extraPayments, setExtraPayments] = useState(extraPaymentsInitialState);
    const [eicProductData, setEicProductData] = useState(eicProductDataInitialState);
    const [portfolioChartOptions, setPortfolioChartOptions] = useState(portfolioChartOptionsInitialState);
    const [chartOptions, setChartOptions] = useState(chartOptionsInitialState);

    const [eicModal, setEicModal] = useState(false);
    const [eicForm, setEicForm] = useState(eicFormInitialState);
    const [extraPaymentModal, setExtraPaymentModal] = useState(false);
    const [extraPaymentForm, setExtraPaymentForm] = useState(extraPaymentFormInitialState);
    const [portfolioChart, setPortfolioChart] = useState(null);
    const [chart, setChart] = useState(null);
    const [activeRequest, setActiveRequest] = useState(false);

    const [showExportPdfModal, setShowExportPdfModal] = useState(false);

    function trySetChart(chart){
        if (chart && chart.renderer && chart.renderer.forExport===true){
            return false;
        }
        setChart(chart);
    }

    function trySetPortfolioChart(chart){
        if (chart && chart.renderer && chart.renderer.forExport===true){
            return false;
        }
        setPortfolioChart(chart);
    }

    function saveCalcDetail(values, result, filteredResult, portfolio, investorsList, activeProductsList, extraPayments, eicProductData, portfolioChartOptions, chartOptions){
        const calcDetailState = {
            values:values,
            result:result,
            filteredResult:filteredResult,
            portfolio:portfolio,
            investorsList:investorsList,
            activeProductsList:activeProductsList,
            extraPayments:extraPayments,
            eicProductData:eicProductData,
            portfolioChartOptions:portfolioChartOptions,
            chartOptions:chartOptions,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        if (portfolio){
            saveCalcDetail(values, result, filteredResult, portfolio, investorsList, activeProductsList, extraPayments, eicProductData, portfolioChartOptions, chartOptions);
        }
        // eslint-disable-next-line
    }, [values, result, filteredResult, investorsList, activeProductsList, extraPayments, eicProductData, portfolioChartOptions, chartOptions]);

    const reloadInvestorsList = useCallback(() => {
        ApiService.getInvestorsList(ApiService.consts().investor.savings)
            .then((res) => {
                    setInvestorsList(res.investors);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
    }, []);

    useEffect(() => {
        if (!investorsList || investorsList.length===0) {
            reloadInvestorsList();
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (portfolio){
            onRecalculateSubmit();
            updatePortfolioChartOptions(portfolio);
        }
        // eslint-disable-next-line
    }, [portfolio, eicProductData, extraPayments]);

    useEffect(() => {
        transformResult();
        // eslint-disable-next-line
    }, [result, activeProductsList, extraPayments]);

    useEffect(() => {
        if (filteredResult){
            updateChartOptions(filteredResult);
        }
        // eslint-disable-next-line
    }, [filteredResult]);

    function transformResult(){
        if (result){
            let newResult = Object.assign({}, result);
            newResult.results = newResult.results.filter((product)=>{
                return activeProductsList.find((activeProduct)=>{return activeProduct.id===product.productId;});
            }).map((activeProductResult)=>{
                activeProductResult.product = getProductById(activeProductResult.productId);
                return activeProductResult;
            });
            newResult.extraPayments = [...newResult.paymentsTotalForMonth.map((val, index)=>{
                const payment = extraPayments.find(p=>(p.month*1)===(1*index+1));
                return (payment ? payment.payment : 0);
            })]
            setFilteredResult(newResult);
            if (window.debug){
                console.log('Transforming result to filteredResult', newResult)
            }
        }
    }

    function updatePortfolioChartOptions(portfolio){
        let periodInMonths = portfolio.growFond.length;
        let options = {
            title: {
                text: ''
            },
            legend: {
                layout: 'horizontal',
                align: 'left',
                verticalAlign: 'top',
                floating: true,
                backgroundColor: '#FFFFFF'
            },
            credits: {
                enabled: false
            },
            chart: {
                height: 200 + 'px'
            },
            exporting: {
                sourceWidth: 1000,
                sourceHeight: 400,
            },
            yAxis: {title: {text: "Hodnota"}},
            xAxis: {
                title: {text: "Doba investovania"},
                categories: [...Array(periodInMonths).keys()].map((val, index)=>{ return (index+1); })
            },
            series: [
                {
                    name: 'Rastový fond',
                    type: 'line',
                    data: portfolio.growFond
                },
            ],
        }
        if (values.stabilizationPhase){
            options.series.push({
                name: 'Stabilizačný fond',
                type: 'line',
                data: portfolio.stabilizationFond
            });
        }

        setPortfolioChartOptions(options);
    }

    function updateChartOptions(filteredResult=null){
        let periodInMonths = filteredResult.paymentsTotalForMonth.length;
        let options = {
            title: {
                text: ''
            },
            legend: {
                layout: 'horizontal',
            },
            credits: {
                enabled: false
            },
            exporting: {
                sourceWidth: 1000,
                sourceHeight: 400,
            },
            yAxis: {title: {text: "Hodnota"}},
            xAxis: {
                title: {text: "Doba investovania"},
                categories: [...Array(periodInMonths).keys()].map((val, index)=>{ return (index+1); })
            },
            series: [
                {
                    name: 'Vklad',
                    type: 'line',
                    data: filteredResult.paymentsTotalForMonth
                },
            ],
        }

        activeProductsList.map((product)=>{
            const p = getProductResultById(product.id);
            options.series.push({
                name: product.name,
                type: 'line',
                data: p ? p.monthResults : []
            });
            return product;
        });

        setChartOptions(options);
    }

    function getProductResultById(id){
        if (!result || !result.results){
            return null;
        }
        const p = result.results.find((p)=>{return p.productId === id;});
        return p ? p : null;
    }

    function getProductById(id){
        if (!investorsList){
            return null;
        }
        const p = investorsList.find((p)=>{return p.id === id;});
        return p ? p : null;
    }

    function onFormSubmit(values){
        const reqData = {
            earnings: values.strategyObject.id===4 ? values.earnings : values.strategyObject.earnings,
            sigma: values.strategyObject.id===4 ? values.sigma : values.strategyObject.sigma,
            periodInYears: values.periodInYears,
            earningsStabilizationPhase: values.earningsStabilizationPhase,
            sigmaStabilizationPhase: values.sigmaStabilizationPhase,
            yearsOfStabilizationPhase: values.stabilizationPhase ? values.yearsOfStabilizationPhase : 0
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/savings/ILI/portfolio', reqData)
            .then((res) => {
                    setPortfolio(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    function onRecalculateSubmit(vals=null){
        if (window.debug){
            console.log('Recalculating after change')
        }
        let _values = vals ? vals : values;
        const reqData = {
            payment: _values.payment,
            periodInYears: _values.periodInYears,
            portfolio: portfolio,
            yearsOfStabilizationPhase: _values.stabilizationPhase ? _values.yearsOfStabilizationPhase : 0
        };

        if (eicProductData){
            reqData.eicProductData = eicProductData;
        }

        if (extraPayments && extraPayments.length){
            reqData.extraPayment = extraPayments.map((payment)=>{
                return {
                    month: payment.month-1,
                    payment: 1*payment.payment,
                };
            });
        }

        setActiveRequest(true);
        ApiService.call('post', '/savings/ILI', reqData)
            .then((res) => {
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'investicne-produkty';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(options){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SavingIzpPdfExport.generatePdf({values:values,result:filteredResult,activeProductsList:activeProductsList,extraPayments:extraPayments,portfolioChart:portfolioChart,chart:chart, options}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function openAddEditExtraPaymentModal(month, val){
        setExtraPaymentForm(Object.assign({}, extraPaymentForm,ExtraPayment(month, val)));
        setExtraPaymentModal(true);
    }

    function onExtraPaymentFormSubmit(data){
        const newExtraPayments = [...extraPayments.filter((op)=>op.month!==data.month), ExtraPayment(data.month, data.payment)];
        setExtraPayments(newExtraPayments);
        setExtraPaymentModal(false);
        setExtraPaymentForm(extraPaymentFormInitialState);
    }

    function removeExtraPayment(index){
        if (extraPayments && extraPayments[index]){
            const newExtraPayments = [...extraPayments.slice(0, index), ...extraPayments.slice(index + 1)]
            setExtraPayments(newExtraPayments);
        }
    }

    function openEicModal(data=null){
        setEicForm(eicFormInitialState);
        setEicModal(true);
    }

    function onEicFormSubmit(data){
        const newEicProductData = EicProductData(
            data.strategyObject.id===4 ? data.bonds : data.strategyObject.bonds,
            data.strategyObject.id===4 ? data.cash : data.strategyObject.cash,
            data.strategyObject.id===4 ? data.equities : data.strategyObject.equities,
            data.strategyObject.id===4 ? data.mixed : data.strategyObject.mixed,
            data.fee,
            data.feeFromPayment,
            data.feePeriodInMonths
        );
        setEicProductData(newEicProductData);

        //after we write the options, we can finally check the eic investor option
        const newInvestorsList = investorsList.map((i)=>{
            if (i.id === 'EIC'){
                i = Object.assign(i, {isChecked: true});
            }
            return i;
        });
        setInvestorsList([...newInvestorsList]);
        setActiveProductsList([...newInvestorsList.filter((p)=>{return p.isChecked;})] );
        setEicModal(false);
    }

    function updateInvestor(investor, dataToSet){
        //pri eic potrebujeme nastavit dalsie parametre.
        // takze ich zmazeme ked to chceme vypnut a vypytame ked ich chceme zapnut.
        // za pridanie dat je zodpovedny formular.
        if (investor.id==='EIC' && dataToSet.isChecked===true && !eicProductData){
            investor.isChecked = false;
            openEicModal();
            return false;
        }
        if (investor.id==='EIC' && dataToSet.isChecked===false){
            setEicProductData(null);
        }

        const newInvestorsList = investorsList.map((i)=>{
            if (i.id === investor.id){
                i = Object.assign(i, dataToSet);
            }
            return i;
        });
        setInvestorsList([...newInvestorsList]);
        setActiveProductsList([...newInvestorsList.filter((p)=>{return p.isChecked;})] );
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state.values);
            setResult(res.contents.state.result);
            setFilteredResult(res.contents.state.filteredResult);
            setPortfolio(res.contents.state.portfolio);
            setInvestorsList(res.contents.state.investorsList);
            setActiveProductsList(res.contents.state.activeProductsList);
            setExtraPayments(res.contents.state.extraPayments);
            setEicProductData(res.contents.state.eicProductData);
            setPortfolioChartOptions(res.contents.state.portfolioChartOptions);
            setChartOptions(res.contents.state.chartOptions);
        }
        setShowModal(false);
    }

    function onSavinglzipExportModal(res){
        if(res){
            toast.success('Šablóna bola uložená', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setShowExportPdfModal(false);
    }

    const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state.values);
                        setResult(res.contents.state.result);
                        setFilteredResult(res.contents.state.filteredResult);
                        setPortfolio(res.contents.state.portfolio);
                        setInvestorsList(res.contents.state.investorsList);
                        setActiveProductsList(res.contents.state.activeProductsList);
                        setExtraPayments(res.contents.state.extraPayments);
                        setEicProductData(res.contents.state.eicProductData);
                        setPortfolioChartOptions(res.contents.state.portfolioChartOptions);
                        setChartOptions(res.contents.state.chartOptions);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SavingIzpHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={{
                                                 values:values,
                                                 result:result,
                                                 filteredResult:filteredResult,
                                                 portfolio:portfolio,
                                                 investorsList:investorsList,
                                                 activeProductsList:activeProductsList,
                                                 extraPayments:extraPayments,
                                                 eicProductData:eicProductData,
                                                 portfolioChartOptions:portfolioChartOptions,
                                                 chartOptions:chartOptions,
                                             }}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <PdfExportModal
                        show={showExportPdfModal}
                        closeaction={(res) => onSavinglzipExportModal(res)}
                        values={values}
                        pinKey={pinKey}
                        exportPdf={exportPdf}
                        renderColumnRowSelection={(template) => <ColumnRowSelection template={template} />}
                        disabledSubmitButton={values.pdfGenerating}
                    >
                        <Button margin={{right:'xsmall'}} size={'small'} secondary disabled={!result} label={ <UgPdfIcon />} onClick={()=>{setShowExportPdfModal(true)}} />
                    </PdfExportModal>
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        setFilteredResult(null);
                        setPortfolio(null);
                        setActiveProductsList([]);
                        setExtraPayments([]);
                        const newInvestorsList = investorsList.map((i)=>{ i.isChecked=false; return i; });
                        setInvestorsList(newInvestorsList);
                        saveCalcDetail(defaultValues, null, null, null, newInvestorsList, [], []);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField width={'full'} label="Doba investovania (r)" >
                                <TextInput name="periodInYears" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Platba (€)" width={'full'}>
                                <TextInput name="payment" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Investičná stratégia</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Stratégia" width={'full'}>
                                <Select options={investmentStrategiesOptions}
                                        required
                                        name="strategyObject"
                                        labelKey="name"
                                        valueKey="id"
                                        dropHeight="large"
                                        value={values.strategyObject}
                                        placeholder="Výber investora"
                                        emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>

                        {values.strategyObject && values.strategyObject.id!==4 && <Box basis={'1/3'} direction={'row'} align={'center'}><Box direction={'column'} alignItems={'center'} wrap>
                            <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Výnos: {values.strategyObject.earnings}(%)</Box>
                            <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Volatilita: {values.strategyObject.sigma}(%)</Box>
                        </Box></Box>
                        }

                        {values.strategyObject && values.strategyObject.id===4 && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výnos (%)" width={'full'}>
                                <TextInput name="earnings" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}
                        {values.strategyObject && values.strategyObject.id===4 && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Volatilita (%)" width={'full'}>
                                <TextInput name="sigma" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}

                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Stabilizačná fáza</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Stabilizačná fáza" width={'full'}>
                                <CheckBox name="stabilizationPhase" label="Zapnúť" />
                            </FormField>
                        </Box>


                        {values.stabilizationPhase && <Box direction={'row'} alignItems={'center'} wrap>
                            <FormField width={'auto'} label="Doba (r)" >
                                <TextInput name="yearsOfStabilizationPhase" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>}

                        {values.stabilizationPhase && <Box direction={'row'} alignItems={'center'} wrap>
                            <FormField width={'auto'} label="Výnos (%)" >
                                <TextInput name="earningsStabilizationPhase" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}

                       {values.stabilizationPhase && <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Volatilita (%)" width={'full'}>
                                <TextInput name="sigmaStabilizationPhase" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}
                    </Box>

                    {portfolio && portfolioChartOptions && <Box>
                        <Box pad={'small'} margin={{vertical: 'small'}} round={'small'} border={true} background={'white'}>
                            <Box direction="column" basis={'full'} margin={{vertical: 'medium'}} round={'small'}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={portfolioChartOptions}
                                    callback={trySetPortfolioChart}
                                />
                            </Box>
                        </Box>
                    </Box>}

                    <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openAddEditExtraPaymentModal(ExtraPayment(1, 0))} icon={<Icons.AddCircle />} label="Pridať mimoriadny vklad" />
                        </Box>
                        {extraPayments && extraPayments.map((payment, pindex)=>{
                            return <Card elevation="none" border={true} key={"extraPaymentCard_"+pindex} width="small" background={"light-1"} margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.Calendar />&nbsp;&nbsp;&nbsp;Mesiac:</Box></td>
                                            <td><span><b>{payment.month}</b></span></td>
                                        </tr>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.Currency />&nbsp;&nbsp;&nbsp;Vklad:</Box></td>
                                            <td><span><b>{payment.payment}</b> €</span></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </CardBody>
                                <CardFooter background={"light-2"}>
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeExtraPayment(pindex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {filteredResult && filteredResult.results && <Box margin={{vertical: 'medium'}} flex={false} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} round={'small'} background={'white'} align={'start'} alignContent={'between'}>
                    <Box margin={{right:'small'}}>
                        <Heading level={3}>Aktuálne produkty</Heading>
                        <Box wrap>
                            {investorsList.filter(investor => investor.isActual).map((investor, i)=>
                                <Box pad={{right:'xsmall', bottom:'small'}}><CheckBox key={'checkbox-'+investor.id} label={investor.name} checked={investor.isChecked}
                                     onChange={() => updateInvestor(investor, {isChecked: !investor.isChecked})} /></Box>)}
                        </Box>
                        <Heading level={3}>Staršie produkty</Heading>
                        <Box wrap>
                            {investorsList.filter(investor => !investor.isActual).map((investor, i)=>
                                <Box pad={{right:'xsmall', bottom:'small'}}><CheckBox key={'checkbox-'+investor.id} label={investor.name} checked={investor.isChecked}
                                     onChange={() => updateInvestor(investor,{isChecked: !investor.isChecked})} /></Box>)}
                        </Box>
                    </Box>
                    <div className="table-responsive">
                        <table className="table styled-table text-center">
                            <thead className="sticky-header">
                            <tr>
                                <th>Obdobie (m)</th>
                                <th>Mim. vklad (€)</th>
                                <th>Zaplatené (€)</th>
                                {filteredResult.results.map((product, c)=><th key={'header_'+c}>{product.product.name}</th>)}
                            </tr>
                            <tr>
                                <th class="text-left" colSpan={3}>Reálny výnos p.a. (%)</th>
                                {filteredResult.results.map((product, c)=><td className="table-cell-important" key={'val_rv_'+c}>{product.realProfit}</td>)}
                            </tr>
                            {filteredResult.paymentsTotalForMonth.filter((row,r)=>r===filteredResult.paymentsTotalForMonth.length-1).map((row)=>{
                                const r = filteredResult.paymentsTotalForMonth.length-1; //last row
                                return <tr key={'row_sum_'+r}>
                                    <th class="text-left">Hodnota na konci ({r+1}.)</th>
                                    <th>{filteredResult.extraPayments[r]}</th>
                                    <th>{FormatService.round(filteredResult.paymentsTotalForMonth[r])}</th>
                                    {filteredResult.results.map((product, c)=><th style={{padding: "unset"}} key={'val_'+r+'_'+c}><div><th style={{display: "block"}} className="table-cell-highlighted">{product.monthResults[r]}</th></div></th>)}
                                </tr>
                            })}
                            </thead>
                            <tbody>

                            {filteredResult.paymentsTotalForMonth.map((row, r)=>{
                                return <tr key={'row'+r}>
                                    <th>{r+1}.</th>
                                    <td>
                                        <div className="text-editable"
                                             onClick={() => openAddEditExtraPaymentModal(r+1, filteredResult.extraPayments[r])}
                                        >{filteredResult.extraPayments[r]}</div>
                                    </td>
                                    <td>{FormatService.round(filteredResult.paymentsTotalForMonth[r])}</td>
                                    {filteredResult.results.map((product, c)=><td key={'val_'+r+'_'+c}>{product.monthResults[r]}</td>)}
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>}

            {filteredResult && chartOptions && <Box>
                <Box pad={'small'} margin={{bottom: 'medium'}} round={'small'} border={true} background={'white'}>
                    <Box direction="column" basis={'full'} margin={{vertical: 'medium'}} round={'small'}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            callback={trySetChart}
                        />
                    </Box>
                </Box>
            </Box>}

            {extraPaymentModal && (<Layer onEsc={() => setExtraPaymentModal(false)} onClickOutside={() => setExtraPaymentModal(false)}>
                <Box pad="medium">
                    <Form
                        value={extraPaymentForm}
                        onChange={nextValue => {
                            setExtraPaymentForm(nextValue);
                        }}
                        onReset={() => { setExtraPaymentForm(extraPaymentFormInitialState); }}
                        onSubmit={({ value }) => onExtraPaymentFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Mimoriadny vklad</Heading>

                        <FormField label="Výška vkladu (€)" width={'full'}>
                            <TextInput autoFocus name="payment" type="number" step="0.001" required={true} />
                        </FormField>

                        <FormField label="Mesiac (poradie)" width={'full'}>
                            <TextInput name="month" type="number" required={true} />
                        </FormField>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setExtraPaymentModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

            {eicModal && (<Layer onEsc={() => setEicModal(false)} onClickOutside={() => setEicModal(false)}>
                <Box pad="medium">
                    <Form
                        value={eicForm}
                        onChange={nextValue => {
                            setEicForm(nextValue);
                        }}
                        onSubmit={({ value }) => onEicFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Nastavenie parametrov sporiaceho programu</Heading>

                        <Box basis={'auto'} direction={'row'} align={'center'} wrap>
                            <FormField label="Stratégia" width={'full'}>
                                <Select options={[
                                    {id: 1, name:'Superdynamická', equities:100, bonds:0, mixed:0, cash:0},
                                    {id: 2, name:'Dynamická', equities:90, bonds:10, mixed:0, cash:0},
                                    {id: 3, name:'Balancovaná', equities:70, bonds:30, mixed:0, cash:0},
                                    {id: 4, name:'Vlastná', equities:0, bonds:0, mixed:0, cash:0},
                                ]}
                                        required={true}
                                        name="strategyObject"
                                        labelKey="name"
                                        valueKey="id"
                                        dropHeight="large"
                                        value={eicForm.strategyObject}
                                        placeholder="Výber stratégie"
                                />
                            </FormField>
                            {eicForm.strategyObject && eicForm.strategyObject.id!==4 && <Box direction={'row'} alignItems={'center'}>
                                <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Akciové/Alternatívne: {eicForm.strategyObject.equities}(%)</Box>
                                <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Dlhopisové: {eicForm.strategyObject.bonds}(%)</Box>
                                <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Zmiešané: {eicForm.strategyObject.mixed}(%)</Box>
                                <Box pad={'xsmall'} margin={'xxsmall'} border={true} round={'4px'} background={'light-1'}>Peňažné: {eicForm.strategyObject.cash}(%)</Box>
                            </Box>}

                            {eicForm.strategyObject && eicForm.strategyObject.id===4 && <Box direction={'row'} basis={'1/4'}>
                                <FormField width={'auto'} label="Akciové/Alternatívne (%)" >
                                    <TextInput name="equities" type="number" step="0.001" required={true} />
                                </FormField>
                            </Box>}

                            {eicForm.strategyObject && eicForm.strategyObject.id===4 && <Box direction={'row'} basis={'1/4'}>
                                <FormField label="Dlhopisové (%)" width={'full'}>
                                    <TextInput name="bonds" type="number" step="0.001" required={true} />
                                </FormField>
                            </Box>}

                            {eicForm.strategyObject && eicForm.strategyObject.id===4 && <Box direction={'row'} basis={'1/4'}>
                                <FormField width={'auto'} label="Zmiešané (%)" >
                                    <TextInput name="mixed" type="number" step="0.001" required={true} />
                                </FormField>
                            </Box>}

                            {eicForm.strategyObject && eicForm.strategyObject.id===4 && <Box direction={'row'} basis={'1/4'}>
                                <FormField label="Peňažné (%)" width={'full'}>
                                    <TextInput name="cash" type="number" step="0.001" required={true} />
                                </FormField>
                            </Box>}
                        </Box>

                        <FormField label="Poplatok (%)" width={'auto'}>
                            <TextInput name="fee" type="number" step="0.001" autoFocus required={true} />
                        </FormField>

                        <FormField label="Doba splácania poplatku (m)" width={'auto'}>
                            <TextInput name="feePeriodInMonths" type="number" required={true} />
                        </FormField>

                        <Box basis={'auto'} border={true} round={'xsmall'} pad={'medium'} margin={{bottom: 'large'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="feeFromPayment" options={[
                                {value: true,label: "Úhrada poplatku z mesačnej platby"},
                                {value: false,label: "Úhrada poplatku nad rámec platby"},
                            ]} />
                        </Box>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setEicModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

        </Box>
    );
}

export default SavingIzp;