import {Box} from 'grommet';
import React from "react";

function TurnOverCompanyProfitHelp() {
    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka Obrat / Zisk firmy slúži na výpočet potrebného obratu, zisku alebo vyplatenej dividendy spoločnosti na akceptáciu uvedeného príjmu v jednotlivých bankách.</p>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Uveďte mesačný príjem, ktorý klient potrebuje z danej spoločnosti akceptovať</li>
            <li>Zadajte percento podielu klienta na firme</li>
            <li>Nastavte uveďte ako dlho klient vlastní zadaný podiel na spoločnosti (ak sa podiel klienta na spoločnosti v čase menil, zadajte čas, počas ktorého klient vlastnil minimálne zadanú výšku podielu resp. vyššiu)</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup je zobrazený v tabuľke.</p>

    </Box>);
}

export default TurnOverCompanyProfitHelp;
