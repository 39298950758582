import {Box} from 'grommet';
import React from "react";

function LoanRefinanceHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie výhodnosti refinancovania súčasného úveru (Pôvodný úver)novým úverom (Refinančný úver)
            s ktorým sú spojené poplatky. Výška refinančného úveru je automaticky dopočítaná tak, aby úver pokryl vyplateni
            pôvodného úveru a všetky poplatky. Úver si môžete namodelovať v Základnom modelovaní aj so zmenami ú.s.,
            resp. s mim. splátkami, a následne ho presunúť sem.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre Pôvodného úveru – výšku, splatnosť a úrokovú sadzbu.</li>
            <li>Zvoľte parametre refinancovania – mesiac vyplatenia a poplatky (za predčasné splatenie, znalecký, kataster,...).</li>
            <li>Kliknite na <b>Vypočítaj</b></li>
            <li>Výška refinančného úveru a jeho splatnosť sa dopočítajú</li>
            <li>Zadajte úrokovú sadzbu v %-tách</li>
            <li>Kliknite na <b>Refinancuj</b></li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky pozostáva zo štyroch nasledovných častí:</p>
        <ul>
            <li>Tabuľka zobrazuje hodnoty splátky, zostatku a celkových nákladov Pôvodného úveru (biela farba), splátky,
                zostatku a celkových nákladov Refinančného úveru (bledozelená farba). Refinančný úver začína až v mesiaci
                vyplatenia.</li>
            <li>Hodnoty Pôvodného úveru zobrazujú splátku a celkové náklady na konci splatnosti.</li>
            <li>Hodnota bod zlomu (mesiac, kedy sú celkové náklady Refinančného úveru nižšie ako náklady Pôvodného úveru),
                úspora (kladná hodnota) / strata (záporná hodnota) vo zvolenom mesiaci (výročie fixácie) od začiatku úveru.</li>
            <li>Hodnoty Refinančného úveru zobrazujú splátku a celkové náklady na konci splatnosti.</li>
        </ul>
        <br /><br />
    </Box>);
}

export default LoanRefinanceHelp;
