
function DdsInvestmentPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'period', label: 'Investičný horizont', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
        {key: 'annualProfit', label: 'Očakávaný ročný výnos (%)', value: values.annualProfit},
        {key: 'clientDeposit', label: 'Pravidelný príspevok klienta (EUR)', value: values.clientDeposit},
        {key: 'employerDeposit', label: 'Pravidelný príspevok zamestnávatela (EUR)', value: values.employerDeposit},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <p>Kalkulačka slúži na výpočet výnosu DDS, ktorý je tvorený výnosom fondov a príspevkami zamestnávateľa. Najmä vďaka príspevkom zamestnávateľa je táto forma sporenia aj v dnešnej dobe atraktívna.</p>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
        return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
    }).join('')}
            </table>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <div class="card">
                <div class="card-block">
                    <h3>
                        Hodnota účtu na konci investičného horizontu je
                        <strong class="pull-right">
                            ${result.totalValue} €
                        </strong>
                    </h3>
                </div>
            </div>
        </div>
         <div class="col-12">
            <div class="card">
                <div class="card-block">
                    <h3>
                        Čistý percentuálny výnos sporenia je
                        <strong class="pull-right">
                            ${result.profit} %
                        </strong>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default DdsInvestmentPdfExport;