import {Box} from 'grommet';
import React from "react";

function LoanAdvancedHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na modelovanie úveru, pričom je automaticky možné modelovať:</p>
        <ol type={'A'}>
            <li>klasický úver</li>
            <li>jednorázové mimoriadne vklady, pričom pri každom vklade je možné si zvoliť buď skrátenie doby splácania alebo zníženie splátky</li>
            <li>zmenu ú.s. v ľubovolných mesiacoch</li>
            <li>
                Po pridaní jednotlivých modulov sa dá naviac modelovať
                <br />
                    <ul>
                        <li>úver so ŠPM (rôzne sadzby pre časť úveru so ŠPM a bez)</li>
                        <li>pravidelné mimoriadne vklady</li>
                        <li>výber konkrétnej banky, z čoho vyplýva možná voľba výšky poplatku za spracovanie</li>
                        <li>možnosť voľby dátumov splátky a čerpania pri zvolenej banke</li>
                    </ul>
            </li>
        </ol>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru – výšku, splatnosť a úrokovú sadzbu.</li>
            <li>Kliknite na <b>Vypočítať</b>.</li>
            <li>Zmenu úrokovej sadzby/Pridanie mimoriadnej splátky môžete nastaviť dopredu alebo neskôr priamo v tabuľke Mo výstupnej tabuľke</li>
        </ol>
        <h3>Špecifikácia banky</h3>
        <ol type={'1'}>
            <li>Modul slúži na presnejšie modelovanie úveru tým, že na základe banky sa pridáva poplatok za
                úver (je možné ho upravovať), jeho zaúčtovanie a spôsob splácania (NEM. vs. FR. metóda).</li>
        </ol>
        <h3>Štátny príspevok pre mladých</h3>
        <ol type={'1'}>
            <li>ŠPM Modul slúži na presnejšie modelovanie úveru tým, že na základe banky sa volí rozdelenie úveru na
                2 (resp.3 v prípade VÚB a splnenia podmienok) samostatné, úvery, kde je možné si zvoliť, a následne
                aj modelovať sadzby pri oboch úveroch. Ú.s. v Základnom moduli je základná ú.s. neznížená o 3% a ú.s.
                pre doplnkový úver v ŠPM Moduli je ú.s. pre zvyšnú časť úveru bez ŠPM.</li>
        </ol>
        <h3>Špecifikovanie dátumov</h3>
        <ol type={'1'}>
            <li>Modul slúži na presnejšie modelovanie úveru tým, že na sa určia presnejšie dátumy, ktoré sú zohľadnené
                pri bankách počítaných FR. metódou. Tento modul má na výsledné modelovanie minimálny efekt</li>
        </ol>
        <h2>Výstup</h2>
        <table className="table styled-table table-compact">
            <tbody>
            <tr>
                <th>Mesiac</th>
                <td>poradové číslo mesiaca od začiatku úveru</td>
            </tr>
            <tr>
                <th>Dátum splátky</th>
                <td>len pri bankách, kde je možné špecifikať dátumy</td>
            </tr>
            <tr>
                <th>Počet dní medzi splátkami</th>
                <td>zohľadňuje FR./NEM. metódu splácania</td>
            </tr>
            <tr>
                <th>ú.s. (hlavný úver)</th>
                <td>hlavná sadzba pre úver. Pri ŠPM zohľadňuje spôsob znižovania istiny podľa vybranej banky (-3%, -1%, -0%)</td>
            </tr>
            <tr>
                <th>ú.s. (dopl. úver)</th>
                <td>sadzba pre zvyšnú časť úveru bez ŠPM (len pri ŠPM moduli)</td>
            </tr>
            <tr>
                <th>Splátka</th>
                <td>hodnota splátky</td>
            </tr>
            <tr>
                <th>Splátka (znížená)</th>
                <td>splátka znížená o vrátený úrok pri určitých bankách (len pri ŠPM moduli)</td>
            </tr>
            <tr>
                <th>Zostatok pred splátkou</th>
                <td></td>
            </tr>
            <tr>
                <th>Poplatok pred splátkou</th>
                <td></td>
            </tr>
            <tr>
                <th>ISTINA</th>
                <td>splátka istiny</td>
            </tr>
            <tr>
                <th>ÚROK predpis</th>
                <td></td>
            </tr>
            <tr>
                <th>ÚROK vrátený</th>
                <td>pri určitých bankách (len pri ŠPM moduli)</td>
            </tr>
            <tr>
                <th>ÚROK doplatiť</th>
                <td>dlh na úroku po splátke</td>
            </tr>
            <tr>
                <th>Zaplatená istina</th>
                <td></td>
            </tr>
            <tr>
                <th>Zaplatený úrok</th>
                <td></td>
            </tr>
            <tr>
                <th>Zostatok po splátke</th>
                <td></td>
            </tr>
            <tr>
                <th>Mim. splátka</th>
                <td>možnosť zvoliť si mim. splátky</td>
            </tr>
            <tr>
                <th>Náklady</th>
                <td>výška nákladov klienta (splátky, mim. vklady a poplatky)</td>
            </tr>
            <tr>
                <th>Celkové náklad</th>
                <td>náklady + zostatok úveru po splátke</td>
            </tr>
            </tbody>
        </table>
        <br /><br />
        <p><b>Poznámka: </b>Spôsob strhávania poplatku a defaultné hodnoty dátumov sú nastavené tak, aby korešpondovali s Úverovou ponukou SLSP.</p>
    </Box>);
}

export default LoanAdvancedHelp;
