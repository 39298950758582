import FormatService from "../../services/FormatService";
import ApiService from "../../services/ApiService";


class LoanBankComparisonPdfExport{

    static getRows(){
        return [
            'payments',
            'balance',
            'totalCost'
        ];
    }

    static mapRowNameToLabel(name){
        const rows = {
            'payments': "Splátky",
            'balance': "Zostatok úveru",
            'totalCost': "Náklady spolu"
        }
        return rows[name];
    }
   
    static generatePdf({values,result,chart, options}) {
        /*
            options: {
                rows: [],
                periods: []
            }
        */
        let inputs = [
            {key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount},
            {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
            {key: 'spmType', label: 'Typ ŠPM', value: values.spmObject.name}
        ];
    
        if (values.spmObject.id!==ApiService.consts().spm.none){
            inputs.push({key: 'assuranceAmount', label: 'Hodnota zabezpečenia (EUR)', value: values.spmAssuranceAmount});
        }
    
        //filter out all banks we didnt edit
        let res = JSON.parse(JSON.stringify(result));
        res.results = res.results.filter((bank)=>{
            return bank.bank.loanRate>0;
        });
        res.periods = res.periods.map((period)=>{
            period.bankPeriodResults = period.bankPeriodResults.filter((bankperiod)=>{
                return bankperiod.bank.loanRate>0;
            })
            return period;
        });

        function generateRow(rowName, period){
            const rowExist = LoanBankComparisonPdfExport.getRows().includes(rowName);
            if(!rowExist) return "";
            return(
            `<tr class='text-center'>
                    <th>${LoanBankComparisonPdfExport.mapRowNameToLabel(rowName)} (€)</th>
                    ${period.bankPeriodResults.map((bankPeriod)=>{
                        return `<td>${FormatService.round(bankPeriod.actualPeriodResult[rowName])}</td>`;
                    }).join('')}
                </tr>`
            )
        }  
        
        return (`
        <div class="row-flex mb3">
            <div class="col-12">
                <p>Kalkulačka slúži na porovnanie výhodnosti jednotlivých bánk z pohľadu nákladov. Do porovnania sú zahrnuté</p>
                <ul>
                    <li>spracovateľský poplatok (pri SLSP je strhávaný postupne)</li>
                    <li>poplatok za vedenie bežného účtu</li>
                    <li>spôsob úročenia (NEM. vs. FR. metóda)</li>
                    <li>rozličné znižovanie istiny v prípade ŠPM</li>
                    <li>rozličné ú.s. na časť úveru so ŠPM a na časť úveru bez ŠPM</li>
                </ul>
            </div>
        </div>
        <div class="row-flex mb3">
            <div class="col-12">
                <table>
                    ${inputs.map((i) => {
                        return `<tr>
                                 <th>${i.label}</th>
                                 <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                                </tr>`;
                    }).join('')}
                </table>
            </div>
        </div>
       
        <div class="row-flex mb3">
            <div class="col-12">
                    
                 <table class="table styled-table">
                                <tbody>
                                    <tr class='text-center'>
                                        <td>&nbsp;</td>
                                        ${res.results.map((bank)=>{
                                             return `<th>${bank.bank.name}</th>`;
                                        }).join('')}
                                    </tr>
                                    <tr class='text-center'>
                                        <th>${values.spmObject.id===ApiService.consts().spm.spm2017 ? 'Úroková sadzba pred zľavou (%)' : 'Úroková sadzba (%)' }</th>
                                        ${res.results.map((bank)=>{
                                            return `<td>${FormatService.round(bank.bank.loanRate)}</td>`;
                                        }).join('')}
                                    </tr>
                                    <tr class='text-center'>
                                        <th>Poplatok (%)</th>
                                        ${res.results.map((bank)=>{
                                            return `<td>${FormatService.round(bank.bank.feePercent)}</td>`;
                                        }).join('')}
                                    </tr>
                                    <tr class='text-center'>
                                        <th>Poplatok (€)</th>
                                        ${res.results.map((bank)=>{
                                            return `<td>${FormatService.round(bank.bank.fee)}</td>`;
                                        }).join('')}
                                    </tr>
                                    <tr class='text-center'>
                                        <th>Poplatok za účet (€)</th>
                                        ${res.results.map((bank)=>{
                                            return `<td>${FormatService.round(bank.bank.feeAcount)}</td>`;
                                        }).join('')}
                                    </tr>
                                    <tr class='text-center'>
                                        <th>Splátka (€)</th>
                                        ${res.results.map((bank)=>{
                                            return `<td>${FormatService.round(bank.payment)}</td>`;
                                        }).join('')}
                                    </tr>
    
                                    ${ [1].map(()=>{
                                       return values.spmObject.id===ApiService.consts().spm.spm2017 ? 
                                            `<tr class='text-center'>
                                            <th class='table-cell-important'>Úver so ŠPM</th>
                                            ${res.results.map((bank)=>{
                                                return `<td>&nbsp;</td>`;
                                            }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Výška (€)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.loanAmountSpm)}</td>`;
                                                }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Ú.s. po zľave (%)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.loanRateSpm)}</td>`;
                                                }).join('')}
                                            </tr>
    
                                            <tr class='text-center'>
                                                <th class='table-cell-important'>Úver so Zvýhodnením</th>
                                                ${res.results.map(()=>{
                                                    return` <td>&nbsp;</td>`;
                                                }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Výška (€)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.loanAmountBonus)}</td>`;
                                                }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Úroková sadzba (%)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.bank.loanRateBonus)}</td>`;
                                                }).join('')}
                                            </tr>
    
                                            <tr class='text-center'>
                                                <th class='table-cell-important'>Zvyšný úver</th>
                                                ${res.results.map(()=>{
                                                    return `<td>&nbsp;</td>`;
                                                }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Výška (€)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.loanAmountRest)}</td>`;
                                                }).join('')}
                                            </tr>
                                            <tr class='text-center'>
                                                <th>Úroková sadzba (%)</th>
                                                ${res.results.map((bank)=>{
                                                    return `<td>${FormatService.round(bank.bank.loanRateRest)}</td>`;
                                                }).join('')}
                                            </tr>` : '';
                                        }).join('')}
    
                                    ${res.periods.map((period)=>{
                                        if(!options.periods.includes(period.period)) return;
                                        return `<tr class='text-center'>
                                                    <th class='table-cell-important'>Porovnanie po ${period.period%12===0 ? period.period/12 : period.period}
                                                    ${period.period%12===0 ? FormatService.pluralify(period.period/12,' roku', ' rokoch',' rokoch') : FormatService.pluralify(period.period,' mesiaci', ' mesiacoch',' mesiacoch')}</th>
                                                    ${period.bankPeriodResults.map(()=>{
                                                        return `<td>&nbsp;</td>`;
                                                    }).join('')}
                                                </tr>
                                                ${options.rows.map(rowName => {
                                                    return generateRow(rowName, period)
                                                })}             
                                                ${ [1].map(()=>{ 
                                                    return values.spmObject.id===ApiService.consts().spm.spm2018 ?
                                                    `<tr class='text-center'>
                                                        <th>Úspora na úroku spolu (€)</th>
                                                        ${period.bankPeriodResults.map((bankPeriod)=>{
                                                            return `<td>${FormatService.round(bankPeriod.actualPeriodResult.interestSavings)}</td>`;
                                                        }).join('')}
                                                    </tr>` : '';})
                                                }`;
                                        }).join('')
                                    }
                                </tbody>
                            </table>
                 
            </div>
        </div>
    
        <div class="row-flex">
            <div class="col-12 text-center">
                ${chart ? chart.getSVG() : ''}
            </div>
        </div>
        `);
    }
}



export default LoanBankComparisonPdfExport;