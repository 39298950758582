import {Box} from 'grommet';
import React from "react";

function LoanFixationHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet sadzby, ktorú keď po uplynutí kratšej fixácie dostaneme, budú náklady po skončení
            dlhšej fixácie totožné. (nech máme FIX3 1,2% a FIX5 1,35%, pri akej najvyššej sadzbe, ktorú dostanem po 3
            rokoch sa mi oplatí zobrať kratšiu fixáciu?)</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru – výšku a splatnosť.</li>
            <li>Pridajte fixácie, pre porovnanie</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky je priamo v tabuľke - číslo v riadku "R" a stĺpci "S" reprezentuje úrokovú sadzbu,
            ktorú by sme museli dostať po uplinutí fixácie "R", aby boli náklady na úver po "S" rokoch rovnaké,
            ako pri použití fixácie "S". (napr. máte sadzbu pre FIX2 a FIX3. V 2.riadku a 3.stĺpci je úroková sadzba,
            ktorú ak po uplynutí 2 rokoch dostanete (pri použití FIX2 na prvé 2 roky), tak budú náklady na úver po 3
            rokoch totožné ako pri úvere s FIX3 sadzbou)</p>
        <br /><br />
    </Box>);
}

export default LoanFixationHelp;
