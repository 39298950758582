import FormatService from "../../services/FormatService";

function SalaryPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'salary', label: values.mode==='net' ? 'Hrubá mzda (€)' : 'Čistá mzda (€)', value: values.salary},
        //{key: 'salaryPeriod', label: 'Obdobie', value: values.salaryPeriod.substr(5)},
        {key: 'spouseWithoutIncome', label: 'Manžel/-ka bol/-a v minulom roku bez príjmu', value: values.spouseWithoutIncome? 'Áno':'Nie'}
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
                ${values.children.length ?                    `
                    ${values.children.map((child, idx) => {
                        return `
                            <tr>
                                <th>Nezaopatrené dieťa ${idx+1}</th>   
                                <td class="text-sm-right">${child.month.label}/${child.year}</td>                                 
                            </tr>
                        `
                    }).join('')}
                `
                : ""
                }
            </table>
        </div>
    </div>
        <div class="row-flex mb3">
            <div class="col-12">
                 <table class="table styled-table">
                        <thead>
                        <tr>
                            <td>&nbsp;</td>
                            <th colSpan="2">Zamestnanec</th>
                            <th colSpan="2">Zamestnávateľ</th>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <th>Sadzba</th>
                            <th>Hodnota</th>
                            <th>Sadzba</th>
                            <th>Hodnota</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Zdravotné poistenie</th>
                            <td>4%</td>
                            <td>${result.employeeHealthInsurance}€</td>
                            <td>10%</td>
                            <td>${result.employerHealthInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Nemocenské poistenie</th>
                            <td>1,4%</td>
                            <td>${result.employeeMedicare}€</td>
                            <td>1,4%</td>
                            <td>${result.employerMedicare}€</td>
                        </tr>
                        <tr>
                            <th>Starobné poistenie</th>
                            <td>4%</td>
                            <td>${result.employeeOldAgeInsurance}€</td>
                            <td>14%</td>
                            <td>${result.employerOldAgeInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Invalidné poistenie</th>
                            <td>3%</td>
                            <td>${result.employeeDisabilityInsurance}€</td>
                            <td>3%</td>
                            <td>${result.employerDisabilityInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Poistenie v nezamestnanosti</th>
                            <td>1%</td>
                            <td>${result.employeeUnemploymentInsurance}€</td>
                            <td>1%</td>
                            <td>${result.employerUnemploymentInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Garančný fond</th>
                            <td>0%</td>
                            <td>${result.employeeGuaranteeFund}€</td>
                            <td>0,25%</td>
                            <td>${result.employerGuaranteeFund}€</td>
                        </tr>
                        <tr>
                            <th>Rezervný fond</th>
                            <td>0%</td>
                            <td>${result.employeeReserveFund}€</td>
                            <td>4,75%</td>
                            <td>${result.employerReserveFund}€</td>
                        </tr>
                        <tr>
                            <th>Úrazové poistenie</th>
                            <td>0%</td>
                            <td>${result.employeeAccidentInsurance}€</td>
                            <td>0,8%</td>
                            <td>${result.employerAccidentInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Odvody spolu</th>
                            <td>&nbsp;</td>
                            <th class="text-right table-cell-highlighted">${result.employeeTotal}€</th>
                            <td>&nbsp;</td>
                            <th class="text-right table-cell-highlighted">${result.employerTotal}€</th>
                        </tr>
                        </tbody>
                        <tr>
                            <td colSpan="5">&nbsp;</td>
                        </tr>
                        <tbody>
                        <tr>
                            <th>Čiastkový základ dane</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${FormatService.round(result.partialTaxBase).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Odpočítateľná položka</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${result.deductibleItem}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Základ dane</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${result.taxBase}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daň z príjmu</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${result.incomeTax}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daňový bonus na deti</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${FormatService.round(result.childrenBonus).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daň z príjmu znížená o daňový bonus</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${FormatService.round(result.incomeTaxWithoutChildrenBonus).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        ${result.incomeTaxWithoutChildrenBonus ? 
                        `<tr>
                            <th>${values.mode==='net' ? 'Čistý príjem' : 'Hrubý príjem'} bez daňového preplatku</th>
                            <td>&nbsp;</td>
                            <td class="text-right table-cell-highlighted">${FormatService.round(result.incomeWithoutTaxOverpayment).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>` : ""}
                        <tr>
                            <th>${values.mode==='net' ? 'Čistý príjem' : 'Hrubý príjem'}</th>
                            <td>&nbsp;</td>
                            <th class="text-right table-cell-important">${FormatService.round(result.income).toFixed(2)}€</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tbody>
                        <tr>
                            <th>Mzdové náklady</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <th class="text-right">${result.costs}€</th>
                        </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default SalaryPdfExport;