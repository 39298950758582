import {
    Box,
    Button,
    CheckBox,
    CheckBoxGroup,
    Form,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    TextInput
} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SavingEicHelp from "./SavingEicHelp";
import SavingEicPdfExport from "./SavingEicPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function SavingEic(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'SavingEic';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'EIC nákladovosť',
        mode: 'ONE_TIME',

        period: 15,
        periodUnits: 'y',

        isMixEnabled: false,

        bondsFundsCount: 0,
        bondsPayment: 0,
        cashFundsCount: 0,
        cashPayment: 0,
        equityFundsCount: 1,
        equityPayment: 100,
        etfPayment: 0,
        etfFundsCount: 0,
        mixedFundsCount: 0,
        mixedPayment: 0,
        managementFee: 0,
        monthsForFee: 0,
        payment: 100,
        revenue: 6,
        startFee: 3,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const exportPdfModeFormInitialState = {exportPdfMode: ['invest', 'managed']};

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [activeRequest, setActiveRequest] = useState(false);
    const [exportPdfModeForm, setExportPdfModeForm] = useState(exportPdfModeFormInitialState);
    const [exportPdfModeModal, setExportPdfModeModal] = useState(false);

    function saveCalcDetail(values, result){
        const calcDetailState = {
            values:values,
            result:result,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result);
        // eslint-disable-next-line
    }, [values, result]);

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;
        const reqData = {
            bondsFundsCount: values.bondsFundsCount,
            bondsPayment: values.bondsPayment,
            cashFundsCount: values.cashFundsCount,
            cashPayment: values.cashPayment,
            equityFundsCount: values.equityFundsCount,
            equityPayment: values.equityPayment,
            etfFundsCount: values.etfFundsCount,
            etfPayment: values.etfPayment,
            mixedFundsCount: values.mixedFundsCount,
            mixedPayment: values.mixedPayment,
            periodInMonths: periodMonths,
            payment: values.payment,
            revenue: values.revenue,
            startFee: values.startFee,
            managementFee: values.managementFee,
            monthsForFee: values.monthsForFee,
            type: values.mode,
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/savings/eicCost', reqData)
            .then((res) => {
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    function onExportPdfModeFormSubmit(exportPdfModeForm){
        exportPdf(exportPdfModeForm);
        setExportPdfModeModal(false);
    }

    const pinKey = 'eic-nakladovost';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(exportPdfModeForm){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SavingEicPdfExport({values:values,result:result,exportPdfModeForm:exportPdfModeForm,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SavingEicHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        if (values.mode!==nextValue.mode){
                            setResult(null);
                        }
                        setValues(nextValue);
                        //dispatch({type:'calc/save', payload: {regularInvestment: nextValue}});
                    }}
                    onReset={() => {
                        let init = defaultValues;
                        init.mode = values.mode;
                        setValues(init);
                        setResult(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" required={true} options={[
                                {value: 'ONE_TIME',label: "Jednorázová investícia"},
                                {value: 'RECURRING',label: "Pravidelná investícia"},
                            ]} />
                        </Box>
                    </Box>

                    <Box direction={'row'} alignItems={'center'} margin={{bottom: 'medium'}} wrap>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Výnos p.a. (%)" width={'full'}>
                                <TextInput name="revenue" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Vstupný poplatok (%)" width={'full'}>
                                <TextInput name="startFee" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Management fee (%)" width={'full'}>
                                <TextInput name="managementFee" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        {values.mode!=='ONE_TIME' && <Box basis={'auto'} direction={'row'} align={'center'} >
                            <FormField label="Doba strhávania poplatku (m)" width={'full'}>
                                <TextInput name="monthsForFee" type="number" step="1" required={true} />
                            </FormField>
                        </Box>}

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Doba investovania' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Box margin={{vertical: 'small'}} direction={'row-responsive'} align={'start'}>
                        <Box basis={'auto'} direction={'column'} pad={'small'} align={'start'} border={true} round={'small'} background={'white'}>
                            <Box basis={'full'}>
                                <Box basis={'auto'} direction={'row'} align={'end'}>
                                    <FormField label="Výška vkladu (€)" width={'full'}>
                                        <TextInput name="payment" type="number" step="0.01" required={true} />
                                    </FormField>
                                </Box>
                            </Box>
                            <table className="table minimal-table">
                                <thead>
                                <tr>
                                    <td><CheckBox name="isMixEnabled" toggle={true} border={false} label="Vlastný pomer" /></td>
                                    <th>Rozdelenie fondov (%)</th>
                                    <th>Počet fondov</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className="text-right">Akciové fondy</th>
                                    <td><TextInput name="equityPayment" type="number" step="0.01" disabled={!values.isMixEnabled} required={true}/></td>
                                    <td><TextInput name="equityFundsCount" type="number" disabled={!values.isMixEnabled} required={true}/></td>
                                </tr>
                                <tr>
                                    <th className="text-right">Dlhopisové fondy</th>
                                    <td><TextInput name="bondsPayment" type="number" step="0.01" disabled={!values.isMixEnabled} required={true}/></td>
                                    <td><TextInput name="bondsFundsCount" type="number" disabled={!values.isMixEnabled} required={true}/></td>
                                </tr>
                                <tr>
                                    <th className="text-right">Zmiešané fondy</th>
                                    <td><TextInput name="mixedPayment" type="number" step="0.01" disabled={!values.isMixEnabled} required={true}/></td>
                                    <td><TextInput name="mixedFundsCount" type="number" disabled={!values.isMixEnabled} required={true}/></td>
                                </tr>
                                <tr>
                                    <th className="text-right">Peňažné fondy</th>
                                    <td><TextInput name="cashPayment" type="number" step="0.01" disabled={!values.isMixEnabled} required={true}/></td>
                                    <td><TextInput name="cashFundsCount" type="number" disabled={!values.isMixEnabled} required={true}/></td>
                                </tr>
                                <tr>
                                    <th className="text-right">ETF</th>
                                    <td><TextInput name="etfPayment" type="number" step="0.01" disabled={!values.isMixEnabled} required={true}/></td>
                                    <td><TextInput name="etfFundsCount" type="number" disabled={!values.isMixEnabled} required={true}/></td>
                                </tr>

                                </tbody>
                            </table>
                        </Box>
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>


            {result!==null &&
            <Box direction={props.size==='small'? 'column' : 'row'} margin={{vertical: 'medium'}} pad={'medium'} border={true} round={'small'} background={'white'} align={'start'} justify={'start'}>
                <Box pad={'medium'} margin={{right: 'small'}} border={true} round={'small'} background={'white'} width={'1/2'}>
                    <Heading level="3" margin={'none'}>{values.mode==='ONE_TIME' ? 'Výstup investičného účtu' : 'Výstup cieľového účtu'}</Heading>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <tbody>
                            <tr>
                                <th>Hodnota investície na konci investičného horiznotu</th>
                                <td className="text-right"><b>{FormatService.round(result.investEndValue)}</b> €</td>
                            </tr>
                            <tr>
                                <th>Absolútny výnos</th>
                                <td className="text-right"><b>{FormatService.round(result.investAbsolutProfit*100)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Čistý výnos p.a.</th>
                                <td className="text-right"><b>{FormatService.round(result.investProfit*100)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Návratnosť v mesiacoch</th>
                                <td className="text-right"><b>{FormatService.round(result.investMonthReturn)}</b> m</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Box>
                <Box pad={'medium'} margin={{right: 'small'}} border={true} round={'small'} background={'white'} width={'1/2'}>
                    <Heading level="3" margin={'none'}>Výstup spravovaného účtu</Heading>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <tbody>
                            <tr>
                                <th>Hodnota investície na konci investičného horiznotu</th>
                                <td className="text-right"><b>{FormatService.round(result.recurringEndValue)}</b> €</td>
                            </tr>
                            <tr>
                                <th>Absolútny výnos</th>
                                <td className="text-right"><b>{FormatService.round(result.recurringAbsolutProfit*100)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Výnos p.a.</th>
                                <td className="text-right"><b>{FormatService.round(result.recurringProfit*100)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Návratnosť v mesiacoch</th>
                                <td className="text-right"><b>{FormatService.round(result.recurringMonthReturn)}</b> m</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

            {exportPdfModeModal && (<Layer onEsc={() => setExportPdfModeModal(false)} onClickOutside={() => setExportPdfModeModal(false)}>
                <Box pad="medium">
                    <Form
                        value={exportPdfModeForm}
                        onChange={nextValue => {
                            setExportPdfModeForm(nextValue);
                        }}
                        onSubmit={({ value }) => onExportPdfModeFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Nastavenie exportu</Heading>

                        <Box direction={'row-responsive'}>
                            <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                                <CheckBoxGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="exportPdfMode" required={true} options={[
                                    {value: 'invest',label: "Zahrnúť Investičný účet"},
                                    {value: 'managed',label: "Zahrnúť Spravovaný účet"},
                                ]} />
                            </Box>
                        </Box>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Vybrať" disabled={!exportPdfModeForm.exportPdfMode || !exportPdfModeForm.exportPdfMode.length} />
                            <Button onClick={() => setExportPdfModeModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

        </Box>
    );
}

export default SavingEic;