import {Box, Button, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput} from 'grommet';
import React, { useEffect, useState } from "react";
import * as Icons from "grommet-icons";
import SavedPdfExportTemplatesService from '../../services/SavedPdfExportTemplatesService';
import ErrorService from '../../services/ErrorService';
import { DateTime } from 'luxon';
import SavingIzpColumnRowSelection from '../SavingIzp/SavingIzpColumnRowSelection';
import LoanAdvancedColumnRowSelection from '../LoanAdvanced/LoanAdvancedColumnRowSelection';
import { toast } from 'react-toastify';
import AddEditPdfExportTemplatesModal from './AddEditPdfExportTemplatesModal';
import LoanBankComparisionColumnRowSelection from '../LoanBankComparison/LoanBankComparisonColumnRowSelection';
import LoanInsuranceColumnRowSelection from '../LoanInsurance/LoanInsuranceColumnRowSelection';

function SavedPdfExportTemplates(props) {

    const [savedTemplates, setSavedTemplates] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [showExportPdfModal, setShowExportPdfModal] = useState(false);

    useEffect(() => {
        loadTemplateList()
    }, [])

    function loadTemplateList(){
        SavedPdfExportTemplatesService.getList()
        .then((res) => {
                setSavedTemplates(res)
            },
            (error) => {
                ErrorService.fromApi(error);
        });
    }

    useEffect(()=>{
        if (!search){
            setFilteredTemplates([...savedTemplates]);
            return true;
        }
        setFilteredTemplates(savedTemplates.filter((item)=>{
            return (
                item.contents.title.toLowerCase().includes(search.toLowerCase())
                || item.contents.state.title.toLowerCase().includes(search.toLowerCase())
            );
        }));
    }, [search, savedTemplates]);

    function onRemoveTemplate(id){
        if (true===window.confirm('Chcete vymazať tento záznam? Akcia sa nedá vrátiť späť.')){
            SavedPdfExportTemplatesService.delSavedTemplate(id)
            .then((res) => {
                    setSavedTemplates(savedTemplates.filter((item)=>{
                        return item.id !== id;
                    }));
                },
                (error) => {
                    ErrorService.fromApi(error);
            });
        }
    }

    function onEditTemplate(template){
        setTemplate(template);
        setShowExportPdfModal(true)
    }

    function onExportPdfClose(res){
        if(res){
            loadTemplateList()
            toast.success('Šablóna bola uložená', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setTemplate(null);
        setShowExportPdfModal(false)
    }

    function getPdfExportTemplateTypes(){
        return [
            {
                label: "Základné modelovanie úveru",
                value: "zakladne-modelovanie-uveru", //value by mala byt rovnaka ako pinKey v kalkulacke
                columnRowSelectionElement: LoanAdvancedColumnRowSelection
            },
            {
                label: "Investičné produkty",
                value: "investicne-produkty",
                columnRowSelectionElement: SavingIzpColumnRowSelection
            },
            {
                label: "Porovnanie bánk",
                value: "porovnanie-bank",
                columnRowSelectionElement: LoanBankComparisionColumnRowSelection
            },
            {
                label: "Poistné krytie pre prípad smrti",
                value: "klesajuce-poistne-sumy",
                columnRowSelectionElement: LoanInsuranceColumnRowSelection
            },
        ]
    }

    function getColumnRowSelectionElement(calc_key, template= null){
     
        const pdfExportTypes = getPdfExportTemplateTypes()
        const selectedType  = pdfExportTypes.find(type => type.value === calc_key);

        if(selectedType){
            const ColumnRowSelection = selectedType.columnRowSelectionElement;
            return <ColumnRowSelection template={template} />
        }
    
        ErrorService.fromApp(`Pre calc_key "${calc_key}, nie je priradena/vytvorena ziadny ColumnRowSelection component v getColumnRowSelectionElement"`)
        return <></>
        
    }

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>ULOŽENÉ ŠABLÓNY NA EXPORT</Heading>
            </Box>

            <Box pad={'small'} direction={'column'} >
            
                {savedTemplates && savedTemplates.length>0 &&                
                    <Box direction={'column'} fill={true}>
                        <Box direction={'row'}>
                            <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}} width={{min: "100%"}}  direction={'row'} pad={'xsmall'}>
                                <Box basis="1/2"  direction={'row'} >
                                    <Box>
                                        <TextInput placeholder="Hľadať" value={search} name="search" onChange={event => setSearch(event.target.value)} />
                                        {search && <Button secondary icon={<Icons.FormClose size={'16px'} color={'red'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} hoverIndicator onClick={()=>setSearch('')} />}
                                    </Box>
                                </Box>
                                <Box basis="1/2" justify='end' direction="row" gap="medium" >
                                    <Button primary style={{lineHeight: "18px"}} onClick={() => setShowExportPdfModal(true)} label="Pridať" />
                                </Box>
                            </Box>
                        </Box>  
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell border="bottom">Názov</TableCell>
                                    <TableCell border="bottom">Typ</TableCell>
                                    <TableCell border="bottom">Upravené</TableCell>
                                    <TableCell border="bottom" align={'center'}><Icons.Services size={'16px'} /></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredTemplates.map((item, index)=>{
                                    let last = (index === filteredTemplates.length-1);

                                    return <TableRow key={item.id} style={{'background':'brand'}}>
                                        <TableCell border={last?'bottom':''}>{item.contents.title}</TableCell>
                                        <TableCell border={last?'bottom':''}>{item.contents.state.title}</TableCell>
                                        <TableCell border={last?'bottom':''}>{DateTime.fromISO(item.updated_at.replace(' ', 'T')).toLocaleString(DateTime.DATETIME_SHORT)}</TableCell>
                                        <TableCell border={last?'bottom':''}>
                                            <Box justify={'center'} direction={'row-responsive'} gap={'xsmall'}>
                                                <Button secondary icon={<Icons.Catalog color={'brand'} size={'16px'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} onClick={() => onEditTemplate(item)} />
                                                <Button secondary icon={<Icons.Trash size={'16px'} color={'red'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} onClick={()=>{onRemoveTemplate(item.id)}} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                }                
                {(!savedTemplates || !savedTemplates.length) &&
                    <Box direction={'row'}>
                        <Box basis='1/2'>
                            <Text color={'gray'} >Zatiaľ ste nepridali žiadne šablóny. Uložiť šablonu môžete priamo z detailu kalkulačky.</Text>
                        </Box>
                        <Box basis="1/2" justify='end' direction="row" gap="medium" >
                            <Button primary style={{lineHeight: "18px"}} onClick={() => setShowExportPdfModal(true)} label="Pridať" />
                        </Box>
                    </Box>
                }

                
                {showExportPdfModal && 
                <AddEditPdfExportTemplatesModal
                    show={showExportPdfModal}
                    template={template}
                    templateTypes={getPdfExportTemplateTypes()}
                    closeAction={(res) => onExportPdfClose(res)}
                    renderColumnRowSelection={(calc_key, template) => getColumnRowSelectionElement(calc_key, template) }
                />} 
            </Box>

        </Box>
    );
}

export default SavedPdfExportTemplates;
