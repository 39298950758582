import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormField,
    Heading,
    RadioButtonGroup,
    Select,
    TextInput
} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SwissLoanHelp from "./SwissLoanHelp";
import SwissLoanPdfExport from "./SwissLoanPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgReloadIcon} from "../../components/Icons/UgReloadIcon";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function SwissLoan(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'SwissLoan';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Švajčiarska hypotéka',
        period: 30,
        periodUnits: 'y',
        annualInterestRate: 3,
        loanAmount: 70000,
        investment: 0,
        investorObject: {id: 'investor', name: 'Investor'},
        revenue: 0,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const investorsListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['investorsList']) ? state.CalcDetail[calcStateKey]['investorsList'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [investorsList, setInvestorsList] = useState(investorsListInitialState);
    const [filteredInvestorsList, setFilteredInvestorsList] = useState(investorsListInitialState);
    const [activeRequest, setActiveRequest] = useState(false);

    function saveCalcDetail(values, result, investorsList){
        const calcDetailState = {
            values:values,
            result:result,
            investorsList:investorsList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result, investorsList);
        // eslint-disable-next-line
    }, [values, result, investorsList]);

    const reloadInvestorsList = useCallback(() => {
        ApiService.getInvestorsList(ApiService.consts().investor.loan)
            .then((res) => {
                    setInvestorsList(res.investors);
                    setFilteredInvestorsList(res.investors);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
    }, []);

    useEffect(() => {
        reloadInvestorsList();
    }, [reloadInvestorsList]);

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        const reqData = {
            annualInterestRate: values.annualInterestRate,
            periodInMonths: periodMonths,
            loanAmount: values.loanAmount,
            investment: values.investment,
            investorId: values.investorObject.id,
            revenue: values.revenue,
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/swissLoan', reqData)
            .then((res) => {
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    function onFormSubmitRefinance(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        const reqData = {
            annualInterestRate: values.annualInterestRate,
            periodInMonths: periodMonths,
            loanAmount: values.loanAmount,
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/swissLoan/basicModel', reqData)
            .then((res) => {
                    setValues(Object.assign({}, values, {investment: FormatService.round(res.investment)}));
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'svajciarska-hypoteka';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SwissLoanPdfExport({values:values,result:result,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SwissLoanHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Heading level={3} margin={{bottom: 'none'}}>Základný model</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                                <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Investičná zložka</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Investičný program" width={'full'}>
                                <Select options={filteredInvestorsList}
                                        required={true}
                                        name="investorObject"
                                        labelKey="name"
                                        valueKey="id"
                                        dropHeight="large"
                                        value={values.investorObject}
                                        placeholder="Výber investora"
                                        onClose={() => setFilteredInvestorsList(investorsList)}
                                        onSearch={searchText => {
                                            const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                            const exp = new RegExp(escapedText, 'i');
                                            setFilteredInvestorsList( investorsList.filter(o => {return exp.test(o.name)}) );
                                        }}
                                        emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výnos p.a. (%)" width={'full'}>
                                <TextInput name="revenue" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výška investície (€)" width={'full'}>
                                <TextInput name="investment" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                    </Box>

                    <Box basis={'auto'} direction={'row'} align={'center'}>
                        <Button type="button" margin={{top:'medium'}} secondary onClick={()=>onFormSubmitRefinance(values)} icon={<UgReloadIcon />} label="Navrhni výšku investície" disabled={activeRequest} />
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result!==null &&
            <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <Box direction={'row-responsive'} basis={'full'}>
                        <Card basis={'1/3'} margin={{right: 'medium'}} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Základný modul</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Splátka (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.results[0].loanPayment)}</td>
                                    </tr>
                                    <tr>
                                        <th>Celkové náklady (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.costs)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>

                        <Card basis={'1/3'} margin={{right: 'medium'}} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Švajčiarska hypotéka</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Splátka (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.loanPaymentSwiss)}</td>
                                    </tr>
                                    <tr>
                                        <th>Bod zlomu</th>
                                        <td className={'text-right'}>{result.breakEvenMonth}</td>
                                    </tr>
                                    <tr>
                                        <th>Náklady v bode zlomu (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.breakEvenCosts)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>

                        <Card basis={'1/3'} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Zhrnutie</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Bilancia na účte v {(values.periodUnits === 'm' ? values.period : values.period*12)}. mesiaci (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.balance)}</td>
                                    </tr>
                                    <tr>
                                        <th>Náklady v {(values.periodUnits === 'm' ? values.period : values.period*12)}. mesiaci (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.costs)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Box>

                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className="sticky-header">
                            <tr>
                                <th></th>
                                <th colSpan={2}>Základný modul</th>
                                <th colSpan={2}>Švajčiarska hypotéka</th>
                                <th colSpan={3}>Investičná zložka</th>
                            </tr>
                            <tr>
                                <th>Mesiac</th>
                                <th>Splátka</th>
                                <th>Zostatok úveru</th>
                                <th>Splátka</th>
                                <th>Zostatok úveru</th>
                                <th>Výška investície</th>
                                <th>Hodnota účtu</th>
                                <th>Výsledná bilancia</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result && result.results && result.results.length && result.results.map((row, index)=>{
                                return <tr key={"result_"+index} className={'text-center'}>
                                    <td>{row.month}.</td>
                                    <td>{FormatService.round(row.loanPayment)}</td>
                                    <td>{FormatService.round(row.remainingPrincipal)}</td>
                                    <td>{FormatService.round(row.loanPaymentSwiss)}</td>
                                    <td>{FormatService.round(row.remainingPrincipalSwiss)}</td>
                                    <td>{FormatService.round(row.investment)}</td>
                                    <td>{FormatService.round(row.investmentAccountValue)}</td>
                                    <td>{FormatService.round(row.balance)}</td>
                                </tr>;
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

        </Box>
    );
}

export default SwissLoan;