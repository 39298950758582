import { Box, Button, Form, FormField, Heading, Select, TextInput } from "grommet";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import CalcDetailService from "../../services/CalcDetailService";
import CalcPinService from "../../services/CalcPinService";
import TurnOverCompanyProfitHelp from "./TurnOverCompanyProfitHelp";
import { UgHelpIcon } from "../../components/Icons/UgHelpIcon";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import { useDebouncedEffect } from "../../effects/UseDebouncedEffect";
import { UgSpinner } from "../../components/Spinner/UgSpinner";
import { UgPdfIcon } from "../../components/Icons/UgPdfIcon";
import TurnOverCompanyProfitPdfExport from "./TurnOverCompanyProfitPdfExport";
import ThemeService from "../../services/ThemeService";
import AuthService from "../../services/AuthService";
import FormatService from "../../services/FormatService";

const TurnOverCompanyProfit = (props) => {
    const calcStateKey = 'TurnOverCompanyProfit';
    const calcStateVersion = 1;
    const pinKey = 'obrat-zisk-firmy';
    const state = useSelector((state) => state);

	const defaultValues = {
        title: 'Obrat / zisk firmy',
		income: 2000,
		companyShare: 100,
		ownershipPeriod: 24,
		previousPeriod: null,
        openHelp: false,
        pdfGenerating: false,
    };

	const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
	const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const banksListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['banksList']) ? state.CalcDetail[calcStateKey]['banksList'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [activeRequest, setActiveRequest] = useState(false);
	const [banksList, setBanksList] = useState(banksListInitialState);


    const reloadBanksList = useCallback(() => {
        ApiService.getLoanBanksList()
            .then((res) => {
                    setBanksList(res);                },
                (error) => {
                    ErrorService.fromApi(error);
                })
        // eslint-disable-next-line
    }, []);

	useEffect(() => {
        reloadBanksList();
    }, [reloadBanksList]);

	useDebouncedEffect(() => {
        saveCalcDetail(values, result, banksList);
    }, 500, [values, result, banksList]);

	function saveCalcDetail(values, result, banksList){
        const calcDetailState = {
            values:values,
            result:result,
            banksList:banksList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    const dispatch = useDispatch();
	function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }	

	function getBankById(id){
        return banksList?.filter((bank)=>{return id===bank.id;}).pop();
    }

	function updateResultBank(res){
        let r = Object.assign({}, res);
        r.banks = [...r.banks.map((bank)=>{
            bank.bank_data = getBankById(bank.bank)
            return bank;
        })];
        r = JSON.parse(JSON.stringify(r));
        setResult(r);
    }

	function onFormSubmit(v){
		let reqData = {
			income: v.income,
			companyShare: Number(v.companyShare)/100,
			ownershipPeriod: v.ownershipPeriod
		}

        if(v.ownershipPeriod < 18){
            reqData.previousPeriod = v.previousPeriod.value
        }

		setActiveRequest(true);
        ApiService.call('post', '/v4/loan/companyprofit', reqData)
		.then((res) => {
				updateResultBank(res)
				setActiveRequest(false);
			},
			(error) => {
				setActiveRequest(false);
				ErrorService.fromApi(error);
			});
	}

	function exportPdf(options){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: TurnOverCompanyProfitPdfExport({values:values, result:result, translateBankError: translateBankError}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

	function translateBankError(error){
		switch(error){
			case "COMPANY_INCOME_NOT_ACCEPTED":
				return "Neakceptujú príjem z firmy"
			case "OWNERSHIP_PERIOD":
				return "Doba vlastníctva firmy je krátka"
			default:
				return error
		}
	}

	function calculatePreviousPeriodOptions(ownershipPeriod){
		const options = [];
        options.push({label: "< 6 mesiacov", value: 0})

        if(ownershipPeriod >= 6){
			options.push({label: ">= 6 mesiacov", value: 6})
		}
		if(ownershipPeriod >= 12){
			options.push({label: "= 12 mesiacov", value: 12})
		}
		return options;
	}

    function transformResponseCellLabel(data){
        let label = "";
        if(!data.value && !data.note ){
            return "Neakceptujú"
        }        
        
        if(data.value){
            label = FormatService.round(data.value);
        }

        if(data.note){
            label += label ? " "+data.note : data.note
        }

        return label;

    }
	const previousPeriodOptions = useCallback(calculatePreviousPeriodOptions(values.ownershipPeriod), [values.ownershipPeriod])

	return (
		<Box flex={false} margin={{'horizontal': 'small'}}>
			<Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><TurnOverCompanyProfitHelp/></Helpbar>
			<Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
				<Box direction={'row'}>
					<Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
			</Box>
			<Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
				<Form
					value={values}	
					onChange={nextValue => {
						if(values.ownershipPeriod !== nextValue.ownershipPeriod){
							const options = calculatePreviousPeriodOptions(nextValue.ownershipPeriod);
							if(options.length){
								const isCurrentOptionAvailable = options.filter(option => option.value == nextValue.previousPeriod?.value);
								if(!isCurrentOptionAvailable.length){
									nextValue.previousPeriod = options[0]
								}
							}else{
								nextValue.previousPeriod = null;
							}
						}
                        setValues(nextValue);
                    }}
					onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                    }}
					onSubmit={({ value }) => onFormSubmit(value) }
				>
					<Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Mesačný príjem (€)" width={'full'}>
                                <TextInput name="income" type="number" step="1" required={true} />
                            </FormField>
                        </Box>
					</Box>
					<Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField name="companyShare" label="Percento podielu na firme (%)" width={'full'}>
                                <TextInput name="companyShare" type="number" step="0.01" max={100} min={0.01} required={true} />
                            </FormField>
                        </Box>
						<Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Doba vlastníctva firmy (mesiace)" width={'full'}>
                                <TextInput name="ownershipPeriod" type="number" step="1" min={0} required={true} />
                            </FormField>
                        </Box>
						{values.ownershipPeriod < 18 && previousPeriodOptions.length !== 0 &&
						<Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Počet mesiacov v predchádzajúcom období" width={'full'}>
                                <Select 
									options={previousPeriodOptions}
									required={true}
									name="previousPeriod"
									labelKey="label"
									valueKey="value"
									dropHeight="large"
									value={values.previousPeriod}
									placeholder="Výber počet mesiacov "
									emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>}
					</Box>
					<Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
				</Form>
			</Box>
			
			{result && result.banks &&
			<Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className={"sticky-header"}>
                            <tr className="text-center">
                                <th>Banka</th>
								<th>Obrat</th>
                                <th>Zisk</th>
                                <th>Dividenda</th>
							</tr>
							</thead>
							<tbody>
								{result.banks.map(bank => {
									if(bank.type === "error"){
										return (
											<tr key={bank.bank} className="text-center">
												<td>{getBankById(bank.bank)?.pdfName}</td>
												<td></td>
												<td>{translateBankError(bank.error)}</td>
												<td></td>
											</tr>
										)
									}
									return (
										<tr key={bank.bank} className="text-center">
											<td>{getBankById(bank.bank)?.pdfName}</td>
											<td>{bank.turnover.map((d, idx) => {
                                                return <>{idx ? <br/> : ""}{transformResponseCellLabel(d)}</>
                                                })}
                                            </td>
                                            <td>{bank.profit.map((d, idx) => {
                                                return <>{idx ? <br/> : ""}{transformResponseCellLabel(d)}</>
                                                })}
                                            </td>
                                            <td>{bank.dividend.map((d, idx) => {
                                                return <>{idx ? <br/> : ""}{transformResponseCellLabel(d)}</>
                                                })}
                                            </td>					
										</tr>
									)
								})}

							</tbody>
						</table>
					</div>
					
				</Box>
			</Box>

			}
		</Box>

	)
}

export default TurnOverCompanyProfit;