import jwt from 'jsonwebtoken';
import ErrorService from "./ErrorService";
import { DateTime } from "luxon";

const AuthService = {
    logout: async function(){
        const accessToken = AuthService.getAccessToken();
        const authHeaders = !accessToken ? {} : {'Authorization': 'Bearer '+accessToken};

        //registering own abort signal managment and never calling it prevents cancelling the request after redirect
        const controller = new AbortController()
        const signal = controller.signal
        return fetch(
            process.env.REACT_APP_INTRANET_API_URL + '/oauth/logout',
            {
                method: 'post',
                signal: signal,
                headers: authHeaders,
                body: null
            }
        );
    },
    getRedirectUrl: function(){
      return window.location.origin+'/login-callback';
    },
    clearAuthData: function(){
        if (window.debug){
            console.log('clearing auth data');
        }
        localStorage.removeItem('ugc_profile');
        localStorage.removeItem('ugc_auth');
        localStorage.removeItem('ugc_cv');
        localStorage.removeItem('ugc_theme');
    },
    clearUserData: function(id){
        if (window.debug){
            console.log('clearing user data');
        }
        localStorage.removeItem('ugc_calc_details_'+id);
        localStorage.removeItem('ugc_calc_pin_'+id);
    },
    getAccessToken: function(){
        const auth = localStorage.getItem('ugc_auth');
        if (auth){
            let token = JSON.parse(auth);
            return token?.access_token;
        }
        return null;
    },
    getRefreshToken: function(){
        const auth = localStorage.getItem('ugc_auth');
        if (auth){
            let token = JSON.parse(auth);
            return token?.refresh_token;
        }
        return null;
    },
    getUser: function(){
        const userData = AuthService.getAccessToken();
        if (userData){
            return jwt.decode(userData);
        }
        return null;
    },
    getAuthData: function(){
        let auth = localStorage.getItem('ugc_auth');
        if (auth){
            auth = JSON.parse(auth);
            auth.user = AuthService.getUser();
            return auth;
        }
        return null;
    },
    getProfile: function(){
        const userData = localStorage.getItem('ugc_profile');
        if (userData){
            return JSON.parse(userData);
        }
        return {label: ""};
    },
    setProfile: function(profile){
        if (profile){
            localStorage.setItem('ugc_profile', JSON.stringify(profile));
            return true;
        }
        localStorage.removeItem('ugc_profile');
    },
    doLogout: function(){
        let id = AuthService.getProfile().id;
        AuthService.clearUserData(id);
        this.logout().then(
            res => {
                //silence
                if (window.debug){
                    console.log('logout success');
                }
                AuthService.clearAuthData();
                setTimeout(()=>{window.location = window.location.assign(window.location);}, 200);
            },
            res => {
                //silence
                //silence
                if (window.debug){
                    console.log('logout failed');
                }
                AuthService.clearAuthData();
                setTimeout(()=>{window.location = window.location.assign(window.location);}, 200);
            }
        );
    },
    doGetProfile: function(){
        const accessToken = AuthService.getAccessToken();
        const authHeaders = !accessToken ? {} : {'Authorization': 'Bearer '+accessToken};
        return fetch(
            process.env.REACT_APP_INTRANET_API_URL + '/oauth/user',
            {
                method: 'get',
                headers: authHeaders,
                body: null
            }
        )
            .then(res => res.json())
            .then(res => {
                console.log(res);
                AuthService.setProfile(res);
            })
            .catch((e)=>{
                console.error(e);
            });
    },
    _doRefreshToken: function(){
        if (window.debug){
            console.log('Refreshing access token');
        }
        const qParams = [
            `grant_type=refresh_token`,
            `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
            `client_secret=${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`,
            `refresh_token=${AuthService.getRefreshToken()}`,
        ].join("&");

        return new Promise((resolve, reject)=>{
            fetch(`${process.env.REACT_APP_INTRANET_API_URL}/oauth/token?${qParams}`)
                .then((res) => {
                    if (res.ok){
                       return res.json();
                    } else {
                        ErrorService.fromApi(res);
                        AuthService.doLogout();
                        reject();
                    }
                })
                .then(json => {
                    AuthService.handleTokenResponse(json);
                    resolve();
                })
        });
    },
    doRefreshToken: function(){
        if (this.checkRefreshTokenExpired()){
            this.doLogout();
            return false;
        }
        this._doRefreshToken()
            .catch((e)=>{
                console.error(e);
                AuthService.doLogout();
            });
    },
    handleUnauthorizedResponse: function(responseData){
        if (window.debug){
            console.log('Unauthorized response handler', responseData);
        }
        if (this.getRefreshToken()){
            this.doRefreshToken();
        }
    },
    handleTokenResponse: function(responseData){
        if (window.debug){
            console.log(responseData);
        }
        if (responseData.access_token){
            responseData.access_token_expires_at = DateTime.now().plus({seconds: responseData.expires_in}).toISO();
            responseData.refresh_token_expires_at = DateTime.now().plus({hours: 24}).toISO();
            localStorage.setItem('ugc_auth', JSON.stringify(responseData));
        }
    },
    startAccessTokenAutoRefresh: function(){
        if (!window.accessTokenAutoRefreshTimer){
            window.accessTokenAutoRefreshTimer = setTimeout(()=> {
                if (AuthService.getAccessToken() && AuthService.getRefreshToken() && !AuthService.checkRefreshTokenExpired()){
                    AuthService.doRefreshToken();
                }
                AuthService.stopAccessTokenAutoRefresh();
                AuthService.startAccessTokenAutoRefresh();
            }, 1000*60*10);//10mins
        }
    },
    stopAccessTokenAutoRefresh: function(){
        if (window.accessTokenAutoRefreshTimer){
            clearTimeout(window.accessTokenAutoRefreshTimer);
        }
    },
    checkTokenValid: async function(){
        try {
            if (window.debug){
                console.log('Validating access token');
            }

            //https://www.npmjs.com/package/jsonwebtoken
            const accessToken = AuthService.getAccessToken();
            if (!accessToken){
                throw new Error('Neviem overiť access token.');
            }

            const publicKey = await fetch(window.location.origin+'/assets/oauth2-public.key').then(response => response.text()).then(data => { return data; });
            if (!publicKey){
                //public key in pem format
                throw new Error('Neviem overit certifikát.');
            }

            //throws error
            return jwt.verify(accessToken, publicKey, {algorithms: ["RS256"]});
        } catch (e) {
            if (window.debug){
                console.log('Token validation failed: '+e.message);
            }
            return Promise.reject('Token validation failed: '+e.message);
        }
    },
    checkAccessTokenExpired: function(){
        let expired = false;
        let authData = this.getAuthData();
        if (authData && authData.access_token_expires_at){
            expired = DateTime.fromISO(authData.access_token_expires_at) <= DateTime.now();
        }
        if (window.debug){
            console.log('AccessTokenExpired', expired);
        }
        return expired;
    },
    checkRefreshTokenExpired: function(){
        let expired = false;
        let authData = this.getAuthData();
        if (authData && authData.refresh_token_expires_at){
            expired = DateTime.fromISO(authData.refresh_token_expires_at).toMillis() <= DateTime.now().toMillis();
        }
        if (window.debug){
            console.log('RefreshTokenExpired', expired);
        }
        return expired;
    }
};

export default AuthService;