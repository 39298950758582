import AuthService from "./AuthService";
import IntranetApiService from "./IntranetApiService";

const CalcPinService = {
    getKey: function(){
        const user = AuthService.getProfile();
        if (user){
            return 'ugc_calc_pin_'+user.id;
        }
        return null;
    },
    getStateData: function(){
        const key = this.getKey();
        if (key){
            const stateData = localStorage.getItem(key);
            if (stateData){
                return JSON.parse(stateData);
            }
        }
        return null;
    },
    setStateData: function(state){
        const key = this.getKey();
        if (key){
            if (state){
                localStorage.setItem(key, JSON.stringify(state));
                return state;
            }
            localStorage.removeItem(key);
        }
        return null;
    },
    isCalcPinned: function(key){
        let pinned = false;
        const list = this.getStateData();
        if(list && list.length){
            for (let i=0; i<list.length; i++){
                if (list[i].hasOwnProperty('calc_key') && list[i]['calc_key']===key){
                    pinned = true;
                    break;
                }
            }
        }
        return pinned;
    },
    unpinCalc: function(key){
        return IntranetApiService.unpinCalculator(key);
    },
    pinCalc: function(key){
        return IntranetApiService.pinCalculator(key);
    }
};

export default CalcPinService;