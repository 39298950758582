import ErrorService from "../../services/ErrorService";
import FormatService from "../../services/FormatService";

class LoanInsurancePdfExport {

    static BASE_ROW_OPTION_ALL = "1"
    static BASE_ROW_OPTION_CUSTOM = "2"

    static CUSTOM_ROW_OPTION_FIRST_LAST_ROW = "1"
    static CUSTOM_ROW_OPTION_VYROCIA = "2"
    static CUSTOM_ROW_OPTION_CUSTOM = "3"

    static generatePdf({values,result,chart, options}) {

        function getPageNumbersFromString(string){
            const pagesString = string.split(",")
            let pages_numbers = new Set();
            pagesString.forEach(page => {
                if(page.includes("-")){
                    let [start, end] = page.split("-")
                    if(isNaN(start) || isNaN(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not numbers")
                    start = Number(start)
                    end = Number(end)
                    if(!Number.isInteger(start) || !Number.isInteger(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not integers")
                    if(start > end) ErrorService.fromApp("getPageNumbersFromString start is greater than end")
                    for(let i = start; i <= end; i++){
                        pages_numbers.add(i)
                    }
                }else{
                    const page_num = Number(page)
                if(!Number.isInteger(page_num)) ErrorService.fromApp("getPageNumbersFromString page is not integer")
                pages_numbers.add(page_num)
                }
            })
            return pages_numbers;
        }


        let inputs = [
            {key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount},
            {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
            {key: 'annualInterestRate', label: 'Ročná úroková sadzba (%)', value: values.annualInterestRate},
            {key: 'decreasingInsuranceCover', label: 'Poistenie klesajúcej PS pre prípad smrti (€)', value: values.decreasingInsuranceCover},
            {key: 'maxUnderinsuredValue', label: 'Maximálne akceptované podpoistenie (€)', value: values.maxUnderinsuredValue},
        ];

        const pages_numbers = options.row_custom_option_row_num ? getPageNumbersFromString(options.row_custom_option_row_num) : null;

    
        return (`
        <div class="row-flex mb3">
            <div class="col-12">
                <p>Kalkulačka slúži na nastavenie poistných súm krytia smrti tak, aby v žiadnom okamihu nedošlo k nižšiemu
                plneniu z poistenia ako je zostatok úveru. Na základe zvolenej výšky krytia pre poistenie s klesajúcou PS
                sa dopočíta poistná suma pre poistenie s konštantnou PS.</p>
            </div>
        </div>
        <div class="row-flex mb3">
            <div class="col-12">
                <table>
                    ${inputs.map((i) => {
                        return `<tr>
                                 <th>${i.label}</th>
                                 <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                                </tr>`;
                    }).join('')}
                </table>
            </div>
        </div>
        
        <div class="row-flex">
             <div class="col-12">
                <div class="card">
                    <div class="card-block">
                        <div class="col-sm-9">
                            <h2 class="m-y-0">Konštantná PS</h2>
                            <div class="text-muted">Výška poistnej sumy pre poistenie smrti s konštantnou poistnou sumou</div>
                        </div>
                        <div class="col-sm-3 text-sm-right">
                            <h3><strong>${FormatService.round(result.constantInsuranceCover)} €</strong></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row-flex">
            <div class="col-12 text-center">
                ${chart ? chart.getSVG() : ''}
            </div>
        </div>
       
        <div class="row-flex mb3">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table styled-table">
                                <thead>
                                    <tr>
                                        <td></td>
                                        <th colspan='1'>Zostatok úveru</th>
                                        <th colspan='2'>Klesajúca PS</th>
                                        <th colspan='2'>Kombinácia</th>
                                    </tr>
                                    <tr>
                                        <th>Mesiac</th>
                                        <th>Zostatok po splátke</th>
                                        <th>Lineárne klesajúca PS</th>
                                        <th>Podpoistenie</th>
                                        <th>Kombinácia</th>
                                        <th>Podpoistenie/Nadpoistenie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${result && result.results && result.results.map((row, index)=>{
                                        if(options.row_option === LoanInsurancePdfExport.BASE_ROW_OPTION_CUSTOM){
                                            if(
                                            !(
                                                (options.row_custom_options.includes(LoanInsurancePdfExport.CUSTOM_ROW_OPTION_FIRST_LAST_ROW) && (index === 0 || index === result.results.length-1)) ||
                                                (options.row_custom_options.includes(LoanInsurancePdfExport.CUSTOM_ROW_OPTION_VYROCIA) && (index === 0 || (index + 1) % 12 === 0)) ||
                                                (options.row_custom_options.includes(LoanInsurancePdfExport.CUSTOM_ROW_OPTION_CUSTOM) && pages_numbers.has(index+1))
                                            )                    
                                            ){
                                                return "";
                                            }
                                         }

                                        return `<tr class='text-center'>
                                            <td>${row.month}.</td>
                                            <td>${FormatService.round(row.remainingPrincipal)}</td>
                                            <td>${FormatService.round(row.linearDecreasingInsuranceCover)}</td>
                                            <td>${FormatService.round(row.underInsuredValue)}</td>
                                            <td>${FormatService.round(row.combination)}</td>
                                            <td>${FormatService.round(row.underOverInsurance)}</td>
                                        </tr>`;
                                        }).join('')
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
        </div>
        
       
        `);
    }
}


export default LoanInsurancePdfExport;