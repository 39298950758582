import FormatService from "../../services/FormatService";

class InsuredSumsPdfExport {


    static generatePdf({values, result}){
        let inputs = [
            {key: 'sums', label: 'Poistné sumy podľa', value: values.sums == "prijmi" ? "príjmu" : "výdavkov"},
            {key: 'monthValue', label: values.sums == "prijmi" ? "Mesačný čistý príjem" : "Mesačné výdavky" , value: values.monthValue},
            {key: 'obligations', label: 'Suma finančných záväzkov', value: values.obligations},
            {key: 'grossSalary', label: 'Hrubý mesačný príjem', value: values.grossSalary},
            {key: 'partner', label: 'Partner poisteného', value: values.partner ? 'Áno' : 'Nie'},
            {key: 'partnerMonthValue', label: values.sums == "prijmi" ? "Mesačný čistý príjem" : "Mesačné výdavky", value: values.partnerMonthValue},
            {key: 'partnerObligations', label: 'Suma finančných záväzkov', value: values.partnerObligations},
            {key: 'partnerGrossSalary', label: 'Hrubý mesačný príjem', value: values.partnerGrossSalary},
            {key: 'children', label: 'Deti', value: values.children ? 'Áno' : 'Nie'},
            {key: 'jointObligations', label: 'Suma spoločných finančných záväzkov', value: values.jointObligations},
            {key: 'insurance', label: 'Poistenie PN/DNL', value: values.insurance ? 'Áno' : 'Nie'},
        ];
        //TODO #21 textacia
        return (`
        <div class="row-flex mb3">
            <div class="col-12">
                <p>Kalkulačka slúži na výpočet sadzby, ktorú keď po uplynutí kratšej fixácie dostaneme, budú náklady po skončení
                dlhšej fixácie totožné.</p>
            </div>
        </div>
        <div class="row-flex mb3">
            <div class="col-12">
                <table>
                    ${inputs.map((i) => {
                        if(
                            (!values.insurance && ["grossSalary","partnerGrossSalary"].includes(i.key)) ||
                            (!values.partner && ['partnerMonthValue', 'partnerObligations', 'partnerGrossSalary', 'jointObligations'].includes(i.key))
                        ){
                            return "";
                        }
                        return `<tr>
                                <th>${i.label}</th>
                                <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                                </tr>`;
                    }).join('')}
                </table>
            </div>
        </div>
        <div class="row-flex mb3">
        
        <div class="col-12">
        <table class="table styled-table">
        <thead>
            <tr>
                <td>&nbsp;</td>
                <th colspan="2">Poistený</th>
                ${result.partner ? '<th colspan="2">Partner poisteného</th>' : ""}
                ${result.kids ? '<th colspan="2">Deti</th>' : "" }
            </tr>
            <tr>
                <th>&nbsp;</th>
                <th>Optimálna PS</th>
                <th>Minimálna PS</th>
                ${result.partner ?`                
                    <th>Optimálna PS</th>
                    <th>Minimálna PS</th>
                ` : ""
                }

                ${result.kids ?`                
                    <th>Optimálna PS</th>
                    <th>Minimálna PS</th>
                ` : ""
                }
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Smrť akákoľvek - klesajúca PS</th>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.deathOptimal,2)}</td>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.deathMinimal,2)}</td>
                ${result.partner ?`                
                    <td style="text-align: center">${FormatService.round(result.partner.deathOptimal,2)}</td>
                    <td style="text-align: center">${FormatService.round(result.partner.deathMinimal ,2)}</td>
                ` : ""
                }
                ${result.kids ?`                
                    <td style="text-align: center">-</td>
                    <td style="text-align: center">-</td>
                ` : ""
                }

            </tr>
            <tr>
                <th>Invalidita od 40%/70% - klesajúca PS</th>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.invalidityOptimal,2)}</td>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.invalidityMinimal,2)}</td>
                ${result.partner ?`                    
                    <td style="text-align: center">${FormatService.round(result.partner?.invalidityOptimal, 2)}</td>
                    <td style="text-align: center">${FormatService.round(result.partner?.invalidityMinimal, 2)}</td>
                    ` : ""
                }
                ${result.kids ?`                    
                        <td style="text-align: center">-</td>
                        <td style="text-align: center">-</td>
                    ` : ""
                }


            </tr>
            <tr>
                <th>Kritické choroby</th>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.criticalDiseasesOptimal,2)}</td>
                <td style="text-align: center">${FormatService.round(result.insuredPerson?.criticalDiseasesMinimal,2)}</td>
                ${result.partner ? `                    
                        <td style="text-align: center">${FormatService.round(result.partner?.criticalDiseasesOptimal, 2)}</td>
                        <td style="text-align: center">${FormatService.round(result.partner?.criticalDiseasesMinimal, 2)}</td>
                    `: ""
                }
                ${result.kids ? `                    
                        <td style="text-align: center">${FormatService.round(result.kids?.criticalDiseasesOptimal , 2)}</td>
                        <td style="text-align: center">${FormatService.round(result.kids?.criticalDiseasesMinimal , 2)}</td>
                    `: ""
                }
            </tr>
            <tr>
                <th>Trvalé následky úrazom</th>
                <td style="text-align: center">${FormatService.round(result.insuredPerson.permanentInjuryConsequencesOptimal,2)}</td>
                <td style="text-align: center">${FormatService.round(result.insuredPerson?.permanentInjuryConsequencesMinimal,2)}</td>
                ${result.partner ? `                    
                        <td style="text-align: center">${FormatService.round(result.partner?.permanentInjuryConsequencesOptimal, 2)}</td>
                        <td style="text-align: center">${FormatService.round(result.partner?.permanentInjuryConsequencesMinimal, 2)}</td>
                    `: ""
                }
                ${result.kids ? `                    
                        <td style="text-align: center">${FormatService.round(result.kids?.permanentInjuryConsequencesOptimal,2)}</td>
                        <td style="text-align: center">${FormatService.round(result.kids?.permanentInjuryConsequencesMinimal ,2)}</td>
                    `: ""
                }
            </tr>
            <tr>
                <th>PN / DNL</th>
                <td colspan="2" style="text-align: center">${result.insuredPerson.pnDnl === null ? "-" : `${FormatService.round(result.insuredPerson.pnDnl,0)}/deň`}</</td>
                ${result.partner ? `
                    <td colspan="2" style="text-align: center">${result.partner?.pnDnl === null ? "-" : `${FormatService.round(result.partner?.pnDnl, 0)}/deň`}</td>
                `: ""}
                ${result.kids ? `
                    <td colspan="2" style="text-align: center">-</td>
                ` : ""}
            </tr>
            
        </tbody>
    </table>
    <br>
    <br>
    Poistné riziká Smrť akákoľvek a Invalidita od 40%/70% je možné poistiť aj spoločne v balíčku 1. rizika. V takom prípade bude plnené to riziko, ktoré nastane prvé (a druhé zaniká). Odporúčaná je invalidita od 40%, výber závisí od finančných možností klienta.
            
         </div>
    `
        )
    }
}


export default InsuredSumsPdfExport;