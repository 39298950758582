import {Box, Button, CheckBox, Form, FormField, Heading, RadioButtonGroup, Select, TextInput} from 'grommet';
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SZCOHelp from "./SZCOHelp";
import SZCOPdfExport from "./SZCOPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import _ from "lodash";

function SZCO(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'SZCO';
    const calcStateVersion = 4;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'SZČO kalkulačka',
        mode: 'net',

        children: [],
        healthPayment: 800,
        socialPayment: 2500,
        months: 12,
        salary: 20000,
        netIncome: 1000,
        salaryPeriod: "YEAR_2024",
        spouseWithoutIncome: false,
        isFlatRate: true,
        otherPaymentValue: 0,

        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const periodsInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'periods', [], calcStateVersion);
    const periodsFilterInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'periodsFilter', [], calcStateVersion);

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [periodsList, setPeriodsList] = useState(periodsInitialState);
    const [filteredPeriodsList, setFilteredPeriodsList] = useState(periodsFilterInitialState);


    function getChildrenYearOptions(){
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 25;
        const options = [];
        for(let i = currentYear; i >= lastYear; i-- ){
            options.push({value: i, label: i});
        }
        return options;
    }
    const childrenYearOptions = getChildrenYearOptions();

    const childrenMonthOptions = [...Array(12).keys()].map(key => {
        return {"value": key+1, "label": new Date(0, key).toLocaleString('sk', { month: 'long' })}
    });

    function child(month, year){
        return {
            month: month ? month : childrenMonthOptions[0],
            year: year ? year : childrenYearOptions[0].value,
        }
    }

    function saveCalcDetail(values, result, periodsList, filteredPeriodsList){
        const calcDetailState = {
            values:values,
            result:result,
            periodsList:periodsList,
            filteredPeriodsList:filteredPeriodsList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    const reloadPeriodsList = useCallback(() => {
        ApiService.getSZCOPeriodsList()
            .then((res) => {
                    const r = res.periods.map((period)=>{
                        return {
                            id: period,
                            name: period.substr(5) //"YEAR_" part of YEAR_2020
                        }
                    });

                    setPeriodsList(r);
                    setFilteredPeriodsList(r);
                    saveCalcDetail(values, result, r, r);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reloadPeriodsList();
    }, [reloadPeriodsList]);

    function childToDTO(child){
        return {
            month: child.month.value,
            year: child.year
        }
    }

    function onFormSubmit(values){
        let reqData = {
            childrenData: values.children.map(child => childToDTO(child)),
            healthPayment: values.healthPayment,
            socialPayment: values.socialPayment,
            months: values.months,
            salary: values.mode==='net' ? values.salary : values.netIncome,
            salaryPeriod: values.salaryPeriod,
            spouseWithoutIncome: values.spouseWithoutIncome,
            otherPayment: {
                isFlatRate: values.isFlatRate,
                value: values.otherPaymentValue
            }
        };

        setValues(Object.assign({}, values, {activeRequest: true}));
        ApiService.call('post', '/v2/szco/'+values.mode, reqData)
            .then((res) => {
                    setResult(res);
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    saveCalcDetail(values, res, periodsList, periodsList);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'szco';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SZCOPdfExport({values:values,result:result,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    function addChild(){
        const children = [...values.children, child()]
        setValues(Object.assign({}, values, {children}));
    }

    function removeChild(index){
        const children  = values.children.filter((child, i) => i != index)

        const newValues = Object.assign({}, values, {children});
        setValues(newValues);
    }

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SZCOHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        let myResult = result;
                        if (values.mode!==nextValue.mode){
                            setResult(null);
                            myResult = null;
                        }
                        const cleanState = {};
                        _.forIn(nextValue, (value, key) => {
                            _.set(cleanState, key, value);
                        });

                        setValues(cleanState);
                        saveCalcDetail(nextValue, myResult, periodsList, periodsList);
                    }}
                    onReset={() => {
                        let init = defaultValues;
                        init.mode = values.mode;
                        setValues(init);
                        setResult(null);
                        saveCalcDetail(init, null, periodsList, periodsList);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" options={[
                                {value: 'net',label: "Čistý príjem"},
                                {value: 'turnover',label: "Obrat"},
                            ]} />
                        </Box>
                    </Box>

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            {values.mode==='net' && <FormField label="Obrat (€)" width={'full'}>
                                <TextInput name="salary" type="number" step="0.01" required={true} />
                            </FormField>}

                            {values.mode!=='net' && <FormField label="Čistý mesačný príjem (€)" width={'full'}>
                                <TextInput name="netIncome" type="number" step="0.01" required={true} />
                            </FormField>}
                        </Box>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Obdobie" width={'full'}>
                                <Select options={filteredPeriodsList}
                                        required={true}
                                        name="salaryPeriod"
                                        labelKey="name"
                                        valueKey={{ key: 'id', reduce: true }}
                                        dropHeight="large"
                                        value={values.salaryPeriod}
                                        placeholder="Výber obdobia"
                                        onClose={() => setFilteredPeriodsList(periodsList)}
                                        onSearch={searchText => {
                                            const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                            const exp = new RegExp(escapedText, 'i');
                                            setFilteredPeriodsList( periodsList.filter(o => {return exp.test(o.name)}) );
                                        }}
                                        emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Počet mesiacov SZČO" width={'full'}>
                                <Select options={[1,2,3,4,5,6,7,8,9,10,11,12]}
                                        required={true}
                                        name="months"
                                        dropHeight="large"
                                        value={values.months}
                                        placeholder="Počet mesiacov SZČO"
                                />
                            </FormField>
                        </Box>

                    </Box>

                    <Box direction={'row-responsive'}>
     
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Bez príjmu" width={'full'}>
                                <CheckBox name="spouseWithoutIncome" label="Manžel/-ka bol/-a v minulom roku bez príjmu" />
                            </FormField>
                        </Box>
                    </Box>

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Zdravotné poistenie (€)" width={'full'}>
                                <TextInput name="healthPayment" type="number" step="0.01" required={true}/>
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Sociálne poistenie (€)" width={'full'}>
                                <TextInput name="socialPayment" type="number" step="0.01" required={true}/>
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Paušálne náklady" width={'full'}>
                                <CheckBox name="isFlatRate" label="Uplatnené paušálne náklady" />
                            </FormField>
                        </Box>

                        {!values.isFlatRate &&
                            <Box basis={'1/3'} direction={'row'} align={'center'}>
                                <FormField label="Iné náklady (€)" width={'full'}>
                                    <TextInput name="otherPaymentValue" step="0.01" type="number"/>
                                </FormField>
                            </Box>
                        }
                    </Box>

                    <Heading level={3} margin={{bottom:'none'}}>Nezaopatrené deti</Heading>

                    {values.children.length !== 0 && values.children.map((child, index) => {
                        return (
                            <Box direction={'row-responsive'} key={'child-'+index}>

                                <Box basis={'1/3'} direction={'row'} align={'center'}>
                                    <FormField label="Mesiac narodenia" width={'full'}>
                                        <Select options={childrenMonthOptions}
                                                required={true}
                                                name={`children[${index}][month]`}
                                                dropHeight="large"
                                                value={values.children[index].month}
                                                labelKey="label"
                                                valueKey={{key: "value"}}
                                        />
                                    </FormField>
                                </Box>
                                <Box basis={'1/3'} direction={'row'} align={'center'} justify={'center'}>
                                    <FormField label="Rok narodenia" width={'full'}>
                                        <Select options={childrenYearOptions}
                                                required={true}
                                                name={`children[${index}][year]`}
                                                dropHeight="large"
                                                labelKey="label"
                                                valueKey={{key: "value", reduce: true}}
                                                value={values.children[index].year}
                                        />
                                    </FormField>
                                    <Box direction={'row'} alignSelf={'end'}>
                                        <Button secondary pad={'medium'} margin={{bottom:'15px'}} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=> removeChild(index)} />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}

                    <Box direction={'row-responsive'} margin={{bottom:'medium'}}>
                        <Button type="button" secondary label="Pridať dieťa" icon={<Icons.AddCircle/>} onClick={()=> addChild()} />
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result!==null &&
            <Box direction={'row-responsive'} pad={'medium'} margin={{vertical: 'medium'}} border={true} round={'small'} background={'white'}>
                <Box direction={'row-responsive'} basis={props.size==='medium'? '2/3' : '1/3'} border={true} round={'small'} className='overflow-hidden'>
                    <table className="table styled-table">
                        <tbody>
                        <tr>
                            <th>Čiastkový základ dane </th>
                            <td className="text-right">{FormatService.round(result.partialTaxBase)}€</td>
                        </tr>
                        <tr>
                            <th>Odpočítateľná položka </th>
                            <td className="text-right">{FormatService.round(result.deductibleItem)}€</td>
                        </tr>
                        <tr>
                            <th>Základ dane</th>
                            <td className="text-right">{FormatService.round(result.taxBase)}€</td>
                        </tr>

                        <tr>
                            <th>Daň </th>
                            <td className="text-right">{FormatService.round(result.tax)}€</td>
                        </tr>
                        <tr>
                            <th>Daňový bonus na deti</th>
                            <td className="text-right">{FormatService.round(result.childrenBonus)}€</td>
                        </tr>
                        <tr>
                            <th>Daň zňížená od daňový bonus</th>
                            <td className="text-right">{FormatService.round(result.taxWithoutChildrenBonus)}€</td>
                        </tr>
                        {result.taxWithoutChildrenBonus < 0 && 
                            <tr>
                                <th>{values.mode==='net' ? 'Čistý príjem' : 'Obrat'} bez daňového preplatku </th>
                                <td className="table-cell-highlighted text-right">{FormatService.round(result.incomeWithoutTaxOverpayment, 2)}€</td>
                            </tr>
                        }
                        <tr>
                            <th>{values.mode==='net' ? 'Čistý príjem' : 'Obrat'}</th>
                            <td className="table-cell-important text-right">{values.mode==='net' ? FormatService.round(result.netSalary) : FormatService.round(result.turnover)}€</td>
                        </tr>
                        <tr>
                            <th>Mesačné zdravotné odvody pre ďalšie obdobie </th>
                            <td className="table-cell-highlighted text-right">{FormatService.round(result.healthLevy)}€</td>
                        </tr>
                        <tr>
                            <th>Mesačné sociálne odvody pre ďalšie obdobie </th>
                            <td className="table-cell-highlighted text-right">{FormatService.round(result.socialLevy)}€</td>
                        </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
            }

        </Box>
    );
}

export default SZCO;