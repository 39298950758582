import FormatService from "../../services/FormatService";

function SavingBuildingPdfExport({values,result,chart,activeResultsFilterList,activeProductsList,extraPayments}) {
    let inputs = [
        {key: 'payment', label: 'Platba (€)', value: values.payment},
        {key: 'periodInYears', label: 'Doba sporenia (r)', value: values.periodInYears},
        {key: 'baseRegularity', label: 'Pravideľnosť platby', value: values.baseRegularity.name},
        {key: 'firstPaymentTimestamp', label: 'Prvá platba', value: FormatService.dateFromTimestamp(new Date(values.firstPaymentTimestamp).getTime())},
        {key: 'targetAmount', label: 'Cieľová suma (€)', value: values.targetAmount},


    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    
    <div class="row-flex mb3">
        <div class="col-12">
            <h3 style="margin-bottom:10px">Mimoriadne vklady</h3>
            <div class="row">
                 ${extraPayments.map((f) => {
                    return `<div class="col-sm-3">
                                <div class="card">
                                     <div class="card-body" style="padding:10px;">
                                          <div class="row">
                                              <div class="col-sm-6"><span class="icon-refresh"></span> Typ:</div>
                                              <div class="col-sm-6"><b>${f.regularityObject.name}</b></div>
                                          </div>
                                          <div class="row">
                                              <div class="col-sm-6"><span class="icon-calendar"></span> Mesiac:</div>
                                              <div class="col-sm-6"><b>${f.month}</b> r</div>
                                          </div>
                                          <div class="row">
                                               <div class="col-sm-6"><span class="icon-wallet"></span> Vklad:</div>
                                               <div class="col-sm-6"><b>${f.payment}</b> €</div>
                                          </div>
                                     </div>
                                </div>
                           </div>`;
                }).join('')}   
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
   
    <div class="row-flex mb3">
        <div class="col-12">
            <table class="table styled-table text-center">
                 <thead>
                 <tr>
                     <th colspan="4"></th>
                     ${activeProductsList && activeProductsList.map((product, c)=>`<th colspan="${activeResultsFilterList.length ? activeResultsFilterList.length : 1}">${product.fullName}</th>`).join('')}
                 </tr>
                 <tr>
                     <th>Obdobie</th>
                     <th>Platba (€)</th>
                     <th>Zaplatené (€)</th>
                     <th>Štátna prémia (€)</th>
                     ${activeProductsList.map(()=>
                         activeResultsFilterList.map((filter)=>
                              `<th>${filter.shortName}</th>`
                         ).join('')
                     ).join('')}
                 </tr>
                 </thead>
                 <tbody>
                 ${result.rows && result.rows.map((row, monthIndex)=>{
                                return `<tr class='text-center'>
                                    <td>${row.paymentDates}</td>
                                    <td>${FormatService.round(row.payments)}</td>
                                    <td>${FormatService.round(row.payedList)}</td>
                                    <td>${FormatService.round(row.stateBonus)}</td>
                                    ${result.productsData.map((product, c)=>
                                        activeResultsFilterList.map((filter)=>
                                            `<td>${FormatService.round(product[filter.id][monthIndex])}</td>`
                                        ).join('')
                                    ).join('')}
                                </tr>`;
                            }).join('')
                 }
                 </tbody>
            </table>
        </div>
    </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default SavingBuildingPdfExport;