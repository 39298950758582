import './Helpbar.css';
import React from "react";
import {Box, Button, Collapsible, ResponsiveContext} from "grommet";
import * as Icons from "grommet-icons";

function Helpbar(props) {
    const size = React.useContext(ResponsiveContext);
    return (
        <Collapsible direction={'horizontal'} open={props.open}>
            <Box width={size==='small'? 'full' : 'medium'} margin={'none'} fill={"vertical"} background={'white'} pad={'small'} elevation={'xlarge'} id={'Helpbar'} direction={'column'} align={'center'}>
                <Box basis={'auto'} alignSelf={'end'} margin={{bottom:'medium'}}>
                    <Button align={'center'} label={<Icons.Close />} onClick={() => {props.closeaction();} } />
                </Box>
                <Box pad={{left: 'small',right: 'small',top: 'none',bottom: 'medium'}} id={'HelpbarContent'}>
                    {props.children}
                </Box>
            </Box>
        </Collapsible>
    );
}

export default Helpbar;
