import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import FormatService from "../../services/FormatService";


class LoanAdvancedPdfExport {

    static BASE_ROW_OPTION_ALL = "1"
    static BASE_ROW_OPTION_CUSTOM = "2"

    static CUSTOM_ROW_OPTION_FIRST_LAST_ROW = "1"
    static CUSTOM_ROW_OPTION_MIMORIADNE_VKLADY = "2"
    static CUSTOM_ROW_OPTION_ZMENA_UROK_SADZBY = "3"
    static CUSTOM_ROW_OPTION_VYROCIA = "4"
    static CUSTOM_ROW_OPTION_CUSTOM = "5"

    static mapColumnNameToLabel(name){
        const columns = {
            month: "Mesiac",
            paymentDate: "Dátum splátky",
            monthLength: "Počet dní medzi splátkami",
            interestRate:  "ú.s. hlavný úver" ,
            interestRateRest: "ú.s. doplnkový úver" ,
            payment: "Splátka" ,
            paymentReduced: "Splátka (znížená)",
            remainingPrincipalBeforePayment: "Zostatok pred splátkou" ,
            feeBeforePayment: "Poplatok pred splátkou",
            principalPayment: "Istina" ,
            interestPayment: "Úrok predpis",
            interestReturned: "Úrok vrátený",
            interestCumulative: "Úrok kumulatívny" ,
            interestAliquot: "Úrok alikvotny" ,
            interestDept: "Úrok doplatiť",
            feeAfterPayment: "Poplatok po splátke",
            pricnipalTotal: "Zaplatená istina",
            interestTotal: "Zaplatený úrok",
            remainingPrincipalAfterPayment: "Zostatok po splátke",
            mimSplatka: "Mim. splátka",
            cost: "Náklady" ,
            totalCost: "Celkové náklady",

        }

        return columns[name]
    }

    static getAvailableColumns(values){
        const columns = LoanAdvancedPdfExport.getAllColumns();
        const remove_columns = []
        if(values.dates) remove_columns.push('paymentDate')

        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2017)) remove_columns.push('interestRateRest')
        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2017)) remove_columns.push('paymentReduced')

        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2017)) remove_columns.push('interestReturned')
        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2018)) remove_columns.push('interestCumulative')
        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2018)) remove_columns.push('interestAliquot')
        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment)) remove_columns.push('interestDept')
        if(!(values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment)) remove_columns.push('feeAfterPayment')

        return columns.filter(column => !remove_columns.includes(column))
    }

    static getAllColumns(){
        return [
            'month',
            'paymentDate',
            'monthLength',
            'interestRate',
            'interestRateRest',
            'payment',
            'paymentReduced',
            'remainingPrincipalBeforePayment',
            'feeBeforePayment',
            'principalPayment',
            'interestPayment',
            'interestCumulative',
            'interestAliquot',
            'interestDept',
            'feeAfterPayment',
            'pricnipalTotal',
            'interestTotal',
            'remainingPrincipalAfterPayment',
            'mimSplatka',
            'cost',
            'totalCost',
        ];
    }

    static generatePdf({
        values,
        result,
        extraPayments,
        annualInterestRates,
        spmInterestRatesRest,
        chart,
        options
    }){
        /*
        options example:

        columns: selectedColumns,
        rows: {
            base: LoanAdvancedPdfExport.BASE_ROW_OPTION_CUSTOM,
            customOptions: [CUSTOM_ROW_OPTION_FIRST_LAST_ROW],
            customOptionRowNum: "1-5,6-12"
        },

        */
        function getPageNumbersFromString(string){
            const pagesString = string.split(",")
            let pages_numbers = new Set();
            pagesString.forEach(page => {
                if(page.includes("-")){
                    let [start, end] = page.split("-")
                    if(isNaN(start) || isNaN(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not numbers")
                    start = Number(start)
                    end = Number(end)
                    if(!Number.isInteger(start) || !Number.isInteger(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not integers")
                    if(start > end) ErrorService.fromApp("getPageNumbersFromString start is greater than end")
                    for(let i = start; i <= end; i++){
                        pages_numbers.add(i)
                    }
                }else{
                    const page_num = Number(page)
                if(!Number.isInteger(page_num)) ErrorService.fromApp("getPageNumbersFromString page is not integer")
                pages_numbers.add(page_num)
                }
            })
            return pages_numbers;
        }

        function generateTable(){
            let html = '<table className="table styled-table compact-table">'
            html += "<thead>"
            html += "<tr>"
            const availableColumns = LoanAdvancedPdfExport.getAvailableColumns(values)
            availableColumns.forEach(column => {
                html += options.columns.includes(column) ? `<th>${LoanAdvancedPdfExport.mapColumnNameToLabel(column)}</th>` : ""
            })

            html += "</tr>"
            html += "</thead>"                    
            html +="<tbody>"

            const pages_numbers = options.row_custom_option_row_num ? getPageNumbersFromString(options.row_custom_option_row_num) : null;

            html += result.loanResultData.map((row, index, loanResult)=>{
                if(options.row_option === LoanAdvancedPdfExport.BASE_ROW_OPTION_CUSTOM){
                    if(
                    !(
                        (options.row_custom_options.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_FIRST_LAST_ROW) && (index === 0 || index === result.loanResultData.length-1)) ||
                        (options.row_custom_options.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_MIMORIADNE_VKLADY) && row.extraPayment) ||
                        (options.row_custom_options.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_VYROCIA) && (index === 0 || (index + 1) % 12 === 0)) ||
                        (options.row_custom_options.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_ZMENA_UROK_SADZBY) && loanResult[0].interestRate !== row.interestRate) ||
                        (options.row_custom_options.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_CUSTOM) && pages_numbers.has(index+1))
                    )                    
                    ){
                        return "";
                    }

                }
                return `<tr class="text-center">
                        ${options.columns.includes("month") && availableColumns.includes("month") ? `<td>${(index+1)}</td>` : ''}
                        ${options.columns.includes("paymentDate") && availableColumns.includes("paymentDate") ? '<td>'+FormatService.dateFromTimestamp(row.paymentDate)+'</td>' : ''}
                        ${options.columns.includes("monthLength") && availableColumns.includes("monthLength") ? '<td>'+row.monthLength+'</td>' : ''}
                        ${options.columns.includes("interestRate") && availableColumns.includes("interestRate") ? '<td>'+FormatService.round(row.interestRate)+'</td>' : ''}
                        ${options.columns.includes("interestRateRest") && availableColumns.includes("interestRateRest") ? `<td>${FormatService.round(row.interestRateRest)}</td>` : ''}
                        ${options.columns.includes("payment") && availableColumns.includes("payment") ? `<td>${FormatService.round(row.payment)}</td>` : ''}
                        ${options.columns.includes("paymentReduced") && availableColumns.includes("paymentReduced") ? `<td>${FormatService.round(row.paymentReduced)}</td>` : ''}
                        ${options.columns.includes("remainingPrincipalBeforePayment") && availableColumns.includes("remainingPrincipalBeforePayment") ? `<td>${FormatService.round(row.remainingPrincipalBeforePayment)}</td>` : ''}
                        ${options.columns.includes("feeBeforePayment") && availableColumns.includes("feeBeforePayment") ? `<td>${FormatService.round(row.feeBeforePayment)}</td>` : ''}
                        ${options.columns.includes("principalPayment") && availableColumns.includes("principalPayment") ? `<td>${FormatService.round(row.principalPayment)}</td>` : ''}
                        ${options.columns.includes("interestPayment") && availableColumns.includes("interestPayment") ? `<td>${FormatService.round(row.interestPayment)}</td>` : ''}
                        ${options.columns.includes("interestReturned") && availableColumns.includes("interestReturned") ? `<td>${FormatService.round(row.interestReturned)}</td>` : ''}
                        ${options.columns.includes("interestCumulative") && availableColumns.includes("interestCumulative") ? `<td>${FormatService.round(row.interestCumulative)}</td>` : ''}
                        ${options.columns.includes("interestAliquot") && availableColumns.includes("interestAliquot") ? `<td>${FormatService.round(row.interestAliquot)}</td>` : ''}
                        ${options.columns.includes("interestDept") && availableColumns.includes("interestDept") ? `<td>${FormatService.round(row.interestDept)}</td>` : ''}
                        ${options.columns.includes("feeAfterPayment") && availableColumns.includes("feeAfterPayment") ? `<td>${FormatService.round(row.feeAfterPayment)}</td>` : ''}
                        ${options.columns.includes("pricnipalTotal") && availableColumns.includes("pricnipalTotal") ? `<td>${FormatService.round(row.pricnipalTotal)}</td>` : ''}
                        ${options.columns.includes("interestTotal") && availableColumns.includes("interestTotal") ? `<td>${FormatService.round(row.interestTotal)}</td>` : ''}
                        ${options.columns.includes("remainingPrincipalAfterPayment") && availableColumns.includes("remainingPrincipalAfterPayment") ? `<td>${FormatService.round(row.remainingPrincipalAfterPayment)}</td>` : ''}
                        ${options.columns.includes("mimSplatka") && availableColumns.includes("mimSplatka") ? `<td>${FormatService.round(row.extraPayment ? row.extraPayment.mimSplatka : 0)}</td>` : ''}
                        ${options.columns.includes("cost") && availableColumns.includes("cost") ? `<td>${FormatService.round(row.cost)}</td>` : ''}
                        ${options.columns.includes("totalCost") && availableColumns.includes("totalCost") ? `<td>${FormatService.round(row.totalCost)}</td>` : ''}
                        </tr>`;

                }).join('')
                    
            html += "</tbody>"
            html += "</table>"

            return html;
        }

        let inputs = [
            {key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount},
            {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
            {key: 'baseInterestRate', label: 'Základná úroková sadzba (%)', value: values.baseInterestRate},
        ];
    
        if (values.bank){
            inputs.push({key: 'bankObject', label: 'Financujúca banka', value: values.bankObject.name});
            inputs.push({key: 'loanFee', label: 'Poplatok za úver (€)', value: values.loanFee});
            if (values.bankObject.canUseDecreaseByPayment && values.spmUseDecreateByPayment){
                inputs.push({key: 'spmUseDecreateByPayment', label: 'Použiť znižovanie poplatku splátkou', value: 'Áno'});
            }
        }
    
        if (values.spm){
            inputs.push({key: 'spmType', label: 'Typ štátneho príspevku pre mladých', value: () => {
                    let t;
                    switch (values.spmType) {
                        case ApiService.consts().spm.spm2018: t='ŠPM od 2018'; break;
                        case ApiService.consts().spm.spm2017: t='ŠPM do 2017'; break;
                        case ApiService.consts().spm.none: t='-'; break;
                        default: break;
                    }
                    return t; }});
            if(values.spmType == ApiService.consts().spm.spm2017) {
                inputs.push({
                    key: 'assuranceAmount',
                    label: 'Hodnota zabezpečenia (€)',
                    value: values.spmAssuranceAmount
                });
            }
            inputs.push({key: 'baseSpmInterestRatesRest', label: 'Úroková sadzba pre doplnkový úver', value: values.baseSpmInterestRatesRest});
        }
    
        if (values.dates && values.datesFirstPayment && values.datesDissaving){
            inputs.push({key: 'datesDissaving', label: 'Začiatok čerpania', value: FormatService.dateFromTimestamp(new Date(values.datesDissaving).getTime())});
            inputs.push({key: 'datesFirstPayment', label: 'Prvá splátka', value: FormatService.dateFromTimestamp(new Date(values.datesFirstPayment).getTime())});
        }
    
        if(annualInterestRates.length) {
            inputs.push({
                key: 'annualInterestRates',
                label: 'Zmeny úrokovej sadzby pre hlavný úver',
                value: annualInterestRates.length ? 'Áno' : 'Nie'
            });
        }
        if(spmInterestRatesRest.length) {
            if (values.spm && values.spmType === ApiService.consts().spm.spm2017) {
                inputs.push({
                    key: 'spmInterestRatesRest',
                    label: 'Zmeny úrokovej sadzby pre doplnkový úver',
                    value: spmInterestRatesRest.length ? 'Áno' : 'Nie'
                });
            }
        }
        return (`
        <div class="row-flex mb3">
            <div class="col-12">
                <table>
                    ${inputs.map((i) => {
                        return `<tr>
                                 <th>${i.label}</th>
                                 <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                                </tr>`;
                    }).join('')}
                </table>
            </div>
        </div>` +
    
        (extraPayments && extraPayments.length ? `
        <div class="row-flex mb3">
            <div class="col-12">
                <table class="col-sm-4 styled-table">
                <thead>
                <tr><th colspan="3">Mimoriadne splátky</th></tr>
                <tr>
                    <th>Mesiac</th>
                    <th>Pravidelnosť</th>
                    <th>Typ</th>
                    <th>Výška vkladu</th>
                </tr>
                </thead>
                <tbody>
                ${extraPayments && extraPayments.map((payment) => {
                    return `<tr>
                        <td class="text-sm-center">${payment.recurring ? '-' : (payment.month + 1) + '.'}</td>
                        <td class="text-sm-center">${payment.recurring ? FormatService.pluralify(payment.recurring, 'Každý ', 'Každé ', 'Každých ') + payment.recurring + FormatService.pluralify(payment.recurring, ' mesiac', ' mesiace', ' mesiacov') : 'Jednorázový vklad'}</td>
                        <td class="text-sm-center">${payment.isPaymentTerm ? 'Zníženie splatnosti':'Zníženie splátky'}</td>
                        <td class="text-sm-center">${payment.payment} €</td>
                    </tr>`;
                }).join('')}
                </tbody>
                </table>
            </div>
        </div>` : ``
        ) +
    
        `<div class="row-flex mb3">
            <div class="col-12">
                ${generateTable()}
            </div>
         </div>
          
        
        <div class="row-flex">
            <div class="col-12 text-center">
                ${chart ? chart.getSVG() : ''}
            </div>
        </div>
        `);
    }
}


   


export default LoanAdvancedPdfExport;