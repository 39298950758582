import { Box, CheckBoxGroup, Heading, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import FormatService from "../../services/FormatService";
import LoanBankComparisonPdfExport from "./LoanBankComparisonPdfExport";

const CUSTOM_DATA_DEFAULT = [12, 24, 36 , 40, 48, 60, 72, 84, 120] //mesiace

const LoanBankComparisionColumnRowSelection = ({template , values = null, customData = CUSTOM_DATA_DEFAULT}) => {

    const getPeriodsOptions = (periods, template_periods) => {   
        const allPeriods = [];
        [...periods, ...template_periods].forEach(period => {
            if(!allPeriods.includes(period)){
                allPeriods.push(period)
            }
        })
        sortPeriods(allPeriods)
        return allPeriods.map(period => {
            const value = period;
            const label = period%12 === 0 ? `${period/12} ${FormatService.pluralify(period/12,' rok', ' roky',' rokov')}` : `${period} ${FormatService.pluralify(period,' mesiac', ' mesiace',' mesiacov')}`
            return {label, value, disabled: template && !customData.includes(period) ? true : false}
        })
    }
    

    function sortPeriods(array) {
        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array.length; j++) {
                if (array[j] > array[j + 1]) {
                    let temp = array[j];
                    array[j] = array[j + 1];
                    array[j + 1] = temp;
                }
            }
        }
        return array;
    }

    const getRowsOptions = () => {
        const rows = LoanBankComparisonPdfExport.getRows();
        const rowOptions = [];
        rows.forEach(row => {
            const label = LoanBankComparisonPdfExport.mapRowNameToLabel(row)
            rowOptions.push({label: label, value: row})
        })
        return rowOptions;
    }

    useEffect(() => {
        if(template){
            setSelectedPeriods(template.contents.state.periods)
            setSelectedRows(template.contents.state.rows )
        }        
    },[template])


    const [selectedRows, setSelectedRows] = useState(LoanBankComparisonPdfExport.getRows())
    const [selectedPeriods, setSelectedPeriods] = useState(getPeriodsOptions(customData, template?.contents?.state?.periods || []).map(period => period.value))

    const periodsOptions = useMemo( () => getPeriodsOptions(customData, template?.contents?.state?.periods || []), [customData, template]);
    const rowOptions = getRowsOptions();

    return (
        <>
        <Heading level={"5"} margin={{bottom:'xsmall'}}>Nastavenie</Heading>
        <Box direction={'row'} gap="large">
            <Box basis={'1/2'} round={'xsmall'} margin={{bottom: 'small', top:'small'}}>
                <Text margin={{bottom: 'medium'}} color={'dark-5'} textAlign={'center'} >Fixácia</Text>
                <CheckBoxGroup
                    onChange={(e) => setSelectedPeriods(e.value)}
                    options={periodsOptions} 
                    valueKey={'value'} 
                    value={selectedPeriods}   
                    name="periods" 
                />
            </Box>

            <Box direction={'column'} basis="1/2">
                <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'small', top:'small'}}>
                    <Text margin={{bottom: 'medium'}} color={'dark-5'} textAlign={'center'} >Riadky</Text>
                    <CheckBoxGroup
                        onChange={(e) => setSelectedRows(e.value)}
                        options={rowOptions} 
                        valueKey={'value'} 
                        value={selectedRows}   
                        name="rows" 
                    />
                </Box> 
            </Box>
        </Box>
    </>
    )  
}


export default LoanBankComparisionColumnRowSelection;