import { Box, Button, Form, FormField, Heading, Layer, Select, TextInput } from "grommet";
import { useState } from "react";
import ErrorService from "../../services/ErrorService";
import SavedPdfExportTemplatesService from "../../services/SavedPdfExportTemplatesService";

const AddEditPdfExportTemplatesModal = ({show, renderColumnRowSelection, closeAction, template, templateTypes}) => {

    const [title, setTitle] = useState(template ? template.contents.title : "")
    const [templateType, setTemplateType] = useState(null);
    const [filteredTemplateTypes, setFilteredTemplatesTypes] = useState([{value: "", label: "Zvoľte typ kalkulačky",}, ...templateTypes])


    function closeModal(res=null){
        closeAction(res)
    }

    function savePdfExportTemplate(title, state, pinKey, id=null){

        return SavedPdfExportTemplatesService.addEditSavedTemplate(pinKey, title, state, id)
        .then((res) => {
                closeModal(res)
            },
            (error) => {
                ErrorService.fromApi(error);
        });
    }

    function onSubmit(values){
        const state = {
            ...values,
            title: template ? template.contents.state.title : templateType.label
        }
        savePdfExportTemplate(title, state, template ? template.calc_key : templateType.value, template ? template.id : null )
    }

    return (
        <>
        {show===true && (
            <Layer
                onEsc={()=>closeModal()}
                onClickOutside={()=>closeModal()}
            >
            <Box pad={'medium'} overflow="scroll" width={"large"} round={'small'} border={true} background={'white'} align={'center'} >
            <Form onSubmit={(e) => onSubmit(e.value)} >
                <Heading margin={{bottom:'xsmall'}}>{!template ? "Vytvorenie šablóny" : "Uloženie šablóny"}</Heading>
                    {!template && 
                        <Box direction={'row'} align={'center'}>
                        <Box basis={'full'} >
                            <Select 
                                width="full"
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                                options={filteredTemplateTypes.map(item=>{return {label: item.label, value: item.value}})}
                                onSearch={(text) => {
                                    // The line below escapes regular expression special characters:
                                    // [ \ ^ $ . | ? * + ( )
                                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                    // Create the regular expression with modified value which
                                    // handles escaping special characters. Without escaping special
                                    // characters, errors will appear in the console
                                    const exp = new RegExp(escapedText, 'i');
                                    setFilteredTemplatesTypes(templateTypes.filter((o) => exp.test(o.label)));
                                }}
                                onChange={(e) => {
                                    setTemplateType(e.option)
                                    setFilteredTemplatesTypes(templateTypes)
                                }}
                            />
                            </Box>
                        </Box>
                    }
                    {(template || templateType) &&
                    <>
                        <FormField label="Názov" width={'full'}>
                            <TextInput value={title} onChange={e => setTitle(e.target.value)} required={true} />
                        </FormField>
                        {renderColumnRowSelection(template ? template.calc_key : templateType.value, template)}

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label={"Uložiť"} />
                            <Button label="Zatvoriť" onClick={() => closeModal()} />
                        </Box>
                    </>
                    }
                    </Form>
            </Box>
            </Layer>
        )}
        </>
    )
}


export default AddEditPdfExportTemplatesModal