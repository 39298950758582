import {Box} from 'grommet';
import React from "react";

function RpmnHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet ročnej percentuálnej miery nákladov pre spotrebiteľský úver.</p>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je hodnota RPMN a výška celkových nákladov na úver.</p>
        <br /><br />
        <p><b>Poznámka: </b>ak nie je uvedené inak, výpočet nezohľadňuje daň!</p>
    </Box>);
}

export default RpmnHelp;
