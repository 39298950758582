import {Box, Button, Form, FormField, Heading, Layer, RadioButtonGroup, Select, TextInput} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanBankComparisonHelp from "./LoanBankComparisonHelp";
import LoanBankComparisonPdfExport from "./LoanBankComparisonPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import PdfExportModal from '../SavedPdfExportTemplates/PdfExportModal';
import LoanBankComparisionColumnRowSelection from './LoanBankComparisonColumnRowSelection';

function LoanBankComparison(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'LoanBankComparison';
    const calcStateVersion = 2;

    HighchartsExporting(Highcharts);

    function BankData(bank, loanRate=0, loanRateBonus=0, loanRateRest=0){
        return {
            fee: bank.fee,
            feeAcount: bank.feeAcount,
            feePercent: bank.feePercent,
            id: bank.id,
            name: bank.name,

            loanRate: loanRate,
            loanRateBonus: loanRateBonus,
            loanRateRest: loanRateRest
        };
    }

    const defaultValues = {
        title: 'Porovnanie bánk',
        period: 30,
        periodUnits: 'y',
        loanAmount: 70000,
        spmAssuranceAmount: 70000,
        spmObject: {id: ApiService.consts().spm.none, name: "Bez ŠPM"},

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const comparisonResultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'comparisonResult', null, calcStateVersion);
    const initialBanksListInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'initialBanksList', [], calcStateVersion);
    const banksListInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'banksList', [], calcStateVersion);
 // const comparisonResultInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['comparisonResult']) ? state.CalcDetail[calcStateKey]['comparisonResult'] : null;
 //    const initialBanksListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['initialBanksList']) ? state.CalcDetail[calcStateKey]['initialBanksList'] : [];
 //    const banksListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['banksList']) ? state.CalcDetail[calcStateKey]['banksList'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [comparisonResult, setComparisonResult] = useState(comparisonResultInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [initialBanksList, setInitialBanksList] = useState(initialBanksListInitialState);
    const [banksList, setBanksList] = useState(banksListInitialState);
    const [activeRequest, setActiveRequest] = useState(false);
    const [tableValueModal, setTableValueModal] = useState(false);
    const [tableValueForm, setTableValueForm] = useState({title: 'title', label: 'label', value: null, bankId: null, bankKey: null});
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [showExportPdfModal, setShowExportPdfModal] = useState(false);


    function saveCalcDetail(values, comparisonResult, result, initialBanksList, banksList){
        const calcDetailState = {
            values:values,
            comparisonResult:comparisonResult,
            result:result,
            initialBanksList:initialBanksList,
            banksList:banksList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, comparisonResult, result, initialBanksList, banksList);
        // eslint-disable-next-line
    }, [values, comparisonResult, result, initialBanksList,banksList]);

    const reloadBanksList = useCallback(() => {
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;
        let reqData = {
            loanAmount: values.loanAmount,
            periodInMonths: periodMonths,
            spm: {
                spmType: values.spmObject.id
            }
        };
        if (values.spmObject.id===ApiService.consts().spm.spm2017){
            reqData.spm.assuranceAmount = values.spmAssuranceAmount;
        }

        ApiService.call('post', '/v2/loan/comparison/bankData', reqData)
            .then((res) => {
                    const newBanksList = res.banks.map((bank)=>{
                        let newBank = BankData(bank);
                        //we copy over old loanRate changes that user supplied
                        if (banksList && banksList.length>0){
                            let b = banksList.filter((b)=>b.id===bank.id)[0];
                            if (b && b.hasOwnProperty('loanRate')){
                                newBank.loanRate = b.loanRate;
                            }
                        }
                        return newBank;
                    });
                    setInitialBanksList([...newBanksList]);
                    setBanksList([...newBanksList]);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
        // eslint-disable-next-line
    }, [values]);

    //called on values change and init
    useEffect(() => {
        if (autoRefresh){
            reloadBanksList();
        }
    }, [reloadBanksList]);

    //refresh table if we already have old table
    useEffect(() => {
        if (comparisonResult && autoRefresh){
            updateResultBank(comparisonResult);
        }
    }, [comparisonResult]);

    //refresh table if we change something in it. indirect - submit triggers setComparisonResult and that triggers effect above.
    useEffect(() => {
        if (comparisonResult && autoRefresh){
            onFormSubmit(values);
        }
    }, [banksList]);

    function updateResultBank(comparisonResult){
        let r = Object.assign({}, comparisonResult);
        r.results = [...r.results.map((bank)=>{
            bank.bank = getBankById(bank.id)
            return bank;
        })];
        //in case we updated the banks list, we remove banks we cannot identify from result.
        r.results = r.results.filter((bank)=>{return bank.bank;});
        r.periods = [...r.results[0].periodsResult.map((periodData)=>{
            return {
                period: periodData.period,
                bankPeriodResults: r.results.map((bank)=>{
                    return {
                        id: bank.id,
                        bank: getBankById(bank.id),
                        actualPeriodResult: bank.periodsResult.filter((pr)=>{return pr.period===periodData.period;}).pop()
                    };
                })
            };
        })];
        r = JSON.parse(JSON.stringify(r));
        setResult(r);
    }

    function onFormSubmit(_values=null){
        let v = _values ? _values : values;
        const periodMonths = v.periodUnits === 'm' ? v.period : v.period*12;

        let reqData = {
            banksData: banksList.map((bank)=>{
                return {
                    fee: bank.fee,
                    feeAcount: bank.feeAcount,
                    id: bank.id,
                    loanRate: bank.loanRate,
                    loanRateBonus: bank.loanRateBonus,
                    loanRateRest: bank.loanRateRest
                };
            }),
            loanAmount: v.loanAmount,
            periodInMonths: periodMonths,
            periods: [12, 24, 36, 40, 48, 60, 72, 84, 120].filter((period)=>{return period<=periodMonths;}),
            spm: {
                assuranceAmount: v.spmAssuranceAmount,
                spmType: v.spmObject.id
            }
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/comparison', reqData)
            .then((res) => {
                    setComparisonResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'porovnanie-bank';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(options){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanBankComparisonPdfExport.generatePdf({values:values,result:result,chart:null, options}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function getBankById(id){
        return banksList.filter((bank)=>{return id===bank.id;}).pop();
    }

    function openTableValueModal(title='', label='', value=null, bankId= null, bankKey=null){
        setTableValueForm({title: title, label: label, value: value, bankId: bankId, bankKey: bankKey});
        setTableValueModal(true);
    }

    function onTableValueFormSubmit(modalValues){
        let newBanksList = banksList.map((bank)=>{
            if (bank.id===modalValues.bankId){
                if (modalValues.bankKey==='fee'){
                    bank.feePercent = modalValues.value*100/values.loanAmount;
                }
                if (modalValues.bankKey==='feePercent'){
                    bank.fee = (values.loanAmount * (modalValues.value*1/100));
                }

                bank[modalValues.bankKey]=modalValues.value*1;
            }
            return bank;
        });
        setBanksList(newBanksList);
        setTableValueModal(false);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setAutoRefresh(false);
            setSavedState(res);
            setValues(res.contents.state.values);
            setInitialBanksList(res.contents.state.initialBanksList);
            setBanksList(res.contents.state.banksList);
            setResult(res.contents.state.result);
            setComparisonResult(res.contents.state.comparisonResult);
            setAutoRefresh(true);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        if (!res.contents || !res.contents.state || !res.contents.state.values){
                            alert('Ospravedlňujeme sa, štruktúra dát sa zmenila a tento záznam už nie je možné načítať.');
                            return false;
                        }
                        setAutoRefresh(false);
                        setSavedState(res);
                        setValues(res.contents.state.values);
                        setInitialBanksList(res.contents.state.initialBanksList);
                        setBanksList(res.contents.state.banksList);
                        setResult(res.contents.state.result);
                        setComparisonResult(res.contents.state.comparisonResult);
                        setAutoRefresh(true);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    function onExportPdfClose(res){
        if(res){
            toast.success('Šablóna bola uložená', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setShowExportPdfModal(false);
    }

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanBankComparisonHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={{
                                                 values:values,
                                                 comparisonResult:comparisonResult,
                                                 result:result,
                                                 initialBanksList:initialBanksList,
                                                 banksList:banksList,
                                             }}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                        {showExportPdfModal && <PdfExportModal
                            values={values}
                            customData={result.periods.map(res => res.period)}
                            show={showExportPdfModal}
                            pinKey={pinKey}
                            closeaction={(res) => onExportPdfClose(res)}
                            exportPdf={exportPdf}
                            renderColumnRowSelection={(template, values, customData) => <LoanBankComparisionColumnRowSelection customData={customData} template={template} values={values}/>}
                            disabledSubmitButton={values.pdfGenerating}
                        />}
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{ setShowExportPdfModal(true)}} />
                   
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        if (values.loanAmount!==nextValue.loanAmount && nextValue.loanAmount>values.spmAssuranceAmount){
                            nextValue.spmAssuranceAmount = nextValue.loanAmount;
                        }
                        setValues(nextValue);
                        //dispatch({type:'calc/save', payload: {regularInvestment: nextValue}});
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setBanksList(initialBanksList);
                        setComparisonResult(null);
                        setResult(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.001" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="ŠPM" width={'full'}>
                                <Select options={[
                                    {id: ApiService.consts().spm.none, name: "Bez ŠPM"},
                                    {id: ApiService.consts().spm.spm2017, name: "ŠPM 2017"},
                                    {id: ApiService.consts().spm.spm2018, name: "DB 2018"},
                                    {id: ApiService.consts().spm.spm2024, name: "DB 2024"},

                                ]}
                                        required
                                        name="spmObject"
                                        labelKey="name"
                                        valueKey="id"
                                        dropHeight="large"
                                        value={values.spmObject}
                                        placeholder="ŠPM"
                                />
                            </FormField>
                        </Box>

                        {(values.spmObject.id===ApiService.consts().spm.spm2017) && <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Hodnota zabezpečenia (€)" width={'full'}>
                                <TextInput name="spmAssuranceAmount" type="number" step="0.001" required={true} />
                            </FormField>
                        </Box>}

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result && result.results &&
            <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <tbody>
                                <tr className={'text-center sticky-header'}>
                                    <th>&nbsp;</th>
                                    {result.results.map((bank)=>{
                                         return <th key={'head_'+bank.id}>{bank.bank.name}</th>;
                                    })}
                                </tr>
                                <tr className={'text-center'}>
                                    <th>{values.spmObject.id===ApiService.consts().spm.spm2017 ? 'Úroková sadzba pred zľavou (%)' : 'Úroková sadzba (%)' }</th>
                                    {result.results.map((bank)=>{
                                        return <td key={'loanRate_'+bank.id}>
                                            <div className={'text-editable'}
                                                 onClick={() => openTableValueModal(
                                                     bank.bank.name+': ' + (values.spmObject.id===ApiService.consts().spm.spm2017 ? 'Úroková sadzba pred zľavou' : 'Úroková sadzba'),
                                                     'Úroková sadzba (%)',
                                                     bank.bank.loanRate,
                                                     bank.id,
                                                     'loanRate'
                                                 )}
                                                >{FormatService.round(bank.bank.loanRate)}</div>
                                        </td>;
                                    })}
                                </tr>
                                <tr className={'text-center'}>
                                    <th>Poplatok (%)</th>
                                    {result.results.map((bank)=>{
                                        return <td key={'feePercent_'+bank.id}>
                                            <div className={'text-editable'}
                                                 onClick={() => openTableValueModal(
                                                     bank.bank.name+': Poplatok v percentách',
                                                     'Poplatok (%)',
                                                     FormatService.round(bank.bank.feePercent),
                                                     bank.id,
                                                     'feePercent'
                                                 )}
                                            >{FormatService.round(bank.bank.feePercent)}</div>
                                        </td>;
                                    })}
                                </tr>
                                <tr className={'text-center'}>
                                    <th>Poplatok (€)</th>
                                    {result.results.map((bank)=>{
                                        return <td key={'fee_'+bank.id}>
                                            <div className={'text-editable'}
                                                 onClick={() => openTableValueModal(
                                                     bank.bank.name+': Poplatok v €',
                                                     'Poplatok (€)',
                                                     FormatService.round(bank.bank.fee),
                                                     bank.id,
                                                     'fee'
                                                 )}
                                            >{FormatService.round(bank.bank.fee)}</div>
                                        </td>;
                                    })}
                                </tr>
                                <tr className={'text-center'}>
                                    <th>Poplatok za účet (€)</th>
                                    {result.results.map((bank)=>{
                                        return <td key={'feeAcount_'+bank.id}>
                                            <div className={'text-editable'}
                                                 onClick={() => openTableValueModal(
                                                     bank.bank.name+': Poplatok za účet v €',
                                                     'Poplatok za účet (€)',
                                                     FormatService.round(bank.bank.feeAcount),
                                                     bank.id,
                                                     'feeAcount'
                                                 )}
                                            >{FormatService.round(bank.bank.feeAcount)}</div>
                                        </td>;
                                    })}
                                </tr>
                                <tr className={'text-center'}>
                                    <th>Splátka (€)</th>
                                    {result.results.map((bank)=>{
                                        return <td key={'feeAcount_'+bank.id}>{FormatService.round(bank.payment)}</td>;
                                    })}
                                </tr>

                                {values.spmObject.id===ApiService.consts().spm.spm2017 &&
                                    <><tr className={'text-center'}>
                                        <th className={'table-cell-important'}>Úver so ŠPM</th>
                                        {result.results.map((bank)=>{
                                            return <td key={'spm17bankspacer_'+bank.id}>&nbsp;</td>;
                                        })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Výška (€)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanAmountSpm_'+bank.id}>{FormatService.round(bank.loanAmountSpm)}</td>;
                                            })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Ú.s. po zľave (%)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanRateSpm_'+bank.id}>{FormatService.round(bank.loanRateSpm)}</td>;
                                            })}
                                        </tr>

                                        <tr className={'text-center'}>
                                            <th className={'table-cell-important'}>Úver so Zvýhodnením</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17bankspacer2_'+bank.id}>&nbsp;</td>;
                                            })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Výška (€)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanAmountBonus_'+bank.id}>{FormatService.round(bank.loanAmountBonus)}</td>;
                                            })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Úroková sadzba (%)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanRateBonus_'+bank.id}>
                                                    <div className={'text-editable'}
                                                         onClick={() => openTableValueModal(
                                                             bank.bank.name+': Úroková sadzba',
                                                             'Úroková sadzba (%)',
                                                             FormatService.round(bank.bank.loanRateBonus),
                                                             bank.id,
                                                             'loanRateBonus'
                                                         )}
                                                    >{FormatService.round(bank.bank.loanRateBonus)}</div>
                                                </td>;
                                            })}
                                        </tr>

                                        <tr className={'text-center'}>
                                            <th className={'table-cell-important'}>Zvyšný úver</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17bankspacer3_'+bank.id}>&nbsp;</td>;
                                            })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Výška (€)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanAmountRest_'+bank.id}>{FormatService.round(bank.loanAmountRest)}</td>;
                                            })}
                                        </tr>
                                        <tr className={'text-center'}>
                                            <th>Úroková sadzba (%)</th>
                                            {result.results.map((bank)=>{
                                                return <td key={'spm17loanRateRest_'+bank.id}>
                                                    <div className={'text-editable'}
                                                         onClick={() => openTableValueModal(
                                                             bank.bank.name+': Úroková sadzba',
                                                             'Úroková sadzba (%)',
                                                             FormatService.round(bank.bank.loanRateRest),
                                                             bank.id,
                                                             'loanRateRest'
                                                         )}
                                                    >{FormatService.round(bank.bank.loanRateRest)}</div>
                                                </td>;
                                            })}
                                        </tr>
                                    </>
                                }

                                {result.periods.map((period)=>{
                                    return <><tr key={'period_header'+period.period} className={'text-center'}>
                                        <th className={'table-cell-important'}>Porovnanie po {period.period%12===0 ? period.period/12 : period.period}
                                        {period.period%12===0 ? FormatService.pluralify(period.period/12,' roku', ' rokoch',' rokoch') : FormatService.pluralify(period.period,' mesiaci', ' mesiacoch',' mesiacoch')}</th>
                                        {period.bankPeriodResults.map((bankPeriod)=>{
                                            return <td key={'bankperiodspacer_'+period.period+'_'+bankPeriod.id}>&nbsp;</td>;
                                        })}
                                    </tr>
                                        <tr key={'period_splatky'+period.period} className={'text-center'}>
                                            <th>Splátky (€)</th>
                                            {period.bankPeriodResults.map((bankPeriod)=>{
                                                return <td key={'bankperiodpayments_'+period.period+'_'+bankPeriod.id}>{FormatService.round(bankPeriod.actualPeriodResult.payments)}</td>;
                                            })}
                                        </tr>
                                        <tr key={'period_zostatok'+period.period} className={'text-center'}>
                                            <th>Zostatok úveru (€)</th>
                                            {period.bankPeriodResults.map((bankPeriod)=>{
                                                return <td key={'bankperiodbalance_'+period.period+'_'+bankPeriod.id}>{FormatService.round(bankPeriod.actualPeriodResult.balance)}</td>;
                                            })}
                                        </tr>
                                        <tr key={'period_naklady'+period.period} className={'text-center'}>
                                            <th>Náklady spolu (€)</th>
                                            {period.bankPeriodResults.map((bankPeriod)=>{
                                                return <td key={'bankperiodtotalCost_'+period.period+'_'+bankPeriod.id}>{FormatService.round(bankPeriod.actualPeriodResult.totalCost)}</td>;
                                            })}
                                        </tr>
                                        {(values.spmObject.id===ApiService.consts().spm.spm2018 || values.spmObject.id===ApiService.consts().spm.spm2024) &&
                                        <tr key={'period_uspora'+period.period} className={'text-center'}>
                                            <th>Úspora na úroku spolu (€)</th>
                                            {period.bankPeriodResults.map((bankPeriod)=>{
                                                return <td key={'bankperiodInterestSavings_'+period.period+'_'+bankPeriod.id}>{FormatService.round(bankPeriod.actualPeriodResult.interestSavings)}</td>;
                                            })}
                                        </tr>
                                        }
                                    </>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

            {tableValueModal &&
            (<Layer onEsc={() => setTableValueModal(false)} onClickOutside={() => setTableValueModal(false)}>
                <Box pad="medium">
                    <Form
                        value={tableValueForm}
                        onChange={nextValue => {
                            setTableValueForm(nextValue);
                        }}
                        onSubmit={({ value }) => onTableValueFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>{tableValueForm.title}</Heading>

                        <FormField label={tableValueForm.label} width={'full'}>
                            <TextInput autoFocus name="value" type="number" step="0.001" required={true} />
                        </FormField>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setTableValueModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

        </Box>
    );
}

export default LoanBankComparison;