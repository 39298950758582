import {Box} from 'grommet';
import React from "react";

function SwissLoanHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie výhodnosti tzv. Švajčiarskej hypotéky, kde sa banke platí splátka len vo
            výške úroku a rozdiel medzi takto vypočítanou splátkou a klasickou anuitnou splátkou je investovaný
            prostredníctvom IŽP.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru – výšku, splatnosť a úrokovú sadzbu.</li>
            <li>Kliknite na <b>Navrhnúť výšku investície</b></li>
            <li>Výška investície sa vypočíta, ak máte záujem, môžete ju zmeniť</li>
            <li>Zadajte výnos p.a. v %-tách</li>
            <li>Zvoľte investičný nástroj</li>
            <li>Kliknite na <b>Prefinancovať</b></li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky pozostáva zo štyroch nasledovných častí:</p>
        <ul>
            <li>Tabuľka zobrazuje hodnoty splátky a zostatku štandardne (anuitne) splácaného úveru,
                splátky a zostatku úveru pri Švajčiarskej hypotéke a výšku investície,
                jej hodnotu a výslednú bilanciu</li>
            <li>Hodnoty základného modelu zobrazujú splátku a celkové náklady pri štandardne splácanom úvere.</li>
            <li>Hodnoty v bode zlomu zobrazujú splátku, bod zlomu (mesiac, kedy vieme z investície vyplatiť celý úver)
                a celkové náklady v bode zlomu.</li>
            <li>Hodnoty v mesiaci pôvodnej splatnosti zobrazujú celkové náklady a bilanciu na účte v mesiaci, v
                ktorom by nastalo splatenie štandardného úveru. Ak je bilancia kladná, na účte je dostatok prostriedkov
                na vyplatenie úverua naopak.</li>
        </ul>
        <br /><br />
        <p><b>Poznámka: </b>"Investorom" sa rozumie forma sporenia bez poplatkov</p>
        <p><b>Poznámka: </b>ak nie je uvedené inak, výpočet nezohľadňuje daň!</p>
    </Box>);
}

export default SwissLoanHelp;
