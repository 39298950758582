import {Box} from 'grommet';
import React from "react";

function SalaryHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet čistej/hrubej mzdy.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte typ kalkulačky, t.j. zvoľte parameter, ktorý chcete vypočítať</li>
            <li>Uveďte hrubú/čistú mzdu</li>
            <li>Zvoľte obdobie, pre ktoré chcete počítať mzdu</li>
            <li>Zvoľte počet vyživovaných nezaopatrených detí a nastavte dátumy narodenia. </li>
            <li>V prípade, že bola manželka v minulom roku bez príjmu, zaškrtnite checkbox.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je tabuľka s hodnotami čistej/hrubej mzdy, odvodov, bonusov a dane zamestnanca a zamestnávateľa.</p>
        <br /><br />
    </Box>);
}

export default SalaryHelp;
