import ApiService from "./ApiService";

const ErrorService = {
    fromApi: function(res) {
        if (window.debug){
            console.log(res);
        }
        if (res && res.error){
            let message = 'Pri spracovaní požiadavku nastala chyba. \nPodrobnosti: ';
            alert(message+res.error);
        } else {
            let message = 'Pri spracovaní požiadavku nastala chyba. \n';
            let details = res.toString();

            switch (details){
                case ApiService.consts().errors.unauthorized: {
                    details = 'Vaše prihlásenie je potrebné obnoviť. V prípade že sa nám to nepodarí automaticky, budete presmerovaný na prihlásenie.';
                    return false;
                }
                default: break;
            }

            alert(message+(details ? 'Podrobnosti: '+details : ''));
        }
    },

    fromApp: function(res) {
        if (window.debug){
            console.log(res);
        }
    }
};

export default ErrorService;