import './App.css';
import React from 'react';
import {Grommet, ResponsiveContext} from 'grommet';
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";

import RegularInvestment from "../../pages/RegularInvestment/RegularInvestment";
import MyProfile from "../../pages/MyProfile/MyProfile";
import Login from "../../pages/Login/Login";
import LoginCallback from "../../pages/Login/LoginCallback";
import {useSelector} from "react-redux";
import SetTheme from "../../pages/MyProfile/SetTheme";
import SidebarLayout from "../../layouts/SidebarLayout";
import SingleColumnLayout from "../../layouts/SingleColumnLayout";
import SideLogoLayout from "../../layouts/SideLogoLayout";
import {deepMerge} from "grommet/es6/utils";
import {grommet} from "grommet/es6";
import OneTimeInvestment from "../../pages/OneTimeInvestment/OneTimeInvestment";
import DdsInvestment from "../../pages/DdsInvestment/DdsInvestment";
import Rpmn from "../../pages/Rpmn/Rpmn";
import Loan from "../../pages/Loan/Loan";
import Salary from "../../pages/Salary/Salary";
import SZCO from "../../pages/SZCO/SZCO";
import Annuity from "../../pages/Annuity/Annuity";
import LoanAdvanced from "../../pages/LoanAdvanced/LoanAdvanced";
import LoanPlusInvest from "../../pages/LoanPlusInvest/LoanPlusInvest";
import LoanRefinance from "../../pages/LoanRefinance/LoanRefinance";
import LoanBankComparison from "../../pages/LoanBankComparison/LoanBankComparison";
import SwissLoan from "../../pages/SwissLoan/SwissLoan";
import LoanInsurance from "../../pages/LoanInsurance/LoanInsurance";
import LoanFixation from "../../pages/LoanFixation/LoanFixation";
import SavingIzp from "../../pages/SavingIzp/SavingIzp";
import SavingBuilding from "../../pages/SavingBuilding/SavingBuilding";
import SavingEic from "../../pages/savingEic/SavingEic";
import AuthService from "../../services/AuthService";
import ResetState from "../../pages/MyProfile/ResetState";
import Home from "../../pages/Home/Home";
import Help from "../../pages/Help/Help";
import IntranetApiService from "../../services/IntranetApiService";
import SidebarLightLayout from "../../layouts/SidebarLightLayout";
import SavedStates from "../../pages/SavedStates/SavedStates";
import SavedPdfExportTemplates from '../../pages/SavedPdfExportTemplates/SavedPdfExportTemplates';
import TurnOverCompanyProfit from '../../pages/TurnOverCompanyProfit/TurnOverCompanyProfit';
import InsuredSums from '../../pages/InsuredSums/InsuredSums';

function App() {
    window.debug = process.env.REACT_APP_DEBUG==='true';
    window.calcMap = {
        'pravidelne-investovanie': {key: 'pravidelne-investovanie', title: 'Pravidelné investovanie', path: '/pravidelne-investovanie'},
        'jednorazove-investovanie': {key: 'jednorazove-investovanie', title: 'Jednorázové investovanie', path: '/jednorazove-investovanie'},
        'uver': {key: 'uver', title: 'Úver', path: '/uver'},
        'dds': {key: 'dds', title: 'DDS', path: '/dds'},
        'rpmn': {key: 'rpmn', title: 'RPMN', path: '/rpmn'},
        'mzdova-kalkulacka': {key: 'mzdova-kalkulacka', title: 'Mzdová kalkulačka', path: '/mzdova-kalkulacka'},
        'szco': {key: 'szco', title: 'SZČO', path: '/szco'},
        'kalkulacka-renty': {key: 'kalkulacka-renty', title: 'Kalkulačka renty', path: '/kalkulacka-renty'},
        'zakladne-modelovanie-uveru': {key: 'zakladne-modelovanie-uveru', title: 'Základné modelovanie úveru', path: '/zakladne-modelovanie-uveru'},
        'preplatenie': {key: 'preplatenie', title: 'Preplatenie', path: '/preplatenie'},
        'refinancovanie': {key: 'refinancovanie', title: 'Refinancovanie', path: '/refinancovanie'},
        'porovnanie-bank': {key: 'porovnanie-bank', title: 'Porovnanie bánk', path: '/porovnanie-bank'},
        'svajciarska-hypoteka': {key: 'svajciarska-hypoteka', title: 'Švajčiarska hypotéka', path: '/svajciarska-hypoteka'},
        'klesajuce-poistne-sumy': {key: 'klesajuce-poistne-sumy', title: 'Klesajúce poistné sumy', path: '/klesajuce-poistne-sumy'},
        'fixacia-uveru': {key: 'fixacia-uveru', title: 'Fixácia úveru', path: '/fixacia-uveru'},
        'investicne-produkty': {key: 'investicne-produkty', title: 'Investičné produkty', path: '/investicne-produkty'},
        'stavebne-sporenie': {key: 'stavebne-sporenie', title: 'Stavebné sporenie', path: '/stavebne-sporenie'},
        'eic-nakladovost': {key: 'eic-nakladovost', title: 'EIC Nákladovosť', path: '/eic-nakladovost'},
		'obrat-zisk-firmy': {key: 'obrat-zisk-firmy', title: 'Obrat / zisk firmy', path: '/obrat-zisk-firmy'},
        'kalkulacka-poistnych-sum': {key: 'kalkulacka-poistnych-sum', title: 'Kalkulačka poistných súm', path: '/kalkulacka-poistnych-sum'},


    };

    const state = useSelector((state) => state);
    const theme = deepMerge(grommet, {
        global: {
            font: {
                family: 'Roboto',
                size: '15px',
                height: '18px',
            },
            colors: {
                primary: state.Theme.primaryColor,
                active: state.Theme.primaryColorAlt,
                brand: state.Theme.primaryColor,
                loginBrand: state.Theme.colorLoginUGCalculator,
                focus: state.Theme.primaryColorAlt,
                text: 'dark',
                dark: '#4F5E6A',
                light: 'light-1',
                gray: '#8398AB',
                white: '#fff',
                background: 'light',
                border: 'light-4'
            },
            animation: {
                duration: "1s",
                fadeIn: {duration: "0.3s"},
                slideRight: {duration: "0.3s"},
                slideLeft: {duration: "0.3s"},
                jiggle: {duration: "0.1s"}
            },
        },
        text: {
            small: {
                size: '12px'
            },
            medium: {
                size: '15px'
            }
        },
        button: {
            font: {
                weight: 600,
            },
            size: {
                small: {
                    border: {
                        radius: '4px'
                    },
                },
                medium: {
                    border: {
                        radius: '4px'
                    },
                },
                large: {
                    border: {
                        radius: '4px'
                    },
                },
            },
            border: {
                radius: '4px'
            },
            padding: { horizontal: 'medium', vertical: 'small'},
            primary: {
                background: 'brand',
                color:  'light',
                padding: { horizontal: 'medium', vertical: 'small'},
            },
            secondary: {
                background: 'light',
                color:  'dark',
                padding: { horizontal: 'medium', vertical: 'small'},
            },
            default: {
                background: 'light',
                color:  'dark',
                padding: { horizontal: 'medium', vertical: 'small'},
            },
            hover: {
                primary:  {background: {color: 'active', opacity: 'strong'}, color: 'light'},
                secondary:  {background: {color: 'light-3', opacity: 'strong'}},
                default:  {background: {color: 'light-3', opacity: 'strong'}},
            }
        },
        checkBox: {
            extend: {
                marginTop: '-4px',
                marginBottom: '-4px',
            }
        },
        formField: {
            round: 'xsmall',
            border: {
                side: 'all',
                radius: '6px',
                color: '#D1DCE5'
            },
            label: {
                size: '15px'
            },
            margin: {bottom: "small", right: "small", top: "small"}
        },
        heading: {
            margin: 'none',
            weight: '600',
            level: {
                1: {
                    small: {size: '20pt'},
                    medium: {size: '22pt'},
                    large: {size: '25pt'},
                    xlarge: {size: '25pt'},
                },
                2: {
                    small: {size: '20pt'},
                    medium: {size: '22pt'},
                    large: {size: '25pt'},
                    xlarge: {size: '25pt'},
                },
                3: {
                    small: {size: '15pt'},
                    medium: {size: '18pt'},
                    large: {size: '20pt'},
                    xlarge: {size: '20pt'},
                },
                4: {
                    small: {size: '13pt'},
                    medium: {size: '13pt'},
                    large: {size: '16pt'},
                    xlarge: {size: '16pt'},
                },
            },
        },
        collapsible: {
            minSpeed: 300,
            baseline: 800
        },
        spinner: {
           container: {
               size: 'small',
               border: [
                   { side: 'all', color: 'background-contrast', size: 'large' },
                   { side: 'right', color: 'brand', size: 'large' },
                   { side: 'top', color: 'brand', size: 'large' },
                   { side: 'left', color: 'brand', size: 'large' },
               ],
               extend: `zoom: 0.5;`,
           },
            extend: `zoom: 0.5;`,
        },
    });

    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        if (window.debug){
            console.log('Location changed',location);
        }
        let key = location.pathname.replace('/', '');
        if (window.calcMap && window.calcMap.hasOwnProperty(key) && window.calcMap[key]){
            if (window.debug){
                console.log('Calculator load requested',location.pathname);
            }
            let calc = window.calcMap[key];
            history.title = calc.title;
            document.title = calc.title;

            //save calculator load for stats
            IntranetApiService.setCalculatorLoaded(key, calc.title, location.pathname);
        }
    }, [location]);

    React.useEffect(() => {
        if (window.debug){
            console.log('App state',state);
        }

        //onload refresh access token
        if (AuthService.getAccessToken()){
            if (AuthService.checkAccessTokenExpired()){
                if (!AuthService.checkRefreshTokenExpired()){
                    AuthService.doRefreshToken();
                }
            } else {
                AuthService.checkTokenValid().then(
                    (res)=>{ /* noop */ },
                    (err)=>{AuthService.doRefreshToken();},
                );
            }
        }

        //onload refresh access token when we come back from different tab
        document.onvisibilitychange = function(event) {
            if (document.visibilityState !== "visible") {
                return true;
            }
            if (AuthService.getAccessToken()){
                if (AuthService.checkAccessTokenExpired()){
                    if (!AuthService.checkRefreshTokenExpired()){
                        AuthService.doRefreshToken();
                    }
                } else {
                    AuthService.checkTokenValid().then(
                        (res)=>{ /* noop */ },
                        (err)=>{AuthService.doRefreshToken();},
                    );
                }
            }
        };

        //auto refresh access token after some time
        AuthService.startAccessTokenAutoRefresh();

        // eslint-disable-next-line
    }, []);

    const AuthorizedRoutes = (attr) => (
        <Switch>
            <Route exact path="/" render={(props)                   => ( <SidebarLightLayout mainComponent={<Home {...attr} {...props} />}/> ) }/>
            <Route path="/domov" render={(props)                   => ( <SidebarLightLayout mainComponent={<Home {...attr} {...props} />}/> ) }/>
            <Route path="/ulozene-modelovania" render={(props)                   => ( <SidebarLightLayout mainComponent={<SavedStates {...attr} {...props} />}/> ) }/>
            <Route path="/ulozene-sablony-na-export" render={(props)                   => ( <SidebarLightLayout mainComponent={<SavedPdfExportTemplates {...attr} {...props} />}/> ) }/>
            <Route path="/pravidelne-investovanie/:id?" render={(props)  => ( <SidebarLayout mainComponent={<RegularInvestment {...attr} {...props} />}/> ) }/>
            <Route path="/jednorazove-investovanie/:id?" render={(props)  => ( <SidebarLayout mainComponent={<OneTimeInvestment {...attr} {...props} />}/> ) }/>
            <Route path="/uver/:id?" render={(props)                      => ( <SidebarLayout mainComponent={<Loan {...attr} {...props} />}/> ) }/>
            <Route path="/dds/:id?" render={(props)                      => ( <SidebarLayout mainComponent={<DdsInvestment {...attr} {...props} />}/> ) }/>
            <Route path="/rpmn/:id?" render={(props)                      => ( <SidebarLayout mainComponent={<Rpmn {...attr} {...props} />}/> ) }/>
            <Route path="/mzdova-kalkulacka/:id?" render={(props)       => ( <SidebarLayout mainComponent={<Salary {...attr} {...props} />}/> ) }/>
            <Route path="/szco/:id?" render={(props)       => ( <SidebarLayout mainComponent={<SZCO {...attr} {...props} />}/> ) }/>
            <Route path="/kalkulacka-renty/:id?" render={(props)       => ( <SidebarLayout mainComponent={<Annuity {...attr} {...props} />}/> ) }/>
            <Route path="/zakladne-modelovanie-uveru/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanAdvanced {...attr} {...props} />}/> ) }/>
            <Route path="/preplatenie/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanPlusInvest {...attr} {...props} />}/> ) }/>
            <Route path="/refinancovanie/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanRefinance {...attr} {...props} />}/> ) }/>
            <Route path="/porovnanie-bank/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanBankComparison {...attr} {...props} />}/> ) }/>
            <Route path="/svajciarska-hypoteka/:id?" render={(props)       => ( <SidebarLayout mainComponent={<SwissLoan {...attr} {...props} />}/> ) }/>
            <Route path="/klesajuce-poistne-sumy/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanInsurance {...attr} {...props} />}/> ) }/>
            <Route path="/fixacia-uveru/:id?" render={(props)       => ( <SidebarLayout mainComponent={<LoanFixation {...attr} {...props} />}/> ) }/>
            <Route path="/investicne-produkty/:id?" render={(props)       => ( <SidebarLayout mainComponent={<SavingIzp {...attr} {...props} />}/> ) }/>
            <Route path="/stavebne-sporenie/:id?" render={(props)       => ( <SidebarLayout mainComponent={<SavingBuilding {...attr} {...props} />}/> ) }/>
            <Route path="/eic-nakladovost/:id?" render={(props)       => ( <SidebarLayout mainComponent={<SavingEic {...attr} {...props} />}/> ) }/>
			<Route path="/obrat-zisk-firmy" render={(props)       => ( <SidebarLayout mainComponent={<TurnOverCompanyProfit {...attr} {...props} />}/> ) }/>
            <Route path="/kalkulacka-poistnych-sum/:id?" render={(props)       => ( <SidebarLayout mainComponent={<InsuredSums {...attr} {...props} />}/> ) }/>
            <Route path="/moj-profil" render={(props)               => ( <SidebarLayout mainComponent={<MyProfile {...attr} {...props} />}/> ) }/>
            <Route path="/pool/:pool" render={(props)               => ( <SingleColumnLayout mainComponent={<SetTheme {...attr} {...props} />}/> ) }/>
            <Route path="/reset/:slug" render={(props)               => ( <SingleColumnLayout mainComponent={<ResetState {...attr} {...props} />}/> ) }/>
            <Route path="/reset" render={(props)               => ( <SingleColumnLayout mainComponent={<ResetState {...attr} {...props} />}/> ) }/>
            <Route path="/login" render={(props)                    => ( <SideLogoLayout mainComponent={<Login {...attr} {...props} />}/> ) }/>
            <Route path="/help" render={(props)                    => ( <SidebarLayout mainComponent={<Help {...attr} {...props} />}/> ) }/>
            <Redirect to='/' />
        </Switch>
    )

    const PublicRoutes = (attr) => (
        <Switch>
            <Route path="/login" render={(props)                    => ( <SideLogoLayout mainComponent={<Login {...attr} {...props} />}/> ) }/>
            <Route path="/login-callback" render={(props)           => ( <SingleColumnLayout mainComponent={<LoginCallback {...attr} {...props} />}/> ) }/>
            <Route path="/pool/:pool" render={(props)               => ( <SingleColumnLayout mainComponent={<SetTheme {...attr} {...props} />}/> ) }/>
            <Redirect to='/login' />
        </Switch>
    )

    return (
        <Grommet theme={theme} full className="fullheight">
            <ResponsiveContext.Consumer>
                { size => {
                    return  (state.Auth ? <AuthorizedRoutes size={size} /> : <PublicRoutes size={size} />)
                }}
            </ResponsiveContext.Consumer>
        </Grommet>
    );
}

export default App;
