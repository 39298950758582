import React from 'react';
import { Blank } from 'grommet-icons';

export const UgCalcIcon = props => (
    <Blank {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                clipRule="evenodd"
                viewBox="0 0 24 24"
            >
                    <path d="M21.273 5.455A5.457 5.457 0 0015.818 0H8.182a5.457 5.457 0 00-5.455 5.455v13.09A5.457 5.457 0 008.182 24h7.636a5.457 5.457 0 005.455-5.455V5.455zm-2.182 0v13.09a3.274 3.274 0 01-3.273 3.273H8.182a3.274 3.274 0 01-3.273-3.273V5.455a3.274 3.274 0 013.273-3.273h7.636a3.274 3.274 0 013.273 3.273zM8.836 16.364a1.091 1.091 0 110 2.182 1.091 1.091 0 010-2.182zm3.273 0a1.091 1.091 0 110 2.182 1.091 1.091 0 010-2.182zm3.273 0a1.091 1.091 0 110 2.182 1.091 1.091 0 010-2.182zm-6.546-3.273a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zm3.273 0a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zm3.273 0a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zM8.836 9.818a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zm3.273 0a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zm3.273 0a1.092 1.092 0 11-.001 2.183 1.092 1.092 0 01.001-2.183zM16.909 6c0-.903-.733-1.636-1.636-1.636H8.727a1.637 1.637 0 000 3.272h6.546c.903 0 1.636-.733 1.636-1.636z"></path>
            </svg>
    </Blank>
);