import FormatService from "../../services/FormatService";

function SwissLoanPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount},
        {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
        {key: 'annualInterestRate', label: 'Ročná úroková sadzba (%)', value: values.annualInterestRate},
        {key: 'investorId', label: 'Investičný program', value: values.investorObject.name},
        {key: 'investment', label: 'Výška investície (€)', value: values.investment},
        {key: 'revenue', label: 'Výnos p.a. (%)', value: values.revenue},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
              <p>Kalkulačka slúži na porovnanie výhodnosti tzv. Švajčiarskej hypotéky, kde sa banke platí splátka len vo
            výške úroku a rozdiel medzi takto vypočítanou splátkou a klasickou anuitnou splátkou je investovaný
            prostredníctvom IŽP.</p>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
   
        <div class="row-flex mb3">
        
        <div class="col-12">
        <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Základný modul</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Splátka (€)</th>
              <td class='text-right'>${FormatService.round(result.results[0].loanPayment)}</td>
             </tr>
             <tr>
              <th>Celkové náklady (€)</th>
              <td class='text-right'>${FormatService.round(result.costs)}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>

          </div>
         <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Švajčiarska hypotéka</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Splátka (€)</th>
              <td class='text-right'>${FormatService.round(result.loanPaymentSwiss)}</td>
             </tr>
             <tr>
              <th>Bod zlomu</th>
              <td class='text-right'>${result.breakEvenMonth > 0?result.breakEvenMonth:'-'}</td>
             </tr>
             <tr>
              <th>Náklady v bode zlomu (€)</th>
              <td class='text-right'>${result.breakEvenMonth > 0?FormatService.round(result.breakEvenCosts):'-'}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>
          </div>
         <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Zhrnutie</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Bilancia na účte v ${(values.periodUnits === 'm' ? values.period : values.period*12)}. mesiaci (€)</th>
              <td class='text-right'>${FormatService.round(result.balance)}</td>
             </tr>
             <tr>
              <th>Náklady v ${(values.periodUnits === 'm' ? values.period : values.period*12)}. mesiaci (€)</th>
              <td class='text-right'>${FormatService.round(result.costs)}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>
          </div>
        </div>

                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table styled-table">
                            <thead>
                                <tr>
                                    <td></td>
                                    <th colspan='2'>Základný modul</th>
                                    <th colspan='2'>Švajčiarska hypotéka</th>
                                    <th colspan='3'>Investičná zložka</th>
                                </tr>
                                <tr>
                                    <th>Mesiac</th>
                                    <th>Splátka</th>
                                    <th>Zostatok úveru</th>
                                    <th>Splátka</th>
                                    <th>Zostatok úveru</th>
                                    <th>Výška investície</th>
                                    <th>Hodnota účtu</th>
                                    <th>Výsledná bilancia</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${result && result.results && result.results.map((row)=>{
                                    return `<tr class='text-center'>
                                        <td>${row.month}.</td>
                                        <td>${FormatService.round(row.loanPayment)}</td>
                                        <td>${FormatService.round(row.remainingPrincipal)}</td>
                                        <td>${FormatService.round(row.loanPaymentSwiss)}</td>
                                        <td>${FormatService.round(row.remainingPrincipalSwiss)}</td>
                                        <td>${FormatService.round(row.investment)}</td>
                                        <td>${FormatService.round(row.investmentAccountValue)}</td>
                                        <td>${FormatService.round(row.balance)}</td>
                                    </tr>`;
                                    }).join('')
                                }
                            </tbody>
                        </table>
                    </div>
              
            </div>
        </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default SwissLoanPdfExport;