import {Box, Button, Card, CardBody, CardFooter, CheckBox, FormField, Heading, RadioButtonGroup, Select, Text, TextInput} from 'grommet';
import * as Icons from "grommet-icons";

const BasicLoanAmountNbsForm = ({values, addHousehold, removeHousehold, maxLoanLengthOptions}) => {
    return (
        <>
 
            <Box basis={'1/3'} direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'}>
                    <Box direction={'row'} align={'center'} wrap>
                        <Box basis="1/2">
                            <FormField label="Dĺžka úveru" width={'large'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} max={values.maxLoanLength} />
                            </FormField>
                        </Box>
                        <Box basis="1/2">
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'full'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>
                </Box>
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                        <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                            <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                        </FormField>
                </Box>

                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Fixácia" width={'full'}>
                        <CheckBox name="fixationGreater10years" label="Fixácia vyššia ako 10 rokov" />
                    </FormField>
                </Box>                
            </Box>

            <Box direction={'row-responsive'}>
                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Mesačné záväzky (€)" width={'full'}>
                        <TextInput name="monthCosts" type="number" min="0" step="0.01" required={true} />
                    </FormField>
                </Box>
            
                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Výška zostávajúcich úverov (€)" width={'full'}>
                        <TextInput name="otherLoans" type="number" min="0" step="0.01" required={true} />
                    </FormField>
                </Box>  

                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Príjmová rezerva (€)" width={'full'}>
                        <Select options={[{id:0.3, name:'30%'}, {id:0.4, name:'40%'}]}
                                required
                                name="incomeReserve"
                                labelKey="name"
                                valueKey={{ key: 'id', reduce: true }}
                                dropHeight="large"
                                value={values.incomeReserve}
                                placeholder="Výber rezervy"
                        />
                    </FormField>
                </Box>  
                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Maximálna dĺžka úveru" width={'full'}>
                        <Select options={maxLoanLengthOptions}
                                required
                                name="maxLoanLength"
                                labelKey="name"
                                valueKey={{ key: 'id', reduce: true }}
                                dropHeight="large"
                                value={values.maxLoanLengthOptions}
                        />
                    </FormField>
                </Box>                     
            </Box>


            <Heading level={3} margin={{bottom:'none'}}>Domácnosť žiadateľa</Heading>
            
            <Box direction={'row-responsive'}>
                    <Box basis={'1/4'} direction={'row'} width={"small"} align={'center'}>
                        <FormField label="Žiadateľ" width={'full'}>
                            <CheckBox name="householdEnabled" checked={true} disabled={true} label="Áno" />
                        </FormField>
                    </Box>
                
                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                        <FormField label="Počet detí" width={'full'}>
                            <TextInput name="kids" type="number" min="0" step="1" required={true} />
                        </FormField>
                    </Box>

                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                        <FormField label="Vek" width={'full'}>
                            <TextInput name="age" type="number" min="0" step="1" required={true} />
                        </FormField>
                    </Box>
                
                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                        <FormField label="Príjem" width={'full'}>
                            <TextInput name="income" type="number" min="0" step="1" required={true} />
                        </FormField>
                    </Box>
                
            </Box>

            <Box direction={'row-responsive'}>
                <Box basis={'1/4'} direction={'row'} width={"small"} align={'center'}>
                    <FormField label="Spolužiadateľ" width={'full'}>
                        <CheckBox name="isCoapplicant" checked={values.isCoapplicant} label="Áno" />
                    </FormField>
                </Box>

                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Vek" width={'full'}>
                        <TextInput name="coapplicantAge" disabled={values.isCoapplicant === false} type="number" min="0" step="1" required={true} />
                    </FormField>
                </Box>
            
                <Box basis={'1/4'} direction={'row'} align={'center'}>
                    <FormField label="Príjem" width={'full'}>
                        <TextInput name="coapplicantIncome" disabled={values.isCoapplicant === false} type="number" min="0" step="1" required={true} />
                    </FormField>
                </Box>
                
            </Box>

            <Heading level={3} margin={{bottom:'none'}}>Ďaľšie domácnosti</Heading>

            {values.households && values.households.map((household, index)=>{
                    return (
                        <Card key={index} background="light-1" elevation="none" border={true} margin={{right: '10px', top: '10px' }}>
                            <CardBody pad="small">

                            <Box key={'households-'+index}>
                                <Box direction={'row-responsive'}>
                                    <Box basis={'1/4'} direction={'row'} width={"small"} align={'center'}>
                                        <FormField label="Žiadateľ" width={'full'}>
                                            <CheckBox name="applicant" checked={true} disabled={true} label="Áno" />
                                        </FormField>
                                    </Box>

                                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                                        <FormField label="Vek" width={'full'}>
                                            <TextInput name={`households[${index}][age]`} type="number" value={values.households[index]['age']} min="0" step="1" required={true} />
                                        </FormField>
                                    </Box>
                                
                                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                                        <FormField label="Príjem" width={'full'}>
                                            <TextInput name={`households[${index}][income]`} type="number" value={values.households[index]['income']} min="0" step="1" required={true} />
                                        </FormField>
                                    </Box>

                                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                                        <FormField label="Počet detí" width={'full'}>
                                            <TextInput name={`households[${index}][kids]`} value={values.households[index]['kids']} type="number" min="0" step="1" required={true} />
                                        </FormField>
                                    </Box>

                                </Box>

                                <Box direction={'row-responsive'}>
                                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                                        <FormField label="Spolužiadateľ" width={'full'} >
                                            <CheckBox name={`households[${index}][isCoapplicant]`} checked={values.households[index]['isCoapplicant']===true} label="Áno" />
                                        </FormField>
                                    </Box>

                                    <Box basis={'1/4'} direction={'row'} align={'center'}>
                                        <FormField label="Vek" width={'full'}>
                                            <TextInput name={`households[${index}][coapplicantAge]`} disabled={values.households[index]['isCoapplicant']===false} type="number" value={values.households[index]['coapplicantAge']} min="0" step="1" required={true} />
                                        </FormField>
                                    </Box>            

                                    <Box basis={'1/4'} direction={'row'} align={'center'} justify={'center'}>
                                        <FormField label="Príjem" width={'full'}>
                                            <TextInput name={`households[${index}][coapplicantIncome]`} disabled={values.households[index]['isCoapplicant']===false} value={values.households[index]['coapplicantIncome']} type="number" min="0" step="1" required={true} />
                                        </FormField>
                                    </Box>       

                                    <Box basis={'1/4'} direction={'row'} align={'center'} justify={'end'}>
                                        <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeHousehold(index)} />
                                    </Box>                           
                                </Box>                       
    
                            </Box>
                            </CardBody>

                        </Card>
                    )
                }
            )}
                <Box direction={'row-responsive'} margin={{bottom:'medium', top: '10px'}}>
                    <Button type="button" secondary label="Pridať ďalšiu domácnosť" icon={<Icons.AddCircle/>} onClick={()=>addHousehold()} />
                </Box>
        </>
    )
}

export default BasicLoanAmountNbsForm;