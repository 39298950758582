import { Box, CheckBoxGroup, Heading, RadioButtonGroup, Text, TextInput } from "grommet";
import { useEffect, useState } from "react";
import LoanAdvancedPdfExport from "./LoanAdvancedPdfExport";

const LoanAdvancedColumnRowSelection = ({template, values = null}) => {
    const [selectedColumns, setSelectedColumns] = useState(LoanAdvancedPdfExport.getAllColumns())
    const [selectedRowOption, setSelectedRowOption] = useState(getBaseRowOptions()[0].value)
    const [selectedCustomRowOptions, setSelectedCustomRowOptions] = useState([getCustomRowOptions()[0].value])
    const [selectedSpecifiedRows, setSelectedSpecifiedRows] = useState("");

    function getBaseRowOptions(){
        return [
            {
                label: "Všetky",
                value: LoanAdvancedPdfExport.BASE_ROW_OPTION_ALL,
            },
            {
                label: "Prispôsobiť",
                value: LoanAdvancedPdfExport.BASE_ROW_OPTION_CUSTOM,
            },   
        ]
    }
    function getCustomRowOptions(){
        return [
            {
                label: "1. a posledný riadok",
                value: LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_FIRST_LAST_ROW
            },
            {
                label: "zobraziť mimoriadne vklady",
                value: LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_MIMORIADNE_VKLADY
            },
            {
                label: "zobraziť zmeny úrokovej sadzby",
                value: LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_ZMENA_UROK_SADZBY
            },
            {
                label: "zobraziť výročia",
                value: LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_VYROCIA
            },
            {
                label: "Vlastná voľba riadkov",
                value: LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_CUSTOM
            }    
        ]
    }

    function onSelectedCustomRowOption(value){
        if(!value.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_CUSTOM)){
            setSelectedSpecifiedRows("")
        }
        setSelectedCustomRowOptions(value)
    }

    function getColumnOptions(){
        const columnsNames = LoanAdvancedPdfExport.getAllColumns();
        const availableColumns = values ? LoanAdvancedPdfExport.getAvailableColumns(values) : LoanAdvancedPdfExport.getAllColumns()
        const options = [];

        columnsNames.forEach(columnName => {
            const label = LoanAdvancedPdfExport.mapColumnNameToLabel(columnName)
            options.push({label: label, value: columnName, disabled: !availableColumns.includes(columnName)}) 
        });
        
        return options;
    }

    useEffect(() => {
        if(template){
            setSelectedColumns(template.contents.state.columns)
            setSelectedRowOption(template.contents.state.row_option )
            template?.contents?.state?.row_custom_options && setSelectedCustomRowOptions(template.contents.state.row_custom_options)
            template?.contents?.state?.row_custom_option_row_num && setSelectedSpecifiedRows(template.contents.state.row_custom_option_row_num)
        }else if(values){
            setSelectedColumns(LoanAdvancedPdfExport.getAvailableColumns(values))
        }else{
            setSelectedColumns(LoanAdvancedPdfExport.getAllColumns);
        }
        
    },[template])

    const checkboxOptions = getColumnOptions();
    const baseRowOptions = getBaseRowOptions();
    const customRowOptions = getCustomRowOptions();

    return (
        <>
        <Heading level={"5"} margin={{bottom:'xsmall'}}>Nastavenie</Heading>
        <Box direction={'row'} gap="large">
            <Box basis={'1/2'} round={'xsmall'} margin={{bottom: 'small', top:'small'}}>
                <Text margin={{bottom: 'medium'}} color={'dark-5'} textAlign={'center'} >Stĺpce</Text>
                <CheckBoxGroup
                    onChange={(e) => setSelectedColumns(e.value)}
                    options={checkboxOptions} 
                    valueKey={'value'} 
                    value={selectedColumns}   
                    name="columns" 
                />
            </Box>

            <Box direction={'column'} basis="1/2">
            <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'small', top:'small'}}>
                <Text margin={{bottom: 'medium'}} color={'dark-5'} textAlign={'center'} >Riadky</Text>
                <RadioButtonGroup
                    name="row_option"
                    options={baseRowOptions}
                    value={selectedRowOption}
                    onChange={(event) => setSelectedRowOption(event.target.value)}
                />
            </Box>
            {selectedRowOption === LoanAdvancedPdfExport.BASE_ROW_OPTION_CUSTOM &&                         
                <Box basis={'auto'} gap="medium" round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                <CheckBoxGroup
                    onChange={(e) => onSelectedCustomRowOption(e.value)}
                    options={customRowOptions} 
                    valueKey={'value'} 
                    value={selectedCustomRowOptions}  
                    name="row_custom_options"  
                />  
                {selectedCustomRowOptions.includes(LoanAdvancedPdfExport.CUSTOM_ROW_OPTION_CUSTOM) &&                             
                    <TextInput
                        name="row_custom_option_row_num"
                        placeholder="príklad: 1-5, 8, 12, 15-19"
                        value={selectedSpecifiedRows}
                        pattern="[0-9-, ]*"
                        required
                        onChange={event => setSelectedSpecifiedRows(event.target.value)}
                    />
                }    
                </Box>                
            } 
        </Box>
    </Box>
    </>
    )    
}

export default LoanAdvancedColumnRowSelection;