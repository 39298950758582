import { Box, Heading, Select } from "grommet";
import { useEffect, useState } from "react";

const LoadPdfExportTemplate = ({setTemplate, savedTemplates}) => {
    const [filteredTemplates, setFilteredTemplates] = useState([]);


    useEffect(() => {
        setFilteredTemplates(savedTemplates)         
    }, [savedTemplates])

    return (
        <Box direction={'column'} gap="small">
        <Heading level={"5"} margin={{bottom:'xsmall'}}>Načítať šablónu</Heading>

        <Select 
            labelKey="title"
            valueKey={{ key: 'id', reduce: true }}
            options={filteredTemplates.map(item=>{return {id: item.id, title: item.contents.title, calc_key: item.calc_key, template: item}})}
            emptySearchMessage="Žiadne výsledky"
            onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, 'i');
                setFilteredTemplates(savedTemplates.filter((o) => exp.test(o.contents.title)));
            }}
            onChange={(e) => setTemplate(e.option.template)}
        />
        </Box>

    )
}


export default LoadPdfExportTemplate;