import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, compose, createStore} from 'redux'
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import AppReducer from "./reducers/AppReducer";
import App from "./components/App/App";

const initialState = {};
// const middlewares = compose(
//     applyMiddleware(
//         thunkMiddleware,
//     )
// );

// const rootStore = createStore(
//     AppReducer,
//     initialState,
//     middlewares
// );

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootStore = createStore(
    AppReducer,
    initialState,
    composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
        )
    )
);

ReactDOM.render(
  <StrictMode>
      <Provider store={rootStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//fix mobile fullheight problems
function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
}
window.addEventListener('resize', setDocHeight);
window.addEventListener('orientationchange', setDocHeight);
setDocHeight();