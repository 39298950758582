import {Box, Image} from 'grommet';
import React, {useEffect} from "react";
import ThemeService from "../../services/ThemeService";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {UgSpinner} from "../../components/Spinner/UgSpinner";

function SetTheme(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const routeParams = useParams();
    const state = useSelector((state) => state);

    useEffect(() => {
        const pool = routeParams ? routeParams.pool.toUpperCase() : 'UG';

        if (ThemeService.getTheme().pool===pool){
            history.push('/');
            return true;
        }

        ThemeService.doGetSettings(pool).then(()=>{
            dispatch({type:'theme/change'});
            history.push('/');
            return true;
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Box fill={true} direction='column' alignContent='center' align='center' justify='center'>
            <Box width='large' align='center' border={true} pad='30px' animation='fadeIn'>
                <Image width='180px' src={state.Theme.logo} margin='medium' />
                <p>Prosím počkajte...</p>
                <UgSpinner />
            </Box>
        </Box>
    );
}

export default SetTheme;
