import CalcDetailService from "../services/CalcDetailService";

const stateData = CalcDetailService.getStateData();
const initialState = stateData ? stateData : null;

export function CalcDetailReducer(state = initialState, action) {
    switch (action.type) {
        case 'calc/save': return CalcDetailService.getStateData();
        default:
            return state
    }
}