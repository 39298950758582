import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Form,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    TextInput
} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanFixationHelp from "./LoanFixationHelp";
import LoanFixationPdfExport from "./LoanFixationPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function LoanFixation(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'LoanFixation';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    function Fixation(loanRate, fixation){
        return {
            loanRate: loanRate*1,
            fixation: fixation*1,
        }
    }

    const defaultValues = {
        title: 'Dopočet fixácie',
        period: 30,
        periodUnits: 'y',
        loanAmount: 70000,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const fixationsListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['fixationsList']) ? state.CalcDetail[calcStateKey]['fixationsList'] : [];
    const fixationFormInitialState = Fixation(1,3);

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [fixationsList, setFixationsList] = useState(fixationsListInitialState);
    const [fixationsModal, setFixationsModal] = useState(false);
    const [fixationsForm, setFixationsForm] = useState(fixationFormInitialState);
    const [activeRequest, setActiveRequest] = useState(false);

    function saveCalcDetail(values, result, fixationsList){
        const calcDetailState = {
            values:values,
            result:result,
            fixationsList:fixationsList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result, fixationsList);
        // eslint-disable-next-line
    }, [values, result, fixationsList]);

    useEffect(() => {
        if (result){
            onFormSubmit(values);
        }
        // eslint-disable-next-line
    }, [fixationsList]);

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        const reqData = {
            periodInMonths: periodMonths,
            loanAmount: values.loanAmount,
            fixations: fixationsList
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/fixation', reqData)
            .then((res) => {
                    let fixations = res.fixations;
                    let fix = []
                    let fixationsTable = [];

                    for (let i=0; i<fixations.length; i++){
                        if (!fix.includes(fixations[i].fixation1)){
                            fix.push(fixations[i].fixation1)
                        }
                        if (!fix.includes(fixations[i].fixation2)){
                            fix.push(fixations[i].fixation2)
                        }
                    }
                    fix = fix.sort(function(a, b) {return a - b;});

                    for (let r=0; r<fix.length; r++){
                        for (let c=0; c<fix.length; c++){
                            if (!fixationsTable[r]){
                                fixationsTable[r] = [];
                            }
                            if (!fixationsTable[r][c]){
                                fixationsTable[r][c] = null;
                            }
                            if (fixationsTable[r][c]){
                                continue;
                            }

                            if (fix[r]===fix[c]){
                                fixationsTable[r][c] = {
                                    fixation1: fix[r],
                                    fixation2: fix[c],
                                    loanRate: fixationsList.filter((of)=>{return of.fixation===fix[r];}).pop().loanRate,
                                    inputRate: true
                                };
                            } else {
                                let f = findFixation(fix[r],fix[c],fixations);
                                if (f){
                                    f.inputRate = false
                                }
                                fixationsTable[r][c] = f;
                            }
                        }
                    }

                    setResult(fixationsTable);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    function findFixation(fix1, fix2, fixations){
        let found = fixations.filter((f)=>{
            return f.fixation1===fix1 && f.fixation2===fix2;
        });
        return (found && found.length ? found.pop() : null);
    }

    const pinKey = 'fixacia-uveru';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanFixationPdfExport({values:values,result:result,fixationsList:fixationsList,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function openFixationModal(data=null){
        setFixationsForm(Object.assign({}, fixationFormInitialState,data));
        setFixationsModal(true);
    }

    function removeFixation(index){
        if (fixationsList && fixationsList[index]){
            const newFixationsList = [...fixationsList.slice(0, index), ...fixationsList.slice(index + 1)]
            setFixationsList(newFixationsList);
        }
    }

    function onFixationFormSubmit(data){
        const newFixation = Fixation(data.loanRate, data.fixation);
        let newFixationsList = [];
        if (fixationsList.filter((item)=>{return item.fixation === newFixation.fixation;}).length){
            newFixationsList = fixationsList.map((item)=>{
                if (item.fixation === newFixation.fixation){
                    item.loanRate = newFixation.loanRate;
                }
                return item;
            });
        } else {
            newFixationsList = [...fixationsList, newFixation];
        }
        setFixationsList(newFixationsList);
        setFixationsModal(false);
        setFixationsForm(fixationFormInitialState);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state.values);
            setFixationsList(res.contents.state.fixationsList);
            onFormSubmit(res.contents.state.values);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state.values);
                        setFixationsList(res.contents.state.fixationsList);
                        onFormSubmit(res.contents.state.values);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanFixationHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={{
                                                 values:values,
                                                 result:result,
                                                 fixationsList:fixationsList,
                                             }}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        setFixationsList([]);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openFixationModal()} icon={<Icons.AddCircle />} label="Pridať Fixáciu" />
                        </Box>
                        {fixationsList && fixationsList.map((fixation, findex)=>{
                            return <Card elevation="none" border={true} key={"fixationCard_"+findex} width="small" background="light-1" margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.Calendar />&nbsp;&nbsp;&nbsp;Fixácia:</Box></td>
                                            <td><span><b>{fixation.fixation}</b> r</span></td>
                                        </tr>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.LineChart />&nbsp;&nbsp;&nbsp;Úrok:</Box></td>
                                            <td><span><b>{fixation.loanRate}</b> %</span></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                                <CardFooter background="light-2">
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeFixation(findex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result && result.length>0 && <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <div className="table-responsive">
                        <table className="table styled-table text-center">
                            <tbody>
                            <tr className="sticky-header">
                                <th><b>Obdobie fixácie</b></th>
                                {result.map((row, r)=><th key={'header_'+r} className={'text-center'}>{row[row.length-1] ? row[row.length-1].fixation1 + ' r' : '-'}</th>)}
                            </tr>
                            {result.map((row, r)=>{
                                return  <tr key={'row'+r}>
                                    <th>{row[row.length-1] ? row[row.length-1].fixation1+' r' : '-'}</th>
                                    {row.map((column, c)=><td key={'cell_'+r+'_'+c} className={column && column.inputRate ? 'table-cell-important' : ''}>{column ? FormatService.round(column.loanRate) + ' %' : ''}</td>)}
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

            {fixationsModal &&
            (<Layer onEsc={() => setFixationsModal(false)} onClickOutside={() => setFixationsModal(false)}>
                <Box pad="medium">
                    <Form
                        value={fixationsForm}
                        onChange={nextValue => {
                            setFixationsForm(nextValue);
                        }}
                        onSubmit={({ value }) => onFixationFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Pridať fixáciu na porovnanie</Heading>

                        <FormField label="Dĺžka fixácie (roky)" width={'full'}>
                            <TextInput name="fixation" type="number" min="1" step="1" autoFocus required={true} />
                        </FormField>

                        <FormField label="Úroková sadzba (%)" width={'full'}>
                            <TextInput name="loanRate" type="number" step="0.001" required={true} />
                        </FormField>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setFixationsModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

        </Box>
    );
}

export default LoanFixation;