import FormatService from "../../services/FormatService";

function SZCOPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'salary', label: values.mode==='net' ? 'Obrat (€)' : 'Čistý príjem (€)', value: values.mode==='net' ? values.salary : values.netIncome},
        //{key: 'salaryPeriod', label: 'Obdobie', value: values.salaryPeriod.substr(5)},
        {key: 'months', label: 'Počet mesiacov SZČO', value: values.months},
        {key: 'healthPayment', label: 'Zdravotné poistenie', value: values.healthPayment},
        {key: 'socialPayment', label: 'Sociálne poistenie', value: values.socialPayment},
        {key: 'isFlatRate', label: 'Paušálne náklady', value: values.isFlatRate? 'Áno':'Nie'},
        {key: 'otherPaymentValue', label: 'Iné náklady', value: values.otherPaymentValue},
        {key: 'spouseWithoutIncome', label: 'Manžel/-ka bol/-a v minulom roku bez príjmu', value: values.spouseWithoutIncome? 'Áno':'Nie'}
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right" >${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
                ${values.children.length ?                    `
                    ${values.children.map((child, idx) => {
                        return `
                            <tr>
                                <th>Nezaopatrené dieťa ${idx+1}</th>   
                                <td class="text-sm-right">${child.month.label}/${child.year}</td>                                 
                            </tr>
                        `
                    }).join('')}
                    `
                    : ""
                }
            </table>
        </div>
    </div>
        <div class="row-flex mb3">
            <div class="col-12">
                 <table class="table styled-table">
                        <tbody>
                        <tr>
                            <th>Čiastkový základ dane </th>
                            <td class="text-right">${FormatService.round(result.partialTaxBase)}€</td>
                        </tr>
                        <tr>
                            <th>Odpočítateľná položka </th>
                            <td class="text-right">${FormatService.round(result.deductibleItem)}€</td>
                        </tr>
                        <tr>
                            <th>Základ dane</th>
                            <td class="text-right">${FormatService.round(result.taxBase)}€</td>
                        </tr>

                        <tr>
                            <th>Daň </th>
                            <td class="text-right">${FormatService.round(result.tax)}€</td>
                        </tr>
                        <tr>
                            <th>Daňový bonus na deti</th>
                            <td class="text-right">${FormatService.round(result.childrenBonus)}€</td>
                        </tr>
                        <tr>
                            <th>Daň zňížená od daňový bonus</th>
                            <td class="text-right">${FormatService.round(result.taxWithoutChildrenBonus)}€</td>
                        </tr>
                        ${result.taxWithoutChildrenBonus < 0 ?
                            `<tr>
                                <th>${values.mode==='net' ? 'Čistý príjem' : 'Obrat'} bez daňového preplatku </th>
                                <td class="table-cell-highlighted text-right">${FormatService.round(result.incomeWithoutTaxOverpayment, 2)}€</td>
                            </tr>`
                            :
                            ""
                        }
                        <tr>
                            <th>${values.mode==='net' ? 'Čistý príjem' : 'Obrat'}</th>
                            <td class="table-cell-important text-right">${values.mode==='net' ? FormatService.round(result.netSalary) : FormatService.round(result.turnover)}€</td>
                        </tr>
                        <tr>
                            <th>Mesačné zdravotné odvody pre ďalšie obdobie </th>
                            <td class="table-cell-highlighted text-right">${FormatService.round(result.healthLevy)}€</td>
                        </tr>
                        <tr>
                            <th>Mesačné sociálne odvody pre ďalšie obdobie </th>
                            <td class="table-cell-highlighted text-right">${FormatService.round(result.socialLevy)}€</td>
                        </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default SZCOPdfExport;