import {Box} from 'grommet';
import AuthService from "../../services/AuthService";

function MyProfile(props) {
    return (
        <Box full={true}>
            {JSON.stringify(AuthService.getProfile())}
        </Box>
    );
}

export default MyProfile;
