import React from 'react';
import {Box} from 'grommet';
import ThemeService from "../services/ThemeService";
import {ToastContainer} from "react-toastify";

function SingleColumnLayout({mainComponent}) {

    return (
        <Box fill={true} animation='slideRight' alignContent={'center'} style={ThemeService.getCssVariables()}>
            {mainComponent}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Box>
    );
}

export default SingleColumnLayout;
