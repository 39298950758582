import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormField,
    Heading,
    Paragraph,
    RadioButtonGroup,
    TextInput
} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanRefinanceHelp from "./LoanRefinanceHelp";
import LoanRefinancePdfExport from "./LoanRefinancePdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgReloadIcon} from "../../components/Icons/UgReloadIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function LoanRefinance(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'LoanRefinance';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Kalkulačka refinancovania',

        period: 30,
        periodUnits: 'y',
        annualInterestRate: 3,
        loanAmount: 70000,

        monthOfPayment: 20,
        fee: 0,
        feePercent: 0,

        periodRefinance: 30,
        periodUnitsRefinance: 'y',
        annualInterestRateRefinance: 2,
        loanAmountRefinance: 0,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const resultRefinanceInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['resultRefinance']) ? state.CalcDetail[calcStateKey]['resultRefinance'] : null;

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [resultRefinance, setResultRefinance] = useState(resultRefinanceInitialState);
    const [activeRequest, setActiveRequest] = useState(false);

    function saveCalcDetail(values, result, resultRefinance){
        const calcDetailState = {
            values:values,
            result:result,
            resultRefinance:resultRefinance,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result, resultRefinance);
        // eslint-disable-next-line
    }, [values, result, resultRefinance]);

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;
        const periodMonthsRefinance = values.periodUnitsRefinance === 'm' ? values.periodRefinance : values.periodRefinance*12;

        const reqData = {
            annualInterestRate: values.annualInterestRate,
            annualInterestRateRefinance: values.annualInterestRateRefinance,
            periodInMonths: periodMonths,
            periodInMonthsRefinance: periodMonthsRefinance,
            //fee: values.fee*1,
            //penalty: values.feePercent*1,
            loanAmount: values.loanAmount,
            loanAmountRefinance: values.loanAmountRefinance,
            monthOfPayment: values.monthOfPayment,
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/refinance', reqData)
            .then((res) => {
                    let table = [];

                    let maxMonth = res.refinanceLoanResults && res.refinanceLoanResults.length ? res.refinanceLoanResults[res.refinanceLoanResults.length-1].month * 1 : 0;
                    if (maxMonth){
                        for (let i=0; i<maxMonth; i++){
                            table[i] = {
                                month: i+1,
                                baseLoanResult: null,
                                refinanceLoanResult: null,
                            };
                        }
                        if (res.baseLoanResults){
                            for (let i=0; i<res.baseLoanResults.length; i++){
                                let month = res.baseLoanResults[i].month;
                                table[month-1].baseLoanResult = res.baseLoanResults[i];
                            }
                        }
                        if (res.refinanceLoanResults){
                            for (let i=0; i<res.refinanceLoanResults.length; i++){
                                let month = res.refinanceLoanResults[i].month;
                                table[month-1].refinanceLoanResult = res.refinanceLoanResults[i];
                            }
                        }
                    }

                    res.table = table;
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    function onFormSubmitRefinance(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        const reqData = {
            annualInterestRate: values.annualInterestRate,
            periodInMonths: periodMonths,
            loanAmount: values.loanAmount,
            monthOfPayment: values.monthOfPayment,
            fee: values.fee*1,
            penalty: values.feePercent*1,
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/refinance/newLoan', reqData)
            .then((res) => {
                    setResultRefinance(res);

                    setValues(Object.assign({}, values, {
                        loanAmountRefinance: FormatService.round(res.amount),
                        periodRefinance: FormatService.round(res.periodInMonths),
                        periodUnitsRefinance: 'm'
                    }));

                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'refinancovanie';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanRefinancePdfExport({values:values,result:result,resultRefinance:resultRefinance,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanRefinanceHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        setResultRefinance(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Heading level={3} margin={{bottom: 'none'}}>Pôvodný úver</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                                <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Parametre refinancovania</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Mesiac vyplatenia" width={'full'}>
                                <TextInput name="monthOfPayment" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} alignItems={'center'}>
                            <FormField label="Poplatok (€)" width={'full'}>
                                <TextInput name="fee" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'}>
                            <FormField label="Poplatok (%)" width={'full'}>
                                <TextInput name="feePercent" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'}>
                            <Box width={'34px'}><UgHelpIcon color={'dark-4'} /></Box>
                            <Box width={'medium'}><Paragraph color={'dark-4'} size={'small'}>Poplatok v % sa ráta z výšku zostatku v mesiaci refinancovania</Paragraph></Box>
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Refinančný úver</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmountRefinance" min="1" step="0.01" type="number" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                                <TextInput name="annualInterestRateRefinance" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="periodRefinance" type="number" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnitsRefinance" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Box basis={'auto'} direction={'row'} align={'center'}>
                        <Button type="button" margin={{top:'medium'}} secondary onClick={()=>onFormSubmitRefinance(values)} icon={<UgReloadIcon />} label="Navrhni parametre refinančného úveru" disabled={activeRequest} />
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result!==null &&
            <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <Box direction={'row-responsive'} basis={'full'}>
                        <Card basis={'1/3'} margin={{right: 'medium'}} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Pôvodný úver</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Splátka (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.loanPayment)}</td>
                                    </tr>
                                    <tr>
                                        <th>Celkové náklady v {result.table.length}. mesiaci (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.table[result.table.length-1].baseLoanResult.totalCost)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>

                        <Card basis={'1/3'} margin={{right: 'medium'}} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Refinančný úver</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Splátka (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.loanPaymentRefinance)}</td>
                                    </tr>
                                    <tr>
                                        <th>Celkové náklady v {result.table.length}. mesiaci (€)</th>
                                        <td className={'text-right'}>{FormatService.round(result.table[result.table.length-1].refinanceLoanResult.totalCost)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>

                        <Card basis={'1/3'} elevation={'none'} border={true}>
                            <CardHeader pad={'small'}>Zhrnutie</CardHeader>
                            <CardBody>
                                <table className={'table styled-table'}>
                                    <tbody>
                                    <tr>
                                        <th>Bod zlomu</th>
                                        <td className={'text-right table-cell-important'}>{result.breakEvenMonth}</td>
                                    </tr>
                                    <tr>
                                        <th>Úspora v {result.table.length}. mesiaci (€)</th>
                                        <td className={'text-right table-cell-important'}>
                                            {FormatService.round(result.table[result.table.length-1].baseLoanResult.totalCost - result.table[result.table.length-1].refinanceLoanResult.totalCost)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Box>

                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className="sticky-header">
                            <tr>
                                <th></th>
                                <th colSpan={3}>Pôvodný úver</th>
                                <th colSpan={3}>Refinančný úver</th>
                            </tr>
                            <tr>
                                <th>Mesiac</th>
                                <th>Splátka</th>
                                <th>Zostatok úveru</th>
                                <th>Náklady spolu</th>
                                <th>Splátka</th>
                                <th>Zostatok úveru</th>
                                <th>Náklady spolu</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result && result.table && result.table.length && result.table.map((row, index)=>{
                                return <tr key={"result_"+index} className={'text-center'}>
                                    <td>{row.month}.</td>
                                    <td>{FormatService.round(row.baseLoanResult.payment)}</td>
                                    <td>{FormatService.round(row.baseLoanResult.balance)}</td>
                                    <td>{FormatService.round(row.baseLoanResult.totalCost)}</td>
                                    <td>{!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.payment)}</td>
                                    <td>{!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.balance)}</td>
                                    <td>{!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.totalCost)}</td>
                                </tr>;
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

        </Box>
    );
}

export default LoanRefinance;