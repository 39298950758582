import React from 'react';
import { Blank } from 'grommet-icons';

export const UgSavingIcon = props => (
    <Blank {...props}>
        <g>
            <g>
                <path d="M21.421 7h-3.632a1.21 1.21 0 010-2.421h4.843c.668 0 1.21.542 1.21 1.21v4.843a1.21 1.21 0 01-2.421 0V7z"></path>
                <path d="M14 13.797l6.502-7.585a1.211 1.211 0 011.838 1.576l-7.263 8.473a1.21 1.21 0 01-1.675.158l-5.207-4.165-5.286 5.286a1.21 1.21 0 11-1.712-1.712l6.052-6.052a1.21 1.21 0 011.612-.09L14 13.797z"></path>
            </g>
        </g>
    </Blank>
);