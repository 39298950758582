import { Box } from "grommet";

const InsuredSumsHelp = () => {
        //TODO #21 textacia

    return (
        <Box>
        <h2>Popis</h2>
        </Box>
    )
}

export default InsuredSumsHelp;