import {Avatar, Box} from 'grommet';
import AuthService from "../../services/AuthService";
import * as Icons from "grommet-icons";

function MyProfileWidget(props) {
    return (
        <Box direction={'row'}>
            { AuthService.getProfile().label && <Box direction={'row'} align="center" gap="small">
                <Box><strong>{AuthService.getProfile().label}</strong></Box>
                <Avatar background={"brand"}>
                    <Icons.User color={"active"} />
                </Avatar>
            </Box>}

        </Box>
    );
}

export default MyProfileWidget;
