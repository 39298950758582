
function RpmnPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
        {key: 'loanAmount', label: 'Výška úveru (EUR)', value: values.loanAmount},
        {key: 'paymentAmount', label: 'Výška splátky (EUR)', value: values.paymentAmount},
        {key: 'paymentDay', label: 'Deň v mesiaci, kedy zaplatíme splátku', value: values.paymentDay},
        {key: 'fee', label: 'Jednorázový poplatok (EUR)', value: values.fee},
        {key: 'feeDay', label: 'Deň v mesiaci, kedy zaplatíme poplatok', value: values.feeDay},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <div class="card">
                <div class="card-block">
                    <h3>
                       Ročná percentuálna miera nákladov je
                        <strong class="pull-right">
                            ${result.yearPercentageCostsRate} %
                        </strong>
                    </h3>
                </div>
            </div>
        </div>
         <div class="col-12">
            <div class="card">
                <div class="card-block">
                    <h3>
                        Celkové náklady sú
                        <strong class="pull-right">
                            ${result.totalCosts} €
                        </strong>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default RpmnPdfExport;