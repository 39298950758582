
function OneTimeInvestmentPdfExport({values,result,chart}) {
    let inputs = [
        {key: 'period', label: 'Investičný horizont', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
    ];
    switch (values.mode){
        case 'total-value':
            inputs.push({key: 'deposit', label: 'Jednorázová investícia (€)', value: values.deposit});
            inputs.push({key: 'annualProfit', label: 'Očakávaný ročný výnos (%)', value: values.annualProfit});
            inputs.push({key: 'annualInflation', label: 'Ročná inflácia (%)', value: values.annualInflation});
            break;
        case 'deposit':
            inputs.push({key: 'totalValue', label: 'Budúca hodnota investície (€)', value: values.totalValue});
            inputs.push({key: 'annualProfit', label: 'Očakávaný ročný výnos (%)', value: values.annualProfit});
            inputs.push({key: 'annualInflation', label: 'Ročná inflácia (%)', value: values.annualInflation});
            break;
        case 'interest-rate':
            inputs.push({key: 'deposit', label: 'Jednorázová investícia (€)', value: values.deposit});
            inputs.push({key: 'totalValue', label: 'Budúca hodnota investície (€)', value: values.totalValue});
            break;
        default: break;
    }

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <div class="card">
                <div class="card-block">
                    <h3>
                        ${values.mode === 'total-value' ? 'Hodnota investície na konci investičného horizontu je' : ''}
                        ${values.mode === 'deposit' ? 'Na dosiahnutie investičného zámeru treba vložiť' : ''}
                        ${values.mode === 'interest-rate' ? 'Čistý ročný percentuálny výnos investície je' : ''}
                        <strong class="pull-right">
                            ${result}
                            ${values.mode === 'total-value' ? '€' : ''}
                            ${values.mode === 'deposit' ? '€' : ''}
                            ${values.mode === 'interest-rate' ? '%' : ''}
                        </strong>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default OneTimeInvestmentPdfExport;