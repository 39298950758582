import {Box, Button, Form, FormField, Heading, Layer, RadioButtonGroup, Select, Text, TextInput} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ErrorService from "../../services/ErrorService";
import SavedStatesService from "../../services/SavedStatesService";
import {toast} from "react-toastify";

function AddEditSavedStatesModal(props) {

    const defaultValues = {
        title: '',
        mode: 'new',

        activeRequest: false
    };

    const [values, setValues] = React.useState(defaultValues);
    const [savedStates, setSavedStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);

    const reloadSavedStates =  useCallback(() => {
        SavedStatesService
            .getList()
            .then((res) => {
                    const list = res
                        .filter((item)=>{
                            if (!props.pinkey){
                                return true;
                            }
                            return item.calc_key===props.pinkey
                        })
                        .map((item)=>{
                            item.calc = window.calcMap[item.calc_key];
                            return item;
                        });
                    setSavedStates(list);
                },
                (error) => {
                    ErrorService.fromApi(error);
                });
    }, [props.pinkey]);

    useEffect(()=>{
        //pri kazdom otvoreni okna
        if (props.show===true){
            setValues(defaultValues);
            reloadSavedStates();
        }
    }, [props.show, reloadSavedStates])

    useEffect(() => {
        setFilteredStates([...savedStates]);
    }, [savedStates]);
    
    function closeModal(res=null){
        if (res){
            toast.success('Stav bol uložený', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        props.closeaction(res);
    }

    function onFormSubmit(values){
        if (!props.pinkey || !props.state || !props.version){
            alert('Key and state and version are required');
            console.log(props);
            return false;
        }
        setValues(Object.assign({}, values, {activeRequest: true}));
        SavedStatesService.addEditSavedState(props.pinkey, values.title, props.state, props.version, values.mode==='edit'?values.savedState:null)
            .then((res) => {
                    const newValues = Object.assign({}, values, {activeRequest: false});
                    setValues(newValues);
                    closeModal(res);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    return (
        <Box>
            {props.children}

            {props.show===true && (
                <Layer
                    onEsc={()=>closeModal()}
                    onClickOutside={()=>closeModal()}
                    background={'transparent'}
                    elevation={'small'}
                >
                    <Box pad={'large'} round={'small'} border={true} background={'white'} animation={'zoomIn'} align={'center'} >
                        <Heading margin={{bottom:'xsmall'}}>Uloženie modelovania</Heading>
                        <Text color={'dark-5'} textAlign={'center'}>Po uložení sa ku tomuto nastaveniu<br />budete vedieť rýchlo vrátiť.</Text>
                        <Form
                            value={values}
                            onChange={nextValue => {
                                if (nextValue.savedState && nextValue.savedState!==values.savedState && values.mode==='edit'){
                                    const savedState = filteredStates.filter((f)=>f.id===nextValue.savedState).pop();
                                    if (savedState && savedState.calc_key && savedState.id){
                                        nextValue.title = savedState.contents.title;
                                    }
                                }
                                setValues(nextValue);
                            }}
                            onSubmit={({ value }) => onFormSubmit(value) }
                        >
                            <Box direction={'row-responsive'}>
                                <Box basis={'full'} round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                                    <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" options={[
                                        {value: 'new', label: "Uložiť ako nový záznam"},
                                        {value: 'edit', label: "Upraviť pôvodné modelovanie"},
                                    ]} />
                                </Box>
                            </Box>

                            {values.mode==='edit' && <Box basis={'full'} round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                                <Select name="savedState"
                                        labelKey="title"
                                        valueKey={{ key: 'id', reduce: true }}
                                        options={filteredStates.map(item=>{return {id: item.id, title: item.contents.title, calc_key: item.calc_key}})}
                                        onClose={() => setFilteredStates(savedStates)}
                                        onSearch={(text) => {
                                            // The line below escapes regular expression special characters:
                                            // [ \ ^ $ . | ? * + ( )
                                            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                            // Create the regular expression with modified value which
                                            // handles escaping special characters. Without escaping special
                                            // characters, errors will appear in the console
                                            const exp = new RegExp(escapedText, 'i');
                                            setFilteredStates(savedStates.filter((o) => exp.test(o.contents.title)));
                                        }}
                                />
                            </Box>}


                            <Box basis={'full'} direction={'row'} align={'center'}>
                                <FormField label="Názov" width={'full'}>
                                    <TextInput name="title" required={true} />
                                </FormField>
                            </Box>

                            <Box direction="row" gap="medium" margin={{top:'medium'}}>
                                <Button type="submit" primary label="Uložiť" disabled={values.activeRequest} />
                                <Button label="Zatvoriť" onClick={() => closeModal()} />
                            </Box>
                        </Form>
                    </Box>


                </Layer>
            )}
        </Box>
    );
}

export default AddEditSavedStatesModal;
