import {Box} from 'grommet';
import React from "react";

function AnnuityHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet:</p>
        <ol type={'A'}>
            <li>výšky renty, ktorú budeme pravidelne poberať (napr. na dôchodku) počas zvolenej doby vďaka tomu, že budeme "rozpúšťať" jednorázový vklad</li>
            <li>doby poberania renty, počas ktorej sa nám jednorázový vklad bude pravidelne "rozpúšťať" vo vopred stanovenej výške</li>
        </ol>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte typ kalkulačky, t.j. zvoľte parameter, ktorý chcete vypočítať.</li>
            <li>Zvoľte parametre výpočtu - vklad, výnos na účte z ktorého sa bude vyplácať renta a dobu rozpustenia vkladu/výšku pravidelnej renty.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je počítaný parameter.</p>
        <br /><br />
    </Box>);
}

export default AnnuityHelp;
