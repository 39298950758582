import {Box} from 'grommet';
import React from "react";

function DdsInvestmentHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet výnosu DDS, ktorý je tvorený výnosom fondov a príspevkami zamestnávateľa. Najmä vďaka príspevkom zamestnávateľa je táto forma sporenia aj v dnešnej dobe atraktívna.</p>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre sporenia.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je ročný výnos pravidelného sporenia a hodnota účtu na konci investičného horizontu.</p>
        <br /><br />
        <p><b>Poznámka: </b>ak nie je uvedené inak, výpočet nezohľadňuje daň!</p>
    </Box>);
}

export default DdsInvestmentHelp;
