import {Box, CheckBox, FormField, RadioButtonGroup, Select, TextInput} from 'grommet';

const BasicPaymentForm = ({values, setFilteredBanksList, banksList, filteredBanksList}) => {
    return (
        <>

            <Box basis={'1/3'} direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'}>
                    <Box direction={'row'} align={'center'} wrap>
                        <Box basis="1/2">
                            <FormField label="Dĺžka úveru" width={'large'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>
                    <Box basis="1/2">
                        <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                            {value: 'm',label: "Mesiacov"},
                            {value: 'y',label: "Rokov"},
                        ]} />
                        </Box>
                    </Box>
                </Box>       
            </Box>

            <Box direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                        <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                    </FormField>
                </Box>

                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Výška úveru (€)" width={'full'}>
                        <TextInput name="loanAmount" type="number" min="0" step="0.01" required={true} />
                    </FormField>
                </Box>
         
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Banka" width={'full'}>
                        <Select options={filteredBanksList}
                                required
                                name="bankId"
                                labelKey="name"
                                valueKey={{ key: 'id', reduce: true }}
                                dropHeight="large"
                                value={values.bankId}
                                placeholder="Výber banky"
                                onClose={() => setFilteredBanksList(banksList)}
                                onSearch={searchText => {
                                    const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                    const exp = new RegExp(escapedText, 'i');
                                    setFilteredBanksList( banksList.filter(o => {return exp.test(o.name)}) );
                                }}
                                emptySearchMessage={"Nič som nenašiel..."}
                        />
                    </FormField>
                </Box>
                        
            </Box>
    
            <Box direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Hypotéka pre mladých (ŠPM 2017)" width={'full'}>
                        <CheckBox name="spm" label="ŠPM 2017" />
                    </FormField>
                </Box>
        
                {values.spm && 
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Hodnota zabezpečenia (€)" width={'full'}>
                        <TextInput name="spmAssuranceAmount" type="number" min="0" step="0.01" required={true} />
                    </FormField>
                </Box>}            
            </Box>
        </>
    )
}

export default BasicPaymentForm;