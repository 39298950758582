import FormatService from "../../services/FormatService";

function createHouseholdTable(label, income, age, kids, isCoapplicant, coapplicantIncome, coapplicantAge){
    return (
        `<table class="table styled-table">
            <tbody>
                <tr class='text-center'> 
                    <th colspan="3" class='table-cell-important'>${label}</th>   
                </tr>
                <tr>
                    <td></td>
                    <td><strong>Žiadateľ</strong></td>
                    <td><strong>Spolužiadateľ</strong></td>
                </tr>
                <tr>
                    <td><strong>Vek</strong></td>
                    <td>${age}</td>
                    <td>${isCoapplicant ? coapplicantAge : "-"}</td>
                </tr>
                <tr>
                    <td><strong>Príjem</strong></td>
                    <td>${income}</td>
                    <td>${isCoapplicant ? coapplicantIncome : "-"}</td>
                </tr>
                <tr>
                    <td><strong>Počet detí</strong></td>
                    <td class="text-center" colspan="2" >${kids}</td>
                </tr>           
            </tbody>
        </table>`
    )
}

function LoanPdfExport({values,result,banksList,chart}) {
    let inputs = [
        {key: 'mode', label: 'Typ kalkulačky', value: () => {
            switch (values.mode){
                case 'basic_payment': return 'Výpočet splátky úveru';
                case 'basic_interest': return 'Výpočet úroku úveru';
                case 'basic_loan_amount': return 'Výpočet výšky úveru';
                case 'basic_loan_amount_nbs': return 'Maximálny úver podľa NBS';
                default: return ''; 
            } 
        }},
        {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
    ];
    switch (values.mode){
        case 'basic_payment':
            inputs.push({key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount});
            inputs.push({key: 'annualInterestRate', label: 'Ročná úroková sadzba (%)', value: values.annualInterestRate});
            inputs.push({key: 'bankId', label: 'Banka', value: banksList.filter((item)=>item.id===values.bankId)[0].pdfName});
            if (values.spm){
                inputs.push({key: 'annualProfit', label: 'Hypotéka pre mladých (ŠPM 2017)', value: values.spm ? 'Áno':'Nie'});
                inputs.push({key: 'assuranceAmount', label: 'Hodnota zabezpečenia (€)', value: values.spmAssuranceAmount});
            }
            break;
        case 'basic_interest':
            inputs.push({key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount});
            inputs.push({key: 'loanPayment', label: 'Výška splátky (€)', value: values.loanPayment});
            break;
        case 'basic_loan_amount':
            inputs.push({key: 'annualInterestRate', label: 'Ročná úroková sadzba (%)', value: values.annualInterestRate});
            inputs.push({key: 'loanPayment', label: 'Výška splátky (€)', value: values.loanPayment});
            break;
        case 'basic_loan_amount_nbs':
            inputs.push({key: 'annualInterestRate', label: 'Ročná úroková sadzba (%)', value: values.annualInterestRate});
            inputs.push({key: 'income', label: 'Celkový príjem (€)', value: [
                {
                    income: values.income,
                    isCoapplicant: values.isCoapplicant,
                    coapplicantIncome: values.coapplicantIncome
                },
                ...values.households
            ].reduce((totalIncome, currentHousehold) => {
                return totalIncome + (currentHousehold.isCoapplicant ? Number(currentHousehold.income) + Number(currentHousehold.coapplicantIncome) : currentHousehold.income)
            }, 0)});
            inputs.push({key: 'incomeReserve', label: 'Prijímová rezerva', value: values.incomeReserve*100+'%'});
            inputs.push({key: 'monthCosts', label: 'Mesačné záväzky (€)', value: values.monthCosts});
            if (values.otherLoans && values.otherLoans > 0) {
                inputs.push({key: 'otherLoans', label: 'Výška zostávajúcich úverov (€)', value: values.otherLoans});
            }
            inputs.push({key: 'fixationGreater10years', label: 'Fixácia vyššia ako 10 rokov', value: values.fixationGreater10years? 'Áno':'Nie'});
            break;
        default: break;
    }

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    ${ !['basic_loan_amount_nbs'].includes(values.mode) ?
        `<div class="row-flex mb3">
            <div class="col-12">
                <div class="card">
                    <div class="card-block">
                        <h3>
                            ${values.mode === 'basic_payment' ? 'Výška splátky je ' : ''}
                            ${values.mode === 'basic_interest' ? 'Ročná úroková sadzba je ' : ''}
                            ${values.mode === 'basic_loan_amount' ? 'Maximálna výška úveru je ' : ''}
                            <strong class="pull-right">
                                ${FormatService.round(result.result)}
                                ${values.mode === 'basic_payment' ? '€' : ''}
                                ${values.mode === 'basic_interest' ? '%' : ''}
                                ${values.mode === 'basic_loan_amount' ? '€' : ''}
                            </strong>
                        </h3>
                    </div>
                </div>
            </div>
        </div>`
        : 
        `
        <div class="row-flex mb3">
            ${createHouseholdTable(
                "Domácnosť žiadateľa",
                values.income, 
                values.age, 
                values.kids,
                values.isCoapplicant, 
                values.coapplicantIncome, 
                values.coapplicantAge 
            )}
        </div>
        <div class="row-flex mb3">
            ${values.households.map((household, index) => {
                return createHouseholdTable(
                        `${index+2}. Domácnosť`,
                        household.income, 
                        household.age, 
                        household.kids, 
                        household.isCoapplicant, 
                        household.coapplicantIncome, 
                        household.coapplicantAge
                    )
            })}
        </div>

        <div class="row-flex mb3">
            <div class="col-12">
                <div class="card">
                    <div class="card-block">
                        <h3 style="margin-bottom: 5px;">Maximálna výška úveru (prjmová rezerva) je <strong class="pull-right" style="padding-left:6px">${result.loanAmount}€</strong></h3>
                    </div>
                </div>
                ${result.globalDti ?
                    `<div class="card">
                        <div class="card-block">
                            <h3 style="margin-bottom: 5px;">Maximálna výška úveru (DTI ${FormatService.round(result.globalDti.coefficient,4 )}) je <strong class="pull-right" style="padding-left:6px">${result.globalDti.value}€</strong></h3>
                            ${(result.globalDti.valueWithoutOtherLoans && values.otherLoans && values.otherLoans > 0) ? `<div>Pri zohľadení výšky zostávajúcich úverov <strong class="pull-right" style="padding-left:6px">${result.globalDti.valueWithoutOtherLoans}<span>€</span></strong></div>`: ``}
                        </div>
                    </div>`
                : ""}
                <div class="card">
                    <div class="card-block">
                        <h3 style="margin-bottom: 5px;">Maximálna výška úveru (DTI 8) je <strong class="pull-right" style="padding-left:6px">${result.dti8}€</strong></h3>
                        ${(result.dti8WithoutOtherLoans && values.otherLoans && values.otherLoans > 0) ? `<div>Pri zohľadení výšky zostávajúcich úverov <strong class="pull-right" style="padding-left:6px">${result.dti8WithoutOtherLoans}<span>€</span></strong></div>`: ``}
                    </div>
                </div>
            </div>
        </div>

        
        
        `

        
    }
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default LoanPdfExport;