import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CheckBox,
    DateInput,
    Form,
    FormField,
    Heading,
    Layer,
    Select,
    TextInput
} from 'grommet';
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SavingBuildingHelp from "./SavingBuildingHelp";
import SavingBuildingPdfExport from "./SavingBuildingPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function SavingBuilding(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'SavingBuilding';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const RegularityList = [
        {name: 'Jednorázová platba', value: null},
        {name: 'Každý mesiac', value: 'month_1'},
        {name: 'Každé 3 mesiace', value: 'month_3'},
        {name: '2x ročne', value: 'month_6'},
        {name: 'Raz ročne', value: 'month_12'},
    ];

    const _resultsFilterList = [
        {shortName: 'Hodnota', name: 'Hodnota účtu', id: 'accountBalance'},
        {shortName: 'Výnos z hodnoty', name: 'Výnos pravidelnej investície (z hodnoty účtu)', id: 'loanRate'},
        {shortName: 'Odkupná hodnota', name: 'Odkupná hodnota', id: 'buyout'},
        {shortName: 'Výnos z odkupu', name: 'Výnos pravidelnej investície (z odkupnej hodnoty)', id: 'loanRate2'},
    ];

    function regularity(regularity){
        return RegularityList.find((o)=>o.value===regularity);
    }

    function ExtraPayment(month, payment, regularityValue=null){
        return {
            month: month,
            payment: payment,
            regularityObject: regularity(regularityValue),
        };
    }

    const defaultValues = {
        title: 'Stavebné sporenie',

        payment: 233.33,
        periodInYears: 6,
        firstPaymentTimestamp: new Date().toISOString(),
        targetAmount: 20000,
        baseRegularity: regularity('month_1'),

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const filteredResultInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['filteredResult']) ? state.CalcDetail[calcStateKey]['filteredResult'] : null;
    const productsListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['productsList']) ? state.CalcDetail[calcStateKey]['productsList'] : [];
    const resultsFilterListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['resultsFilterList']) ? state.CalcDetail[calcStateKey]['resultsFilterList'] : _resultsFilterList;
    const extraPaymentsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['extraPayments']) ? state.CalcDetail[calcStateKey]['extraPayments'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [filteredResult, setFilteredResult] = useState(filteredResultInitialState);
    const [productsList, setProductsList] = useState(productsListInitialState);
    const [resultsFilterList, setResultsFilterList] = useState(resultsFilterListInitialState);
    const [extraPayments, setExtraPayments] = useState(extraPaymentsInitialState);
    const [extraPaymentModal, setExtraPaymentModal] = useState(false);
    const [extraPaymentForm, setExtraPaymentForm] = useState({});
    const [activeRequest, setActiveRequest] = useState(false);

    function saveCalcDetail(values, result, filteredResult, productsList, resultsFilterList, extraPayments){
        const calcDetailState = {
            values:values,
            result:result,
            filteredResult:filteredResult,
            productsList:productsList,
            resultsFilterList:resultsFilterList,
            extraPayments:extraPayments,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result, filteredResult, productsList, resultsFilterList, extraPayments);
        // eslint-disable-next-line
    }, [values, result, filteredResult, productsList, resultsFilterList, extraPayments]);

    const reloadProductsList = useCallback(() => {
        ApiService.getBuidlingProductsList()
            .then((res) => {
                    setProductsList(res.products);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
    }, []);

    useEffect(() => {
        if (!productsList || productsList.length===0){
            reloadProductsList();
        }
        // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        transformResult();
        // eslint-disable-next-line
    }, [result, productsList]); //activeproductslist

    useEffect(() => {
        if (result){
           onFormSubmit(values);
        }
        // eslint-disable-next-line
    }, [extraPayments]);

    function transformResult(){
        if (result){
            if (window.debug){
                console.log('Transforming result to filteredResult')
            }
            let activeProdIds = productsList.filter((i)=>i.isChecked).map((prod)=>prod.id);
            let newResult = {
                rows: [],
                productsData: result.productsData.filter((prod)=>{
                    return activeProdIds.includes(prod.id);
                })
            };

            for (let i=0; i<result.payedList.length; i++){
                newResult.rows.push(
                    {
                        payedList: FormatService.round(result.payedList[i]),
                        paymentDates: FormatService.dateFromTimestamp(result.paymentDates[i], {
                            year: "numeric",
                            month: "short"
                        }),
                        payments: FormatService.round(result.payments[i]),
                        stateBonus: FormatService.round(result.stateBonus[i]),
                    }
                );
            }

            setFilteredResult(newResult);
        }
    }

    function onFormSubmit(values){
        let reqData = {
            periodInYears: values.periodInYears,
            targetAmount: values.targetAmount,
            firstPaymentTimestamp: values.firstPaymentTimestamp ? new Date(values.firstPaymentTimestamp).getTime() : null
        };

        reqData.deposits = [
            ...[ExtraPayment(1, values.payment, values.baseRegularity.value)].map((payment)=>{
                return {
                    month: payment.month-1,
                    payment: 1*payment.payment,
                    regularity: payment.regularityObject ? payment.regularityObject.value : null
                };}),

            ...extraPayments.map((payment)=>{
            return {
                month: payment.month-1,
                payment: 1*payment.payment,
                regularity: payment.regularityObject ? payment.regularityObject.value : null
            };})
        ];

        setActiveRequest(true);
        ApiService.call('post', '/v2/savings/building', reqData)
            .then((res) => {
                    setResult(res);

                    const newValues = Object.assign({}, values, {targetAmount: res.correctedTargetAmount});
                    setValues(newValues);

                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'stavebne-sporenie';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SavingBuildingPdfExport({
                values:values,
                result:filteredResult,
                activeResultsFilterList:resultsFilterList.filter((i)=>i.isChecked),
                activeProductsList:productsList.filter((i)=>i.isChecked),
                chart:null,
                extraPayments: extraPayments
            }),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function openAddEditExtraPaymentModal(month, payment, regularityValue){
        setExtraPaymentForm(Object.assign(extraPaymentForm, ExtraPayment(month, payment, regularityValue)));
        setExtraPaymentModal(true);
    }

    function onExtraPaymentFormSubmit(data){
        const newExtraPayments = [...extraPayments.filter((op)=>1*op.month!==1*data.month), ExtraPayment(1*data.month, data.payment, data.regularityObject.value)];
        setExtraPayments(newExtraPayments);
        setExtraPaymentModal(false);
    }

    function removeExtraPayment(index){
        if (extraPayments && extraPayments[index]){
            const newExtraPayments = [...extraPayments.slice(0, index), ...extraPayments.slice(index + 1)]
            setExtraPayments(newExtraPayments);
        }
    }

    function updateResult(result, dataToSet){
        const newResultFilterList = resultsFilterList.map((r)=>{
            if (r.id === result.id){
                r = Object.assign(r, dataToSet);
            }
            return r;
        });
        setResultsFilterList([...newResultFilterList]);
    }

    function updateProduct(product, dataToSet){
        const newProductsList = productsList.map((i)=>{
            if (i.id === product.id){
                i = Object.assign(i, dataToSet);
            }
            return i;
        });
        setProductsList([...newProductsList]);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state.values);
            setExtraPayments(res.contents.state.extraPayments);
            onFormSubmit(res.contents.state.values);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state.values);
                        setExtraPayments(res.contents.state.extraPayments);
                        onFormSubmit(res.contents.state.values);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SavingBuildingHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={{
                                                 values:values,
                                                 extraPayments:extraPayments,
                                             }}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        setFilteredResult(null);
                        setExtraPayments([]);
                        setProductsList(productsList.map((i)=>{i.isChecked=false; return i;}));
                        setResultsFilterList(resultsFilterList.map((i)=>{i.isChecked=false; return i;}));
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box direction={'row'} align={'center'} >
                            <FormField label="Platba (€)" width={'full'}>
                                <TextInput name="payment" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        <Box direction={'row'} align={'center'}>
                            <FormField width={'auto'} label="Doba sporenia (r)" >
                                <TextInput name="periodInYears" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>
                        <Box direction={'row'} align={'center'}>
                            <FormField label="Pravideľnosť platby" width={'full'}>
                                <Select options={RegularityList.filter((rli)=>rli.value)}
                                        required={true}
                                        name="baseRegularity"
                                        labelKey="name"
                                        valueKey="value"
                                        dropHeight="large"
                                        value={values.baseRegularity}
                                />
                            </FormField>
                        </Box>
                        <Box direction={'row'} align={'center'}>
                            <FormField label="Prvá platba" width={'full'}>
                                <DateInput name="firstPaymentTimestamp" format="dd.mm.yyyy"
                                           calendarProps={FormatService.calendarDefaults()} required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'}>
                            <FormField label="Cieľová suma (€)" width={'full'}>
                                <TextInput name="targetAmount" type="number" step="0.01" />
                            </FormField>
                        </Box>
                    </Box>

                    <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openAddEditExtraPaymentModal(ExtraPayment(1, 0, null))} icon={<Icons.AddCircle />} label="Pridať mimoriadny vklad" />
                        </Box>
                        {extraPayments && extraPayments.map((payment, pindex)=>{
                            return <Card elevation="none" border={true} key={"extraPaymentCard_"+pindex} width="small" background={"light-1"} margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}><Box direction={'row'} align={'center'}><Icons.Refresh />&nbsp;&nbsp;&nbsp;<span><b>{payment.regularityObject.name}</b></span></Box></td>
                                        </tr>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.Calendar />&nbsp;&nbsp;&nbsp;Mesiac:</Box></td>
                                            <td><span><b>{payment.month}</b></span></td>
                                        </tr>
                                        <tr>
                                            <td><Box direction={'row'} align={'center'}><Icons.Currency />&nbsp;&nbsp;&nbsp;Vklad:</Box></td>
                                            <td><span><b>{payment.payment}</b> €</span></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </CardBody>
                                <CardFooter background={"light-2"}>
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeExtraPayment(pindex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result && filteredResult!==null && <Box margin={{vertical: 'medium'}} flex={false} pad='medium' round='small' border={true} background={'white'}>
                <Box margin={{right:'small'}}>
                    <Heading level={4} margin={{bottom:'small'}}>Výstupy</Heading>
                    <Box direction={'row-responsive'} align={'center'} wrap>
                        {resultsFilterList.map((resultFilter, i)=>
                            <Box key={'checkbox-res-'+resultFilter.id} pad={{right:'small', bottom:'xsmall'}}><CheckBox label={resultFilter.name} checked={resultFilter.isChecked}
                                      onChange={() => updateResult(resultFilter,{isChecked: !resultFilter.isChecked})} /></Box>)}
                    </Box>
                    <Heading level={4} margin={{bottom:'small'}}>Produkty</Heading>
                    <Box direction={'row-responsive'} align={'center'} wrap>
                        {productsList.map((product, i)=>
                            <Box key={'checkbox-prod-'+product.id} pad={{right:'small', bottom:'xsmall'}}><CheckBox pad={'xsmall'} label={product.fullName} checked={product.isChecked}
                                      onChange={() => updateProduct(product,{isChecked: !product.isChecked})} /></Box>)}
                    </Box>
                </Box>
                <Box direction={'row-responsive'} round={'small'} background={'white'} align={'start'} alignContent={'between'}>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className="sticky-header">
                            <tr>
                                <th colSpan={4}></th>
                                {productsList.filter((i)=>i.isChecked).map((product, c)=><>
                                    <th key={'header_'+c} colSpan={resultsFilterList.filter((i)=>i.isChecked).length ? resultsFilterList.filter((i)=>i.isChecked).length : 1}>{product.fullName}</th>
                                </>)}
                            </tr>
                            <tr>
                                <th>Obdobie</th>
                                <th>Platba (€)</th>
                                <th>Zaplatené (€)</th>
                                <th>Štátna prémia (€)</th>
                                {productsList.filter((i)=>i.isChecked).map((product, c)=>
                                    resultsFilterList.filter((i)=>i.isChecked).map((filter)=>
                                        <th key={'header_'+filter.id+'_'+c}>{filter.shortName}</th>
                                    )
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {filteredResult.rows && filteredResult.rows.map((row, monthIndex)=>{
                                return <tr key={"result_"+monthIndex} className={'text-center'}>
                                    <td title={(monthIndex+1)+'. mesiac'}>{row.paymentDates}</td>
                                    <td>
                                        <div className="text-editable"
                                             onClick={() => openAddEditExtraPaymentModal(monthIndex+1, FormatService.round(row.payments))}
                                        >{FormatService.round(row.payments)}</div>
                                    </td>
                                    <td>{FormatService.round(row.payedList)}</td>
                                    <td>{FormatService.round(row.stateBonus)}</td>
                                    {filteredResult.productsData.map((product, c)=>
                                        resultsFilterList.filter((i)=>i.isChecked).map((filter)=>
                                            <td key={'row_'+monthIndex+'_'+filter.id+'_'+c}>{FormatService.round(product[filter.id][monthIndex])}</td>
                                        )
                                    )}
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>}

            {extraPaymentModal && (<Layer onEsc={() => setExtraPaymentModal(false)} onClickOutside={() => setExtraPaymentModal(false)}>
                <Box pad="medium">
                    <Form
                        value={extraPaymentForm}
                        onChange={nextValue => {
                            setExtraPaymentForm(nextValue);
                        }}
                        onSubmit={({ value }) => onExtraPaymentFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Mimoriadny vklad</Heading>

                        <FormField label="Výška vkladu (€)" width={'full'}>
                            <TextInput autoFocus name="payment" type="number" step="0.001" required={true} />
                        </FormField>

                        <FormField label="Mesiac (poradie)" width={'full'}>
                            <TextInput name="month" type="number" required={true} />
                        </FormField>

                        <Box direction={'row'} align={'center'}>
                            <FormField label="Pravideľnosť vkladu" width={'full'}>
                                <Select options={RegularityList}
                                        required={true}
                                        name="regularityObject"
                                        labelKey="name"
                                        valueKey="value"
                                        dropHeight="large"
                                        value={extraPaymentForm.regularityObject}
                                />
                            </FormField>
                        </Box>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setExtraPaymentModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

        </Box>
    );
}

export default SavingBuilding;