import {Box, Button, CheckBox, Form, FormField, Heading, RadioButtonGroup, Select, TextInput} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import SalaryHelp from "./SalaryHelp";
import SalaryPdfExport from "./SalaryPdfExport";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import FormatService from '../../services/FormatService';
import _ from "lodash";

function Salary(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'Salary';
    const calcStateVersion = 3;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Mzdová kalkulačka',
        mode: 'net',

        children: [],
        salary: 1000,
        salaryPeriod: "YEAR_2024",
        spouseWithoutIncome: false,

        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const periodsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['periods']) ? state.CalcDetail[calcStateKey]['periods'] : [];
    const periodsFilterInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['periodsFilter']) ? state.CalcDetail[calcStateKey]['periodsFilter'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [periodsList, setPeriodsList] = useState(periodsInitialState);
    const [filteredPeriodsList, setFilteredPeriodsList] = useState(periodsFilterInitialState);

    function saveCalcDetail(values, result, periodsList, filteredPeriodsList){
        const calcDetailState = {
            values:values,
            result:result,
            periodsList:periodsList,
            filteredPeriodsList:filteredPeriodsList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    function getChildrenYearOptions(){
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 25;
        const options = [];
        for(let i = currentYear; i >= lastYear; i-- ){
            options.push({value: i, label: i});
        }
        return options;
    }
    const childrenYearOptions = getChildrenYearOptions();

    const childrenMonthOptions = [...Array(12).keys()].map(key => {
        return {"value": key+1, "label": new Date(0, key).toLocaleString('sk', { month: 'long' })}
    });

    function child(month, year){
        return {
            month: month ? month : childrenMonthOptions[0],
            year: year ? year : childrenYearOptions[0].value,
        }
    }

    function addChild(){
        const children = [...values.children, child()]
        setValues({...values, children})
        //setValues(Object.assign({}, values, {children}));
    }

    function removeChild(index){
        const children  = values.children.filter((child, i) => i != index)

        const newValues = Object.assign({}, values, {children});
        setValues(newValues);
    }

    const reloadPeriodsList = useCallback(() => {
        ApiService.getSalaryPeriodsList()
            .then((res) => {
                    const r = res.periods.map((period)=>{
                        return {
                            id: period,
                            name: period.substr(5) //"YEAR_" part of YEAR_2020
                        }
                    });

                    setPeriodsList(r);
                    setFilteredPeriodsList(r);
                    saveCalcDetail(values, result, r, r);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reloadPeriodsList();
    }, [reloadPeriodsList]);

    function childToDTO(child){
        return {
            month: child.month.value,
            year: child.year
        }
    }

    function onFormSubmit(values){
        let reqData = {
            childrenData: values.children.map(child => childToDTO(child)),
            salary: values.salary,
            salaryPeriod: values.salaryPeriod,
            spouseWithoutIncome: values.spouseWithoutIncome,
        };

        setValues(Object.assign({}, values, {activeRequest: true}));
        ApiService.call('post', '/v2/salary/'+values.mode, reqData)
            .then((res) => {
                    setResult(res);
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    saveCalcDetail(values, res, periodsList, periodsList);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'mzdova-kalkulacka';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: SalaryPdfExport({values:values,result:result,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><SalaryHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        let myResult = result;
                        if (values.mode!==nextValue.mode){
                            setResult(null);
                            myResult = null;
                        }

                        const cleanState = {};
                        _.forIn(nextValue, (value, key) => {
                            _.set(cleanState, key, value);
                        });

                        setValues(cleanState);
                        saveCalcDetail(nextValue, myResult, periodsList, periodsList);
                    }}
                    onReset={() => {
                        let init = defaultValues;
                        init.mode = values.mode;
                        setValues(init);
                        setResult(null);
                        saveCalcDetail(init, null, periodsList, periodsList);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" options={[
                                {value: 'net',label: "Čistá mzda"},
                                {value: 'gross',label: "Hrubá mzda"},
                            ]} />
                        </Box>
                    </Box>

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label={values.mode==='net' ? 'Hrubá mzda (€)' : 'Čistá mzda (€)'} width={'full'}>
                                <TextInput name="salary" type="number" step="0.01" required={true}  />
                            </FormField>
                        </Box>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Obdobie" width={'full'}>
                                <Select options={filteredPeriodsList}
                                        required={true}
                                        name="salaryPeriod"
                                        labelKey="name"
                                        valueKey={{ key: 'id', reduce: true }}
                                        dropHeight="large"
                                        value={values.salaryPeriod}
                                        placeholder="Výber obdobia"
                                        onClose={() => setFilteredPeriodsList(periodsList)}
                                        onSearch={searchText => {
                                            const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                            const exp = new RegExp(escapedText, 'i');
                                            setFilteredPeriodsList( periodsList.filter(o => {return exp.test(o.name)}) );
                                        }}
                                        emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Bez príjmu" width={'full'}>
                                <CheckBox name="spouseWithoutIncome" label="Manžel/-ka bol/-a v minulom roku bez príjmu" />
                            </FormField>
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom:'none'}}>Nezaopatrené deti</Heading>

                    {values.children.length !== 0 && values.children.map((child, index) => {
                        return (
                            <Box direction={'row-responsive'} key={'child-'+index}>

                                <Box basis={'1/3'} direction={'row'} align={'center'}>
                                    <FormField label="Mesiac narodenia" width={'full'}>
                                        <Select options={childrenMonthOptions}
                                                required={true}
                                                name={`children[${index}][month]`}
                                                dropHeight="large"
                                                value={values.children[index].month}
                                                labelKey="label"
                                                valueKey={{key: "value"}}
                                        />
                                    </FormField>
                                </Box>
                                <Box basis={'1/3'} direction={'row'} align={'center'} justify={'center'}>
                                    <FormField label="Rok narodenia" width={'full'}>
                                        <Select options={childrenYearOptions}
                                                required={true}
                                                name={`children[${index}][year]`}
                                                dropHeight="large"
                                                labelKey="label"
                                                valueKey={{key: "value", reduce: true}}
                                                value={values.children[index].year}
                                        />
                                    </FormField>
                                    <Box direction={'row'} alignSelf={'end'}>
                                        <Button secondary pad={'medium'} margin={{bottom:'15px'}} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=> removeChild(index)} />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}

                    <Box direction={'row-responsive'} margin={{bottom:'medium'}}>
                        <Button type="button" secondary label="Pridať dieťa" icon={<Icons.AddCircle/>} onClick={()=> addChild()} />
                    </Box>


                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>


            {result!==null &&
            <Box direction={props.size==='small'? 'column' : 'row'} pad={'medium'} margin={{vertical: 'medium'}} border={true} round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                <div className="table-responsive table-unclipped">
                    <table className="table styled-table">
                        <thead>
                        <tr>
                            <td>&nbsp;</td>
                            <th colSpan="2">Zamestnanec</th>
                            <th colSpan="2">Zamestnávateľ</th>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <th>Sadzba</th>
                            <th>Hodnota</th>
                            <th>Sadzba</th>
                            <th>Hodnota</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Zdravotné poistenie</th>
                            <td className="text-right">4%</td>
                            <td className="text-right">{result.employeeHealthInsurance}€</td>
                            <td className="text-right">10%</td>
                            <td className="text-right">{result.employerHealthInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Nemocenské poistenie</th>
                            <td className="text-right">1,4%</td>
                            <td className="text-right">{result.employeeMedicare}€</td>
                            <td className="text-right">1,4%</td>
                            <td className="text-right">{result.employerMedicare}€</td>
                        </tr>
                        <tr>
                            <th>Starobné poistenie</th>
                            <td className="text-right">4%</td>
                            <td className="text-right">{result.employeeOldAgeInsurance}€</td>
                            <td className="text-right">14%</td>
                            <td className="text-right">{result.employerOldAgeInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Invalidné poistenie</th>
                            <td className="text-right">3%</td>
                            <td className="text-right">{result.employeeDisabilityInsurance}€</td>
                            <td className="text-right">3%</td>
                            <td className="text-right">{result.employerDisabilityInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Poistenie v nezamestnanosti</th>
                            <td className="text-right">1%</td>
                            <td className="text-right">{result.employeeUnemploymentInsurance}€</td>
                            <td className="text-right">1%</td>
                            <td className="text-right">{result.employerUnemploymentInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Garančný fond</th>
                            <td className="text-right">0%</td>
                            <td className="text-right">{result.employeeGuaranteeFund}€</td>
                            <td className="text-right">0,25%</td>
                            <td className="text-right">{result.employerGuaranteeFund}€</td>
                        </tr>
                        <tr>
                            <th>Rezervný fond</th>
                            <td className="text-right">0%</td>
                            <td className="text-right">{result.employeeReserveFund}€</td>
                            <td className="text-right">4,75%</td>
                            <td className="text-right">{result.employerReserveFund}€</td>
                        </tr>
                        <tr>
                            <th>Úrazové poistenie</th>
                            <td className="text-right">0%</td>
                            <td className="text-right">{result.employeeAccidentInsurance}€</td>
                            <td className="text-right">0,8%</td>
                            <td className="text-right">{result.employerAccidentInsurance}€</td>
                        </tr>
                        <tr>
                            <th>Odvody spolu</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{result.employeeTotal}€</td>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{result.employerTotal}€</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                            <td colSpan="5">&nbsp;</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                            <th>Čiastkový základ dane</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{FormatService.round(result.partialTaxBase).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Odpočítateľná položka</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{result.deductibleItem}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Základ dane</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{result.taxBase}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daň z príjmu</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{result.incomeTax}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daňový bonus na deti</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{FormatService.round(result.childrenBonus).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Daň z príjmu znížená o daňový bonus</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{FormatService.round(result.incomeTaxWithoutChildrenBonus).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        {result.incomeTaxWithoutChildrenBonus < 0 &&
                        <tr>
                            <th>{values.mode==='net' ? 'Čistý príjem' : 'Hrubý príjem'} bez daňového preplatku</th>
                            <td>&nbsp;</td>
                            <td className="text-right table-cell-highlighted">{FormatService.round(result.incomeWithoutTaxOverpayment).toFixed(2)}€</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>}
                        <tr>
                            <th>{values.mode==='net' ? 'Čistý príjem' : 'Hrubý príjem'}</th>
                            <td>&nbsp;</td>
                            <th className="text-right table-cell-important">{FormatService.round(result.income).toFixed(2)}€</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                            <td colSpan="5">&nbsp;</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                            <th>Mzdové náklady</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <th className="text-right table-cell-important">{FormatService.round(result.costs).toFixed(2)}€</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
            }

        </Box>
    );
}

export default Salary;