
const ThemeService = {
    initialTheme: function(){
        return {
            contrastColor: "#c2425a",
            contrastColorAlt: "#a6384d",
            logo: "/assets/img/logo-umbrella-dark.svg",
            logoDark: "/assets/img/logo-umbrella.svg",
            logoLoginUGCalculator: "/assets/img/logo-umbrella.svg",
            pool: "UG",
            poolGroup: "1",
            primaryColor: "#74BC64",
            colorLoginUGCalculator: "#74BC64",
            primaryColorAlt: "#489439",
            sidebarLogoBackground: "#545454",
            dashboardImageHueRotate: "0deg",
        };
    },
    clearTheme: function(){
        localStorage.removeItem('ugc_theme');
        window.location = window.location.assign(window.location);
    },
    getTheme: function(){
        const theme = localStorage.getItem('ugc_theme');
        if (theme){
            const th = JSON.parse(theme);

            //toto bol docany fix, aby sa obnovila tema.
            if (!th.sidebarLogoBackground){
                this.setTheme(ThemeService.initialTheme());
                return this.initialTheme();
            }

            return JSON.parse(theme);
        }
        return ThemeService.initialTheme();
    },
    setTheme: function(theme){
        if (theme){
            if (theme.pool==='UG'){
                theme = this.initialTheme();
            }
            localStorage.setItem('ugc_theme', JSON.stringify(theme));
            return true;
        }
        localStorage.removeItem('ugc_theme');
    },
    doGetSettings: function(pool='UG', group=1){
        const qParams = [
            `pool=${pool}`,
            `group=${group}`,
        ].join("&");
        return fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/calculatorTheme?${qParams}`)
            .then(res => res.json())
            .then(json => {
                ThemeService.handleSettingsResponse(json);
            })
            .catch((e)=>{
                console.error(e);
                ThemeService.clearTheme();
            });
    },
    handleSettingsResponse: function(json){
        if (window.debug){
            console.log('profile settings for pool', json);
        }
        if (json && json.pool){
            ThemeService.setTheme(json);
        }
    },
    hexToRgb: function(hex) {
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    },
    cssHexToRgb: function(hex) {
        if (!hex){
            return null;
        }
        let res = this.hexToRgb(hex);
        return res ? res.r+', '+res.g+', '+res.b : null;
    },
    getCssVariables: function(){
        return {
            '--color-primary': this.getTheme().primaryColor,
            '--color-primary-rgb': this.cssHexToRgb(this.getTheme().primaryColor),
            '--color-border':'#DADADA',
            '--color-border-rgb': this.cssHexToRgb('#DADADA'),
            '--color-dark': '#4F5E6A',
            '--color-dark-rgb': this.cssHexToRgb('#4F5E6A'),
            '--color-sidebar-logo-bcg': this.cssHexToRgb(this.getTheme().sidebarLogoBackground),
            '--dashboard-hue-rotate': this.getTheme().dashboardImageHueRotate,
            // '--dashboard-hue-rotate': "200deg",
        };
    }
};

export default ThemeService;