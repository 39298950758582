import {Box, Button, Form, Heading, Layer, Select, Text} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ErrorService from "../../services/ErrorService";
import SavedStatesService from "../../services/SavedStatesService";
import {useHistory} from "react-router-dom";

function LoadSavedStatesModal(props) {

    const defaultValues = {
        activeRequest: false
    };
    const history = useHistory();
    const [savedStates, setSavedStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);
    const [values, setValues] = React.useState(defaultValues);

    const reloadSavedStates =  useCallback(() => {
        SavedStatesService
            .getList()
            .then((res) => {
                    const list = res
                        .filter((item)=>{
                            if (!props.pinkey){
                                return true;
                            }
                            return item.calc_key===props.pinkey
                        })
                        .map((item)=>{
                            item.calc = window.calcMap[item.calc_key];
                            return item;
                        });
                    setSavedStates(list);
                },
                (error) => {
                    ErrorService.fromApi(error);
                });
    }, [props.pinkey]);

    useEffect(()=>{
        if (props.show===true){
            setValues(defaultValues);
            reloadSavedStates();
        }
    }, [props, reloadSavedStates])

    useEffect(() => {
        setFilteredStates([...savedStates]);
    }, [savedStates]);
    
    function closeModal(res=null){
        props.closeaction(res);
    }

    function onFormSubmit(values){
        if (values.savedState){
            const savedState = filteredStates.filter((f)=>f.id===values.savedState).pop();
            if (savedState && savedState.calc_key && savedState.id){
                const path = window.calcMap[savedState.calc_key].path+'/'+savedState.id;
                history.push(path);
            }
        }
    }

    return (
        <Box>
            {props.children}

            {props.show===true && (
                <Layer
                    onEsc={()=>closeModal()}
                    onClickOutside={()=>closeModal()}
                >
                    <Box pad={'large'} round={'small'} border={true} background={'white'} align={'center'} >
                        <Heading margin={{bottom:'xsmall'}}>Načítanie modelovania</Heading>
                        <Text color={'dark-5'} textAlign={'center'}>Po načítaní sa prestavia hodnoty<br />podľa uloženého stavu.</Text>
                        <Form
                            value={values}
                            onChange={nextValue => {
                                setValues(nextValue);
                            }}
                            onSubmit={({ value }) => onFormSubmit(value) }
                        >
                           <Box direction={'row-responsive'}>
                                <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                                    <Select name="savedState"
                                            labelKey="title"
                                            valueKey={{ key: 'id', reduce: true }}
                                            options={filteredStates.map(item=>{return {id: item.id, title: item.contents.title, calc_key: item.calc_key}})}
                                            onClose={() => setFilteredStates(savedStates)}
                                            onSearch={(text) => {
                                                // The line below escapes regular expression special characters:
                                                // [ \ ^ $ . | ? * + ( )
                                                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                                // Create the regular expression with modified value which
                                                // handles escaping special characters. Without escaping special
                                                // characters, errors will appear in the console
                                                const exp = new RegExp(escapedText, 'i');
                                                setFilteredStates(savedStates.filter((o) => exp.test(o.contents.title)));
                                            }}
                                    />
                                </Box>
                            </Box>

                            <Box direction="row" gap="medium" margin={{top:'medium'}}>
                                <Button type="submit" primary label="Načítať" disabled={values.activeRequest} />
                                <Button label="Zatvoriť" onClick={() => closeModal()} />
                            </Box>
                        </Form>
                    </Box>

                </Layer>
            )}
        </Box>
    );
}

export default LoadSavedStatesModal;
