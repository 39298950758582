import {Box} from 'grommet';
import React from "react";

function LoanPlusInvestHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie výhodnosti úveru s kratšou splatnosťou (Pôvodný zámer klienta) a kombinácie úveru s
            čo možno najdlhšou splatnosťou s investovaním (Kombinovaný model). Výška investície do investičného programu sa vypočíta ako
            rozdiel splátok medzi úvermi s rôznou splatnosťou</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru pre Pôvodný zámer klienta – výšku, splatnosť a úrokovú sadzbu.</li>
            <li>Zvoľte parametre úveru pre Kombinovaný model – výšku, splatnosť a úrokovú sadzbu</li>
            <li>Zvoľte investičný nástroj</li>
            <li>Zadajte výnos p.a. v %-tách</li>
            <li>Výška investície sa vypočíta kliknutím na tlačidlo "Navrhni výšku investície", ak máte záujem, môžete ju zmeniť</li>
            <li>Kliknite na <b>Vypočítaj</b></li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky pozostáva zo štyroch nasledovných častí:</p>
        <ul>
            <li>Tabuľka zobrazuje hodnoty splátky a zostatku úveru podľa pôvodného zámeru klienta, splátky a zostatku
                úveru pri kombinovanom úvere s dlhšou splatnosťou a výšku investície, jej
                hodnotu a výslednú bilanciu</li>
            <li>Hodnoty splátky a celkové náklady úveru zobrazujú pôvodný zámer klienta.</li>
            <li>Hodnoty pre kombinovaný úver zobrazujú splátku, celkové náklady a bilanciu na účte v mesiaci, v ktorom
                by nastalo splatenie úveru podľa pôvodného zámeru klienta. Ak je bilancia kladná, na účte je dostatok prostriedkov na vyplatenie úveru a naopak.</li>
            <li>Hodnoty v zhrnutí zobrazujú bod zlomu (mesiac, kedy vieme z investície vyplatiť celý úver)
                a celkové náklady v bode zlomu pre kombinovaný model.</li>
        </ul>
        <br /><br />
        <p><b>Poznámka: </b>"Investorom" sa rozumie forma sporenia bez poplatkov</p>
        <p><b>Poznámka: </b>ak nie je uvedené inak, výpočet nezohľadňuje daň!</p>
    </Box>);
}

export default LoanPlusInvestHelp;
