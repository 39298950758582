import {Box} from 'grommet';
import React from "react";

function SavingBuildingHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na modelovanie stavebného sporenia oddnes do budúcnosti.</p>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Nastavte platbu a jej pravidelnosť (slúži aj na výpočet CS).</li>
            <li>Zvoľte dobu, kedy chcete dosporiť (slúži aj na výpočet CS).</li>
            <li>Zvoľte CS (ak ju nezvolíte, bude dopočítaná automaticky).</li>
            <li>Kliknite na <b>Vypočítaj</b></li>
            <li>Zvoľte jednotlivé produkty.</li>
        </ol>

        <h2>Výstup</h2>
        <ul>
            <li><b>Hodnota účtu</b> - výpočet hodnoty úćtu, ktorý zohľadňuje vklady, výnos, prémiu, vedenie účtu a daň,</li>
            <li><b>Odkupná hodnota</b> - hodnota účtu stavebného sporenia, ktorá zohľadňuje vstupný poplatok, odkupný poplatok a prípadné krátenie prémie,</li>
            <li><b>Výnos pravidelnej investície</b> - priemerný čistý ročný výnos v percentách počítaný z hodnoty účtu/odkupnej hodnoty</li>
        </ul>
        <br /><br />
        <p><b>Poznámka: </b>výpočet zohľadňuje daň!</p>
    </Box>);
}

export default SavingBuildingHelp;
