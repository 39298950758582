import IntranetApiService from "./IntranetApiService";

const SavedPdfExportTemplatesService = {
    getList: function(){
        return IntranetApiService.call('get', '/ugcalculator/getPdfExportTemplatesList');
    },
    delSavedTemplate: function(id){
        return IntranetApiService.call('delete', '/ugcalculator/delPdfExportTemplate/id/'+id);
    },
    addEditSavedTemplate: function(key, title, state, id){
        let req ={
            key: key,
            title: title,
            contents: state,
        };
        if (id){
            req['id'] = id;
        }
        return IntranetApiService.call('post', '/ugcalculator/addEditPdfExportTemplate', req);
    }
};

export default SavedPdfExportTemplatesService;