import ErrorService from "../../services/ErrorService"

class SavingIzpPdfExport{


    static BASE_ROW_OPTION_ALL = "1"
    static BASE_ROW_OPTION_CUSTOM = "2"

    static CUSTOM_ROW_OPTION_FIRST_LAST_ROW = "1"
    static CUSTOM_ROW_OPTION_MIMORIADNE_VKLADY = "2"
    static CUSTOM_ROW_OPTION_VYROCIA = "3"
    static CUSTOM_ROW_OPTION_CUSTOM = "4"

    static generatePdf({values,result, activeProductsList, extraPayments, portfolioChart, chart, options }){

        function getPageNumbersFromString(string){
            const pagesString = string.split(",")
            let pages_numbers = new Set();
            pagesString.forEach(page => {
                if(page.includes("-")){
                    let [start, end] = page.split("-")
                    if(isNaN(start) || isNaN(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not numbers")
                    start = Number(start)
                    end = Number(end)
                    if(!Number.isInteger(start) || !Number.isInteger(end)) ErrorService.fromApp("getPageNumbersFromString start/end are not integers")
                    if(start > end) ErrorService.fromApp("getPageNumbersFromString start is greater than end")
                    for(let i = start; i <= end; i++){
                        pages_numbers.add(i)
                    }
                }else{
                    const page_num = Number(page)
                if(!Number.isInteger(page_num)) ErrorService.fromApp("getPageNumbersFromString page is not integer")
                pages_numbers.add(page_num)
                }
            })
            return pages_numbers;
        }

        let inputs = [
            {key: 'payment', label: 'Platba (€)', value: values.payment },
            {key: 'periodInYears', label: 'Doba investovania (r)', value: values.periodInYears},
            {key: 'strategy', label: 'Investičná stratégia', value: values.strategyObject.name},
            {key: 'earnings', label: '- výnos (%)', value: values.strategyObject.id===4 ? values.earnings : values.strategyObject.earnings},
            {key: 'sigma', label: '- volatilita (%)', value: values.strategyObject.id===4 ? values.sigma : values.strategyObject.sigma},
        ];
        if (values.stabilizationPhase){
            inputs.push({key: 'yearsOfStabilizationPhase', label: 'Dĺžka stabilizačnej fázy (r)', value: values.yearsOfStabilizationPhase});
            inputs.push({key: 'earningsStabilizationPhase', label: '- výnos (%)', value: values.earningsStabilizationPhase});
            inputs.push({key: 'sigmaStabilizationPhase', label: ' - volatilita (%)', value: values.sigmaStabilizationPhase});
        }

        const pages_numbers = options.row_custom_option_row_num ? getPageNumbersFromString(options.row_custom_option_row_num) : null;

        return (`
        <div class="row-flex mb3">
            <div class="col-12">
                <p>Kalkulačka slúži na porovnanie výhodnosti jednotlivých sporiacich programov a IŽP. Porovnávanie pozostáva 
                výhradne z porovnania poplatkových štruktúr pri zvolenom investičnom horizonte, mesačnej platbe a 
                investičnej stratégii, a teda nezohľadňuje krytie, plnenie, servis, atď.</p>
            </div>
        </div> 
        <div class="row-flex mb3">
            <div class="col-12">
                <table>
                    ${inputs.map((i) => {
                        return `<tr>
                                 <th>${i.label}</th>
                                 <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                                </tr>`;
                    }).join('')}
                </table>
            </div>
        </div> ` +
    
        (extraPayments && extraPayments.length ? `<div class="row-flex mb3">
            <div class="col-12">
                <h3 style="margin-bottom:10px">Mimoriadne vklady</h3>
                <div class="row">
                     ${extraPayments.map((f) => {
                        return `<div class="col-sm-3">
                                    <div class="card">
                                         <div class="card-body" style="padding:10px;">
                                              <div class="row">
                                                  <div class="col-sm-6"><span class="icon-calendar"></span> Mesiac:</div>
                                                  <div class="col-sm-6"><b>${f.month}</b>.</div>
                                              </div>
                                              <div class="row">
                                                   <div class="col-sm-6"><span class="icon-wallet"></span> Vklad:</div>
                                                   <div class="col-sm-6"><b>${f.payment}</b> €</div>
                                              </div>
                                         </div>
                                    </div>
                               </div>`;
                    }).join('')}   
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        ` : ``
        ) +
        
        `<div class="row-flex mb3">
            <div class="col-12 text-center">
                ${portfolioChart ? portfolioChart.getSVG() : ''}
            </div>
        </div>
        
        <div class="row-flex mb3">
            <div class="col-12 text-center">
                ${chart ? chart.getSVG() : ''}
            </div>
        </div>
       
        <div class="row-flex mb3">
            <div class="col-12">
                <table class="table styled-table text-center">
                     <thead>
                     <tr>
                          <th>Obdobie (m)</th>
                          <th>Mim. vklad (€)</th>
                          <th>Zaplatené (€)</th>
                          ${result.results.map((product)=>`<th>${product.product.name}</th>`).join('')}
                     </tr>
                     </thead>
                     <tbody>
                     <tr>
                         <th colspan="3">Reálny výnos p.a. (%)</th>
                         ${result.results.map((product)=>`<td class="table-cell-important">${product.realProfit}</td>`).join('')}     
                     </tr>
                         ${result.paymentsTotalForMonth.filter((row,r)=>r===result.paymentsTotalForMonth.length-1).map((row)=>{
                            const r = result.paymentsTotalForMonth.length-1; //last row
                            return  `<tr>
                                      <th>Hodnota na konci ${r+1}.</th>
                                      <td>${result.extraPayments[r]}</td>
                                      <td>${result.paymentsTotalForMonth[r]}</td>
                                      ${result.results.map((product)=>`<td class="table-cell-highlighted">${product.monthResults[r]}</td>`).join('')}
                                    </tr>`
                         }).join('')}
                         ${result.paymentsTotalForMonth.map((row, index)=>{
                             if(options.row_option === SavingIzpPdfExport.BASE_ROW_OPTION_CUSTOM){
                                if(
                                !(
                                    (options.row_custom_options.includes(SavingIzpPdfExport.CUSTOM_ROW_OPTION_FIRST_LAST_ROW) && (index === 0 || index === result.paymentsTotalForMonth.length-1)) ||
                                    (options.row_custom_options.includes(SavingIzpPdfExport.CUSTOM_ROW_OPTION_MIMORIADNE_VKLADY) && result.extraPayments[index]) ||
                                    (options.row_custom_options.includes(SavingIzpPdfExport.CUSTOM_ROW_OPTION_VYROCIA) && (index === 0 || (index + 1) % 12 === 0)) ||
                                    (options.row_custom_options.includes(SavingIzpPdfExport.CUSTOM_ROW_OPTION_CUSTOM) && pages_numbers.has(index+1))
                                )                    
                                ){
                                    return "";
                                }
                             }
                             return  `<tr>
                                 <th>${index+1}.</th>
                                 <td>${result.extraPayments[index]}</td>
                                 <td>${result.paymentsTotalForMonth[index]}</td>
                                 ${result.results.map((product)=>`<td>${product.monthResults[index]}</td>`).join('')}
                             </tr>`
                         }).join('')}
                     </tbody>
                </table>
            </div>
        </div>
        `);
    }



}






export default SavingIzpPdfExport;