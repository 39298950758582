import {Box, Button, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ErrorService from "../../services/ErrorService";
import SavedStatesService from "../../services/SavedStatesService";
import * as Icons from "grommet-icons";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";

function SavedStates(props) {

    const [savedStates, setSavedStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);
    const [search, setSearch] = useState('');

    const reloadSavedStates =  useCallback(() => {
        SavedStatesService
            .getList()
            .then((res) => {
                    const list = res.map((item)=>{
                        item.calc = window.calcMap[item.calc_key];
                        return item;
                    });
                    setSavedStates(list);
                },
                (error) => {
                    ErrorService.fromApi(error);
                });
    }, []);

    useEffect(() => {
        reloadSavedStates();
    }, [reloadSavedStates]);

    function onRemoveState(id){
        if (true===window.confirm('Chcete vymazať tento záznam? Akcia sa nedá vrátiť späť.')){
            SavedStatesService.delSavedState(id)
                .then((res) => {
                        setSavedStates(savedStates.filter((item)=>{
                            return item.id !== id;
                        }));
                    },
                    (error) => {
                        ErrorService.fromApi(error);
                    });
        }
    }

    useEffect(()=>{
        if (!search){
            setFilteredStates([...savedStates]);
            return true;
        }
        setFilteredStates(savedStates.filter((item)=>{
            return (
                item.contents.title.toLowerCase().includes(search.toLowerCase())
                || item.calc.title.toLowerCase().includes(search.toLowerCase())
            );
        }));
    }, [search, savedStates]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>Uložené modelovania</Heading>
            </Box>

            <Box pad={'small'} direction={'column'} >
                {savedStates && savedStates.length>0 &&
                    <Box direction={'column'} fill={true}>
                        <Box direction={'row'}>
                            <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}} direction={'row'} pad={'xsmall'}>
                                <TextInput placeholder="Hľadať" value={search} name="search" onChange={event => setSearch(event.target.value)} />
                                {search && <Button secondary icon={<Icons.FormClose size={'16px'} color={'red'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} hoverIndicator onClick={()=>setSearch('')} />}
                            </Box>
                        </Box>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell border="bottom">Názov</TableCell>
                                    <TableCell border="bottom">Typ</TableCell>
                                    <TableCell border="bottom">Upravené</TableCell>
                                    <TableCell border="bottom" align={'center'}><Icons.Services size={'16px'} /></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredStates.map((item, index)=>{
                                    let last = (index === filteredStates.length-1);

                                    return <TableRow key={item.id} style={{'background':'brand'}}>
                                        <TableCell border={last?'bottom':''}>{item.contents.title}</TableCell>
                                        <TableCell border={last?'bottom':''}>{item.calc?.title}</TableCell>
                                        <TableCell border={last?'bottom':''}>{DateTime.fromISO(item.updated_at.replace(' ', 'T')).toLocaleString(DateTime.DATETIME_SHORT)}</TableCell>
                                        <TableCell border={last?'bottom':''}>
                                            <Box justify={'center'} direction={'row-responsive'} gap={'xsmall'}>
                                                <Link to={item.calc?.path+'/'+item.id} style={{'color':'#fff','opacity':0.8,'textDecoration':'none'}}>
                                                    <Button secondary icon={<Icons.Catalog color={'brand'} size={'16px'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} />
                                                </Link>
                                                <Button secondary icon={<Icons.Trash size={'16px'} color={'red'} />} style={{'padding':'6px 10px', 'lineHeight':'6px'}} onClick={()=>{onRemoveState(item.id)}} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                }
                {(!savedStates || !savedStates.length) &&
                    <Box direction={'row'}>
                        <Text color={'gray'}>Zatiaľ ste nepridali žiadne modelovanie. Uložiť ho môžete priamo z detailu kalkulačky.</Text>
                    </Box>
                }
            </Box>

        </Box>
    );
}

export default SavedStates;
