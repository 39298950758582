import {Box} from 'grommet';
import React from "react";

function LoanInsuranceHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na nastavenie poistných súm krytia smrti tak, aby v žiadnom okamihu nedošlo k nižšiemu
            plneniu z poistenia ako je zostatok úveru. Na základe zvolenej výšky krytia pre poistenie s klesajúcou PS
            sa dopočíta poistná suma pre poistenie s konštantnou PS.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru – výšku, splatnosť a úrokovú sadzbu.</li>
            <li>Výška poistnej sumy pre poistenie s klesajúcou PS sa nastaví na výšku úveru.</li>
            <li>Môžete nastaviť akceptovateľné podpoistenie.</li>
            <li>Kliknite na <b>Vypočítaj</b></li>
        </ol>

        <h2>Výstup</h2>
        <p>Výstup kalkulačky pozostáva z troch nasledovných častí:</p>
        <ul>
            <li>Hodnota Konštantná PS je výška poistnej sumy pre poistenie smrti s konštantnou PS tak, aby nedošlo v
                žiadnom momente k neakceptovateľnému podpoisteniu.</li>
            <li>Tabuľka zobrazuje hodnotu zostatku úveru, poistnej sumy pre poistenie smrti s klesajúcou
                PS a podpoistenie a poistnú sumu kombinácie.</li>
            <li>Graf zobrazujúci pokles zostatku úveru a výšku poistnej sumy kombinácie poistení.</li>
        </ul>
        <br /><br />
    </Box>);
}

export default LoanInsuranceHelp;
