import AuthService from "./AuthService";

const defaultHeaders = {
    'Content-Type': 'application/json'
};

const ApiService = {
    call: async function(method='get', endpoint, data=null, requestHeaders) {
        const accessToken = AuthService.getAccessToken();
        const authHeaders = !accessToken ? {} : {'Authorization': 'Bearer '+accessToken};
        const allHeaders = Object.assign({}, defaultHeaders, authHeaders, requestHeaders);
        if (window.debug){
            console.log(method, endpoint, data, allHeaders);
        }

        if (accessToken && AuthService.checkAccessTokenExpired() && AuthService.checkRefreshTokenExpired()){
            AuthService.doLogout();
        }

        let qParams = data && ['GET'].includes(method.toUpperCase()) ? '?' + Object.entries(data).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&') : '';
        let url = process.env.REACT_APP_CALC_API_URL + endpoint + qParams;

        return fetch( url, {
            method: method,
            headers: allHeaders,
            body: data && ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase()) ? JSON.stringify(data) : null
        })
            .then(res => {
                if (res.ok){
                    return res.json();
                } else {
                    if (res.status===401){
                        AuthService.handleUnauthorizedResponse(res);
                        return Promise.reject(ApiService.consts().errors.unauthorized);
                    }
                    return res.json().then(res => {throw res})
                }
            });
    },
    consts: function(){
        return {
            spm: {
                none: 'NONE',
                spm2017: 'SPM_2017',
                spm2018: 'SPM_2018',
                spm2024: 'SPM_2024',
            },
            investor: {
                loan: 'LOAN',
                savings: 'SAVINGS',
            },
            errors: {
                unauthorized: 'UNAUTHORIZED',
            }
        }
    },
    getLoanBanksList: async function(spm){
        return ApiService.call('get', '/v2/banks', {spmParam: spm ? spm : ApiService.consts().spm.none});
    },
    getBankFee: async function(bankId, loanAmount, spmType=ApiService.consts().spm.none, spmAssuranceAmount=0){
        return ApiService.call('post', '/v2/banks/fee', {
            id: bankId,
            loanAmount: loanAmount*1,
            spm: {
                assuranceAmount: spmAssuranceAmount,
                spmType: spmType
            }
        });
    },
    getSalaryPeriodsList: async function(){
        return ApiService.call('get', '/v2/salary/periods')
    },
    getSZCOPeriodsList: async function(){
        return ApiService.call('get', '/v2//szco/periods')
    },
    getInvestorsList: async function(investorType){
        return ApiService.call('get', '/v2/loan/investors', {investorType: investorType})
    },
    getBuidlingProductsList: async function(){
        return ApiService.call('get', '/v2/savings/building/products')
    },
};


export default ApiService;