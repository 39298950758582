import AuthService from "./AuthService";

const defaultHeaders = {
    'Content-Type': 'application/json'
};

const IntranetApiService = {
    call: async function(method='get', endpoint, data=null, requestHeaders) {
        const accessToken = AuthService.getAccessToken();
        const authHeaders = !accessToken ? {} : {'Authorization': 'Bearer '+accessToken};
        const allHeaders = Object.assign({}, defaultHeaders, authHeaders, requestHeaders);
        if (window.debug){
            console.log(method, endpoint, data, allHeaders);
        }

        if (accessToken && AuthService.checkAccessTokenExpired() && AuthService.checkRefreshTokenExpired()){
            AuthService.doLogout();
        }

        let qParams = data && ['GET'].includes(method.toUpperCase()) ? '?' + Object.entries(data).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&') : '';
        let url = process.env.REACT_APP_INTRANET_API_URL + endpoint + qParams;

        return fetch( url, {
            method: method,
            headers: allHeaders,
            body: data && ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase()) ? JSON.stringify(data) : null
        })
            .then(res => {
                if (res.ok){
                    return res.json();
                } else {
                    if (res.status===401){
                        AuthService.handleUnauthorizedResponse(res);
                        return Promise.reject(IntranetApiService.consts().errors.unauthorized);
                    }
                    return res.json().then(res => {throw res})
                }
            });
    },
    consts: function(){
        return {
            errors: {
                unauthorized: 'UNAUTHORIZED',
            }
        }
    },

    getDashboard: async function(){
        return IntranetApiService.call('get', '/ugcalculator/dashboard')
    },

    setLastUsedCalculator: async function(calculatorKey, title, path){
        return IntranetApiService.call('post', '/ugcalculator/setLastUsedCalculator', {key: calculatorKey, title:title, path:path})
    },

    setCalculatorLoaded: async function(calculatorKey, title, path){
        return IntranetApiService.call('post', '/ugcalculator/setCalculatorLoaded', {key: calculatorKey, title:title, path:path})
    },

    pinCalculator: async function(calculatorKey){
        return IntranetApiService.call('post', '/ugcalculator/pinCalculator', {key: calculatorKey})
    },

    unpinCalculator: async function(calculatorKey){
        return IntranetApiService.call('post', '/ugcalculator/unpinCalculator', {key: calculatorKey})
    },
};


export default IntranetApiService;