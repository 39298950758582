import {Box, Image} from 'grommet';
import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import AuthService from "../../services/AuthService";
import {useDispatch, useSelector} from "react-redux";
import ThemeService from "../../services/ThemeService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";

function LoginCallback(props) {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const state = useSelector((state) => state);

    useEffect(() => {
        if (process.env.REACT_APP_DEVMOCK==='true'){
            setTimeout(function(){
                AuthService.setProfile({
                    email: "tester@umbrellagroup.sk",
                    label: "Test Tester",
                    mobile_number: "123456789",
                    name: "Test",
                    pool: ThemeService.getTheme(),
                    surname: "Tester",
                    user_id: "666"
                });
                AuthService.handleTokenResponse({
                    access_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiOWY5YmEzMDMwOTU0ZjhhOWFhYzg3OTY5OWFkMmM3MmYyOGUwNGRkM2NmNDljMGFiOThlNzg2MzJiZmZkYTlmMjgxNDM0YTQ0MDNhZmM5NTYiLCJpYXQiOjE2MTM0Nzc1NDgsIm5iZiI6MTYxMzQ3NzU0OCwiZXhwIjoxNjEzNDgxMTQ3LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.n7PbcwKneqDQ8tvFMSPLuFCQ0Fs0F5VpqghyUSh_aaBiveBX0sYOQbgajwSvJoytuk82WrI9rEyyE-ZpHkNgvmBLQ1-dlJ39VhjRguklvkN0R0HbZZ1KzEROagpH7qOcQkqsAmGHD-ciuPJJru5K2vhFgc3DXgmv_PZsPKRxZ05egihaj_uwgDZPy1EWCwLWi9LhVaVS5u3ryOZJEJe0JugU3vXDJJwZyHbpE34i5R_WjmDc7CHxBztkLnauOvnt5JyUqYYzftgdnTgyq7cyHgZzMo2mRih2hbZ-bRPiKUsboy3erE_gsuJ_fcI8SRGIZO645tdoZu8P8PpiIopU_A",
                    expires_in: 3600,
                    refresh_token: "def50200ddafa002ffa03e1bb5ff97978c61ac530d5d5d4e371035f1345dcc0b2133489d398567dcf936e60e1c2df178fc551ae1ca309d61f3f39eb5c88946d19c77ebd9a799babad0753d78fab4283a30e9185cd38f610c1c1f0ef026894b966b646ce637df152e520552b25eebb7d9b8f4ae49b29cd006547d75fd03bd3e04702891736ae3f2b937c2c9a9970e34e28c58b369726b707d9214d53c941606dc6095a06374560e6a4ca0511adda9e9f88e11f42517119c5753fcec49a57f15cd6c6d1e577a9b3412cf84804082bc475efee3904d3256813cef2e1a262e0f05f737cf2d7576a37727ec16207f8fffb1f47270c0a7174aff0daaebd2ae6b278d3b3b9c6060df7b9f7257fd0244726a5997cab1845e4d1ef70a022712575982ded1a8db1013a7e6e354e833d105c6c150e433516bd734e6fd3d943fa1373f3dc66bf0a8889e87ffc4d494930aed7657287eab0295abdf283d24033aa6009fb721b9d49547",
                    token_type: "Bearer"
                });
                dispatch({type:'auth/login'});
                history.push('/');
            }, 1000);
            return true;
        }

        const code_challenge = localStorage.getItem('ugc_cv');
        const code = (location.search.match(/code=([^&]+)/) || [])[1];
        const state = (location.search.match(/state=([^&]+)/) || [])[1];
        const qParams = [
            `grant_type=authorization_code`,
            `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
            `code=${code}`,
            `state=${state}`,
            `redirect_uri=${AuthService.getRedirectUrl()}`,
            `code_verifier=${code_challenge}`,
        ].join("&");

        if (!code || !code_challenge){
            alert('Nemám povinný parameter a neviem pokračovať.');
            history.push('/');
            return false;
        }

        if (AuthService.getAccessToken() && AuthService.getProfile()){
            return true;
        }

        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/oauth/token?${qParams}`)
            .then(res => res.json())
            .then(res => {
                //login user
                AuthService.handleTokenResponse(res);

                //get user profile data
                AuthService.doGetProfile().then(()=>{
                    const profile = AuthService.getProfile();

                    //get user theme data based on his profile
                    const pool = profile && profile.pool && profile.pool.code ? profile.pool.code : 'UG';
                    ThemeService.doGetSettings(pool).then(()=>{
                        dispatch({type:'theme/change'});
                        //we postpone auth callback so that theme has chance to propagate.
                        //this triggers redraw of routing and forces us to homepage right after redraw.
                        dispatch({type:'auth/login'});
                    });
                });

            })
            .catch((res) => {
                history.push('/');
                console.error('login callback failed', res);
            });
    }, [location, history, dispatch]);

    return (
        <Box fill={true} direction='column' alignContent='center' align='center' justify='center' background={'light-1'}>
            <Box width='large' align='center' pad='medium' animation='fadeIn' background={'white'} border={true}>
                <Image width='180px' src={state.Theme.logo} margin='medium' />
                <p>Prosím počkajte...</p>
                <UgSpinner />
            </Box>
        </Box>
    );
}

export default LoginCallback;
