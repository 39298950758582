

function TurnOverCompanyProfitPdfExport({values, result, translateBankError}){

	function getInputsToRender(){
		const inputsToRender = []
		inputsToRender.push({key: 'income', label: 'Mesačný príjem (€)', value: values.income})
		inputsToRender.push({key: 'companyShare', label: 'Percento podielu na firme (%)', value: values.companyShare})
		inputsToRender.push({key: 'ownershipPeriod', label: 'Doba vlastníctva firmy (mesiace)', value: values.ownershipPeriod})
		if(values.ownershipPeriod < 24){
			inputsToRender.push({key: 'previousPeriod', label: 'Počet mesiacov v predchádzajúcom období', value: values.previousPeriod.label})
		}

		return inputsToRender;
	}	

	const inputs = getInputsToRender();

	return(`
	
		<div class="row-flex mb3">
			<div class="col-12">
				<p></p>
			</div>
		</div>

		<div class="row-flex mb3">
			<div class="col-12">
				<table>
					${inputs.map((i) => {
						return `<tr>
								<th>${i.label}</th>
								<td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
								</tr>`;
					}).join('')}
				</table>
			</div>
		</div>

		<div class="row-flex mb3">
			<div class="col-12">
			<table class="table styled-table">
				<thead class="sticky-header">
					<tr class="text-center">
						<th>Banka</th>
						<th>Obrat</th>
						<th>Zisk</th>
						<th>Dividenda</th>
					</tr>
				</thead>
				<tbody>
				${result.banks.map(bank => {
						if(bank.type === "error"){
							return `
								<tr>
									<td class="text-sm-center">${bank.bank_data.pdfName}</td>
									<td></td>
									<td class="text-sm-center">${translateBankError(bank.error)}</td>
									<td></td>
								</tr>
							`
						}
						return `
							<tr >
								<td class="text-sm-center">${bank.bank_data.pdfName}</td>
								<td class="text-sm-center">${bank.turnover || "Neakceptujú"}</td>
								<td class="text-sm-center">${bank.profit || "Neakceptujú"}</td>
								<td class="text-sm-center">${bank.dividend || "Neakceptujú"} ${bank.note || ""}</td>
							</tr>
						`
					}).join('')}
				</tbody>
			</table>
			</div>
		</div>
	
	
	`)
}

export default TurnOverCompanyProfitPdfExport;