import React from 'react';
import {Box} from 'grommet';
import {useSelector} from "react-redux";
import {ResponsiveContext} from "grommet/es6";
import ThemeService from "../services/ThemeService";
import {ToastContainer} from "react-toastify";

function SideLogoLayout({mainComponent}) {
    const state = useSelector((state) => state);
    const size = React.useContext(ResponsiveContext);

    return (
        <Box fill={true} direction={size==='small'?'column':'row'} style={ThemeService.getCssVariables()}>
            <Box justify={'center'} align={'center'} width={size==='small'?'100%':'medium'} background={'loginBrand'} animation='slideRight' pad={size==='small'?'xlarge':'large'}>
                <img className={state.Theme.pool==='UG'?"color-white":""} src={state.Theme.logoLoginUGCalculator} alt="" />
            </Box>
            <Box animation='slideLeft' fill={true} overflow={'auto'} pad={'large'}>
                {mainComponent}
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Box>
        </Box>
    );
}

export default SideLogoLayout;
