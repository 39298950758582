import {Box, Heading, Stack, Text} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import AuthService from "../../services/AuthService";
import MyProfileWidget from "../MyProfile/MyProfileWidget";
import * as Icons from "grommet-icons";
import IntranetApiService from "../../services/IntranetApiService";
import ErrorService from "../../services/ErrorService";
import {DateTime} from "luxon";
import {ResponsiveContext} from "grommet/es6";
import {Link} from "react-router-dom";
import CalcPinService from "../../services/CalcPinService";
import {useDispatch, useSelector} from "react-redux";

function Home(props) {

    const state = useSelector((state) => state);
    const dispatch = useDispatch();

    const size = React.useContext(ResponsiveContext);

    const dashboardDataInitialState = {
        lastUsedCalculator: null, //{name: 'Pravidelné investovanie', path: '/pravidelne-investovanie'}
        lastLogged: null,
        links: null,
        mostUsedCalculators: null,
        pinnedCalculators: null,
        lastSavedStatesList: null
    };
    const [dashboardData, setDashboardData] = useState(dashboardDataInitialState);

    const reloadDashboardData =  useCallback(() => {
        IntranetApiService
            .getDashboard()
            .then((res) => {
                    setDashboardData(res);
                    CalcPinService.setStateData(res.pinnedCalculators);
                },
                (error) => {
                    ErrorService.fromApi(error);
                });
    }, []);

    function togglePinCalc(event, key){
        if (!event){
            return false;
        }
        if (!key){
            return false;
        }
        event.preventDefault();
        if (true!==window.confirm('Naozaj si prajete odopnúť kalkulačku?')){
            return false;
        }
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    useEffect(() => {
        reloadDashboardData();
    }, [reloadDashboardData]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'} margin={{bottom:'medium'}}>
                <Box>
                    <Heading margin={{'bottom':'none'}} weight={'light'} level={2}>Vitaj späť {AuthService.getProfile().label}</Heading>
                    {dashboardData?.lastLogged?.created_at && <Text>naposledy prihlásený {DateTime.fromISO(dashboardData.lastLogged.created_at.replace(' ', 'T')).toLocaleString(DateTime.DATETIME_SHORT)}</Text>}
                </Box>
                <Box direction={'row'}>
                    <MyProfileWidget/>
                </Box>
            </Box>

            <Box direction={'row-responsive'} gap={'medium'}>

                <Box id="dasboard-umbrella" direction={'column'} justify="between" height={{min:"250px"}} pad="medium" background={{
                    color: "brand",
                    dark: true,
                    opacity: false,
                    position: "center",
                    repeat: "no-repeat",
                    size: "cover",
                    image: "url(/assets/img/bg_umbrella.png)"
                }} elevation={'xsmall'} basis={'2/3'} round={"small"}>
                    <Box direction={'row'}>
                        {dashboardData?.lastUsedCalculator?.calc_key && <Link to={window.calcMap[dashboardData.lastUsedCalculator.calc_key].path} style={{'textDecoration':'none'}}><Box background={"black"} round={'small'} pad={'small'} width={'auto'}><Icons.History color={'white'}/></Box></Link>}
                        {((dashboardData?.lastUsedCalculator && !dashboardData?.lastUsedCalculator?.calc_key) || !dashboardData?.lastUsedCalculator) && <Box background={"black"} round={'small'} pad={'small'} width={'auto'}><Icons.History color={'white'}/></Box>}
                    </Box>
                    <Box direction={'column'}>
                        {dashboardData?.lastUsedCalculator?.calc_key && <Link to={window.calcMap[dashboardData.lastUsedCalculator.calc_key].path} style={{'textDecoration':'none'}}><Heading level={3} margin={'none'} color={'white'} size={'large'}>Naposledy použité</Heading><div style={{'color':'#fff','opacity':0.8,}}>{window.calcMap[dashboardData.lastUsedCalculator.calc_key].title}</div></Link>}
                        {dashboardData?.lastUsedCalculator && !dashboardData?.lastUsedCalculator?.calc_key && <div><Heading level={3} margin={'none'} color={'white'} size={'large'}>Naposledy použité</Heading></div>}
                        {!dashboardData?.lastUsedCalculator && <div><Heading level={3} margin={'none'} color={'white'} size={'large'}>Naposledy použité</Heading><div style={{'color':'#fff','opacity':0.5,'textDecoration':'none'}}>Zatiaľ čakám na dáta...</div></div>}
                    </Box>
                </Box>

                <Box direction={'column'} justify="between" height={{min:"250px"}} pad="medium" background={'white'} gap='medium' elevation={'xsmall'} basis={'1/3'} round={"small"}>
                    <Box direction={'row'}>
                        <Box background={"brand"} round={'small'} pad={'small'} width={'auto'}>
                            <Icons.Download color={'black'}/>
                        </Box>
                    </Box>
                    <Box direction={'column'}>
                        <Heading level={3} margin={'none'} size={'large'}>Stiahnite si appku</Heading>
                        <Box direction={'column'} gap={'small'} margin={{top:'small'}}>
                            {dashboardData?.links?.length && dashboardData.links.map((link)=>{
                                return <a href={link.url} style={{'color':'black','opacity':0.8,'textDecoration':'none'}}><Box direction={'row'} align={'center'} justify={'between'}>{link.label} <Icons.FormNext/></Box></a>
                            })}
                        </Box>
                    </Box>
                </Box>

                <Box direction={'column'} justify="between" height={{min:"250px"}} pad="medium" background={{
                    color: "black",
                    dark: true,
                    opacity: false,
                    position: "center",
                    repeat: "no-repeat",
                    size: "cover",
                    image: "url(/assets/img/bg_question.png)"
                }} elevation={'xsmall'} basis={'2/3'} round={"small"}>
                    <Box direction={'row'}>
                        <a href="/help" style={{'textDecoration':'none'}}>
                            <Box background={"white"} round={'small'} pad={'small'} width={'auto'}>
                                <Icons.Help color={'brand'} />
                            </Box>
                        </a>
                    </Box>
                    <Box direction={'column'}>
                        <a href="/help" style={{'color':'#fff','textDecoration':'none'}}>
                            <Heading level={3} margin={'none'} color={'white'} size={'large'}>Pomocník</Heading>
                            <div style={{'opacity':0.8}}>Nájdite odpovede na najčastejšie otázky</div>
                        </a>
                    </Box>
                </Box>

            </Box>


            <Box direction={'row-responsive'} gap={'small'} margin={{top:'large'}}>

                <Box direction={'column'} pad="small" basis={size=='small'?'auto':'1/3'}>
                    <Box direction={'row'} gap={'small'} align={'center'}  margin={{bottom:'medium'}}>
                        <Box background={"brand"} round={'large'} pad={'small'} width={'auto'}>
                            <Icons.List color={'white'}/>
                        </Box>
                        <Heading level={4} margin={'none'} size={'large'}><strong>Uložené modelovania</strong></Heading>
                    </Box>
                    <Box direction={'column'} border={true} round={'medium'} pad={"medium"} justify={'center'} justify={'center'}>
                        {dashboardData?.lastSavedStatesList?.length>0 && dashboardData.lastSavedStatesList.map((item, index)=>{
                            let last = dashboardData.lastSavedStatesList.length===index+1;
                            return <Link key={item.id} to={window.calcMap[item.calc_key].path+'/'+item.id} style={{'color':'black','opacity':0.8,'textDecoration':'none'}}><Box direction={'row'} align={'center'} pad={{vertical:'small'}} justify={'between'} style={{borderBottom:last?'':'1px solid #ccc'}}><span>{item.contents.title}</span> <Icons.FormNext/></Box></Link>
                        })}
                        {!dashboardData?.lastSavedStatesList?.length && <div style={{'color':'black','opacity':0.5,'textDecoration':'none'}}>Zatiaľ nemáte uložené žiadne modelovania...</div>}
                    </Box>
                </Box>

                <Box direction={'column'} pad="small" basis={size=='small'?'auto':'1/3'}>
                    <Box direction={'row'} gap={'small'} align={'center'}  margin={{bottom:'medium'}}>
                        <Box background={"brand"} round={'large'} pad={'small'} width={'auto'}>
                            <Icons.Trophy color={'white'}/>
                        </Box>
                        <Heading level={4} margin={'none'} size={'large'}><strong>Najpoužívanejšie kalkulačky</strong></Heading>
                    </Box>
                    <Box direction={'column'} border={true} round={'medium'} pad={"medium"} justify={'center'}>
                        {dashboardData?.mostUsedCalculators?.length>0 && dashboardData.mostUsedCalculators.map((item, index)=>{
                            let last = dashboardData.mostUsedCalculators.length===index+1;
                            return <Link key={item.id} to={window.calcMap[item.calc_key].path} style={{'color':'black','opacity':0.8,'textDecoration':'none'}}><Box direction={'row'} align={'center'} pad={{vertical:'small'}} justify={'between'} style={{borderBottom:last?'':'1px solid #ccc'}}><span>{window.calcMap[item.calc_key].title}</span> <Icons.FormNext/></Box></Link>
                        })}
                        {!dashboardData?.mostUsedCalculators?.length && <div style={{'color':'black','opacity':0.5,'textDecoration':'none'}}>Zatiaľ čakám na dáta...</div>}
                    </Box>
                </Box>

                <Box direction={'column'} pad="small" basis={size=='small'?'auto':'1/3'} >
                    <Box direction={'row'} gap={'small'} align={'center'}  margin={{bottom:'medium'}}>
                        <Box background={"brand"} round={'large'} pad={'small'} width={'auto'}>
                            <Icons.Trophy color={'white'}/>
                        </Box>
                        <Heading level={4} margin={'none'} size={'large'}><strong>Pripnuté kalkulačky</strong></Heading>
                    </Box>
                    {dashboardData?.pinnedCalculators?.length>0 && <Box direction={'column'} border={true} round={'medium'} pad={"medium"} justify={'center'}>
                        { dashboardData.pinnedCalculators.map((item, index)=>{
                            let last = dashboardData.pinnedCalculators.length===index+1;
                            return <Link key={item.id} to={window.calcMap[item.calc_key].path} style={{'color':'black','opacity':0.8,'textDecoration':'none'}}>
                                <Box direction={'row'} align={'center'} pad={{vertical:'small'}} justify={'between'} style={{borderBottom:last?'':'1px solid #ccc'}} >
                                    <Box direction={'row'} align={'center'} gap={'small'}>
                                         <a style={{cursor:'pointer', padding:'4px', background: 'red', borderRadius: '100px', textAlign:'center', width:'18px'}} onClick={(event)=>{togglePinCalc(event,item.calc_key)}}>
                                              <Icons.Pin color={'white'} size={'small'} />
                                          </a>
                                         {window.calcMap[item.calc_key].title}
                                    </Box>
                                    <Icons.FormNext/>
                                </Box>
                            </Link>
                        })}</Box>}
                    {!dashboardData?.pinnedCalculators?.length && <Box direction={'column'} border={true} round={'medium'} pad={"medium"} align={'center'} justify={'center'}>
                        <div style={{'color':'black','opacity':0.8,'textDecoration':'none', 'textAlign':'center'}}><p>Momentálne nemáte pripnuté žiadne kalkulačky.</p>
                            <p>Pre pripnutie kliknite na ikonku pripináčiku v detaile zvolenej kalkulačky.</p></div></Box>}
                </Box>

            </Box>

        </Box>
    );
}

export default Home;
