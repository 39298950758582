import React from 'react';
import { Blank } from 'grommet-icons';

export const UgHelpIcon = props => (
    <Blank {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2.2a8.8 8.8 0 100-17.6 8.8 8.8 0 000 17.6zm0-1.1a1.1 1.1 0 11-.001-2.199A1.1 1.1 0 0112 19.7z"></path>
            <path
                fillRule="nonzero"
                d="M12 5.4a4.4 4.4 0 011.271 8.614l-.171.046v1.24c0 .564-.425 1.029-.972 1.093L12 16.4a1.101 1.101 0 01-1.093-.972L10.9 15.3v-2.2c0-.564.425-1.029.972-1.093L12 12a2.2 2.2 0 10-2.2-2.2 1.1 1.1 0 11-2.2 0A4.4 4.4 0 0112 5.4z"
            ></path>
        </svg>
    </Blank>
);