import {Box} from 'grommet';
import React from "react";

function SZCOHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet čistého príjmu/obratu pre samostatne zárobkovo činné osoby (SZČO) a tiež na
            výpočet nákladov na zdravotné a sociálne odvody.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte typ kalkulačky, t.j. zvoľte parameter, ktorý chceme vypočítať.</li>
            <li>Zvoľte obdobie, pre ktoré chcete počítať mzdu.</li>
            <li>Uveďte obrat/čistý príjem, odvody a iné náklady, ktoré mal SZČO za daný rok. V prípade uplatnenia
                paušálnych nákladov v danom roku, zaškrtnite checkbox.</li>
            <li>Uveďte ročné výdavky na zdravotné/sociálne poistenie.</li>
            <li>Zadajte počet mesiacov za koľko si klient uplatňuje odpočítateľnú položku ako SZČO. V prípade, že klient
                bol predtým zamestnaný, uveďte počet celých mesiacov počas ktorých v danom roku podnikal. V prípade, že
                klient bol predtým celý rok pracovne nečinný, uveďte 12.</li>
            <li>Zvoľte počet vyživovaných nezaopatrených detí a nastavte dátumy narodenia. </li>
            <li>V prípade, že bola manželka v minulom roku bez príjmu, zaškrtnite checkbox.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je čistý príjem/obrat, základ dane, daň a výška zdravotných a sociálnych odvodov.
            Sociálne odvody sa platia od 1.7. v roku v ktorom sa podáva daňové priznanie a zdravotné odvody sa platia
            od 1.1. nasledujúceho roku po ktorom sa podáva daňové priznanie.</p>
        <br /><br />
    </Box>);
}

export default SZCOHelp;
