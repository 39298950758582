import IntranetApiService from "./IntranetApiService";

const SavedStatesService = {
    getList: function(){
        return IntranetApiService.call('get', '/ugcalculator/getSavedStatesList');
    },
    getDetail: function(id){
        return IntranetApiService.call('get', '/ugcalculator/getSavedStateDetail/id/'+id);
    },
    delSavedState: function(id){
        return IntranetApiService.call('delete', '/ugcalculator/delSavedStateDetail/id/'+id);
    },
    addEditSavedState: function(key, title, state, version, id){
        let req ={
            key: key,
            title: title,
            contents: state,
            version: version,
        };
        if (id){
            req['id'] = id;
        }
        return IntranetApiService.call('post', '/ugcalculator/addEditSavedState', req);
    }
};

export default SavedStatesService;