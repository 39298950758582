import FormatService from "../../services/FormatService";

function SavingEicPdfExport({values,result,exportPdfModeForm,chart}) {
    let inputs = [
        {key: 'mode', label: 'Typ', value: () => { switch (values.mode){
                case 'ONE_TIME': return 'Jednorázová investícia';
                case 'RECURRING': return 'Pravidelná investícia';
                default: return ''; } }},
        {key: 'period', label: 'Dĺžka investovania', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
        {key: 'revenue', label: 'Výnos p.a. (%)', value: values.revenue},
        {key: 'startFee', label: 'Vstupný poplatok (%)', value: values.startFee},
        {key: 'managementFee', label: 'Správny poplatok (%)', value: values.managementFee},
        {key: 'monthsForFee', label: 'Doba strhávania poplatku (m)', value: values.monthsForFee},
        {key: 'payment', label: 'Výška vkladu (€)', value: values.payment},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>  
    
    <div class="row-flex mb3">
        <div class="col-12">
            <table class="table minimal-table">
                                <thead>
                                <tr>
                                    <td></td>
                                    <th>Rozdelenie fondov (%)</th>
                                    <th>Počet fondov</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th class="text-right">Akciové fondy</th>
                                    <td>${values.equityPayment}</td>
                                    <td>${values.equityFundsCount}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Dlhopisové fondy</th>
                                    <td>${values.bondsPayment}</td>
                                    <td>${values.bondsFundsCount}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Zmiešané fondy</th>
                                    <td>${values.mixedPayment}</td>
                                    <td>${values.mixedFundsCount}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Peňažné fondy</th>
                                    <td>${values.cashPayment}</td>
                                    <td>${values.cashFundsCount}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Akciové ETF</th>
                                    <td>${values.etfEquityPayment}</td>
                                    <td>${values.etfEquityFundsCount}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Dlhopisové ETF</th>
                                    <td>${values.etfBondsPayment}</td>
                                    <td>${values.etfBondsFundsCount}</td>
                                </tr>
                                </tbody>
            </table>
        </div>
    </div>
    <div class="row-flex mb3">
         <div class="col-12">
          
              ${(exportPdfModeForm && exportPdfModeForm.exportPdfMode && exportPdfModeForm.exportPdfMode.includes('invest')) ? `<div class="card">
                  <div class="card-body">
                     <h3>Výstup investičného účtu</h3>
                     <div class="table-responsive">
                        <table class="table styled-table">
                            <tbody>
                            <tr>
                                <th>Hodnota investície na konci investičného horiznotu</th>
                                <td class="text-right"><b>${FormatService.round(result.investEndValue)}</b> €</td>
                            </tr>
                            <tr>
                                <th>Absolútny výnos</th>
                                <td class="text-right"><b>${FormatService.round(result.investAbsolutProfit * 100, 2)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Čistý výnos p.a.</th>
                                <td class="text-right"><b>${FormatService.round(result.investProfit * 100, 2)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Návratnosť v mesiacoch (hodnota účtu prevyšuje sumu vkladov)</th>
                                <td class="text-right"><b>${FormatService.round(result.investMonthReturn)}</b> m</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
              </div>` : ''}
              
              ${(exportPdfModeForm && exportPdfModeForm.exportPdfMode && exportPdfModeForm.exportPdfMode.includes('managed')) ? `<div class="card">
                 <div class="card-body">
                    <h3>Výstup spravovaného účtu</h3>
                    <div class="table-responsive">
                        <table class="table styled-table">
                            <tbody>
                            <tr>
                                <th>Hodnota investície na konci investičného horiznotu</th>
                                <td class="text-right"><b>${FormatService.round(result.recurringEndValue)}</b> €</td>
                            </tr>
                            <tr>
                                <th>Absolútny výnos</th>
                                <td class="text-right"><b>${FormatService.round(result.recurringAbsolutProfit * 100,2)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Výnos p.a.</th>
                                <td class="text-right"><b>${FormatService.round(result.recurringProfit * 100,2)}</b> %</td>
                            </tr>
                            <tr>
                                <th>Návratnosť v mesiacoch (hodnota účtu prevyšuje sumu vkladov)</th>
                                <td class="text-right"><b>${FormatService.round(result.recurringMonthReturn)}</b> m</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                 </div>
              </div>` : ''}
              
         </div>
    </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default SavingEicPdfExport;