import {Box} from 'grommet';
import React from "react";

function LoanBankComparisonHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie výhodnosti jednotlivých bánk z pohľadu nákladov po troj a päťročnej fixácií. Do porovnania sú zahrnuté</p>
        <ul>
            <li>spracovateľský poplatok (pri SLSP je strhávaný postupne)</li>
            <li>poplatok za vedenie bežného účtu</li>
            <li>spôsob úročenia (NEM. vs. FR. metóda)</li>
            <li>rozličné znižovanie istiny v prípade ŠPM</li>
            <li>rozličné ú.s. na časť úveru so ŠPM a na časť úveru bez ŠPM</li>
        </ul>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte parametre úveru – bez ŠPM/ŠPM od 2018/ŠPM do 2017 (vrátane), výšku úveru, hodnotu zabezpečenia
                (pri ŠPM) a splatnosť</li>
            <li>V tabuľke sa automaticky nastavia hodnoty poplatkov</li>
            <li>Kliknite na <b>Vypočítaj</b></li>
            <li>V časti "Základné parametre" zadajte ú.s. pri jednotlivých bankách. V prípade ŠPM zadávajte vždy ú.s.
                pred zľavou 3%. Upravte hodnotu poplatkov (v prípade spracovateľského poplatku môžete poplatok zadať
                buď v %-tách alebo v EUR, druhá hodnota sa dopočíta automaticky)</li>
            <li><b>Len pre ŠPM 2017: </b>V časti "Zvyšný úver" zadajte ú.s. pri jednotlivých bankách v prípade, že je
                sadzba na zvyšnú časť úveru (bez ŠPM) odlišná od základnej sadzby.</li>
        </ol>

        <h2>Výstup</h2>
        <p>Tabuľkový výstup kalkulačky pozostáva z troch nasledovných častí:</p>
        <ul>
            <li>SPLÁTKA, zobrazuje splátku celkového úveru, pričom v prípade ŠPM je už po odrátaní príspevkov banky a štátu.</li>
            <li>"Po 3./4./10.roku", v tejto časti sa zobrazujú celkové splátky klienta za 3/4/10 roky/-ov, zostatok
                úveru a celkové náklady, v ktorých je zahrnutý spracovateľský poplatok a poplatok za vedenie účtu.</li>
            <li>"Po 5.roku", v tejto časti sa zobrazujú celkové splátky klienta za 5 rokov, zostatok úveru a celkové
                náklady, v ktorých je zahrnutý spracovateľský poplatok a poplatok za vedenie účtu.</li>
            <li><b>Len pre ŠPM 2018:</b> "Úrok úspora", v tejto časti sa zobrazuje úspora klienta na úrokoch</li>
        </ul>
        <br /><br />
        <p><b>Poznámka: ŠPM 2017</b> Hodnota výstupu celkové náklady po 5. roku je z hľadiska porovnania výhodnosti pri ŠPM najrelevantnejšia.</p>
        <p><b>Poznámka: SLSP</b>Spôsob strhávania poplatku a defaultné hodnoty dátumov sú nastavené tak, aby korešpondovali s Úverovou ponukou SLSP.</p>
    </Box>);
}

export default LoanBankComparisonHelp;
