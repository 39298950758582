import {Box} from 'grommet';
import React from "react";

function SavingEicHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na porovnanie nákladovosti a návratnosti investície pri jednotlivých účtoch v EIC.</p>

        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte typ investovania (jednorázové/pravidelné).</li>
            <li>Zvoľte parametre investovania - doba investovania, očakávaný výnos, vstupný poplatok, management fee a v prípade sporenia aj dobu strhávania poplatku.</li>
            <li>Zadajte výšku investície.</li>
            <li>V prípade, že chcete presnejšie stanoviť investičnú stratégiu, a teda aj poplatky, upresnite
                prerozdelenie medzi jednotlivé typy fondov a zároveň aj ich počet. Pri manažovanom investičnom účte je
                totiž poplatok vo výške 15 EUR za každú transakciu, a teda pri prvotom nákupe je to 15 EUR za každý
                fond. V prípade, že prerozdelenie do fondov nie je presne 100%, vo výpočte to bude upravené tak, aby sa
                zachoval rovnaký pomer, ako ste zvolili.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky pre klasický a aj manažovaný účet pozostáva zo 4 nasledovných častí:</p>
        <ul>
            <li>Suma na konci je hodnota investície na konci investičného horizontu.</li>
            <li>Absolútny výnos je podiel stavu účtu na konci investičného horizontu a vkladov.</li>
            <li>Výnos p.a. je priemerný ročný výnos investície, ktorý zohľadňuje časovú postupnosť vkladov, poplatkov, kick-backov a výnosu.</li>
            <li>Návratnosť v mesiacoch vyjadruje počet mesiacov, po ktorých prevyšuje hodnota účtu sumu vkladov.</li>
        </ul>
        <br /><br />
    </Box>);
}

export default SavingEicHelp;
