import { Box, FormField, Heading, RadioButtonGroup, TextInput } from "grommet";
import { useEffect, useState } from "react";

const SavePdfExportTemplate = ({template, SAVE_OPTION_EDIT, SAVE_OPTION_NEW, SAVE_OPTION_NONE}) => {

    const [saveOption, setSaveOption] = useState(SAVE_OPTION_NONE)
    const [templateName, setTemplateName] = useState("")

    useEffect(() => {
        if(template && saveOption === SAVE_OPTION_EDIT){
            setTemplateName(template.contents.title);
        }
        else{
            setTemplateName("")
        }
    }, [template, saveOption])

    return (
        <Box direction='column'>
            <Heading level={"5"} margin={{bottom:'xsmall'}}>Uloženie šablónu</Heading>
            <Box direction={'row-responsive'}>
                <Box basis={'full'} round={'xsmall'} margin={{bottom: 'small', top:'medium'}}>
                    <RadioButtonGroup
                        name="save_option"
                        direction={'row'}
                        gap={'medium'}
                        value={saveOption}
                        onChange={(e) => setSaveOption(e.target.value)}
                        options={[
                            {value: SAVE_OPTION_NONE, label: "Neukladať"},
                            {value: SAVE_OPTION_NEW, label: "Uložiť ako nový záznam"},
                            {value: SAVE_OPTION_EDIT, label: "Upraviť pôvodnú šablónu", disabled: template ? false : true},
                    ]} />

                    {saveOption !== SAVE_OPTION_NONE &&
                        <Box basis={'full'} direction={'row'} align={'center'}>
                            <FormField label="Názov" width={'full'}>
                                <TextInput value={templateName} onChange={e => setTemplateName(e.target.value)} name="template_title" required={true} />
                            </FormField>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}


export default SavePdfExportTemplate;