import {
    Box,
    Button,
    Form,
    Heading,
    Layer,
} from 'grommet';
import { useEffect, useState } from 'react';
import ErrorService from '../../services/ErrorService';
import SavedPdfExportTemplatesService from '../../services/SavedPdfExportTemplatesService';
import LoadPdfExportTemplate from './LoadPdfExportTemplate';
import SavePdfExportTemplate from './SavePdfExportTemplate';

function PdfExportModal({pinKey, closeaction, values, show, children, exportPdf, renderColumnRowSelection, customData, disabledSubmitButton=false}){
    
    const SAVE_OPTION_NONE = 'none';
    const SAVE_OPTION_NEW = 'new';
    const SAVE_OPTION_EDIT = 'edit';

    const [template, setTemplate] = useState(null);
    const [savedTemplates, setSavedTemplates] = useState([]);

    function closeModal(res=null){
        setTemplate(null)
        closeaction(res);
    }

    useEffect(() => {
        if(show === true){
            loadTemplatesList();
        }
    }, [show]);

    function loadTemplatesList(){
        SavedPdfExportTemplatesService.getList()
        .then((res) => {
                const calc_templates = res.filter(template => template.calc_key === pinKey )
                setSavedTemplates(calc_templates)
            },
            (error) => {
                ErrorService.fromApi(error);
        }); 
    }


    function savePdfExportTemplate(title, state, id=null){

        return SavedPdfExportTemplatesService.addEditSavedTemplate(pinKey, title, state, id)
        .then((res) => {
                closeModal(res)
            },
            (error) => {
                ErrorService.fromApi(error);
        });
    }

    function onSubmit(formValues){
        const state = {
            ...formValues,
            title: template ? template.contents.state.title : values.title
        }
        delete state.template_title
        delete state.save_option

        const options = {...formValues}
        delete options.template_title
        delete options.save_option
        
        exportPdf(options)        
 
        if(formValues.save_option !== SAVE_OPTION_NONE){
            savePdfExportTemplate(formValues.template_title, state, formValues.save_option === SAVE_OPTION_EDIT ? template.id : null )
        }
    }
    

    return (
        <Box>
        {children}

        {show===true && (
            <Layer
                onEsc={()=>closeModal()}
                onClickOutside={()=>closeModal()}
            >
            <Box pad={'medium'} overflow="scroll" width={"large"} round={'small'} border={true} background={'white'} align={'center'} >
            <Form onSubmit={(e) => onSubmit(e.value)} validate="submit" >
                <Heading margin={{bottom:'xsmall'}}>Export Pdf</Heading>
                    <LoadPdfExportTemplate savedTemplates={savedTemplates} pinKey={pinKey} setTemplate={setTemplate} />
                    <SavePdfExportTemplate SAVE_OPTION_EDIT={SAVE_OPTION_EDIT} SAVE_OPTION_NONE={SAVE_OPTION_NONE} SAVE_OPTION_NEW={SAVE_OPTION_NEW} template={template} />
                    {renderColumnRowSelection(template, values, customData)}

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" disabled={disabledSubmitButton} primary label={"Export"} />
                        <Button label="Zatvoriť" onClick={() => closeModal()} />
                    </Box>
                    </Form>
            </Box>
            </Layer>
        )}
    </Box>
);
    
}

export default PdfExportModal;
