import {Box} from 'grommet';
import React from "react";

function OneTimeInvestmentHelp() {

    return (<Box>
        <h2>Popis</h2>
        <p>Kalkulačka slúži na výpočet jedného z troch parametrov jednorázového investovania, a to:</p>
        <ol type={'A'}>
            <li>hodnotu jednorázovej investície na konci investičného horizontu (chceme vedieť ako sa nám zhodnotí investícia, ak vieme horizont, jej výšku a výnos)</li>
            <li>výšky jednorázovej investície (chceme vedieť koľko musíme zainvestovať, ak vieme výnos, horizont a aj koľko chceme dosiahnuť na konci investičného horizontu (napr. na dôchodok))</li>
            <li>výnosu jednorázovej investície (chceme vedieť aký výnos dosiahneme/sme dosiahli, ak vieme výšku, horizont a ajsumu akú chceme dosiahnuť/sme dosiahli (napr. investícia do fondov))</li>
        </ol>
        <h2>Postup</h2>
        <ol type={'1'}>
            <li>Zvoľte typ kalkulačky, t.j. zvoľte parameter, ktorý chceme vypočítať.</li>
            <li>Zvoľte parametre investovania - výšku investície, investičný horizont, očakávaný výnos, príp. čiastku, ktorú chceme dosiahnuť.</li>
            <li>V prípade, že chcete počítať aj s infláciou (vo výpočte sa zníži výnos o zvolenú infláciu), nastavte ju.</li>
            <li>Kliknite na <b>Vypočítať</b></li>
        </ol>
        <h2>Výstup</h2>
        <p>Výstup kalkulačky je počítaný parameter.</p>
        <br /><br />
        <p><b>Poznámka: </b>ak nie je uvedené inak, výpočet nezohľadňuje daň!</p>
    </Box>);
}

export default OneTimeInvestmentHelp;
