import {Box, Image} from 'grommet';
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import CalcDetailService from "../../services/CalcDetailService";
import AuthService from "../../services/AuthService";

function ResetState(props) {
    const history = useHistory();
    const routeParams = useParams();
    const state = useSelector((state) => state);

    useEffect(() => {
        const slug = routeParams ? routeParams.slug : null;
        if (AuthService.getUser()){
            CalcDetailService.resetState(slug);
        } else {
            alert('Na využitie tejto funkcie musíte byť prihlásený');
        }
        history.push('/');
        // eslint-disable-next-line
    }, []);

    return (
        <Box fill={true} direction='column' alignContent='center' align='center' justify='center'>
            <Box width='large' align='center' border={true} pad='30px' animation='fadeIn'>
                <Image width='180px' src={state.Theme.logo} margin='medium' />
                <p>Prosím počkajte...</p>
                <UgSpinner />
            </Box>
        </Box>
    );
}

export default ResetState;
