import CalcPinService from "../services/CalcPinService";

const stateData = CalcPinService.getStateData();
const initialState = stateData ? stateData : null;

export function CalcPinReducer(state = initialState, action) {
    switch (action.type) {
        case 'calcpin/save': return CalcPinService.getStateData();
        case 'calc/pin': return CalcPinService.getStateData();
        case 'calc/unpin': return CalcPinService.getStateData();
        default:
            return state
    }
}