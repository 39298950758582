import {Box, Button, Form, FormField, Heading, RadioButtonGroup, Text, TextInput} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import RegularInvestmentHelp from "./RegularInvestmentHelp";
import RegularInvestmentPdfExport from "./RegularInvestmentPdfExport";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import SavedStatesService from "../../services/SavedStatesService";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";

function RegularInvestment(props) {
    const calcStateKey = 'RegularInvestment';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Pravidelné investovanie',
        mode: 'total-value',
        period: 10,
        periodUnits: 'y',
        annualProfit: 3,
        annualInflation: 0,
        deposit: 100,
        totalValue: 50000,
        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);

    function saveCalcDetail(values, result){
        const calcDetailState = {
            values:values,
            result:result
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        let reqData = {};
        switch (values.mode){
            case 'total-value':
                reqData = {
                    annualInflation: values.annualInflation,
                    annualProfit: values.annualProfit,
                    periodInMonths: periodMonths,
                    deposit: values.deposit
                }; break;
            case 'deposit':
                reqData = {
                    annualInflation: values.annualInflation,
                    annualProfit: values.annualProfit,
                    periodInMonths: periodMonths,
                    totalValue: values.totalValue
                }; break;
            case 'interest-rate':
                reqData = {
                    totalValue: values.totalValue,
                    deposit: values.deposit,
                    periodInMonths: periodMonths
                }; break;
            default: break;
        }

        setValues(Object.assign({}, values, {activeRequest: true}));
        ApiService.call('post', '/v2/investment/regular/'+values.mode, reqData)
            .then((res) => {
                    setResult(res.result);
                    const newValues = Object.assign({}, values, {activeRequest: false});
                    setValues(newValues);
                    saveCalcDetail(newValues, res.result);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'pravidelne-investovanie';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: RegularInvestmentPdfExport({values:values,result:result,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }

    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)  
                .then((res) => {
                    if (!res || !res.contents || !res.contents.state){
                        setSavedState(null);
                        if (window.debug) {
                            console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                        }
                        return false;
                    }
                    if (res.contents && res.contents.version && calcStateVersion){
                        if (res.contents.version !== calcStateVersion){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                            }
                            return false;
                        }
                    }
                    toast.success('Modelovanie bolo načítané', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setSavedState(res);
                    setValues(res.contents.state);
                    onFormSubmit(res.contents.state);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><RegularInvestmentHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                 <Box direction={'row'}>
                     <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                         <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                     </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        if (values.mode!==nextValue.mode){
                            setResult(null);
                        }
                        setValues(nextValue);
                        saveCalcDetail(nextValue, result);
                    }}
                    onKeyPress={nextValue => {
                        console.log(nextValue.key);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        saveCalcDetail(defaultValues, null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" options={[
                                {value: 'total-value',label: "Hodnota pravidelnej investície"},
                                {value: 'deposit',label: "Výška pravidelnej investície"},
                                {value: 'interest-rate',label: "Úrok pravidelnej investície"}
                            ]} />
                        </Box>
                    </Box>
                    <Box basis={'2/3'} direction={'row'} align={'center'}>
                        <FormField label="Investičný horizont" width={'auto'}>
                            <TextInput name="period" type="number" min="1" step="1" required={true} />
                        </FormField>
                        <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                            {value: 'm',label: "Mesiacov"},
                            {value: 'y',label: "Rokov"},
                        ]} />
                    </Box>
                    <Box direction={'row-responsive'} wrap>
                        {['deposit', 'interest-rate'].includes(values.mode) &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Celková čiastka na konci investičného horiznotu (€)" width={'full'}>
                                <TextInput name="totalValue" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        }
                        {['total-value', 'deposit'].includes(values.mode) &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Očakávaný ročný výnos (%)" width={'full'}>
                                <TextInput name="annualProfit" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        }
                        {['total-value', 'interest-rate'].includes(values.mode) &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Pravidelná investícia (€)" width={'full'}>
                                <TextInput name="deposit" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        }
                        {['total-value', 'deposit'].includes(values.mode) &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Ročná inflácia (%)" width={'full'}>
                                <TextInput name="annualInflation" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        }
                    </Box>
                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>


            {result &&
            <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{vertical: 'medium'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                <Box pad="small" alignContent={'between'} basis={'3/4'}>
                    {['total-value'].includes(values.mode) && <Text>Hodnota investície na konci investičného horizontu</Text>}
                    {['deposit'].includes(values.mode) && <Text>Na dosiahnutie investičného zámeru treba vkladať každý mesiac</Text>}
                    {['interest-rate'].includes(values.mode) && <Text>Čistý ročný percentuálny výnos pravidelnej investície</Text>}
                </Box>
                <Box pad="small" align={'end'} basis={'1/4'}>
                    <Heading level={2} fill={true} margin={'none'}>
                        <strong>
                            {result}
                            {['total-value'].includes(values.mode) && <span>€</span>}
                            {['deposit'].includes(values.mode) && <span>€</span>}
                            {['interest-rate'].includes(values.mode) && <span>%</span>}
                        </strong>
                    </Heading>
                </Box>
            </Box>
            }
        </Box>
    );
}

export default RegularInvestment;