import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CheckBox,
    DateInput,
    Form,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    Select,
    TextInput
} from 'grommet';
import React, {useCallback, useEffect, useRef, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanAdvancedHelp from "./LoanAdvancedHelp";
import LoanAdvancedPdfExport from "./LoanAdvancedPdfExport";
import FormatService from "../../services/FormatService";
import CalcDetailService from "../../services/CalcDetailService";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import {useDebouncedEffect} from "../../effects/UseDebouncedEffect";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import ColumnRowSelection from './LoanAdvancedColumnRowSelection';
import PdfExportModal from '../SavedPdfExportTemplates/PdfExportModal';

function LoanAdvanced(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'LoanAdvanced';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    function ExtraPayment(isPaymentTerm, month, payment, recurring){
        return {
            month: month,
            payment: payment,
            isPaymentTerm: isPaymentTerm,
            recurring: recurring
        };
    }

    function InterestRate(month, rate, mode='main'){
        return {
            month: month,
            rate: rate,
            mode: mode,
        }
    }

    const extraPaymentFormInitialState = {
        recurring: true,
        amount: 100,
        periodicity: 1,
        month: 1,
        mode: 'splatka',
    };

    const annualInterestRateFormInitialState = {
        mode: 'main',
        rate: 5,
        month: 1,
    };

    const defaultValues = {
        title: 'Základné modelovanie úveru',
        period: 30,
        periodUnits: 'y',
        loanAmount: 70000,
        loanFee: 100,
        bank: false,
        bankObject: {
            id: "tatra",
            name: "TATRA",
            hasEditableInterestRateRest: true,
            canUseDecreaseByPayment: false
        },
        dates: false,
        datesDissaving: new Date().toISOString(),
        datesFirstPayment: new Date().toISOString(),
        baseInterestRate: 1,
        spm: false,
        spmType: ApiService.consts().spm.spm2018,
        spmAssuranceAmount: 70000,
        baseSpmInterestRatesRest: 0,
        spmUseDecreateByPayment: false,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const banksListInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['banksList']) ? state.CalcDetail[calcStateKey]['banksList'] : [];
    const datepickerDisabledDaysInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['datepickerDisabledDays']) ? state.CalcDetail[calcStateKey]['datepickerDisabledDays'] : [];
    const extraPaymentsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['extraPayments']) ? state.CalcDetail[calcStateKey]['extraPayments'] : [];
    const annualInterestRatesInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['annualInterestRates']) ? state.CalcDetail[calcStateKey]['annualInterestRates'] : [];
    const spmInterestRatesRestInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['spmInterestRatesRest']) ? state.CalcDetail[calcStateKey]['spmInterestRatesRest'] : [];

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [banksList, setBanksList] = useState(banksListInitialState);
    const [filteredBanksList, setFilteredBanksList] = useState(banksListInitialState);
    const [datepickerDisabledDays, setDatepickerDisabledDays] = useState(datepickerDisabledDaysInitialState);
    const [extraPayments, setExtraPayments] = useState(extraPaymentsInitialState);
    const [annualInterestRates, setAnnualInterestRates] = useState(annualInterestRatesInitialState);
    const [spmInterestRatesRest, setSpmInterestRatesRest] = useState(spmInterestRatesRestInitialState);
    const [extraPaymentModal, setExtraPaymentModal] = useState(false);
    const [extraPaymentForm, setExtraPaymentForm] = useState(extraPaymentFormInitialState);
    const [annualInterestRateModal, setAnnualInterestRateModal] = useState(false);
    const [annualInterestRateForm, setAnnualInterestRateForm] = useState(annualInterestRateFormInitialState);
    const [activeRequest, setActiveRequest] = useState(false);
    const [showExportPdfModal, setShowExportPdfModal] = useState(false);

    function saveCalcDetail(values, result, banksList, datepickerDisabledDays,extraPayments,annualInterestRates,spmInterestRatesRest){
        const calcDetailState = {
            values:values,
            result:result,
            banksList:banksList,
            filteredBanksList:filteredBanksList,
            datepickerDisabledDays:datepickerDisabledDays,
            extraPayments:extraPayments,
            annualInterestRates:annualInterestRates,
            spmInterestRatesRest:spmInterestRatesRest,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    const reloadBanksList = useCallback(() => {
        ApiService.getLoanBanksList(values.spm ? values.spmType : ApiService.consts().spm.none)
            .then((res) => {
                    setBanksList(res);
                    setFilteredBanksList(res);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
        // eslint-disable-next-line
    }, [values.spm, values.spmType]);

    const getBankFee = useCallback(() => {
        if (values.bank && values.bankObject && values.bankObject.id){
            ApiService.getBankFee(values.bankObject.id, values.loanAmount, values.spmType, values.spmAssuranceAmount)
                .then((res) => {
                        let newValues = Object.assign({}, values);
                        newValues.loanFee = FormatService.round(res.loanFee);
                        setValues(newValues);
                    },
                    (error) => {
                        ErrorService.fromApi(error);
                    })
        }
        // eslint-disable-next-line
    }, [values.bankObject, values.loanAmount, values.bank]);

    useEffect(() => {
        reloadBanksList();
    }, [reloadBanksList]);

    useDebouncedEffect(() => {
        getBankFee();
    }, 500, [getBankFee]);

    // we want to refresh the table if input params change, however
    // we are already changing values.bankObject, values.loanAmount, values.bank
    // they are changing on values.spm, values.spmType so we dont want that either.
    useDebouncedEffect(() => {
        if (result && result.loanResultData){
            onFormSubmit(values);
        }
    }, 500, [ values.period,
        values.periodUnits,
        values.loanAmount,
        values.loanFee,
        values.dates,
        values.datesDissaving,
        values.datesFirstPayment,
        values.baseInterestRate,
        values.baseSpmInterestRatesRest,
        values.spmUseDecreateByPayment,
        extraPayments, annualInterestRates, spmInterestRatesRest]);

    useDebouncedEffect(() => {
        saveCalcDetail(values, result, banksList, datepickerDisabledDays,extraPayments,annualInterestRates,spmInterestRatesRest);
    }, 500, [values, result, banksList, datepickerDisabledDays, extraPayments, annualInterestRates, spmInterestRatesRest]);

    function onFormSubmit(v){
        const periodMonths = v.periodUnits === 'm' ? v.period : v.period*12;

        let reqData = {
            periodInMonths: 1*periodMonths,
            annualInterestRates: [InterestRate(0, v.baseInterestRate), ...annualInterestRates].map((rate)=>{
                return {
                    month: rate.month,
                    rate: 1*rate.rate,
                };
            }),
            loanAmount: 1*v.loanAmount,
        };

        if (v.bank && v.bankObject){
            reqData.bankId = v.bankObject.id;
            reqData.loanFee = 1*v.loanFee;
            if (v.bank && v.bankObject.canUseDecreaseByPayment) {
                if (!reqData.spm){
                    reqData.spm = { spmType: ApiService.consts().spm.none };
                }
            }
        }

        if (v.spm){
            if (!reqData.spm){
                reqData.spm = {};
            }
            reqData.spm.interestRatesRest = [InterestRate(0, v.baseSpmInterestRatesRest), ...spmInterestRatesRest].map((rate)=>{
                return {
                    month: rate.month,
                    rate: 1*rate.rate,
                };
            });
            reqData.spm.spmType = v.spmType;
            reqData.spm.assuranceAmount = 1*v.spmAssuranceAmount;
            reqData.spm.useDecreateByPayment = v.spmUseDecreateByPayment;
        }

        if (v.dates){
            if (!reqData.dates){
                reqData.dates = {};
            }
            reqData.dates.firstPaymentTimestamp = new Date(v.datesFirstPayment).getTime();
            reqData.dates.dissavingTimestamp = new Date(v.datesDissaving).getTime();
        }

        if (extraPayments.length){
            reqData.extraPayments = extraPayments.map((payment)=>{
                let req = {
                    month: payment.month,
                    payment: 1*payment.payment,
                    isPaymentTerm: payment.isPaymentTerm,

                };
                if (payment.recurring>0){
                    req.recurring = payment.recurring;
                }
                return req;
            });
        }

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/advanced', reqData)
            .then((res) => {
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'zakladne-modelovanie-uveru';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function onLoadAdvancedExportPdfClose(res){
        if(res){
            toast.success('Šablóna bola uložená', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setShowExportPdfModal(false);
    }

    function exportPdf(options){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanAdvancedPdfExport.generatePdf({values:  values,result: result,extraPayments: extraPayments,annualInterestRates: annualInterestRates, spmInterestRatesRest: spmInterestRatesRest, chart:null, options}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function datepickerDisableNextDays(date){
        let start = new Date(date);
        return [[new Date(start.setDate(start.getDate()+1)).toISOString(), '2077-01-01']];
    }

    function openAddEditMainRateModal(month, val){
        setAnnualInterestRateForm(Object.assign({}, annualInterestRateForm, {mode: 'main', month: month, rate: val}));
        setAnnualInterestRateModal(true);
    }

    function openAddEditSecondaryRateModal(month, val){
        setAnnualInterestRateForm(Object.assign({}, annualInterestRateForm, {mode: 'secondary', month: month, rate: val}));
        setAnnualInterestRateModal(true);
    }

    function openAddEditExtraPaymentModal(month, val){
        setExtraPaymentForm(Object.assign({}, extraPaymentForm,{recurring: false, month: month, amount: val?val:0}));
        setExtraPaymentModal(true);
    }

    function openAddEditRecurringExtraPaymentModal(){
        setExtraPaymentForm(Object.assign({}, extraPaymentForm,{recurring: true}));
        setExtraPaymentModal(true);
    }

    function onExtraPaymentFormSubmit(data){
        const newExtraPayments = [...extraPayments, ExtraPayment(data.mode==='doba', data.month-1, data.amount, data.recurring?data.periodicity:0)];
        setExtraPayments(newExtraPayments);
        setExtraPaymentModal(false);
        setExtraPaymentForm(extraPaymentFormInitialState);
    }

    function removeExtraPayment(index){
        if (extraPayments && extraPayments[index]){
            const newExtraPayments = [...extraPayments.slice(0, index), ...extraPayments.slice(index + 1)]
            setExtraPayments(newExtraPayments);
        }
    }

    function onAnnualInterestRateFormSubmit(data){
        const mode = annualInterestRateForm.mode;

        //decrease month as we need index, not sequence.
        data.month = data.month===0 ? 0 : data.month -1;

        if (mode==='main'){
            onAnnualInterestRateFormSubmitMain(data);
        } else {
            onAnnualInterestRateFormSubmitSecondary(data);
        }

        setAnnualInterestRateModal(false);
        setAnnualInterestRateForm(annualInterestRateFormInitialState);
    }

    function onAnnualInterestRateFormSubmitMain(data){
        const newRates = [...annualInterestRates, InterestRate(data.month, data.rate)];
        setAnnualInterestRates(newRates);
        return newRates;
    }

    function onAnnualInterestRateFormSubmitSecondary(data){
        const newRates = [...spmInterestRatesRest, InterestRate(data.month, data.rate)];
        setSpmInterestRatesRest(newRates);
        return newRates;
    }

    function removeAnnualInterestRate(index){
        if (annualInterestRates && annualInterestRates[index]){
            const newRates = [...annualInterestRates.slice(0, index), ...annualInterestRates.slice(index + 1)]
            setAnnualInterestRates(newRates);
        }
    }

    function removeSecondaryAnnualInterestRate(index){
        if (spmInterestRatesRest && spmInterestRatesRest[index]){
            const newRates = [...spmInterestRatesRest.slice(0, index), ...spmInterestRatesRest.slice(index + 1)]
            setSpmInterestRatesRest(newRates);
        }
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state.values);
            setExtraPayments(res.contents.state.extraPayments);
            setAnnualInterestRates(res.contents.state.annualInterestRates);
            onFormSubmit(res.contents.state.values);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }

    function getSpmTypes1(){
        return [ApiService.consts().spm.spm2018, ApiService.consts().spm.spm2024]
    }
    
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state.values);
                        setExtraPayments(res.contents.state.extraPayments);
                        setAnnualInterestRates(res.contents.state.annualInterestRates);
                        onFormSubmit(res.contents.state.values);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);
    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanAdvancedHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={{
                                                 values:values,
                                                 extraPayments: extraPayments,
                                                 annualInterestRates: annualInterestRates
                                             }}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <PdfExportModal
                        values={values}
                        show={showExportPdfModal}
                        pinKey={pinKey}
                        closeaction={(res) => onLoadAdvancedExportPdfClose(res)}
                        exportPdf={exportPdf}
                        renderColumnRowSelection={(template, values) => <ColumnRowSelection template={template} values={values}/>}
                        disabledSubmitButton={values.pdfGenerating}
                    >
                        <Button margin={{right:'xsmall'}} size={'small'} disabled={!result} secondary label={<UgPdfIcon />} onClick={()=>{ setShowExportPdfModal(true) }} />
                    </PdfExportModal>
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        if (values.spm && values.spmType!==nextValue.spmType){
                            reloadBanksList();
                        }
                        if (nextValue.spm && !nextValue.bank){
                            nextValue.bank = true;
                        }
                        if (values.assuranceAmount !== nextValue.assuranceAmount){
                            nextValue.spmAssuranceAmount = nextValue.assuranceAmount;
                        }
                        if (values.dates && values.datesFirstPayment!==nextValue.datesFirstPayment){
                            setDatepickerDisabledDays(datepickerDisableNextDays(nextValue.datesFirstPayment));
                            nextValue.datesDissaving = nextValue.datesFirstPayment;
                        }
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Základná úroková sadzba  (%)" width={'full'}>
                                <TextInput name="baseInterestRate" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                    </Box>
                    <Box direction={'row-responsive'}>
                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>



                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Špecifikácia banky" width={'full'}>
                                <CheckBox name="bank" label="Výber banky" />
                            </FormField>
                        </Box>

                        {values.bank &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Banka" width={'full'}>
                                <Select options={filteredBanksList}
                                        required={true}
                                        name="bankObject"
                                        labelKey="pdfName"
                                        valueKey="id"
                                        dropHeight="large"
                                        value={values.bankObject}
                                        placeholder="Výber banky"
                                        onClose={() => setFilteredBanksList(banksList)}
                                        onSearch={searchText => {
                                            const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                                            const exp = new RegExp(escapedText, 'i');
                                            setFilteredBanksList( banksList.filter(o => {return exp.test(o.name)}) );
                                        }}
                                        emptySearchMessage={"Nič som nenašiel..."}
                                />
                            </FormField>
                        </Box>}

                        {values.bank &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Poplatok za úver (€)" width={'full'}>
                                <TextInput name="loanFee" type="number" step="0.01" min="0" required={true} />
                            </FormField>
                        </Box>}

                        {values.bank && values.bankObject && values.bankObject.canUseDecreaseByPayment &&
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Znižovanie poplatku splátkou" width={'full'}>
                                <CheckBox name="spmUseDecreateByPayment" label="Áno, použiť" />
                            </FormField>
                        </Box>}
                    </Box>


                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Štátny príspevok pre mladých" width={'full'}>
                                <CheckBox name="spm" label="Výber ŠPM" />
                            </FormField>
                        </Box>

                        {(values.spm) && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Hodnota zabezpečenia (€)" width={'full'}>
                                <TextInput name="spmAssuranceAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}
                        {(values.spm && values.spmType == ApiService.consts().spm.spm2017) && <Box basis={'auto'} direction={'row'} align={'center'}>
                            <FormField label="Úrok pre doplnkový úver (%)" width={'full'}>
                                <TextInput name="baseSpmInterestRatesRest" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>}
                        {(values.spm) && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <RadioButtonGroup gap={'medium'} required={true} margin={{horizontal: 'small', bottom:'small', top:'large'}} width={'auto'} direction={'row'} name="spmType" options={[
                                {value: ApiService.consts().spm.spm2017, label: "ŠPM 2017"},
                                {value: ApiService.consts().spm.spm2018, label: "DB 2018"},
                                {value: ApiService.consts().spm.spm2024, label: "DB 2024"},

                            ]} />
                        </Box>}



                    </Box>

                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Špecifikovať dátumy" width={'full'}>
                                <CheckBox name="dates" label="Spresniť dátumy" />
                            </FormField>
                        </Box>

                        {(values.dates) && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Prvá splátka" width={'full'}>
                                <DateInput name="datesFirstPayment" format="dd.mm.yyyy" required={true}
                                           calendarProps={FormatService.calendarDefaults()} />
                            </FormField>
                        </Box>}

                        {(values.dates && values.datesFirstPayment) && <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Začiatok čerpania" width={'full'}>
                                <DateInput name="datesDissaving" format="dd.mm.yyyy" required={true}
                                           calendarProps={FormatService.calendarDefaults({disabled: datepickerDisabledDays})} />
                            </FormField>
                        </Box>}
                    </Box>

                    <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openAddEditRecurringExtraPaymentModal()} icon={<Icons.AddCircle />} label="Pridať mimoriadnu splátku" />
                        </Box>
                        {extraPayments && extraPayments.map((payment, pindex)=>{
                            return <Card elevation="none" border={true} key={"extraPaymentCard_"+pindex} width="small" background="light-1" margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    {payment.recurring !== 0 && <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Refresh />&nbsp;&nbsp;&nbsp; <span>{FormatService.pluralify(payment.recurring, 'každý', 'každé', 'každých')} <b>{payment.recurring}</b> {FormatService.pluralify(payment.recurring, 'mesiac', 'mesiace', 'mesiacov')}</span></Box>}
                                    {payment.recurring === 0 && <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Calendar />&nbsp;&nbsp;&nbsp; <span><b>{payment.month+1}</b>. mesiac</span></Box>}
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Currency />&nbsp;&nbsp;&nbsp; <span><b>{payment.payment}</b>€</span></Box>
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Descend />&nbsp;&nbsp;&nbsp; <span>{payment.isPaymentTerm ? 'splatnosť' :'splátka' }</span></Box>
                                </CardBody>
                                <CardFooter background="light-2">
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeExtraPayment(pindex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>

                    <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openAddEditMainRateModal(1, values.baseInterestRate)} icon={<Icons.AddCircle />} label="Pridať zmenu ú.s. pre hlavný úver" />
                        </Box>
                        {annualInterestRates && annualInterestRates.map((rate, rindex)=>{
                            return <Card elevation="none" border={true} key={"mainRateModalCard_"+rindex} width="small" background="light-1" margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Calendar />&nbsp;&nbsp;&nbsp; <span><b>{parseInt(rate.month)+1}</b>. mesiac</span></Box>
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.LineChart />&nbsp;&nbsp;&nbsp; <span><b>{rate.rate}</b>%</span></Box>
                                </CardBody>
                                <CardFooter background="light-2">
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeAnnualInterestRate(rindex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>

                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <Box direction={'row-responsive'} align={'center'} flex={false} wrap margin={{vertical: 'small'}} pad={'small'} border={true} round={"6px"}>
                        <Box basis={'full'}>
                            <Button type="button" default size="small" onClick={() => openAddEditSecondaryRateModal(1, values.baseSpmInterestRatesRest)} icon={<Icons.AddCircle />} label="Pridať zmenu ú.s. pre doplnkový úver" />
                        </Box>
                        {spmInterestRatesRest && spmInterestRatesRest.map((rate, rindex)=>{
                            return <Card elevation="none" border={true} key={"secondaryRateModalCard_"+rindex} width="small" background="light-1" margin={{right: '10px', top: '10px' }}>
                                <CardBody pad="small">
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.Calendar />&nbsp;&nbsp;&nbsp; <span><b>{parseInt(rate.month)+1}</b>. mesiac</span></Box>
                                    <Box direction={'row'} align={'center'} margin={{vertical:'xsmall'}}><Icons.LineChart />&nbsp;&nbsp;&nbsp; <span><b>{rate.rate}</b>%</span></Box>
                                </CardBody>
                                <CardFooter background="light-2">
                                    <Button secondary pad={'medium'} icon={<Icons.Trash color="red" size={'medium'} />} onClick={()=>removeSecondaryAnnualInterestRate(rindex)} />
                                </CardFooter>
                            </Card>;
                        })}
                    </Box>}

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result && result.hasOwnProperty('loanResultData') && result.loanResultData.length &&
            <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>
                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className={"sticky-header"}>
                            <tr>
                                <th>Mesiac</th>
                                {values.dates && <th>Dátum splátky</th>}
                                <th>Počet dní medzi splátkami</th>
                                <th title="Úroková sadzba">ú.s. hlavný úver</th>
                                {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <th title="Úroková sadzba">ú.s. doplnkový úver</th>}
                                <th>Splátka</th>
                                {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <th>Splátka (znížená)</th>}
                                <th>Zostatok pred splátkou</th>
                                <th>Poplatok pred splátkou</th>
                                <th>Istina</th>
                                <th>Úrok predpis</th>
                                {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <th>Úrok vrátený</th>}
                                {values.spm && getSpmTypes1().includes(values.spmType) && <th>Úrok kumulatívny</th>}
                                {values.spm && getSpmTypes1().includes(values.spmType) && <th>Úrok alikvotny</th>}
                                {values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment && <th>Úrok doplatiť</th>}
                                {values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment && <th>Poplatok po splátke</th>}
                                <th>Zaplatená istina</th>
                                <th>Zaplatený úrok</th>
                                <th>Zostatok po splátke</th>
                                <th title="Mimoriadna splátka">Mim. splátka</th>
                                <th>Náklady</th>
                                <th>Celkové náklady</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result.loanResultData.map((row, index)=>{
                                return (<tr className="text-center" key={'tr_'+index}>
                                    <td>{index+1}</td>
                                    {values.dates && <td>{FormatService.dateFromTimestamp(row.paymentDate)}</td>}
                                    <td>{row.monthLength}</td>
                                    <td><div key={index+'_interestRate'}
                                             className="text-editable"
                                             onClick={() => openAddEditMainRateModal(index+1, FormatService.round(row.interestRate))}
                                    >{FormatService.round(row.interestRate)}</div></td>
                                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <td><div
                                        key={index+'_interestRateRest'}
                                        className="text-editable"
                                        onClick={() => openAddEditSecondaryRateModal(index+1, FormatService.round(row.interestRateRest))}>{FormatService.round(row.interestRateRest)}</div></td>}
                                    <td>{FormatService.round(row.payment)}</td>
                                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <td>{FormatService.round(row.paymentReduced)}</td>}
                                    <td>{FormatService.round(row.remainingPrincipalBeforePayment)}</td>
                                    <td>{FormatService.round(row.feeBeforePayment)}</td>
                                    <td>{FormatService.round(row.principalPayment)}</td>
                                    <td>{FormatService.round(row.interestPayment)}</td>
                                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && <td>{FormatService.round(row.interestReturned)}</td>}
                                    {values.spm && getSpmTypes1().includes(values.spmType) && <td>{FormatService.round(row.interestCumulative)}</td>}
                                    {values.spm && getSpmTypes1().includes(values.spmType) && <td>{FormatService.round(row.interestAliquot)}</td>}
                                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment && <td>{FormatService.round(row.interestDept)}</td>}
                                    {values.spm && values.spmType===ApiService.consts().spm.spm2017 && values.spmUseDecreateByPayment && <td>{FormatService.round(row.feeAfterPayment)}</td>}
                                    <td>{FormatService.round(row.pricnipalTotal)}</td>
                                    <td>{FormatService.round(row.interestTotal)}</td>
                                    <td>{FormatService.round(row.remainingPrincipalAfterPayment)}</td>
                                    <td><div key={index+'_extraPayment'}
                                             className="text-editable"
                                             onClick={() => openAddEditExtraPaymentModal(index+1, row.extraPayment ? row.extraPayment.mimSplatka : 0)}
                                    >{FormatService.round(row.extraPayment ? row.extraPayment.mimSplatka : 0)}</div></td>
                                    <td>{FormatService.round(row.cost)}</td>
                                    <td>{FormatService.round(row.totalCost)}</td>
                                </tr>);}
                            )}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

            {extraPaymentModal &&
            (<Layer onEsc={() => setExtraPaymentModal(false)} onClickOutside={() => setExtraPaymentModal(false)}>
                <Box pad="medium">
                    <Form
                        value={extraPaymentForm}
                        onChange={nextValue => {
                            setExtraPaymentForm(nextValue);
                        }}
                        onReset={() => { setExtraPaymentForm(extraPaymentFormInitialState); }}
                        onSubmit={({ value }) => onExtraPaymentFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>Mimoriadna splátka</Heading>

                        <FormField label="Pravidelná splátka" width={'full'}>
                            <CheckBox name="recurring" label="Áno, nastaviť pravidelnosť" />
                        </FormField>

                        {<FormField label={"Mesiac (poradie)"} width={'full'}>
                            <TextInput name="month" type="number" min="1" step="1" required={true} />
                        </FormField>}

                        {extraPaymentForm.recurring && <FormField label="Pravidelnosť (každých X mesiacov)" width={'full'}>
                            <TextInput name="periodicity" type="number" step="1" required={true} />
                        </FormField>}

                        <FormField label="Výška vkladu (€)" width={'full'}>
                            <TextInput autoFocus name="amount" type="number" min="0" step="0.001" required={true} />
                        </FormField>

                        <RadioButtonGroup gap={'medium'} margin={{vertical: 'medium'}} width={'auto'} direction={'row'}
                                          required={true} name="mode" options={[
                            {value: 'splatka',label: "Zníženie splátky"},
                            {value: 'doba',label: "Skrátenie doby splácania"},
                        ]} />

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setExtraPaymentModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

            {annualInterestRateModal &&
            (<Layer onEsc={() => setAnnualInterestRateModal(false)} onClickOutside={() => setAnnualInterestRateModal(false)}>
                <Box pad="medium">
                    <Form
                        value={annualInterestRateForm}
                        onChange={nextValue => {
                            setAnnualInterestRateForm(nextValue);
                        }}
                        onReset={() => { setAnnualInterestRateForm(annualInterestRateFormInitialState); }}
                        onSubmit={({ value }) => onAnnualInterestRateFormSubmit(value) }
                    >
                        <Heading level="3" margin={{top: "none"}}>{annualInterestRateForm.mode==='main' ? 'Zmena ú.s. hlavného úver' : 'Zmena ú.s. doplnkového úveru'}</Heading>

                        <FormField label="Mesiac (poradie)" width={'full'}>
                            <TextInput name="month" type="number" min="1" step="1" required={true} />
                        </FormField>

                        <FormField label="Úroková sadzba (%)" width={'full'}>
                            <TextInput autoFocus name="rate" type="number" min="0" step="0.01" required={true} />
                        </FormField>

                        <Box direction="row" gap="medium" margin={{top:'medium'}}>
                            <Button type="submit" primary label="Pridať" />
                            <Button onClick={() => setAnnualInterestRateModal(false)} label="Zavrieť" />
                        </Box>
                    </Form>
                </Box>
            </Layer>)}

    </Box>
    );
}

export default LoanAdvanced;