import {Box, FormField, RadioButtonGroup,TextInput} from 'grommet';

const BasicLoadAmountForm = () => {

    return(
        <>
   
            <Box basis={'1/3'} direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'}>
                    <Box direction={'row'} align={'center'} wrap>
                        <Box basis="1/2">
                            <FormField label="Dĺžka úveru" width={'large'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                        </Box>
                        <Box basis="1/2">
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>
                </Box>       
            </Box>

            <Box direction={'row-responsive'}>
                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                        <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                    </FormField>
                </Box>
            


                <Box basis={'1/3'} direction={'row'} align={'center'}>
                    <FormField label="Výška splátky (€)" width={'full'}>
                        <TextInput name="loanPayment" type="number" min="0" step="0.01" required={true} />
                    </FormField>
                </Box> 
            </Box>
        </>
    )
}

export default BasicLoadAmountForm