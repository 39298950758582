import React from 'react';
import {Box} from 'grommet';
import ThemeService from "../services/ThemeService";
import AppSidebar from "../components/AppSidebar/AppSidebar";
import {ToastContainer} from "react-toastify";

function SidebarLightLayout({mainComponent}) {
    return (
        <Box fill={true} direction={'row'} style={ThemeService.getCssVariables()}>
            <Box>
                <AppSidebar showSubMenu={false} />
            </Box>
            <Box animation='slideLeft' fill={true} overflow={'auto'}>
                {mainComponent}
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Box>
        </Box>
    );
}

export default SidebarLightLayout;
