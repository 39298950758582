import {combineReducers} from 'redux';
import {AuthReducer} from './AuthReducer.js';
import {ThemeReducer} from "./ThemeReducer";
import {CalcDetailReducer} from "./CalcDetailReducer";
import {CalcPinReducer} from "./CalcPinReducer";

const AppReducer = combineReducers({
    Auth: AuthReducer,
    Theme: ThemeReducer,
    CalcDetail: CalcDetailReducer,
    CalcPin: CalcPinReducer,
});

export default AppReducer;