import AuthService from "./AuthService";
import {DateTime} from "luxon";

const CalcDetailService = {
    getKey: function(){
        const user = AuthService.getProfile();
        if (user){
            return 'ugc_calc_details_'+user.id;
        }
        return null;
    },
    pluckInitialState: function(calc, stateKey, defaultValue=null, version=null){
        let state = this.getStateForCalc(calc);
        if (state && version && state['calcStateVersion']){
            if (version !== state['calcStateVersion']){
                if (window.debug) {
                    console.log('pluckInitialState', 'zla verzia ulozenych dat, vraciame default', version, state['calcStateVersion'], defaultValue);
                }
                return defaultValue;
            }
        }
        if (state && state['calcStateExpiresAt']){
            if (DateTime.fromISO(state['calcStateExpiresAt']) <= DateTime.now()){
                if (window.debug) {
                    console.log('pluckInitialState', 'ulozene data expirovali, vraciame default', state['calcStateExpiresAt'], DateTime.now(), defaultValue);
                }
                this.resetState(stateKey);
                return defaultValue;
            }
        }
        if (state && state[stateKey]){
            return state[stateKey];
        }
        return defaultValue;
    },
    getStateData: function(){
        const key = this.getKey();
        if (key){
            const stateData = localStorage.getItem(key);
            if (stateData){
                return JSON.parse(stateData);
            }
        }
        return null;
    },
    setStateData: function(state){
        const key = this.getKey();
        if (key){
            if (state){
                localStorage.setItem(key, JSON.stringify(state));
                return state;
            }
            localStorage.removeItem(key);
        }
        return null;
    },
    getStateForCalc: function(calc){
        const stateData = this.getStateData();
        if (stateData){
            if (stateData.hasOwnProperty(calc)){
                return stateData[calc];
            }
        }
        return null;
    },
    setStateForCalc: function(calc, state, calcStateVersion){
        let newStateData = this.getStateData();
        if (!newStateData){
            newStateData = {};
        }
        newStateData[calc] = state;
        newStateData[calc]['calcStateVersion'] = calcStateVersion;
        newStateData[calc]['calcStateExpiresAt'] = DateTime.now().plus({ days: 7 }).toISO();
        return this.setStateData(newStateData);
    },
    resetState: function(slug=null){
        if (!slug){
            if (window.debug){
                console.log('Reseting all calculators state');
            }
            this.setStateData(null);
            return true;
        }

        if (slug && this.getStateForCalc(slug)){
            if (window.debug){
                console.log('Reseting calculator state for slug ', slug);
            }
            this.setStateForCalc(slug, null);
            return true;
        }

        if (window.debug){
            console.log('Failed to reset calculator state.');
        }
        return false;
    },
};

export default CalcDetailService;