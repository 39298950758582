import './AppSidebar.css';
import {Box, Button} from 'grommet';
import React, {useState} from "react";
import {useHistory} from "react-router";
import * as Icons from "grommet-icons";
import AuthService from "../../services/AuthService";
import {useDispatch, useSelector} from "react-redux";
import {Heading, ResponsiveContext} from "grommet/es6";
import {UgCalcIcon} from "../Icons/UgCalcIcon";
import {UgSavingIcon} from "../Icons/UgSavingIcon";

function AppSidebar(props) {

    const mainMenuItems = [
        {
            label: 'Domov',
            icon: <Icons.Home />,
            id:'dashboard',
            path: '/',
            items: [
                { label: 'Nástenka', path:'/' },
                { label: 'Uložené modelovania', path:'/ulozene-modelovania' },
                { label: 'Uložené šablóny na export', path:'/ulozene-sablony-na-export' },
                { label: 'Pomocník', path:'/help' },
            ],
        },
        {
            label: 'Môj Profil',
            // icon: <UgUserIcon/>,
            icon: <Icons.Logout/>,
            hidden: true,
            id:'moj-profil',
            path:null,
            action: doLogout,
            items: [
                // { label: 'Môj Profil', path:'/moj-profil' },
                { label: 'Odhlásiť', path:null, action: doLogout },
            ],
        },
        {
            label: 'Základné kalkulačky',
            icon: <UgCalcIcon />,
            id: 'zakladne-kalkulacky',
            items: [
                { label: 'Pravidelné investovanie', path:'/pravidelne-investovanie' },
                { label: 'Jednorázové investovanie', path: '/jednorazove-investovanie' },
                { label: 'Úver', path: '/uver' },
                { label: 'Výnos DDS', path: '/dds' },
                { label: 'Výpočet RPMN', path: '/rpmn' },
                { label: 'Mzdová kalkulačka', path: '/mzdova-kalkulacka' },
                { label: 'SZČO kalkulačka', path: '/szco' },
                { label: 'Kalkulačka renty', path: '/kalkulacka-renty' },
                { label: 'Kalkulačka poistných súm', path: '/kalkulacka-poistnych-sum' },

            ],
        },
        {
            label: 'Úverové kalkulačky',
            icon: <Icons.Money />,
            id: 'uverove-kalkulacky',
            items: [
                { label: 'Základné modelovanie', path:'/zakladne-modelovanie-uveru' },
                { label: 'Preplatenie', path: '/preplatenie' },
                { label: 'Refinancovanie', path: '/refinancovanie' },
                { label: 'Porovnanie bánk', path: '/porovnanie-bank' },
                { label: 'Švajčiarska hypotéka', path: '/svajciarska-hypoteka' },
                { label: 'Klesajúca PS', path: '/klesajuce-poistne-sumy' },
                { label: 'Dopočet fixácie', path: '/fixacia-uveru' },
				{ label: 'Obrat / zisk firmy', path: '/obrat-zisk-firmy' },
            ],
        },
        {
            label: 'Sporenie',
            icon: <UgSavingIcon />,
            id: 'sporenie',
            items: [
                { label: 'Investičné produkty', path: '/investicne-produkty' },
                { label: 'Stavebné sporenie', path: '/stavebne-sporenie' },
                { label: 'EIC nákladovosť', path: '/eic-nakladovost' },
            ],
        },
    ];
    const history = useHistory();
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const size = React.useContext(ResponsiveContext);
    const [showsubmenu, setShowsubmenu] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [activePath, setActivePath] = useState(history.location && history.location.pathname ? history.location.pathname : '/');
    const [activeMenu, setActiveMenu] = useState(getActiveMenu() ? getActiveMenu().id : '/');

    function doLogout(){
        const c = window.confirm('Naozaj sa chcete odhlásiť?');
        if (c===true){
            AuthService.doLogout();
        }
    }

    function doRedirect(title, redirectPath){
        if (window.debug) {
            console.log('doRedirect',title,redirectPath);
        }
        history.title = title;
        document.title = title;
        if (redirectPath){
            history.push(redirectPath);
            setActivePath(redirectPath);

            // propagating calc save detail - this triggers animation and redraw,
            // so its logical place to place... not really, i dont know what im doing :/
            dispatch({type:'calc/save'});
        }
    }

    function resolveNavAction(sub){
        sub.action ? sub.action() : doRedirect(sub.label, sub.path);
        setExpanded(false);
    }

    React.useEffect(() => {
        if (window.debug){
            console.log(props);
        }
        if (props && props.hasOwnProperty('showSubMenu')){
            setShowsubmenu(props.showSubMenu);
        } else {
            setShowsubmenu(true);
        }
    }, [props]);

    React.useEffect(() => {
        if (window.debug){
            console.log('SidebarCurrentPath:', history.location.pathname, ' AppHistory:', history  );
        }
        document.title = history && history.title ? history.title : 'Umbrella Group Kalkulačka';
    }, [history, history.title]);

    function getActiveMenu(){
        let activeItem = mainMenuItems.filter((menu)=>{
            return menu.items.reduce((old, item)=>{ return ((item.path===activePath || (activePath.startsWith(item.path)&&activePath.split('/').length>1)) ? true : old); }, false)
        });
        return activeItem ? activeItem.pop() : null;
    }

    const ActiveMenuTab = () => {
        let activeItem = mainMenuItems.filter((menu)=>{
            return (menu.id===activeMenu || (menu.id.startsWith(activeMenu)&&menu.id.split('/').length>1) );
        });
        activeItem = activeItem ? activeItem.pop() : null;

        return <Box pad={'medium'}>
            <Heading level={2} margin={{right: size==='small'?'30%':'none'}}>{activeItem ? activeItem.label : document.title}</Heading>
            {activeItem && activeItem.items && activeItem.items.map((sub, index)=>{
                return <a href={sub.path} key={index} className={['nav-link', (sub.path===activePath || (activePath.startsWith(sub.path)&&activePath.split('/').length>2) ?'active':'')].join(' ')}
                          onClick={(e) => { e.preventDefault(); resolveNavAction(sub); }}>{sub.label}</a>;
            })}
        </Box>;
    };

    return (
        <Box id="sidebar-wrap" className={size==='small'?'mobile':''} direction={'row'} align="center" text="light" fill={'vertical'}>
            <Box id="sidebar" className={[size==='small'?'mobile':'', expanded?'active':'']} width={'475px'} direction={'row'} align="center" text="light" fill={'vertical'}>
                <Box id="sidebar-icons" width={'130px'} background={'primary'} fill={'vertical'} align="center" justify={'between'}>
                    <Box width={'full'}>
                        {state.Theme && <div id="sidebar-logo"><img src={state.Theme.logoDark} alt="" /></div>}
                        {mainMenuItems.map((m)=>{
                            if (m.hidden){ return ''; }
                            return <Button key={m.id} icon={m.icon} onClick={()=>{
                                    if (m.path || m.action){
                                        resolveNavAction(m);
                                    } else {
                                        setActiveMenu(m.id);
                                    }
                                }} className={[activeMenu===m.id?'active':'']} />;
                        })}
                    </Box>
                    <Box width={'full'}>
                        {state.Theme && state.Theme.pool !== 'UG' && <div id="sidebar-bottom-logo"><img src={"assets/img/logo-umbrella.svg"} alt="" /></div>}
                        {mainMenuItems.filter(m=>m.hidden).map((m)=>{
                            return <Button key={m.id} icon={m.icon} onClick={()=>{
                                if (m.path || m.action){
                                    resolveNavAction(m);
                                } else {
                                    setActiveMenu(m.id);
                                }
                            }} className={[activeMenu===m.id?'active':'']} />;
                        })}
                    </Box>
                </Box>
                <Box id="sidebar-menu" width={'370px'} background={'white'} fill={'vertical'}>
                    <div id="sidebar-close" className={size==='small'?'mobile':''}>
                        <Button icon={<Icons.Close />} onClick={()=>setExpanded(false)} />
                    </div>

                    <ActiveMenuTab />
                </Box>
            </Box>
            <div id="sidebar-burger" className={size==='small'?'mobile':''}>
                <Button icon={<Icons.Menu />} onClick={()=>setExpanded(!expanded)} />
            </div>
        </Box>
    );
}

export default AppSidebar;
