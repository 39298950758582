import FormatService from "../../services/FormatService";

function LoanFixationPdfExport({values,result,fixationsList,chart}) {
    let inputs = [
        {key: 'loanAmount', label: 'Výška úveru (€)', value: values.loanAmount},
        {key: 'period', label: 'Dĺžka úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <p>Kalkulačka slúži na výpočet sadzby, ktorú keď po uplynutí kratšej fixácie dostaneme, budú náklady po skončení
            dlhšej fixácie totožné.</p>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    
     <div class="row-flex mb3">
        <div class="col-12">
            <h3 style="margin-bottom:10px">Obdobie fixácie</h3>
            <div class="row">
                 ${fixationsList.map((f) => {
                    return `<div class="col-sm-3">
                                <div class="card">
                                     <div class="card-body" style="padding:10px;">
                                          <div class="row">
                                              <div class="col-sm-6"><span class="icon-calendar"></span> Dĺžka:</div>
                                              <div class="col-sm-6"><b>${f.fixation}</b> r</div>
                                          </div>
                                          <div class="row">
                                               <div class="col-sm-6"><span class="icon-graph"></span> Úrok:</div>
                                               <div class="col-sm-6"><b>${f.loanRate}</b> %</div>
                                          </div>
                                     </div>
                                </div>
                           </div>`;                                                         
                }).join('')}   
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="clearfix"></div>
   
    <div class="row-flex mb3">
        <div class="col-12">
            <table class="text-center">
                <tr>
                    <td><b>Roky fixácie</b></td>
                    ${result.map((row, r)=>`<th key={'header_'+r} class='text-center'>${row[row.length-1].fixation1 + ' r'}</th>`).join('')}
                </tr>
                ${result.map((row, r)=>{
                     return  `<tr key={'row'+r}>
                                 <th>${row[row.length-1].fixation1 + ' r'}</th>
                                 ${row.map((column, c)=>`<td key={'cell_'+r+'_'+c} class=${column && column.inputRate ? 'table-cell-important' : ''}>${column ? FormatService.round(column.loanRate) + ' %' : ''}</td>`).join('')}
                             </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default LoanFixationPdfExport;