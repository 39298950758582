import {Box, Button, Form, FormField, Heading, RadioButtonGroup, Text, TextInput} from 'grommet';
import React, {useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import {useDispatch, useSelector} from "react-redux";
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanInsuranceHelp from "./LoanInsuranceHelp";
import LoanInsurancePdfExport from "./LoanInsurancePdfExport";
import FormatService from "../../services/FormatService";
import HighchartsReact from "highcharts-react-official";
import CalcDetailService from "../../services/CalcDetailService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import PdfExportModal from '../SavedPdfExportTemplates/PdfExportModal';
import LoanInsuranceColumnRowSelection from './LoanInsuranceColumnRowSelection';

function LoanInsurance(props) {
    const state = useSelector((state) => state);
    const calcStateKey = 'LoanInsurance';
    const calcStateVersion = 1;

    HighchartsExporting(Highcharts);

    const defaultValues = {
        title: 'Poistné krytie pre prípad smrti',
        period: 30,
        periodUnits: 'y',
        annualInterestRate: 3,
        loanAmount: 70000,
        decreasingInsuranceCover: 70000,
        maxUnderinsuredValue: 0,

        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const chartOptionsInitialState = (state && state.CalcDetail && state.CalcDetail[calcStateKey] && state.CalcDetail[calcStateKey]['chartOptions']) ? state.CalcDetail[calcStateKey]['chartOptions'] : null;

    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [activeRequest, setActiveRequest] = useState(false);
    const [chartOptions, setChartOptions] = useState(chartOptionsInitialState);
    const [chart, setChart] = useState(null);

    function trySetChart(chart){
        if (chart && chart.renderer && chart.renderer.forExport===true){
            return false;
        }
        setChart(chart);
    }

    const [showExportPdfModal, setShowExportPdfModal] = useState(false);


    function saveCalcDetail(values, result, chartOptionsInitialState){
        const calcDetailState = {
            values:values,
            result:result,
            chartOptionsInitialState:chartOptionsInitialState,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, calcDetailState);
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    useEffect(() => {
        saveCalcDetail(values, result, chartOptions);
        // eslint-disable-next-line
    }, [values, result, chartOptions]);

    useEffect(() => {
        updateChartOptions(result);
        // eslint-disable-next-line
    }, [result]);

    function updateChartOptions(_results){
        let res = _results ? _results : result;
        if (!res && !chartOptions){
            console.log('skipping chart update');
            return;
        }
        const options = {
            title: { text: ''},
            credits: { enabled: false},
            exporting: {
                sourceWidth: 1000,
                sourceHeight: 400,
            },
            yAxis: { title: {text: "Hodnota"} },
            xAxis: { categories: res.results.map((val)=>{ return val.month; }) },
            tooltip: {
                shared: true,
                useHTML: true,
				formatter: function () {
                    let tooltip = '<small>' + this.x + '</small><table>';
                        this.points.forEach(graph => {
                            tooltip += `
                                <tr>
                                    <td>
                                        <span style="display:inline-block;height: 10px; width: 10px; border-radius: 50%; background: linear-gradient(${graph.color.stops[0][1]}, ${graph.color.stops[1][1]})"></span>
                                        <small>${graph.series.name}: </small>
                                    </td>
                                    <td style="text-align: right">
                                        <b><small>${FormatService.round(graph.y, 0)}</b></small>
                                    </td>
                                </tr>`;
                        })

                    tooltip += "</table>"

                    return tooltip;
                }
            },
            series: [
                {
                    name: 'Kombinácia pripoistení',
                    type: 'area',
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, Highcharts.Color(ThemeService.getTheme().primaryColor).setOpacity(0.9).get('rgba')],
                            [1, Highcharts.Color(ThemeService.getTheme().primaryColor).setOpacity(0.2).get('rgba')]
                        ]
                    },
                    data: res.results.map((val)=>{ return val.combination; })
                },
                {
                    name: 'Zostatok úveru po splátke',
                    type: 'area',
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, Highcharts.Color(ThemeService.getTheme().contrastColor).setOpacity(0.9).get('rgba')],
                            [1, Highcharts.Color(ThemeService.getTheme().contrastColor).setOpacity(0.2).get('rgba')]
                        ]
                    },
                    data: res.results.map((val)=>{ return val.remainingPrincipal; })
                }
            ],
        }
        setChartOptions(options);
    }
    function onFormSubmit(values){
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        const reqData = {
            annualInterestRate: values.annualInterestRate,
            periodInMonths: periodMonths,
            loanAmount: values.loanAmount,
            decreasingInsuranceCover: values.decreasingInsuranceCover,
            maxUnderinsuredValue: values.maxUnderinsuredValue
        };

        setActiveRequest(true);
        ApiService.call('post', '/v2/loan/loanInsurance', reqData)
            .then((res) => {
                    setResult(res);
                    setActiveRequest(false);
                },
                (error) => {
                    setActiveRequest(false);
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'klesajuce-poistne-sumy';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function exportPdf(options){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanInsurancePdfExport.generatePdf({values:values,result:result,chart:chart, options}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values,{pdfGenerating: false}));
            })
            .catch(console.error);
    }

    function onLoanInsuranceExportModal(res){
        if(res){
            toast.success('Šablóna bola uložená', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setShowExportPdfModal(false);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

    const [savedState, setSavedState] = useState(null);
const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanInsuranceHelp/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>

                    <PdfExportModal
                        show={showExportPdfModal}
                        closeaction={(res) => onLoanInsuranceExportModal(res)}
                        values={values}
                        pinKey={pinKey}
                        exportPdf={exportPdf}
                        renderColumnRowSelection={(template) => <LoanInsuranceColumnRowSelection template={template} />}
                        disabledSubmitButton={values.pdfGenerating}
                    >
                        <Button margin={{right:'xsmall'}} size={'small'} secondary disabled={!result} label={ <UgPdfIcon />} onClick={()=>{setShowExportPdfModal(true)}} />
                    </PdfExportModal>
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={nextValue => {
                        if (nextValue.loanAmount!==values.loanAmount){
                            nextValue.decreasingInsuranceCover = nextValue.loanAmount;
                        }
                        setValues(nextValue);
                    }}
                    onReset={() => {
                        setValues(defaultValues);
                        setResult(null);
                        setChartOptions(null);
                        setChart(null);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >

                    <Heading level={3} margin={{bottom: 'none'}}>Úver</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Výška úveru (€)" width={'full'}>
                                <TextInput name="loanAmount" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Ročná úroková sadzba (%)" width={'full'}>
                                <TextInput name="annualInterestRate" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label='Dĺžka úveru' width={'auto'}>
                                <TextInput name="period" type="number" min="1" step="1" required={true} />
                            </FormField>
                            <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'column'} name="periodUnits" options={[
                                {value: 'm',label: "Mesiacov"},
                                {value: 'y',label: "Rokov"},
                            ]} />
                        </Box>
                    </Box>

                    <Heading level={3} margin={{bottom: 'none'}}>Poistné krytie</Heading>
                    <Box direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Klesajúca PS (€)" width={'full'}>
                                <TextInput name="decreasingInsuranceCover" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Max. akceptované podpoistenie (€)" width={'full'}>
                                <TextInput name="maxUnderinsuredValue" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>

            {result!==null &&
            <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                <Box direction={'row-responsive'} wrap  round={'small'} background={'white'} align={'center'} alignContent={'between'}>

                    <Box basis={'full'} direction={'row-responsive'} pad={'xsmall'} margin={{top: 'medium', bottom: 'xsmall'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                        <Box pad="small" alignContent={'between'} basis={'3/4'}>
                            <Heading level={2} fill={true} margin={'none'}>Konštantná PS</Heading>
                            <Text>Výška poistnej sumy pre poistenie smrti s konštantnou poistnou sumou</Text>
                        </Box>
                        <Box pad="small" align={'end'} basis={'1/4'}>
                            <Heading level={2} fill={true} margin={'none'}>
                                <strong>{FormatService.round(result.constantInsuranceCover)}</strong> <span>€</span>
                            </Heading>
                        </Box>
                    </Box>

                    {chartOptions &&
                    <Box basis={'full'} margin={{vertical: 'small'}}>
                        <Box pad={'medium'} round={'small'} border={true} background={'white'}>
                            <Box direction="column" basis={'full'} margin={{vertical: 'medium'}} round={'small'}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptions}
                                    callback={trySetChart}
                                />
                            </Box>
                        </Box>
                    </Box>
                    }

                    <div className="table-responsive">
                        <table className="table styled-table">
                            <thead className="sticky-header">
                            <tr>
                                <th></th>
                                <th colSpan={1}>Úver</th>
                                <th colSpan={2}>Pripoistenie klesajúcej PS</th>
                                <th colSpan={2}>Kombinácia klesajúcej a konštantnej PS</th>
                            </tr>
                            <tr>
                                <th>Mesiac</th>
                                <th>Zostatok po splátke</th>
                                <th>Výška PS</th>
                                <th>Podpoistenie</th>
                                <th>Výška PS</th>
                                <th>Podpoistenie/Prepoistenie</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result && result.results && result.results.length && result.results.map((row, index)=>{
                                return <tr key={"result_"+index} className={'text-center'}>
                                    <td>{row.month}.</td>
                                    <td>{FormatService.round(row.remainingPrincipal)}</td>
                                    <td>{FormatService.round(row.linearDecreasingInsuranceCover)}</td>
                                    <td>{FormatService.round(row.underInsuredValue)}</td>
                                    <td>{FormatService.round(row.combination)}</td>
                                    <td>{FormatService.round(row.underOverInsurance)}</td>
                                </tr>;
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
            }

        </Box>
    );
}

export default LoanInsurance;