import React from 'react';
import { Blank } from 'grommet-icons';

export const UgReloadIcon = props => (
    <Blank {...props}>
            <path
                fillRule="nonzero"
                d="M12 0c1.951 0 4.062.441 5.913 1.352 3.803 1.871 6.086 5.435 6.086 10.647 0 3.613-1.608 6.966-4.333 9.231a1.332 1.332 0 11-1.704-2.05 9.312 9.312 0 003.371-7.181c0-4.182-1.695-6.826-4.597-8.254-1.465-.721-3.175-1.078-4.736-1.078A1.333 1.333 0 0112 0z"
            ></path>
            <path
                fillRule="nonzero"
                d="M12 24c-1.951 0-4.062-.441-5.913-1.352C2.284 20.776 0 17.212 0 12 0 5.373 5.373 0 12 0a1.333 1.333 0 010 2.667A9.332 9.332 0 002.667 12c0 4.182 1.694 6.827 4.597 8.255 1.465.721 3.175 1.078 4.736 1.078A1.333 1.333 0 0112 24z"
            ></path>
            <path
                fillRule="nonzero"
                d="M24 21.026c0 .683-.515 1.247-1.178 1.324l-.155.009h-4.923a1.334 1.334 0 01-1.325-1.178l-.009-.155v-4.923a1.334 1.334 0 012.658-.156l.009.156v3.589h3.59c.683 0 1.247.515 1.324 1.178l.009.156z"
            ></path>
    </Blank>
);