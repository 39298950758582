import FormatService from "../../services/FormatService";

function LoanRefinancePdfExport({values,result,resultRefinance,chart}) {
    let inputs = [
        {key: 'loanAmount', label: 'Výška pôvodného úveru (€)', value: values.loanAmount},
        {key: 'period', label: 'Dĺžka pôvodného úveru', value: () => { return (values.period.toString() + (values.periodUnits==='y'?'r':'m')); }},
        {key: 'annualInterestRate', label: 'Úroková sadzba pôvodného úveru (%)', value: values.annualInterestRate},

        {key: 'monthOfPayment', label: 'Mesiac vyplatenia', value: values.monthOfPayment},
        {key: 'fee', label: 'Poplatok (€)', value: values.fee},
        {key: 'feePercent', label: 'Poplatok (%)', value: values.feePercent},

        {key: 'loanAmountRefinance', label: 'Výška refinančného úveru (€)', value: values.loanAmountRefinance},
        {key: 'periodRefinance', label: 'Dĺžka refinančného úveru', value: () => { return (values.periodRefinance.toString() + (values.periodUnitsRefinance==='y'?'r':'m')); }},
        {key: 'annualInterestRateRefinance', label: 'Úroková sadzba refinančného úveru (%)', value: values.annualInterestRateRefinance},
    ];

    return (`
    <div class="row-flex mb3">
        <div class="col-12">
            <p>Kalkulačka slúži na porovnanie výhodnosti refinancovania súčasného úveru (Pôvodný úver) novým úverom (Refinančný úver)
            s ktorým sú spojené poplatky. Výška refinančného úveru je automaticky dopočítaná tak, aby úver pokryl vyplatenie
            pôvodného úveru a všetky poplatky.</p>
        </div>
    </div>
    <div class="row-flex mb3">
        <div class="col-12">
            <table>
                ${inputs.map((i) => {
                    return `<tr>
                             <th>${i.label}</th>
                             <td class="text-sm-right">${typeof i.value === 'function' ? i.value() : i.value}</td>
                            </tr>`;
                }).join('')}
            </table>
        </div>
    </div>
   
        <div class="row-flex mb3">
        
        <div class="col-12">
        <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Pôvodný úver</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Splátka (€)</th>
              <td class='text-right'>${FormatService.round(result.loanPayment)}</td>
             </tr>
             <tr>
              <th>Celkové náklady v ${result.table.length}. mesiaci (€)</th>
              <td class='text-right'>${FormatService.round(result.table[result.table.length-1].baseLoanResult.totalCost)}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>

          </div>
          
         <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Refinančný úver</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Splátka (€)</th>
              <td class='text-right'>${FormatService.round(result.loanPaymentRefinance)}</td>
             </tr>
             <tr>
              <th>Celkové náklady v ${result.table.length}. mesiaci (€)</th>
              <td class='text-right'>${FormatService.round(result.table[result.table.length-1].refinanceLoanResult.totalCost)}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>
          </div>
          
          <div class="col-sm-4">
         <div class="card">
          <div class="card-block p-sm-0">
           <h4 class="card-title m-sm-2">Zhrnutie</h4>
           <table class='table styled-table'>
            <tbody>
             <tr>
              <th>Bod zlomu</th>
              <td class='text-right'>${result.breakEvenMonth}</td>
             </tr>
             <tr>
              <th>Úspora v ${result.table.length}. mesiaci (€)</th>
              <td class='text-right'>${FormatService.round(result.table[result.table.length-1].baseLoanResult.totalCost - result.table[result.table.length-1].refinanceLoanResult.totalCost)}</td>
             </tr>
            </tbody>
           </table>
          </div>
         </div>
          </div>
        </div>

                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table styled-table">
                            <thead>
                                <tr>
                                    <td>&nbsp;</td>
                                    <th colspan='3'>Pôvodný úver</th>
                                    <th colspan='3'>Refinančný úver</th>
                                </tr>
                                <tr>
                                    <th>Mesiac</th>
                                    <th>Splátka</th>
                                    <th>Zostatok úveru</th>
                                    <th>Náklady spolu</th>
                                    <th>Splátka</th>
                                    <th>Zostatok úveru</th>
                                    <th>Náklady spolu</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${result && result.table && result.table.map((row)=>{
                                    return `<tr class='text-center'>
                                        <td>${row.month}.</td>
                                        <td>${FormatService.round(row.baseLoanResult.payment)}</td>
                                        <td>${FormatService.round(row.baseLoanResult.balance)}</td>
                                        <td>${FormatService.round(row.baseLoanResult.totalCost)}</td>
                                        <td>${!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.payment)}</td>
                                        <td>${!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.balance)}</td>
                                        <td>${!row.refinanceLoanResult ? '' : FormatService.round(row.refinanceLoanResult.totalCost)}</td>
                                    </tr>`;
                                    }).join('')
                                }
                            </tbody>
                        </table>
                    </div>
              
            </div>
        </div>
    
    <div class="row-flex">
        <div class="col-12 text-center">
            ${chart ? chart.getSVG() : ''}
        </div>
    </div>
    `);
}

export default LoanRefinancePdfExport;