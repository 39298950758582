import {Box, Button, Heading, Anchor, Image} from 'grommet';
import React from "react";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import * as Icons from "grommet-icons";

function Help(props) {

    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>Pomocník</Heading>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>Táto sekcia je určená na zodpovedanie všeobecnejších otázok
                    týkajúcich sa práce s celým súborom kalkulačiek. Ak potrebujete pomôcť s konkrétnou kalkulačkou,
                    viď. otázka „Čo znamenajú ikony v pravom hornom rohu?“.</div>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading level={5} margin={{bottom: 'none'}}>Čo je to Nástenka?</Heading>
            </Box>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>
                    Je to úvodná obrazovka, ktorá sa vám zobrazí vždy keď UG Calculator spustíte.
                    <p>Obsahuje súbor odkazov na konkrétne modelovania a kalkulačky:</p>
                    <ul>
                        <li>naposledy použitá kalkulačka</li>
                        <li>posledné tri vaše uložené modelovania</li>
                        <li>tri najpoužívanejšie kalkulačky vrámci všetkých používateľov (vyhodnocuje sa automaticky)</li>
                        <li>vaše obľúbené (pripnuté) kalkulačky</li>
                    </ul>
                    Nástenka takisto obsahuje odkaz na aplikáciu UG Calculator, ktorú si môžete stiahnuť do svojho mobilu (a v obmedzenej forme aj akýkoľvek z vašich klientov).
                </div>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading level={5} margin={{bottom: 'none'}} style={{'max-width':'none'}}>Ako sa prepínam medzi jednotlivými časťami kalkulačky?</Heading>
            </Box>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>
                    V ľavej časti obrazovky môžete v menu prepínať medzi základnými časťami kalkulačky, ktorými sú:
                    <ul>
                        <li>nástenka</li>
                        <li>zoznam uložených modelovaní</li>
                        <li>kalkulačky, ktoré sú rozdelené do 3 hlavných kategórií - základné, úverové a investičné.
                            Pod týmito kategóriami nájdete už konkrétne kalkulačky.</li>
                    </ul>
                    {/*<Image fit="cover" src="assets/img/bg_umbrella.png"></Image>*/}
                </div>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading level={5} margin={{bottom: 'none'}} style={{'max-width':'none'}}>Čo znamenajú ikony v pravom hornom rohu?</Heading>
            </Box>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                Pri každej kalkulačke nájdete horné menu, ktoré obsahuje 4 základné ikony:
            </Box>
            <Box pad={'small'}>
                <Box pad={{horizontal: 'small'}} gap={'small'} direction={'row-responsive'} align={'center'} justify={'left'} margin={{bottom: 'none'}}>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} /><b>Načítanie ponuky:</b> Ak ste si predtým uložili konkrétny výpočet/modelovanie v danej kalkulačke, kliknutím na túto ikonu a výberom si ho viete načítať.
                </Box>
                <Box pad={{horizontal: 'small'}} gap={'small'} direction={'row-responsive'} align={'center'} justify={'left'}>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} /><b>Uloženie ponuky:</b> Aktuálny výpočet/modelovanie si viete uložiť ako nový záznam/ponuku, alebo nahradiť už uložený záznam aktuálnym.
                </Box>
                <Box pad={{horizontal: 'small'}} gap={'small'} direction={'row-responsive'} align={'center'} justify={'left'}>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={<UgPdfIcon />} /><b>PDF:</b> Po vykonaní výpočtu v kalkulačke si môžete daný výpočet/modelovanie vygenerovať do PDF.
                </Box>
                <Box pad={{horizontal: 'small'}} gap={'small'} direction={'row-responsive'} align={'center'} justify={'left'}>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={<UgHelpIcon />} /><b>Otáznik:</b> Po kliknutí na ikonu sa vám v pravej časti obrazovky vysunie bočný panel v ktorom je popísaná funkcionalita aktuálnej kalkulačky.
                </Box>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading level={5} margin={{bottom: 'none'}} style={{'max-width':'none'}}>Ako si pripnem kalkulačku tak aby som ju mal prístupnú priamo z nástenky?</Heading>
            </Box>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Box gap={'small'} direction={'row'}>
                    <Icons.Pin margin={{right: '20px'}} color={'dark'} />
                    <Icons.Pin color={'active'} />
                </Box>
            </Box>
            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>
                    Túto ikonku nájdete vždy hore hneď vedľa názvu každej kalkulačky. Po kliknutí na ňu sa ikonka zafarbí.
                    Znamená to, že kalkulačku budete mať pripnutú medzi obľúbenými kalkulačkami na hlavnej nástenke, budete k nej mať teda vždy o trochu rýchlejší prístup.
                </div>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>
                    Zrušiť pripnutie viete opätovným kliknutím na ikonku priamo na danej kalkulačke, prípadne kliknutím na ikonku v červenom krúžku na nástenke v časti pripnutých kalkulačiek.
                </div>
            </Box>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading level={5} margin={{bottom: 'none'}} style={{'max-width':'none'}}>Mám otázku alebo návrh na zlepšenie</Heading>
            </Box>
            <Box pad={'small'} margin={{bottom: '20px'}} direction={'row-responsive'} align={'center'} justify={'between'}>
                <div>
                    V prípade otázok alebo zaujímavých nápadov a návrhov ich napíšte na <Anchor href={'mailto:horacek@umbrellagroup.sk'}>horacek@umbrellagroup.sk</Anchor>
                </div>
            </Box>

        </Box>
    );
}

export default Help;
