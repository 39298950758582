import {Box, Button, Form, Heading, RadioButtonGroup, Text} from 'grommet';
import React, {useCallback, useEffect, useState} from "react";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import Helpbar from "../../components/Helpbar/Helpbar";
import AuthService from "../../services/AuthService";
import ThemeService from "../../services/ThemeService";
import LoanHelp from "./LoanHelp";
import LoanPdfExport from "./LoanPdfExport";
import CalcDetailService from "../../services/CalcDetailService";
import FormatService from "../../services/FormatService";
import {UgSpinner} from "../../components/Spinner/UgSpinner";
import {UgPdfIcon} from "../../components/Icons/UgPdfIcon";
import {UgHelpIcon} from "../../components/Icons/UgHelpIcon";
import * as Icons from "grommet-icons";
import _, { forEach } from "lodash";
import {useDispatch} from "react-redux";
import CalcPinService from "../../services/CalcPinService";
import SavedStatesService from "../../services/SavedStatesService";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import {toast} from "react-toastify";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import BasicPaymentForm from './BasicPaymentForm';
import BasicInterestForm from './BasicInterestForm';
import BasicLoadAmountForm from './BasicLoanAmountForm';
import BasicLoanAmountNbsForm from './BasicLoanAmuntNbsForm';

function Loan(props) {
    const calcStateKey = 'Loan';
    const calcStateVersion = 4;

    HighchartsExporting(Highcharts);

    function Household(isCoapplicant=false, kids=0, income=1000, age=30, coapplicantAge=30, coapplicantIncome=1000){
        return {
            kids: kids,
            income: income,
            age: age,
            isCoapplicant: isCoapplicant,
            coapplicantIncome: coapplicantIncome,
            coapplicantAge: coapplicantAge
        };
    }

    function HouseholdDTO(kids, income, age, isCoapplicant, coapplicantAge, coapplicantIncome ){
        const data = {
            applicant : {
                age: age,
                income: income
            }
        };

        if(kids){
            data.applicant.kids = kids;
        }

        if(isCoapplicant){
            data.coapplicant = {
                income: coapplicantIncome,
                age: coapplicantAge
            }
        }

        return data;
    }

    const defaultValues = {
        title: 'Úver',
        mode: 'basic_payment',
        period: 30,
        periodUnits: 'y',

        annualInterestRate: 1,
        bankId: 'tatra',
        loanAmount: 100000,
        loanPayment: 350,
        spm: false,
        spmAssuranceAmount: 50000,

        incomeReserve: 0.4,
        kids: 0,
        age: 30,
        income: 1000,
        isCoapplicant: false,
        coapplicantIncome: 1000,
        coapplicantAge: 30,
        monthCosts: 0,
        otherLoans: 0,
        fixationGreater10years: false,
        maxLoanLength: 30,
        currentMaxAge: 30,
        households: [],

        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };

    function generateMaxLoanLengthOptions(selectedMaxAge){
        const options = [];
        const MAX = Math.min(75 - selectedMaxAge, 40)

        for(let i=1; i<=MAX; i++){
            let label = "";
            if(i == 1){
                label = "rok"
            }else if(i== 2 || i == 3 || i == 4){
                label = "roky"
            }else{
                label = "rokov"
            }
            options.push({id: i, name: `${i} ${label}`})
        }

        return options;
    }

    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const banksInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'banks', [], calcStateVersion);
    const banksFilterInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'banksFilter', [], calcStateVersion);

    const [maxLoanLengthOptions, setMaxLoanLengthOptions] = useState(generateMaxLoanLengthOptions(valuesInitialState.currentMaxAge));
    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);
    const [banksList, setBanksList] = useState(banksInitialState);
    const [filteredBanksList, setFilteredBanksList] = useState(banksFilterInitialState);

    let modeOptions = [
        {value: 'basic_payment',label: "Splátka úveru"},
        {value: 'basic_interest',label: "Úrok úveru"},
        {value: 'basic_loan_amount',label: "Výška úveru"},
        {value: 'basic_loan_amount_nbs',label: "Maximálny úver podľa NBS"}
    ];

    let userId = AuthService.getProfile().hasOwnProperty('id') ? AuthService.getProfile().id : AuthService.getProfile().user_id;
    if ([6,1114,9027].includes(parseInt(userId))){
        // modeOptions = [...modeOptions, {value: 'basic_loan_amount_nbs_2',label: "Maximálny úver podľa NBS new"}];
    }

    function saveCalcDetail(values, result, banksList, filteredBanksList){
        const calcDetailState = {
            values:values,
            result:result,
            banksList:banksList,
            filteredBanksList:filteredBanksList,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    const reloadBanksList = useCallback(() => {
        ApiService.getLoanBanksList(values.spm===true ? ApiService.consts().spm.spm2017 : ApiService.consts().spm.none)
            .then((res) => {
                    setBanksList(res);
                    setFilteredBanksList(res);
                    saveCalcDetail(values, result, res, res);
                },
                (error) => {
                    ErrorService.fromApi(error);
                })
        // eslint-disable-next-line
    }, [values.spm]);

    useEffect(() => {
        reloadBanksList();
    }, [reloadBanksList]);

    function getUrl(mode){
        switch(mode){
            case 'basic_payment':               
            case 'basic_interest':
            case 'basic_loan_amount':
                return '/v2/loan/'+mode
            case 'basic_loan_amount_nbs':
                return '/v3/loan/'+mode
            default:
                return ""
        }
    }

    function isFormValid(values){
        if(values.mode == "basic_loan_amount_nbs"){
            if(values.period > values.maxLoanLength ){
                window.alert("Dĺžka úveru nesmie byť vačšia ako Maximálna dĺžka úveru")
                return false
            }
        }
        return true;
    }

    function onFormSubmit(values){
        if(!isFormValid(values)) return;
        const periodMonths = values.periodUnits === 'm' ? values.period : values.period*12;

        let reqData = {};
        switch (values.mode){
            case 'basic_payment':
                reqData = {
                    periodInMonths: periodMonths,
                    annualInterestRate: values.annualInterestRate,
                    bankId: values.bankId,
                    loanAmount: values.loanAmount,
                };
                if (values.spm){
                    reqData['spm'] = {
                        assuranceAmount: values.spmAssuranceAmount
                    };
                }
                break;
            case 'basic_interest':
                reqData = {
                    periodInMonths: periodMonths,
                    loanAmount: values.loanAmount,
                    loanPayment: values.loanPayment
                }; break;
            case 'basic_loan_amount':
                reqData = {
                    annualInterestRate: values.annualInterestRate,
                    periodInMonths: periodMonths,
                    loanPayment: values.loanPayment
                }; break;

            case 'basic_loan_amount_nbs':
                /*
                let households = [Household(values.isCoapplicant, values.kids)];
                if (values.households && values.households.length){
                    households = [...households, ...values.households];
                }
                */
               let households = [
                    HouseholdDTO(
                        values.kids,
                        values.income,
                        values.age,
                        values.isCoapplicant,
                        values.coapplicantAge,
                        values.coapplicantIncome
                    )
                ];

                values.households.forEach(household => {
                    households.push(
                        HouseholdDTO(
                            household.kids,
                            household.income,
                            household.age,
                            household.isCoapplicant,
                            household.coapplicantAge,
                            household.coapplicantIncome
                        )
                    )
                })


                reqData = {
                    annualInterestRate: values.annualInterestRate,
                    periodInMonths: periodMonths,
                    incomeReserve: values.incomeReserve,
                    monthCosts: values.monthCosts,
                    otherLoans: values.otherLoans,
                    fixationGreater10years: values.fixationGreater10years,                   
                    households: households,
                    maxPeriodInMonths: values.maxLoanLength * 12
                }; break;
            default: break;
        }

        setValues(Object.assign({}, values, {activeRequest: true}));
        
        ApiService.call('post', getUrl(values.mode), reqData)
            .then((res) => {
                    setResult(res);
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    saveCalcDetail(values, res, banksList, filteredBanksList);
                },
                (error) => {
                    setValues(Object.assign({}, values, {activeRequest: false}));
                    ErrorService.fromApi(error);
                });
    }

    const pinKey = 'uver';
    const dispatch = useDispatch();
    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }

    function addHousehold(){
        if (!values.households){
            values.households = [];
        }
        values.households = [...values.households, Household()];

        const newValues = Object.assign({}, values);
        setValues(newValues);
        saveCalcDetail(newValues, result, banksList, banksList);
    }

    function removeHousehold(index){
        if (!values.households){
            values.households = [];
        }
        values.households = values.households.filter((household, hi)=>{
            return hi!==index;
        });

        const newValues = Object.assign({}, values);
        setValues(newValues);
        saveCalcDetail(newValues, result, banksList, banksList);
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: LoanPdfExport({values:values,result:result,banksList:banksList,chart:null}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }


    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }

     const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);


    function renderFormFields(){
        switch(values.mode){
            case 'basic_payment':
                return <BasicPaymentForm  values={values} setFilteredBanksList={setFilteredBanksList} filteredBanksList={filteredBanksList} banksList={banksList}/>
            case 'basic_interest':
                return <BasicInterestForm />
            case 'basic_loan_amount':
                return <BasicLoadAmountForm />
            case 'basic_loan_amount_nbs':
                return <BasicLoanAmountNbsForm values={values} maxLoanLengthOptions={maxLoanLengthOptions} removeHousehold={removeHousehold} addHousehold={addHousehold}/>
            default:
                return <></>
        }
    }
    const formFields = useCallback(renderFormFields, [values, banksList, filteredBanksList]) ; 


    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><LoanHelp mode={values.mode}/></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={()=>{exportPdf()}} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

           

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                    value={values}
                    onChange={(nextValue, event) => {
                        if (values.mode!==nextValue.mode){
                            setResult(null);
                        }

                        //dont allow negative values
                        for (const [key, value] of Object.entries(nextValue)) {
                            if (!isNaN(value) && value<0){
                                nextValue[key] = 0;
                            }
                        }

                        if(values.mode == "basic_loan_amount_nbs"){
                            let maxAge = values.currentMaxAge;

                            if(maxAge < nextValue.age){
                                maxAge = nextValue.age
                            }

                            if(nextValue.isCoapplicant && maxAge < nextValue.coapplicantAge){
                                maxAge = nextValue.coapplicantAge
                            }

                            values.households.forEach(household => {
                                if(maxAge < household.age){
                                    maxAge = household.age
                                }

                                if(household.isCoapplicant && maxAge < household.coapplicantAge){
                                    maxAge = household.coapplicantAge
                                }
                            })

                            if(maxAge > values.currentMaxAge){
                                const options = generateMaxLoanLengthOptions(maxAge);
                                values.currentMaxAge = maxAge;
                                if(!options.length){
                                    nextValue.maxLoanLength = 0
                                }else if(options.length < values.maxLoanLength){
                                    nextValue.maxLoanLength = options[options.length -1].id                                    
                                }
                                setMaxLoanLengthOptions(options)
                            }
                            
                        }

                        const cleanState = {};
                        _.forIn(nextValue, (value, key) => {
                            _.set(cleanState, key, value);
                        });

                        setValues(cleanState);
                        saveCalcDetail(cleanState, result, banksList, banksList);
                    }}
                    onReset={() => {
                        let init = defaultValues;
                        init.mode = values.mode;
                        setValues(init);
                        setResult(null);
                        saveCalcDetail(defaultValues, null, banksList, banksList);
                    }}
                    onSubmit={({ value }) => onFormSubmit(value) }
                >
                    <Box direction={'row-responsive'}>
                        <Box basis={'auto'} round={'xsmall'} margin={{bottom: 'large', top:'medium'}}>
                            <RadioButtonGroup direction={props.size==='small'? 'column' : 'row'} gap={'medium'} name="mode" options={modeOptions} />
                        </Box>
                    </Box>

                    {formFields()}

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>
            </Box>


            {result && result.hasOwnProperty('result') && ['basic_payment','basic_interest','basic_loan_amount'].includes(values.mode) &&
                <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{vertical: 'medium'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                    <Box pad="small" alignContent={'between'} basis={'3/4'}>
                        {['basic_payment'].includes(values.mode) && <Text>Výška splátky</Text>}
                        {['basic_interest'].includes(values.mode) && <Text>Ročná úroková sadzba</Text>}
                        {['basic_loan_amount'].includes(values.mode) && <Text>Výška úveru</Text>}
                    </Box>
                    <Box pad="small" align={'end'} basis={'1/4'}>
                        <Heading level={2} fill={true} margin={'none'}>
                            <strong>{FormatService.round(result.result)}
                                {['basic_payment'].includes(values.mode) && <span>€</span>}
                                {['basic_interest'].includes(values.mode) && <span>%</span>}
                                {['basic_loan_amount'].includes(values.mode) && <span>€</span>}
                            </strong>
                        </Heading>
                    </Box>
                </Box>
            }

            {result && result.hasOwnProperty('loanAmount') && result.loanAmount && ['basic_loan_amount_nbs'].includes(values.mode) &&
                <Box margin={{vertical: 'medium'}}>
                    <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{vertical: 'xsmall'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                        <Box pad="small" alignContent={'between'} basis={'1/2'}>
                            <Text>Maximálna výška úveru (príjmová rezerva)</Text>
                        </Box>
                        <Box pad="small" align={'end'} basis={'1/2'}>
                            <Heading level={2} fill={true} margin={'none'}><strong>{FormatService.round(result.loanAmount)}<span>€</span></strong> </Heading>
                        </Box>
                    </Box>
                    {result.globalDti &&
                    <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{vertical: 'xsmall'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                        <Box pad="small" alignContent={'between'} basis={'1/2'}>
                            <Text>Maximálna výška úveru (DTI {FormatService.round(result.globalDti.coefficient, 4)})</Text>
                        </Box>
                        <Box pad="small" align={'end'} basis={'1/2'}>
                            <Heading level={2} fill={true} margin={'none'}><strong>{FormatService.round(result.globalDti.value)}<span>€</span></strong></Heading>
                            {result.globalDti.valueWithoutOtherLoans && values.otherLoans && values.otherLoans > 0 ? <Box align={'end'} direction={'row'} gap={'xsmall'}>
                                <Text>Pri zohľadení výšky zostávajúcich úverov</Text>
                                <Heading level={4} fill={true} margin={'none'}><strong>{FormatService.round(result.globalDti.valueWithoutOtherLoans)}<span>€</span></strong></Heading>
                            </Box>: ""}
                        </Box>
                    </Box>}

                    <Box direction={props.size==='small'? 'column' : 'row'} pad={'xsmall'} margin={{vertical: 'xsmall'}} border={true} round={'small'} background={'light-2'} align={'center'} alignContent={'between'}>
                        <Box pad="small" alignContent={'between'} basis={'1/2'}>
                            <Text>Maximálna výška úveru (DTI 8)</Text>
                        </Box>
                        <Box pad="small" align={'end'} basis={'1/2'}>
                            <Heading level={2} fill={true} margin={'none'}><strong>{FormatService.round(result.dti8)}<span>€</span></strong></Heading>
                            {result.dti8WithoutOtherLoans && values.otherLoans && values.otherLoans > 0 ? <Box align={'end'} direction={'row'} gap={'xsmall'}>
                                <Text>Pri zohľadení výšky zostávajúcich úverov</Text>
                                <Heading level={4} fill={true} margin={'none'}><strong>{FormatService.round(result.dti8WithoutOtherLoans)}<span>€</span></strong></Heading>
                            </Box>: ""}
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    );
}

export default Loan;