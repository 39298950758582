const FormatService = {
    round: function(val, places=2) {

        return isNaN(val) ? '-' : (Math.round((val + Number.EPSILON) * Math.pow(10, places)) / Math.pow(10, places));
    },
    pluralify: function(val, one, some, many) {
        const v = parseInt(val);
        //4, mesiac, mesiace, mesiacov
        if (v===1){
            return one;
        } else if (v<5){
            return some;
        } else if (v>4){
            return many;
        }
        return one;
    },
    dateFromTimestamp: function(val, IntlFormatOptions = null) {
        let _format = IntlFormatOptions ? IntlFormatOptions : {
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        return new Intl.DateTimeFormat("sk-SK", _format).format(val).replaceAll(' ', '');
    },
    calendarDefaults: function(overrides = {}){
        return Object.assign({},
            {
                firstDayOfWeek: 1,
                daysOfWeek: true,
                locale: 'sk-SK',
                size: "medium"
            }, overrides);
    },
    dateWithoutTimezone: function(date){
        const d = new Date(date);
        const userTimezoneOffset = d.getTimezoneOffset() * 60000;
        return new Date(d.getTime() - userTimezoneOffset);
    }
};

export default FormatService;