import {Box} from 'grommet';
import React from "react";

function LoanHelp(props) {

    return (<Box>
        <h2>Popis</h2>

        {props && props.mode && props.mode==='basic_loan_amount_nbs' && <div>
            <p>Kalkulačka slúži na výpočet maximálnej výšky úveru, ktorú môže žiadateľ dostať v bankách podľa nariadení NBS o maximálnom úvere podľa DSTI
                (debt service to income, minimálna príjmová rezerva) a o maximálnom úvere podľa DTI (debt to income, pomer výšky úverov voči čistému príjmu).</p>
            <p>Žiadateľ dostane v bankách maximálne menšiu z prvých dvoch výstupných hodnôt. V prípade aspoň jedného žiadateľa nad 40 rokov so splatnosťou úveru
                dlhšou ako do 64. roku tohto žiadateľa sa zobrazí aj tretia výstupná hodnota pre DTI 8 (platná v prípade, že by sa splatnosť skrátila maximálne do 64. roku žiadateľa).</p>
            <h2>Postup</h2>
            <ol type={'1'}>
                <li>Zvoľte parametre úveru - dĺžku úveru, úrokovú sadzbu, fixáciu</li>
                <li>Zadajte mesačné záväzky
                    <ul>
                        <li>Pri spotrebných úveroch započítajte aktuálnu výšku splátky</li>
                        <li>Pri úveroch na bývanie započítajte vyššiu z týchto dvoch hodnôt: 1. aktuálna výška splátky 2. splátka úveru pri maximálnej splatnosti podľa zákona a sadzbou o 2% vyššou</li>
                        <li>Z kreditných kariet a povolených prečerpaní rátajte podľa zákona minimálne 3% z limitu (banky však rátajú väčšinou 5% z limitu)</li>
                        <li>Môžete tiež prirátať mesačné alimenty, ktoré žiadatelia platia. Nie sú súčasťou vzorcu podľa zákona, banky s týmto výdavkom však ratajú.</li>
                    </ul>
                </li>
                <li>Výška zostávajúcich úverov je súčet úverových záväzkov, ktoré žiadateľ nevypláca (pred) novým úverom, ktorého limit zisťuje.</li>
                <li>Zvoľte príjmovú rezervu. Príjmová rezerva je štandardne 40%, banka však môže vybrané úvery posúdiť s príjmovou rezervou 30%.</li>
                <li>Zadajte vek a príjem žiadateľa, v pripade spolužiadateľa z jednej domácnosti aj vek a príjem spolužiadateľa. Zadajte počet detí vočí ktorým má žiadateľ/spolužiadateľ vyživovaciu povinnosť.</li>
                <li>V prípade, že v úverovom prípade vystupujú žiadatelia z ďalšich domácností pridajte ďaľšiu domácnosť a zopakujte predchádzajúci krok aj pre ňu.</li>
                <li>Kliknite na <b>Vypočítať</b></li>
            </ol>

            <h2>Výstup</h2>
            <b>Maximálna výška úveru (príjmová rezerva)</b>
            <p>Splátka maximálneho úveru = (celkový príjem – mesačné výdavky) x (1-zvolená príjmová rezerva) – mesačné záväzky (Počíta sa s úrokovou sadzbou o 2% vyššou,
                ako je uvedené vo vstupoch, maximálne 6%. Pri fixácií vyššej, nie rovnej ako 10 rokov, sa počíta s úrokovou sadzbou vyššou o 1%.)</p>

            <p>Mesačné výdavky sa spočítavajú:</p>
            <ul>
                <li>za žiadateľa životné minimum automaticky</li>
                <li>ak je zaškrnutý spolužiadateľ tak životné minimum za ďalšiu spoločne posudzovanú plnoletú fyzickú osobu</li>
                <li>životné minimum za každé dieťa voči ktorej má žiadateľ/spolužiadateľ vyživovaciu povinnosť</li>
                <li>životné minimá za žiadaťeľov, spolužiadateľov a deti z iných domácností, ak takí vstupujú do úveru</li>
            </ul>

            <b>Maximálna výška úveru (maximálne DTI)</b>
            <p>Maximálne DTI sa vypočíta podľa vzorca stanoveného NBS do ktorého vstupuje vek a príjem jednotlivých žiadateľov.</p>
            <p>Maximálny úver = maximálne DTI x 12 mesiacov x Celkový príjem</p>

            <b>Maximálna výška úveru (DTI 8)</b>
            <p>Maximálny úver = 8 rokov x 12 mesiacov x Celkový príjem</p>
            <p>Vypisuje sa iba v prípade aspoň jedného žiadateľa nad 40 rokov so splatnosťou úveru dlhšou ako do 64. roku tohto žiadateľa. Keďže v tychto prípadoch sa zo zákona maximálne DTI znižuje,
                je to informatívna hodnota DTI pre prípad, že by sa skrátila splatnosť úveru maximálne do 64. roku všetkých žiadateľov.</p>

            <br /><br />
        </div>}

        {(!props || !props.mode || props.mode!=='basic_loan_amount_nbs') && <div>
            <p>Kalkulačka slúži na výpočet maximálneho úveru na základe nariadenia NBS (TYP D) alebo jedného z troch parametrov úveru (TYP A,B,C), a to:</p>
            <ol type={'A'}>
                <li>výšku splátky (chceme vedieť aká je splátka úveru, ak vieme splatnosť, výšku úveru a sadzbu)</li>
                <li>úrok úveru (chceme vedieť aký máme úrok na úvere)</li>
                <li>výšku úveru (chceme vedieť akú výšku úveru by sme si mohli zobrať, ak vieme splácať určitú sumu (splátku), pri zvolenej úrokovej sadzbe a splatnosti)</li>
            </ol>
            <h2>Postup</h2>
            <ol type={'1'}>
                <li>Zvoľte typ kalkulačky, t.j. zvoľte parameter, ktorý chceme vypočítať.</li>
                <li>Zvoľte parametre úveru - výšku úveru, splatnosť, úrokovú sadzbu, príp. splátku. a ostatné parametre</li>
                <li>Kliknite na <b>Vypočítať</b></li>
            </ol>
            <h2>Výstup</h2>
            <p>Výstup kalkulačky je počítaný parameter.</p>
            <br /><br />
        </div>}


    </Box>);
}

export default LoanHelp;
