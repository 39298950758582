import React from 'react';
import { Blank } from 'grommet-icons';

export const UgPdfIcon = props => (
    <Blank {...props}>
            <path
                fillRule="nonzero"
                d="M15.045 0c.241 0 .474.08.663.225l.108.095 6.546 6.545c.17.17.278.392.31.628l.009.143v13.091a3.272 3.272 0 01-3.08 3.267l-.192.006H8.499a4.363 4.363 0 01-4.363-4.364 1.091 1.091 0 012.182 0c0 1.151.89 2.093 2.019 2.176l.162.006h10.91a1.09 1.09 0 001.083-.964l.007-.127V8.089l-5.907-5.907H7.409c-.56 0-1.021.421-1.084.964l-.007.127v5.454a1.091 1.091 0 01-2.175.127l-.007-.127V3.273A3.272 3.272 0 017.216.006L7.409 0h7.636z"
            ></path>
            <path
                fillRule="nonzero"
                d="M4.202 16.909v-2.028h.925c.681 0 1.188-.156 1.52-.466.331-.311.497-.742.497-1.294 0-.537-.152-.951-.456-1.242-.305-.291-.763-.436-1.374-.436H2.506v5.466h1.696zm.414-3.136h-.414v-1.219h.481c.316 0 .531.06.645.179a.604.604 0 01.172.436.547.547 0 01-.198.435c-.131.113-.36.169-.686.169zm5.944 3.136c.301 0 .636-.05 1.007-.149.271-.072.524-.217.76-.434a2.26 2.26 0 00.554-.81c.133-.321.199-.775.199-1.359 0-.373-.044-.726-.134-1.059a2.381 2.381 0 00-.432-.876 1.952 1.952 0 00-.755-.578c-.305-.134-.704-.201-1.199-.201H8.05v5.466h2.51zm-.407-1.242H9.74v-2.986h.421c.437 0 .751.107.939.32.189.214.284.611.284 1.19 0 .437-.042.754-.127.949-.084.195-.201.332-.35.41-.15.078-.401.117-.754.117zm5.519 1.242v-2.233h2.118v-1.104h-2.118v-.955h2.479v-1.174h-4.176v5.466h1.697z"
            ></path>
            <path
                fillRule="nonzero"
                d="M16.136 1.091v4.364c0 .559.421 1.02.964 1.083l.127.007h4.363v2.182h-4.363a3.272 3.272 0 01-3.267-3.08l-.006-.192V1.091h2.182z"
            ></path>
    </Blank>
);