import { Box, Button, CheckBox, Form, FormField, Heading, RadioButtonGroup, TextInput } from "grommet";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Icons from "grommet-icons";
import Helpbar from "../../components/Helpbar/Helpbar";
import { UgHelpIcon } from "../../components/Icons/UgHelpIcon";
import { UgPdfIcon } from "../../components/Icons/UgPdfIcon";
import { UgSpinner } from "../../components/Spinner/UgSpinner";
import CalcDetailService from "../../services/CalcDetailService";
import CalcPinService from "../../services/CalcPinService";
import _ from "lodash";
import ApiService from "../../services/ApiService";
import ErrorService from "../../services/ErrorService";
import InsuredSumsHelp from "./InsuredSumsHelp";
import InsuredSumsPdfExport from "./InsuredSumsPdfExport";
import ThemeService from "../../services/ThemeService";
import AuthService from "../../services/AuthService";
import LoadSavedStatesModal from "../SavedStates/LoadSavedStatesModal";
import AddEditSavedStatesModal from "../SavedStates/AddEditSavedStatesModal";
import SavedStatesService from "../../services/SavedStatesService";
import { toast } from "react-toastify";
import FormatService from "../../services/FormatService";

const InsuredSums = (props) => {

    const calcStateKey = 'InsuredSums';
    const calcStateVersion = 1;
    const pinKey = 'kalkulacka-poistnych-sum';

    const sumsOptions = [
        {value: 'prijmi',label: "príjmu"},
        {value: 'vydavky',label: "výdavkov"},
    ];
    const defaultValues = {
        title: 'Kalkulačka poistných súm',

        //form data
        sums: sumsOptions[0].value,

        monthValue: 1000,
        obligations: 0,
        grossSalary: null,

        partner: false,
        partnerMonthValue: 1000,
        partnerObligations: 0,
        partnerGrossSalary: null,

        children: false,
        jointObligations: 0,
        insurance: false,

        //utils
        activeRequest: false,
        openHelp: false,
        pdfGenerating: false,
    };
    const valuesInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'values', defaultValues, calcStateVersion);
    const resultInitialState = CalcDetailService.pluckInitialState(calcStateKey, 'result', null, calcStateVersion);
    const dispatch = useDispatch();

    function togglePinCalc(key){
        if (CalcPinService.isCalcPinned(key)){
            CalcPinService.unpinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        } else {
            CalcPinService.pinCalc(key).then((res)=>{
                CalcPinService.setStateData(res);
                dispatch({type:'calcpin/save'});
            });
        }
    }
    function saveCalcDetail(values, result){
        const calcDetailState = {
            values:values,
            result:result,
        };
        if (window.debug){
            console.log('Saving ', calcStateKey, );
        }
        CalcDetailService.setStateForCalc(calcStateKey, calcDetailState, calcStateVersion);
    }

    function onFormSubmit(values){

        let reqData = {
            insuredPerson: {
                inputValue: values.monthValue,
                obligations: values.obligations,
            },
            kids: values.children
        }

        if(values.partner){
            reqData.partner = {
                inputValue: values.partnerMonthValue,
                obligations: values.partnerObligations
            }

            reqData.jointObligations = values.jointObligations
        }

        if(values.insurance){
            reqData.insuredPerson.grossSalary = values.grossSalary;

            if(values.partner){
                reqData.partner.grossSalary = values.partnerGrossSalary;
            }
        }

        setValues(Object.assign({}, values, {activeRequest: true}));
        
        ApiService.call('post', "/v2/iv", reqData)
            .then((res) => {
                setResult(res);
                setValues(Object.assign({}, values, {activeRequest: false}));
                saveCalcDetail(values, res,);
            },
            (error) => {
                setValues(Object.assign({}, values, {activeRequest: false}));
                ErrorService.fromApi(error);
            });

    }
    const [values, setValues] = useState(valuesInitialState);
    const [result, setResult] = useState(resultInitialState);

    const monthValueLabelCallback = useCallback(getMonthValueLabel,[values.sums])
    function getMonthValueLabel(){
        let label = "";
        if(values.sums == "prijmi"){
            label = "Mesačný čistý príjem";
        }else{
            label = "Mesačné výdavky";
        }
        return label;
    }

    function exportPdf(){
        setValues(Object.assign({}, values, {pdfGenerating: true}));
        const data = {
            pool: ThemeService.getTheme().pool,
            title: values.title,
            subtitle: '',
            filename: 'calc-export',
            content: InsuredSumsPdfExport.generatePdf({values:values,result:result}),
        };
        fetch(`${process.env.REACT_APP_INTRANET_API_URL}/ugcalculator/generatePdf`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getAccessToken()
            },
            body: data ? JSON.stringify(data) : null
        })
            .then(res => res.blob())
            .then(res => {
                const file = window.URL.createObjectURL(res);
                window.open(file, '_blank');
                setValues(Object.assign({}, values, {pdfGenerating: false}));
            })
            .catch(console.error);
    }
    const monthValueLabel = monthValueLabelCallback();

    const [savedState, setSavedState] = useState(null);
    const [showLoadModal, setShowLoadModal] = useState(false);
    function onLoadSaveStateModalClose(res=null){
        setShowLoadModal(false);
    }

    const [showModal, setShowModal] = useState(false);
    function onAddEditSaveStateModalClose(res=null){
        if (res){
            setSavedState(res);
            setValues(res.contents.state);
            onFormSubmit(res.contents.state);
        }
        setShowModal(false);
    }


    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.id){
            SavedStatesService.getDetail(props.match.params.id)
                .then((res) => {
                        if (!res || !res.contents || !res.contents.state){
                            setSavedState(null);
                            if (window.debug) {
                                console.log('Neviem otvoriť stav, pretože dáta sú neplatné.', savedState, calcStateVersion);
                            }
                            return false;
                        }
                        if (res.contents && res.contents.version && calcStateVersion){
                            if (res.contents.version !== calcStateVersion){
                                setSavedState(null);
                                if (window.debug) {
                                    console.log('loadSavedState', 'zla verzia ulozenych dat, neviem otvorit', res, calcStateVersion);
                                }
                                return false;
                            }
                        }
                        toast.success('Modelovanie bolo načítané', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setSavedState(res);
                        setValues(res.contents.state);
                        onFormSubmit(res.contents.state);
                    },
                    (error) => {
                        setValues(Object.assign({}, values, {activeRequest: false}));
                        ErrorService.fromApi(error);
                    });
        }
    }, [props.match.params.id]);
    return (
        <Box flex={false} margin={{'horizontal': 'small'}}>
            <Helpbar open={values.openHelp} closeaction={ () => setValues(Object.assign({}, values, {openHelp: false})) }><InsuredSumsHelp /></Helpbar>

            <Box pad={'small'} direction={'row-responsive'} align={'center'} justify={'between'}>
                <Heading>{values.title} <a style={{cursor:'pointer'}} onClick={()=>{togglePinCalc(pinKey)}}><Icons.Pin color={CalcPinService.isCalcPinned(pinKey) ? 'active' : 'dark'} /></a></Heading>
                <Box direction={'row'}>
                    <LoadSavedStatesModal show={showLoadModal} pinkey={pinKey} closeaction={(res)=>onLoadSaveStateModalClose(res)}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Catalog />} onClick={()=>setShowLoadModal(true)} />
                    </LoadSavedStatesModal>
                    <AddEditSavedStatesModal show={showModal}
                                             closeaction={(res)=>onAddEditSaveStateModalClose(res)}
                                             state={values}
                                             version={calcStateVersion}
                                             savedState={savedState}
                                             pinkey={pinKey}>
                        <Button margin={{right:'xsmall'}} size={'small'} secondary label={<Icons.Save />} onClick={()=>setShowModal(true)} />
                    </AddEditSavedStatesModal>
                    <Button margin={{right:'xsmall'}} size={'small'} secondary label={values.pdfGenerating ? <UgSpinner /> : <UgPdfIcon />} disabled={!result && !values.pdfGenerating} onClick={exportPdf} />
                    <Button margin={'none'} size={'small'} secondary label={<UgHelpIcon />} onClick={()=>{setValues(Object.assign({}, values, {openHelp: true}))}} />
                </Box>
            </Box>

            <Box flex={false} pad={'medium'} round={'small'} border={true} background={'white'}>
                <Form
                        value={values}
                        onChange={(nextValue, event) => {

                            const cleanState = {};
                            _.forIn(nextValue, (value, key) => {
                                _.set(cleanState, key, value);
                            });

                            setValues(cleanState);
                            saveCalcDetail(cleanState, result);
                        }}
                        onReset={() => {
                        let init = defaultValues;
                        setValues(init);
                        setResult(null);
                        saveCalcDetail(defaultValues, null);
                    }}
                        onSubmit={({ value }) => onFormSubmit(value) }
                >
                    <Box basis={'1/3'} direction={'row'}>
                        <Box direction={'row'} align={'center'} wrap>
                            <FormField label="Poistné sumy podľa" width={'auto'}>
                                <RadioButtonGroup gap={'small'} margin={{left: 'small'}} width={'auto'} direction={'row'} name="sums" options={sumsOptions} />
                            </FormField>
                        </Box>
                    </Box>

                    <Box basis={'1/3'} direction={'row-responsive'}>
                        <Box basis={'1/4'} direction={'row'}>
                            <Box direction={'row'} align={'center'} wrap>
                                <FormField label="Poistený" width={'large'}>
                                    <CheckBox checked disabled={true} label="Áno" />
                                </FormField>
                            </Box>
                        </Box>
                        <Box basis={'1/4'} direction={'row'} align={'center'}>
                            <FormField label={monthValueLabel} width={'full'}>
                                <TextInput name="monthValue" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        <Box basis={'1/4'} direction={'row'} align={'center'}>
                            <FormField label="Suma finančných záväzkov" width={'full'}>
                                <TextInput name="obligations" type="number" step="0.01" required={true} />
                            </FormField>
                        </Box>

                        {values.insurance &&
                            <Box basis={'1/4'} direction={'row'} align={'center'}>
                                <FormField label="Hrubý mesačný príjem" width={'full'}>
                                    <TextInput name="grossSalary" type="number" step="0.01" required={true} />
                                </FormField>
                            </Box>
                        }
                
                    </Box>

                    <Box basis={'1/3'} direction={'row-responsive'}>
                        <Box basis={'1/4'} direction={'row'}>
                            <Box direction={'row'} align={'center'} wrap>
                                <FormField label="Partner poisteného" width={'large'}>
                                    <CheckBox name="partner" label="Áno" />
                                </FormField>
                            </Box>
                        </Box>
                        <Box basis={'1/4'} direction={'row'} align={'center'}>
                            <FormField label={monthValueLabel} width={'full'}>
                                <TextInput name="partnerMonthValue" type="number" step="0.01" required={true}  disabled={!values.partner} />
                            </FormField>
                        </Box>

                        <Box basis={'1/4'} direction={'row'} align={'center'}>
                            <FormField label="Suma finančných záväzkov" width={'full'}>
                                <TextInput name="partnerObligations" type="number" step="0.01" required={true} disabled={!values.partner} />
                            </FormField>
                        </Box>

                        {values.insurance &&
                            <Box basis={'1/4'} direction={'row'} align={'center'}>
                                <FormField label="Hrubý mesačný príjem" width={'full'}>
                                    <TextInput name="partnerGrossSalary" type="number" step="0.01" required={true} disabled={!values.partner} />
                                </FormField>
                            </Box>
                        }
                
                    </Box>

                    <Box basis={'1/3'} direction={'row-responsive'}>
                        <Box basis={'1/3'} direction={'row'}>
                            <Box direction={'row'} align={'center'} wrap>
                                <FormField label="Deti" width={'large'}>
                                    <CheckBox name="children" label="Áno"  />
                                </FormField>
                            </Box>
                        </Box>
                        {values.partner &&
                            <Box basis={'1/3'} direction={'row'} align={'center'}>
                                <FormField label="Suma spoločných finančných záväzkov" width={'full'}>
                                    <TextInput name="jointObligations" type="number" step="0.01" required={true} />
                                </FormField>
                            </Box>                    
                        }


                        <Box basis={'1/3'} direction={'row'} align={'center'}>
                            <FormField label="Poistenie PN/DNL" width={'full'}>
                                <CheckBox name="insurance" label="Áno" />
                            </FormField>
                        </Box>
                
                    </Box>

                    <Box direction="row" gap="medium" margin={{top:'medium'}}>
                        <Button type="submit" primary label="Vypočítať" disabled={values.activeRequest} />
                        <Button type="reset" label="Nastaviť predvolené" />
                    </Box>
                </Form>

            </Box>

            {result!==null &&

                <Box flex={false} margin={{vertical: 'medium'}} pad='medium' round='small' border={true} background={'white'}>
                    <div className="table-responsive table-unclipped">
                        <table className="table styled-table">
                            <thead>
                                <tr>
                                    <td>&nbsp;</td>
                                    <th colSpan="2">Poistený</th>
                                    {result.partner && <th colSpan="2">Partner poisteného</th>}
                                    {result.kids &&  <th colSpan="2">Deti</th> }
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Optimálna PS</th>
                                    <th>Minimálna PS</th>
                                    {result.partner &&
                                    <>
                                        <th>Optimálna PS</th>
                                        <th>Minimálna PS</th>
                                    </>
                                    }

                                    {result.kids &&
                                    <>
                                        <th>Optimálna PS</th>
                                        <th>Minimálna PS</th>
                                    </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Smrť akákoľvek - klesajúca PS</th>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.deathOptimal,2)}</td>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.deathMinimal,2)}</td>
                                    {result.partner &&
                                    <>
                                        <td className="text-center">{FormatService.round(result.partner.deathOptimal,2)}</td>
                                        <td className="text-center">{FormatService.round(result.partner.deathMinimal,2)}</td>
                                    </>
                                    }
                                    {result.kids &&
                                    <>
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                    </>
                                    }

                                </tr>
                                <tr>
                                    <th>Invalidita od 40%/70% - klesajúca PS</th>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.invalidityOptimal,2)}</td>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.invalidityMinimal,2)}</td>
                                    {result.partner &&
                                        <>
                                        <td className="text-center">{FormatService.round(result.partner?.invalidityOptimal, 2)}</td>
                                        <td className="text-center">{FormatService.round(result.partner?.invalidityMinimal, 2)}</td>
                                        </>
                                    }
                                    {result.kids &&
                                        <>
                                            <td className="text-center">-</td>
                                            <td className="text-center">-</td>
                                        </>
                                    }
              
               
                                </tr>

                                <tr>
                                    <th>Kritické choroby</th>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.criticalDiseasesOptimal,2)}</td>
                                    <td className="text-center">{FormatService.round(result.insuredPerson?.criticalDiseasesMinimal , 2)|| "-"}</td>
                                    {result.partner &&
                                        <>
                                            <td className="text-center">{FormatService.round(result.partner?.criticalDiseasesOptimal, 2)}</td>
                                            <td className="text-center">{FormatService.round(result.partner?.criticalDiseasesMinimal, 2)}</td>
                                        </>
                                    }
                                    {result.kids &&
                                        <>
                                            <td className="text-center">{FormatService.round(result.kids?.criticalDiseasesOptimal, 2)}</td>
                                            <td className="text-center">{FormatService.round(result.kids?.criticalDiseasesMinimal , 2)}</td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <th>Trvalé následky úrazom</th>
                                    <td className="text-center">{FormatService.round(result.insuredPerson.permanentInjuryConsequencesOptimal,2)}</td>
                                    <td className="text-center">{FormatService.round(result.insuredPerson?.permanentInjuryConsequencesMinimal,2)}</td>
                                    {result.partner &&
                                        <>
                                            <td className="text-center">{FormatService.round(result.partner?.permanentInjuryConsequencesOptimal, 2)}</td>
                                            <td className="text-center">{FormatService.round(result.partner?.permanentInjuryConsequencesMinimal, 2)}</td>
                                        </>
                                    }
                                    {result.kids &&
                                        <>
                                            <td className="text-center">{FormatService.round(result.kids?.permanentInjuryConsequencesOptimal, 2)}</td>
                                            <td className="text-center">{FormatService.round(result.kids?.permanentInjuryConsequencesMinimal, 2)}</td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <th>PN / DNL</th>
                                    <td colSpan={2} className="text-center">{result.insuredPerson.pnDnl === null ? "-" : `${FormatService.round(result.insuredPerson.pnDnl,0)}/deň`}</td>
                                    {result.partner &&
                                        <td colSpan={2} className="text-center">{result.partner?.pnDnl === null ? "-" : `${FormatService.round(result.partner?.pnDnl, 0)}/deň`}</td>
                                    }
                                    {result.kids &&
                                        <td colSpan={2} className="text-center">-</td>
                                    }
                                </tr>
                                
                            </tbody>
                        </table>
                        <br/>
                        <br/>           
                        Poistné riziká Smrť akákoľvek a Invalidita od 40%/70% je možné poistiť aj spoločne v balíčku 1. rizika. V takom prípade bude plnené to riziko, ktoré nastane prvé (a druhé zaniká). Odporúčaná je invalidita od 40%, výber závisí od finančných možností klienta.
                 
                    </div>
                </Box>
            }

        </Box>
        
    )
}

export default InsuredSums;