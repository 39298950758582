import ThemeService from "../services/ThemeService";

const themeSettings = ThemeService.getTheme();
const initialState = themeSettings ? themeSettings : ThemeService.initialTheme();

export function ThemeReducer(state = initialState, action) {
    switch (action.type) {
        case 'theme/change': return ThemeService.getTheme()
        default:
            return state
    }
}